import React from "react";
import {
  ProfileItem,
  StyledCardBody,
  StyledCardText,
  StyledImg,
  StyledLink,
} from "./styled";

type props = {
  label: string;
  url: string;
  icon: string;
  hasError?: boolean;
  error?: string;
};

const ProfileButton: React.FC<props> = ({ url, label, icon }) => {
  return (
    <ProfileItem>
      <StyledLink href={url}>
        <StyledCardBody>
          <StyledCardText>
            <StyledImg src={icon} alt="" />
            {label}
          </StyledCardText>
        </StyledCardBody>
      </StyledLink>
    </ProfileItem>
  );
};

export default ProfileButton;
