import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import Collapse from "../../components/atoms/colapso";
import Footer from "../../components/organisms/footer";
import NavBar from "../../components/organisms/navbar";
import { FaMapMarkerAlt } from 'react-icons/fa';

//import useAuthenticate from "../../hooks/useAuthenticate";
import {
  Container,
  Content,
  H3,
  H5,
  Location,
  P,
  QRCodeImg,
  Span,
  Title,
  TournamentActions,
  TournamentBanner,
  TournamentContainer,
  TournamentContainerBanner,
  TournamentContent,
  TournamentDescription,
  TournamentEnrollmentLink,
  TournamentEnrollmentLinkEndpage,
  TournamentLink,
  EditalContainer,
  EditalContent,
  EditalTitle
} from "./styles";
// import tabelaPeso from "../../assets/images/tournaments/toledo-international-open-brazilian-jiu-jitsu-2023/tabepla-de-peso.jpeg";
import tabelaPeso from "../../assets/images/tournaments/copa-amizade-de-jiu-jitsu-2024/tabela-copa-amizade-de-jiu-jitsu-2024.jpg";
import qrCodecopaAmizadeDeJiuJitsu2024 from "../../assets/images/tournaments/copa-amizade-de-jiu-jitsu-2024/qr-coce--copa-amizade-de-jiu-jitsu-2024.jpg";

//Patrocinio
import torneio from "../../assets/images/sponsors/torneio-facil.png";
import ticTac from "../../assets/images/sponsors/tic-tac.png"
import construbelMateraisParaConstrucao from "../../assets/images/sponsors/construbel-materais-para-construcao.png"
import donJuanFastBurguer from "../../assets/images/sponsors/don-juan-fast-burguer.png"
import duffConveniencia from "../../assets/images/sponsors/duff-conveniencia.png"
import centroDeNeurocirurgiaEColuna from "../../assets/images/sponsors/centro-de-neurocirurgia-e-coluna.jpeg"
import vilaNovaMadeiras from "../../assets/images/sponsors/vila-nova-madeiras.png"
import academiaSportFit from "../../assets/images/sponsors/academia-sport-fit.png"
import dezTelecom from "../../assets/images/sponsors/dez-telecom.png"
import draFernandaFurlanHarmonizacaoFacialEOzonioterapia from "../../assets/images/sponsors/dra-fernanda-furlan-harmonizacao-facial-e-ozonioterapia.png"
import ortodontiaClinicaDaAtm from "../../assets/images/sponsors/manaus-south-american-city-of-sport-2024.png"
import cresol from "../../assets/images/sponsors/cresol.jpeg"
import luisEugenioEstablished2002 from "../../assets/images/sponsors/luis-eugenio-established-2002.png"






//Apoio
import gracieBarrafranciscoBeltrao from "../../assets/images/sponsors/gracie-barra-francisco-beltrao.png";


import { SponsorContainer, SponsorList } from "src/components/atoms/sponsor-area/styles";
import SponsorButton from "src/components/atoms/sponsor-area";
import useAuthenticate from "../../hooks/useAuthenticate";

const sponsors = [
  { img: ticTac, url: "http://www.torneiofacil.com" },
  { img: cresol, url: "#" },
  { img: construbelMateraisParaConstrucao, url: "#" },
  { img: cresol, url: "#" },
  { img: donJuanFastBurguer, url: "#" },
  { img: cresol, url: "#" },
  { img: duffConveniencia, url: "#" },
  { img: cresol, url: "#" },
  { img: centroDeNeurocirurgiaEColuna, url: "#" },

  { img: cresol, url: "#" },
  { img: vilaNovaMadeiras, url: "#" },
  { img: cresol, url: "#" },
  { img: academiaSportFit, url: "#" },
  { img: cresol, url: "#" },
  { img: dezTelecom, url: "#" },
  { img: cresol, url: "#" },
  { img: draFernandaFurlanHarmonizacaoFacialEOzonioterapia, url: "#" },
  { img: cresol, url: "#" },
  { img: ortodontiaClinicaDaAtm, url: "#" },
  { img: cresol, url: "#" },
  { img: luisEugenioEstablished2002, url: "#" },
  
];

const support = [
  //{ img: esporteNaPeriferia },
  { img: torneio },
  { img: gracieBarrafranciscoBeltrao , url: "https://www.instagram.com/graciebarrafb" },
];

const CopaMestreAcaiDeJiuJitsu2024: React.FC = () => {
  const { authenticated } = useAuthenticate();

  return (
    <>
      <NavBar />

      <Container>
        <TournamentContainer
          image={require("../../assets/images/tournaments/copa-mestre-acai-de-jiu-jitsu-2024/copa-mestre-acai-de-jiu-jitsu-2024.jpg")}
        >
          <TournamentContainerBanner>
            <TournamentBanner
              image={require("../../assets/images/tournaments/copa-mestre-acai-de-jiu-jitsu-2024/copa-mestre-acai-de-jiu-jitsu-2024.jpg")}
            />
          </TournamentContainerBanner>
        </TournamentContainer>
        <TournamentContent>
        <Content>
            <Title>Copa Mestre Açai de Jiu Jitsu 2024</Title>
            <Location>
              <Span>Data: 19 de maio de 2024</Span>
              <Span>Início: 8:30h</Span>
              <Span>Local: Ginásio Arrudão</Span>
              <Span>Endereço: Rua Ten. Camargo, 774 - Centro, Francisco Beltrão - PR, 85601-610</Span>
              {/* <a
                    target={"blank"}
                    style={{ color: "#000", textDecoration: "none" }}
                    href="https://maps.app.goo.gl/ydHx4r5z35tpWA9j6"
                  >
                    <Span> Endereço: Rua Ten. Camargo, 774 - Centro, Francisco Beltrão - PR, CEP 85601-610</Span>
              </a> */}
              <Span>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                <FaMapMarkerAlt style={{ marginRight: '8px', color:"red" }} />
                <a
                  target={"blank"}
                  style={{ color: "#000", textDecoration: "none", fontWeight: "bold"}}
                  href="https://maps.app.goo.gl/ydHx4r5z35tpWA9j6"
                >
                  Ver no Google Maps
                </a>
              </div>
                
                
                
             </Span>
            </Location>
            <P></P>
            <br />

          </Content>
        </TournamentContent>
        <TournamentDescription>
          <TournamentActions>
            <Content>
              <h1 style={{ textAlign: "center" }}>PATROCINADORES</h1>
              <SponsorContainer>
                <SponsorList>
                  {sponsors && sponsors.map((buttons: any, index: number) => (
                    <SponsorButton
                      key={index} // Adicionei uma chave única para cada botão
                      img={buttons.img}
                      url={buttons.url}
                      hasError={false}
                      error="Este campo é obrigatório"
                      target="_blank" // Adicione este atributo
                    />
                  ))}
                </SponsorList>
              </SponsorContainer>
              <h1 style={{ textAlign: "center" }}>APOIO</h1>
              <SponsorContainer>
                <SponsorList>
                  {support &&
                    support.map((buttons: any) => (
                      <SponsorButton
                        img={buttons.img}
                        url={buttons.url}
                        hasError={false}
                        error="Este campo é obrigatório"
                      />
                    ))}
                </SponsorList>
              </SponsorContainer>
            </Content>

            <Content style={{
              marginTop: '32px'
            }}>

              <EditalContainer>
                <EditalTitle>EDITAL COPA MESTRE AÇAI DE JIU JITSU 2024</EditalTitle>
                <EditalContent>
                  <Span>
                    A Copa Mestre Açai de Jiu Jitsu 2024 é um evento tradicional Paranaense
                    fomentando e incentivando a prática do jiu jitsu no Paraná . As lutas
                    serão exclusivamente com GI (com kimono), o evento disponibilizará
                    categorias para todas as idades e faixas, conforme as tabelas de peso
                    deste edital. Os competidores terão a oportunidade de testar suas
                    habilidades em um ambiente controlado e com acompanhamento de
                    profissionais qualificados.
                  </Span>
                  <br />
                  <br />
                  <br />
                </EditalContent>

                <Collapse title="PROTOCOLO DE SEGURANÇA CONTRA COVID-19">
                  <P>
                    Devido a dinâmica da situação atual, estamos avaliando diariamente as
                    recomendações dos Órgãos de Saúde e este protocolo pode ser atualizado a
                    qualquer momento caso novas determinações sejam publicadas.
                  </P>
                  <P>
                    Caso o evento seja cancelado por força maior, todas as inscrições serão
                    automaticamente reembolsadas.
                  </P>
                  <P>
                    A organização não é responsável pelos demais custos dos participantes,
                    tais como transporte e hospedagem.
                  </P>
                  <P>
                    Diretrizes do ginásio: Para a segurança de todos, por favor verifique
                    sua temperatura antes de se dirigir ao evento.{" "}
                  </P>
                  <P>Caso haja qualquer sintoma, procure um médico.</P>{" "}
                  <P>
                    {" "}
                    Caso você se sinta indisposto ou esteve em contato com um paciente
                    diagnosticado com Covid-19, por favor fique em casa e procure seu
                    médico.
                  </P>
                  {/* <P>
                  Todo nosso material será desinfetado antes e durante o evento.
                </P> */}
                </Collapse>
                <Collapse title="PREMIAÇÕES MEDALHAS🥇+QUIMONOS 🥋 + KIT SUPLEMENTOS🥤+DINHEIRO💰">
                  {/* <H3>Por Equipes </H3>
                <P>1° lugar: Troféu + R$500,00</P>
                <P>2° lugar: Troféu + R$300,00</P>
                <P>3° lugar: Troféu + R$200,00</P> */}

                  <br />
                  <H3>Academias:</H3>
                  <P>1° lugar: Troféu</P>
                  <P>2° lugar: Troféu</P>
                  <P>3° lugar: Troféu</P>

                  <br />
                  <H3>Absoluto Masculino GI:</H3>
                  <H5>Infanto-Juvenil Graguado</H5>
                  <P>1° lugar: R$ 200 + medalha</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>
                  <H5>Juvenil Branca</H5>
                  <P>1° lugar: R$ 200 + medalha</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>
                  <H5>Juvenil Azul/Roxa(Juntos)</H5>
                  <P>1° lugar:  R$ 200 + medalha</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>
                  <H5>Adulto Branca</H5>
                  <P>1° lugar: R$ 300 + medalha</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>
                  <H5>Adulto Azul</H5>
                  <P>1° lugar: R$ 300,00 + medalha</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>
                  <H5>Adulto Roxa</H5>
                  <P>1° lugar: R$ 400,00 + medalha</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>
                  <H5>Adulto Marrom</H5>
                  <P>1° lugar: R$ 500,00 + medalha</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>
                  <H5>Adulto Preta</H5>
                  <P>1° lugar: R$ 700,00 + medalha</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>

                  <br />
                  <br />
                  <H5>Adulto/master(juntos) Roxa</H5>
                  <P>1° lugar: R$ 400,00 + brindes</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>
                  <H5>Adulto/master(juntos) Marrom</H5>
                  <P>1° lugar: R$ 500,00 + brindes</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>
                  <H5>Adulto/master(juntos) Preta</H5>
                  <P>1° lugar: R$ 600,00 + brindes</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>

                  <br />
                  <br />
                  <H3>Absoluto Feminino GI:</H3>
                  <H5>Juvenil Branca</H5>
                  <P>1° lugar: R$ 200,00 + medalha</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>
                  <H5>Juvenil Graduado</H5>
                  <P>1° lugar: R$ 200,00 + medalha</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>
                  <H5>Adulto Branca</H5>
                  <P>1° lugar: R$ 300,00 + medalha</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>
                  <H5>Adulto Azul</H5>
                  <P>1° lugar: R$ 300,00 + medalha</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>
                  <H5>Adulto Roxa/Marrom/Preta (juntos)</H5>
                  <P>1° lugar: R$ 700,00 + medalha + brindes</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>

                  <br />
                  <br />
                  <br />



                  <H5>Adulto/Master Marrom/Preta (juntos)</H5>
                  <P>1° lugar: R$ 500,00 + brindes</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>
                  <br />

                  <P>Para a realização de lutas na categoria absoluta, é exigido um mínimo de 4 atletas inscritos.</P>
                  <P>A premiação nas categorias absoluto será efetuada somente se o número de participantes atingir o mínimo de 8 inscritos.</P>
                  <P>Número menor que 8 atletas a premiação ficará a criterio da organização do evento, podendo não ter premição. </P>
                  <br />

                  <H3>Demais Categorias GI</H3>
                  <P>1° lugar: medalha</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>
                </Collapse>

                <Collapse title="INSCRIÇÕES">
                  <Span>
                    As inscrições para o evento serão realizadas exclusivamente online, por
                    meio do site www.torneiofacil.com.br.
                  </Span>
                  <P>
                    Durante o processo de inscrição, o atleta ou seu responsável legal
                    deverá selecionar corretamente a categoria referente idade que o atleta
                    completará no ano de 2024, bem como sua faixa e peso.
                  </P>
                  <P>
                    A idade de competição do atleta é a idade máxima que ele terá em 2024,
                    conforme regra da CBJJ.
                  </P>
                  <br />

                  <H3>Prazo de Inscrição</H3>
                  <P>
                    O prazo final para inscrições e pagamento será até o dia 13/05/2024, às
                    23h59min.
                  </P>
                  <br />
                  <P>As inscrições que não forem pagas serão canceladas.</P>
                  <br></br>

                  <H3>Valor  </H3>
                  <P>Lote 1 até 25/04/2024</P>
                  <P>Inscrição categoria R$ 120,00 (Cento e Vinte Reais)</P>
                  <br />
                  <P>Lote 2 de 30/04/2024 até 05/05/2024</P>
                  <P>Inscrição categoria R$ 130,00 (Cento e Trinta Reais)</P>
                  <br />
                  <P>Lote 3 de 06/05/2024 até 13/05/2024</P>
                  <P>Inscrição categoria R$ 140,00 (Cento e Quarenta Reais)</P>

                  <br />
                  <br />

                  <br />
                  <H3>Checagem</H3>

                  <P>
                    A checagem ficará disponivel até a o dia 19 de maio de 2024. Obs: A
                    correção ficará disponível somente até o dia 13 de maio de 2024
                    (conforme explicado abaixo).
                  </P>
                  <br />
                  <H3>Correção</H3>

                  <P>
                    A correção da inscrição poderá ser realizada somente após a confirmação
                    do pagamento.
                  </P>
                  <P>
                    Em caso de inscrição equivocada, o atleta ou professor responsável
                    poderá corrigir as informações no site www.torneiofacil.com.br,
                    acessando a opção Meu Perfil em seguida Minhas Inscrições.
                  </P>
                  <P>
                    A correção da inscrição ficará disponivel até o encerramento das
                    inscrições 13/05/2024 as 23h59min.
                  </P>
                  <P>
                    Após prazo para correção das inscrições, não serão aceitas modificações
                    nas inscrições.
                  </P>
                  <P>
                    Os atletas que se inscreverem incorretamente estarão sujeitos a
                    desclassificação <b>sem direito a reembolso.</b>
                  </P>

                  <br />

                  <H3>Prazo de Correção</H3>
                  <P>
                    A correção da inscrição fica disponível após a confirmação do pagamento
                    da mesma.
                  </P>

                  <P>
                    A correção da inscrição ficará disponivel até o encerramento das
                    inscrições no 13/05/2024 as 23h59min.
                  </P>
                  <H3>Checagem</H3>

                  <P>
                    O sorteio e divulgação das chaves de lutas será no dia 17 de maio
                    de 2024 até as 23h e 59min.
                  </P>

                  <br />
                  <H3>Inscrição de Absoluto</H3>
                  <P>
                    Somente atletas inscritos na categoria poderão se increver e participar
                    do absoluto.
                  </P>
                  <P>
                    As inscrições para os absolutos são gratuitas, podendo ter no máximo 4
                    atletas da mesma equipe por absoluto.
                  </P>
                  <P>
                    As inscrições para os absolutos serão realizadas pelo professor
                    responsável pelo atleta, junto a organização do evento no dia do torneio
                    após a finalização das lutas da categoria.
                  </P>
                  <P>
                    Os atletas só poderão se inscrever no absoluto referente a categoria da
                    sua inscrição no torneio. Exceção apenas para os master que poderá se
                    inscrever como absoluto adulto.
                  </P>
                </Collapse>
                <Collapse title="VALOR DA INSCRIÇÃO">
                <Span>
                    As inscrições serão divididas em três lotes
                  </Span>
                  <br/>
                  <P>Lote 1 até 25/04/2024</P>
                  <P>Inscrição categoria R$ 120,00 (Cento e Vinte Reais) </P>
                  <br />
                  <P>Lote 2 de 30/04/2024 até 05/05/2024</P>
                  <P>Inscrição categoria R$ 130,00 (Cento e Trinta Reais) </P>
                  <br />
                  <P>Lote 3 de 06/05/2024 até 13/05/2024</P>
                  <P>Inscrição categoria R$ 140,00 (Cento e Quarenta Reais) </P>
                </Collapse>

                <Collapse title="PAGAMENTO">
                  <H3>
                    A confirmação de pagamento deverá ser realizada via WhatsApp enviando o
                    comprovante para o contato abaixo:
                  </H3>
                  <P>Banco Nubank:</P>
                  <P>Titular: Thiago Souza Dellarmelina</P>
                  <P>Chave PIX do tipo Telefone:</P> <P>PIX: 92 98129-1027</P>
                  {/* <QRCodeImg src={qrCodecopaAmizadeDeJiuJitsu2024} alt="qrCode" width={300} /> */}
                  {/* <P>
                    <a
                      target={"blank"}
                      style={{ color: "white" }}
                      href="https://whatsa.me/5592981291027/?t=Ol%C3%A1+Thiago+Dellarmelina%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+na+%2ACOPA+MESTRE+AÇAI+DE+JIU+JITSU+2024%2A"
                    >
                      <strong>WhatsApp: +55 (92) 98129-1027 </strong>
                      <FaWhatsapp />
                    </a>
                  </P> */}
                  <p />
                  {/* <QRCodeImg src={qrCodeCopaParana} alt="qrCode" width={300} /> */}
                </Collapse>

                <Collapse title="REEMBOLSO DAS INSCRIÇÕES">
                  <H3>
                    Após o fim do perido de inscrição não será possivel o reembolso das inscrições pagas.
                  </H3>
                  
                </Collapse>

                <Collapse title="REGRAS">
                  <Span>
                    Todas as regras para o evento estão devidamente estipuladas e detalhadas neste edital.
                  </Span>
                  <Span>
                    Em casos de omissão de regras no presente edital, as normas a serem observadas serão aquelas estabelecidas pela Confederação
                    Brasileira de Jiu-Jitsu (CBJJ), em conformidade com as diretrizes da CBJJ/IBJJF.
                  </Span>
                  <Span>
                    A tabela de peso por categoria, que estabelece os limites de peso para cada categoria, encontra-se disponível no site oficial do evento,
                    acessível por meio do botão denominado "Tabela de Peso".
                  </Span>
                  <Span>
                    As lutas serão com GI (com kimonos de cores Oficiais pela cbjj branco,
                    azul e preto ) os kimonos devem estar limpos sem rasgos e sem odor .
                  </Span>
                  <Span>
                    Para uma categoria com 3 lutadores, será aplicada a regra da CBJJ.
                    Em caso de WO de um atleta, os dois lutadores restantes na categoria disputarão a final.
                  </Span>
                  <Span>
                    A pesagem será realizada no dia do evento. O competidor deverá
                    apresentar documento oficial com foto no momento da pesagem.
                  </Span>
                  <Span>
                    A pesagem dos atletas serão realizadas antes do inicio da categoria.
                  </Span>
                  <Span>
                    Atletas que ultrapasarem o limite de peso da categoria que entiverem
                    inscritos serão automaticamente desclassificados e não poderão lutar o
                    Absoluto.
                  </Span>
                  <Span>Será realizada apenas uma pesagem oficial por atleta.</Span>
                  <Span>
                    Teremos uma balança para pesagem livre (não oficial) para os atletas.
                  </Span>
                  <Span>Teremos duas balanças para pesagem oficial dos atletas.</Span>
                  <Span>
                    Atletas que lutaram em outros eventos com faixa superior não poderão
                    lutar com a faixa abaixo. Se descoberto, o competidor será eliminado do
                    torneio sem direito a reembolso.
                  </Span>
                  <br />
                  {/* <H3>Pontuação por equipe</H3>
                <H5>Categoria</H5>
                <P>1° lugar: 9 pontos</P>
                <P>2° lugar: 3 pontos</P>
                <P>3° lugar: 1 ponto</P>
                <H5>Absoluto</H5>

                <P>1° lugar: 13.5 pontos</P>
                <P>2° lugar: 4.5 pontos</P>
                <P>3° lugar: 1.5 ponto</P>
                <br /> */}

                  <P>
                    A contagem dos pontos será realizada de forma geral contabilizando os
                    pontos de todas as faixas.
                  </P>
                  <P>Campeão direto não contam pontos, chaves com apenas um inscrito.</P>
                  <P>
                    Campeão por WO, devido a desistencia do oponente, contará pontos desde
                    que na chave tenha mais de um inscrito.
                  </P>
                  <P>
                    Categorias com apenas dois atletas da mesma academia não contam pontos
                    para a disputa por academias.
                  </P>
                  <P>
                    O que não estiver neste edital, será acatado o que estiver no livro de
                    regras da CBJJ.
                  </P>
                  <br />
                  <P>
                    <a
                      target={"blank"}
                      href="https://cbjj.com.br/books-videos"
                      style={{ color: "white" }}
                    >
                      <strong>LIVRO DE REGRAS DA CBJJ/IBJJF</strong>
                    </a>
                  </P>
                </Collapse>
                <Collapse title="CRONOGRAMA">
                  {/* (EM BREVE) */}
                  <Container>
                  <br />
                  <Span>Data: 19 de maio de 2024</Span>
                  <Span>Início: 8:30h da manhã</Span>

                  <Span>O valor da entrada é de 1 kg de alimento não perecível  ou 5 reais, 
                    tanto para o público quanto para os atletas.</Span>
                
                  <br />
                  <H3>Abertura</H3>
                  <H5>08:30 - Hino Nacional </H5>
                  <H3>Lutas GI</H3>
                  <H5>9:00 - Categorias Pré-Mirim e Mirim A - Todas as Faixas masculino/feminino na área de aquecimento </H5>
                  <H5>09:30 - Abertura do evento </H5>
                  <H5>10:10 - Categoria Mirim B - Todas as Faixas masculino/feminino </H5>
                  <H5>10:40 - Infantil A - Todas as Faixas masculino/feminino </H5>
                  <H5>11:00 - Infantil B - Todas as faixas masculino/feminino </H5>
                  <H5>11:30 - Infanto-juvenil Todas as faixas Masculino/Feminino  </H5>
                  <H5>12:00 - Juvenil - Todas as faixas masculino/feminino </H5>
                  <H5>12:50 - Faixa Branca Adulto masculino/feminino </H5>
                  <H5>13:30 - Faixa Branca Masters masculino/feminino </H5>
                  <H5>14:00 - Faixa Azul Adulto e Master Masculino e Feminino </H5>
                  <H5>14:30 - Faixa Roxa Adulto e Master Masculino e Feminino </H5>
                  <H5>15:00 - Faixas Marrom e Preta Adulto e Master Masculino e Feminino </H5>
                  <H5>15:40 - Início das Inscrições dos Absolutos </H5>
                  <H5>16:00 - Ínicio dos Absolutos </H5> 
                  <br />
                  <b><Span>Obs: </Span></b>
                  <H5>A pesagem será feita antes da 1• luta do atleta, em caso do atleta não bater 
                    o peso, este será imediatamente desclassificado, será verificado também as condições 
                    do quimono do atleta antes da luta, podendo ocorrer desclassificação.
                 </H5>
                  <H5>As inscrições do Absoluto deverão ser feitas pelo o professor responsável pela sua equipe, 
                    máximo 03 atletas por Equipe para cada Absoluto.</H5>
                  <H5> Todos os atletas devem estar 45.00 minutos antes do tempo de sua  categoria na 
                    área de aquecimento podendo lutar até 30 min antes do horário previsto no cronograma. </H5>

                  <H3> Desejamos a todos uma boa viagem e um excelente campeonato!!!</H3>
                  <H3>Ossssssssss</H3>
                    <br />
                    <H5>
                      Obs.1: O atleta deverá comparecer com uma (01) hora de antecedência do
                      horário previsto no cronograma.
                    </H5>
                    <H5>
                      Obs.2- Os absolutos serão disputados após o término das suas
                      respectivas categorias, lembrando que deverá ter no mínimo oito (08)
                      atletas inscritos para validar a premiação.
                    </H5>
                    <Span>Usaremos 6 áreas de luta</Span>

                    <Span>
                      O atleta deverá estar presente no ginásio pelo menos 1 hora antes da
                      sua luta.
                    </Span>
                    <Span>
                      O cronograma pode ser adiantado em até 1 hora, e atrasar conforme a
                      necessidade do evento.
                    </Span>
                    <Span>
                      O atleta que não estiver presente no momento da chamada para a pesagem
                      ou luta ser desclassificado por W.O.
                    </Span>
                  </Container>
                </Collapse>
                <Collapse title="ENTRADA">
                  <P>O valor da entrada é de 1 kg de alimento não perecível  ou 5 reais, 
                    tanto para o público quanto para os atletas.</P>
                  <br />
                  {/* <P>
                  Será cobrado 1kg de alimento não perecível na entrada para atletas e
                  público.
                </P> */}
                  {/* <br />
                <P>Crianças até 5 anos ficam isentas de qualquer tipo de pagamento.</P>
                <br /> */}
                </Collapse>
                <Collapse title="CONTATO">
                <P>
                  Duvidas sobre cadastro, inscrição, pagamento, correção, checagem
                  ou sugestão de melhorias entre em contato com a equipe do Torneio
                  Fácil por telefone ou WhatsApp.
                  </P>
                <P>
                  Melhor horário para atendimento das 18:00h as 23:59h.
                  </P>
                <a target={"blank"} href="https://whatsa.me/+5592981291027/?t=Ol%C3%A1+Thiago+Dellarmelina+%2APreciso+de+ajuda+com+minha+inscrição+na+COPA+MESTRE+AÇAI+DE+JIU+JITSU+2024%2A*"
                  >
                    <strong>Telefone/WhatsApp: (92) 98129-1027 </strong>
                    <FaWhatsapp />
                </a>
                
                <br />
              </Collapse>
              </EditalContainer>
            </Content>
            <br />
            <Content>
              {authenticated ? (
                <>
                  <TournamentEnrollmentLinkEndpage to="/enrollment/copa-mestre-acai-de-jiu-jitsu-2024-ginasio-arrudao-francisco-beltao-pr">
                    Inscrição Encerrada
                  </TournamentEnrollmentLinkEndpage>

                  <TournamentEnrollmentLinkEndpage to="/profile/subscription">
                    Correção da Inscrição
                  </TournamentEnrollmentLinkEndpage>
                </>
              ) : (
                <>
                  <TournamentEnrollmentLinkEndpage to="/login">
                    Inscrição Encerrada
                  </TournamentEnrollmentLinkEndpage>

                  <TournamentEnrollmentLinkEndpage to="/login">
                    Correção da Inscrição
                  </TournamentEnrollmentLinkEndpage>
                </>
              )}
              {/* <TournamentLink href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.767B171E&u=teste" target="_blank">Chaves</TournamentLink> */}
              {/* // /check/16-COPA-PARANA-DE-JIU-JITSU */}

              <TournamentLink
                // href="#"
                // target="_blank"
                href="/check/copa-mestre-acai-de-jiu-jitsu-2024-ginasio-arrudao-francisco-beltao-pr/search"
                //href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.D7A54E37&u=teste"
                >Checagem</TournamentLink>

              
              <TournamentLink
                href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.D7A54E37&u=teste"
                target="_blank"
              >
                Chaves
              </TournamentLink> 

              {/*               <TournamentLink
                href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.D7A54E37&u=teste"
                target="_blank"
              >
                Chaves GI
              </TournamentLink> */}

              {/*               <TournamentLink
                href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.5B5B1C9A&u=teste"
                target="_blank"
              >
                Chaves NO GI
              </TournamentLink> */}

              <TournamentLink target="blank" href={tabelaPeso}>
                Tabela de pesos
              </TournamentLink>
              {/* <TournamentLink href="#">Resultados (EM BREVE)</TournamentLink> */}
            </Content>
          </TournamentActions>
        </TournamentDescription>
      </Container>
      <Footer />
    </>
  );
};

export default CopaMestreAcaiDeJiuJitsu2024;
