import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  padding: 40px 0;
`;

export const PageTitleContainer = styled.h1`
  padding: 0 20px;
`;

export const PageLocationTitle = styled.h1`
  color: rgb(25, 31, 40);
  text-transform: uppercase;
  font-size: 24px;
  width: 100%;
  font-weight: 600;
`;

export const PageTitle = styled.h1`
  color: rgb(132, 140, 155);
  text-transform: uppercase;
  font-size: 14px;
  width: 100%;
  font-weight: 600;
`;

export const Content = styled.div`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
`;

export const Panel = styled.div`
  background-color: #ffffff;
  color: #223354;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 10px;
  box-shadow: none;
  padding: 0;
  box-shadow: 0px 9px 16px rgba(159, 162, 191, 0.18),
    0px 2px 2px rgba(159, 162, 191, 0.32);
  overflow: hidden;
`;

export const PanelTitleContainer = styled.div`
  padding: 16px;
  border-bottom: 1px solid rgb(34 51 84 / 10%);
`;

export const PanelTitle = styled.h1`
  font-size: 15px;
  margin: 0;
  padding: 0;
`;

export const SubscriptionContainer = styled.div`
  padding: 0 20px;
  box-sizing: border-box;
  display: grid;
  grid-auto-rows: max-content;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: row;

  @media (max-width: 560px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 561px) and (max-width: 1140px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const SubscriptionList = styled.a`  
  margin-bottom: 10px;
  cursor: pointer;
  text-decoration: none;
  
  cursor: pointer;
  background: ${({ disabled }: { disabled?: boolean }) =>
    disabled ? "#569DAA" : "rgb(55, 55, 55)"};
  border-radius: 8px;
  padding: 10px 12px;
  border: none;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: rgb(255, 255, 255);
  display: inline-block;
  text-decoration: none;
  margin-top: 14px;
`;

export const SubscriptionItem = styled.div`
  background-color: #ffffff;
  color: #223354;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  transition: all 0.5s;

  &:hover {
    box-shadow: 0px 9px 16px rgba(159, 162, 191, 0.18),
      0px 2px 2px rgba(159, 162, 191, 0.32);
  }
`;

export const SubscriptionContent = styled.div`
  padding: 10px;
`;

export const SubscriptionImageContent = styled.div``;

export const SubscriptionImage = styled.div`
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 250px;
  width: 100%;
`;

export const SubscriptionTitle = styled.h1`
  font-weight: 700;
  font-size: 14px;
  margin: 0;
  padding: 0;
`;

export const SubscriptionDescription = styled.p`
  margin: 0;
  padding: 0;
  line-height: 24px;
`;

export const SubscriptionActions = styled.div`
  padding: 5px;
`;

export const SubscriptionLink = styled(Link)`
  cursor: pointer;
  background: ${({ disabled }: { disabled?: boolean }) =>
    disabled ? "red" : "rgb(55, 55, 55)"};
  border-radius: 8px;
  padding: 12px 18px;
  border: none;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: rgb(255, 255, 255);
  display: inline-block;
  text-decoration: none;
  margin: 5px;
`;

export const AthletesLink = styled(Link)`
  cursor: pointer;
  background: ${({ disabled }: { disabled?: boolean }) =>
    disabled ? "red" : "rgb(55, 55, 55)"};
  border-radius: 8px;
  padding: 12px 18px;
  border: none;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: rgb(255, 255, 255);
  display: inline-block;
  text-decoration: none;
  margin-top: 14px;
`;
