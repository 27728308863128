import NavBar from "../../../components/organisms/navbar";
import { Container, Content, PaymentContainer, Title, Title2, InstructionTitle, InstructionList, InstructionItem, InstructionStep, InstructionItemContent, StyledLink, HighlightedLink } from "../styles";
import { FaWhatsapp } from "react-icons/fa";
import { useParams } from "react-router-dom";
import React from "react";

const PaymentEnrollmentCopaThomasiDeJiuJitsu = () => {
    const { slug, id } = useParams();
    return (
        <Container>
            <NavBar />
            <Content>
                <PaymentContainer>
                    <Title>Obrigado por fazer a sua inscrição na</Title>
                    <Title2>
                    I COPA THOMASI DE JIU JITSU
                    </Title2>
                    <p style={{ color: "red", marginBottom: "0px" }}><b>Inscrição N° {id}</b></p>
                    <InstructionTitle>Instruções:</InstructionTitle>

                    <InstructionList>
                        {/* <InstructionItem color="#f0f4c3"> */}
                        <InstructionItem color="rgb(210, 210, 210)">
                            <InstructionStep>Passo 1</InstructionStep>
                            <InstructionItemContent>
                                Coloca o número da inscrição ( <b style={{ color: "red", marginBottom: "30px" }}>{id}</b> ) na descrição do PIX.
                            </InstructionItemContent>
                        </InstructionItem>

                        {/* <InstructionItem color="#e3f2fd"> */}
                        <InstructionItem color="rgb(210, 210, 210)">
                            <InstructionStep>Passo 2</InstructionStep>
                            <InstructionItemContent>
                                Efetuar o pagamento através do PIX para:
                                <ul style={{ listStyle: "none" }}>
                                    <br />
                                    <li>Banco NuBank</li>
                                    <li>Chave PIX E-mail</li>
                                    <li><b>Pix: copathomasi@gmail.com</b></li>
                                    <li>Juares Lima dos Santos</li>
                                </ul>
                            </InstructionItemContent>
                        </InstructionItem>

                        <InstructionItem color="rgb(210, 210, 210)">
                            {/* <InstructionItem color="#ffecb3"> */}
                            <InstructionStep>Passo 3</InstructionStep>
                            <InstructionItemContent>
                                Enviar o comprovante de pagamento (com número da inscrição descrição)  para:
                                <ul style={{ listStyle: "none" }}>
                                    <li>
                                        <br />
                                        <StyledLink
                                            target="_blank"
                                            href={`https://whatsa.me/+44999096269/?t=Ol%C3%A1%20Juares%20Lima%20dos%20Santos,%20estou%20enviando%20o%20comprovante%20de%20pagamento%20referente%20a%20inscri%C3%A7%C3%A3o%20na%20*I%20COPA%20THOMASI%20DE%20JIU%20JITSU%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}*`}
                                        >
                                            55 (44) 99909-6269<FaWhatsapp />
                                        </StyledLink>
                                    </li>
                                </ul>
                            </InstructionItemContent>
                        </InstructionItem>

                        <InstructionItem color="rgb(210, 210, 210)">
                            {/* <InstructionItem color="#c8e6c9"> */}
                            <InstructionStep>Passo 4</InstructionStep>
                            <InstructionItemContent>
                                Acompanhe o status do pagamento em:
                                <br />
                                <ul style={{ listStyle: "none" }}>
                                    <li>
                                        <HighlightedLink href="/profile/subscription">Minhas Inscrições</HighlightedLink>
                                    </li>
                                </ul>
                            </InstructionItemContent>
                        </InstructionItem>
                    </InstructionList>
                </PaymentContainer>
            </Content>
        </Container>
    )
}
export default PaymentEnrollmentCopaThomasiDeJiuJitsu
