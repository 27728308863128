import React from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import logoImg from "../../../assets/images/logo.png";
import Button from "../../../components/atoms/button";
import Input from "../../../components/atoms/input";
import useAuthenticate from "../../../hooks/useAuthenticate";

import api from "../../../services/api";
import {
  AccountExists,
  Container,
  Content,
  FormContent,
  FormGroup,
  PageDescription,
  PageLogo,
  PageTitle,
  PasswordRecovery,
  SectionBanner,
  SectionForm,
} from "./styles";
import Swal from "sweetalert2";

const Login: React.FC = () => {
  const { setToken } = useAuthenticate();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    api
      .post("/auth/login", {
        ...data,
      })
      .then((response) => {
        if (response.data.status) {
          toast.success(response.data.message);

          Swal.fire({
            title: 'Sucesso!',
            text: `${response.data.message}`,
            icon: 'success',
          })

          setToken(response.data.accessToken);

          setTimeout(() => {
            navigate("/profile");
          }, 2000);
        } else {
          const responseData = JSON.parse(response.config.data);

          const emailDomain = responseData.email.split('@')[1];

          if (response.headers['content-length'] === '115') { // 115 == inativo
            Swal.fire({
              title: 'Atenção!',
              text: `${response.data.message}`,
              icon: 'error',
              showCancelButton: true,
              confirmButtonText: 'Fazer login com outra conta',
              cancelButtonText: `Abrir e-mail para ativar sua conta `,
            }).then((result) => {
              if (result.isConfirmed) {
                navigate('/login');
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                window.open(`https://${emailDomain}`, "_blank");
                navigate("/login");
              }
            })
          }

          if (response.headers['content-length'] === '87') { // 87 == login errado
            Swal.fire({
              title: 'Atenção!',
              text: `Não foi possível acessar a sua conta, verifique seu e-mail ou senha!`,
              icon: 'error',
            })
          }

          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error.data.message)
        Swal.fire({
          title: 'Atenção!',
          text: `${error.data.message}`,
          icon: 'warning',
        })

        toast.error(
          "Internal server error, por favor entrar em contato com suporte."
        )
      });
  };

  return (
    <Container>
      <SectionForm>
        <Content>
          <Link to={"/"}>
            <PageLogo src={logoImg} />
          </Link>
          <PageTitle>Entrar</PageTitle>
          <PageDescription>
            Bem-vindo ao Torneio Fácil, acesse <br />a sua conta preenchendo os
            dados abaixo.
          </PageDescription>

          <FormContent onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Input
                label="E-mail"
                name="email"
                type="text"
                register={register}
                required
                hasError={!!errors.email}
                error="Este campo é obrigatório"
                transformToLowerCase={true}
                trim={true}
              />
            </FormGroup>
            <FormGroup>
              <Input
                label="Senha"
                name="password"
                type="password"
                register={register}
                required
                hasError={!!errors.password}
                error="Este campo é obrigatório"
                trim={true}
              />
            </FormGroup>
            <FormGroup>
              <PasswordRecovery href="/password-recovery">
                Esqueceu a sua senha? <strong>Recupere aqui.</strong>
              </PasswordRecovery>
            </FormGroup>
            <FormGroup>
              <Button type="submit" label="Entrar" />
            </FormGroup>
            <FormGroup>
              <AccountExists href="/register">
                Ainda não possui conta? Cadastre-se aqui
              </AccountExists>
            </FormGroup>
          </FormContent>
        </Content>
      </SectionForm>
      <SectionBanner></SectionBanner>
    </Container>
  );
};

export default Login;
