import React from "react";
import { StyledButton } from "./styles";

type ButtonProps = {
  label: string;
  disabled?: boolean;
  type?: "submit" | "reset" | "button";
};

const Button: React.FC<ButtonProps> = ({
  type = "button",
  label,
  disabled = false,
}) => {
  return (
    <StyledButton disabled={disabled} type={type}>
      {label}
    </StyledButton>
  );
};

export default Button;
