import React from "react";
import {
  SponsorItem,
  StyledCardBody,
  StyledCardText,
  StyledImg,
  StyledLink,
} from "./styles";

type props = {
  url: string;
  img: string;
  hasError?: boolean;
  error?: string;
  target?: string; // Adicionado target como uma propriedade opcional
};

const SponsorButton: React.FC<props> = ({ url, img }) => {
  return (
    <SponsorItem>
      <StyledLink href={url} target="_blank" rel="noopener noreferrer">
        <StyledCardBody>
          <StyledCardText>
            <StyledImg src={img} alt="" />
          </StyledCardText>
        </StyledCardBody>
      </StyledLink>
    </SponsorItem>
  );
};

export default SponsorButton;
