import { useState } from "react";
import api from "../../../services/api";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useAuthenticate from "../../../hooks/useAuthenticate";

import Input from "../../../components/atoms/input";
import Loading from "../../../components/atoms/loading";
import NavBar from "../../../components/organisms/navbar";
import Button from "../../../components/atoms/button";

import { FormContainer } from "../../enrollment/styles";
import { FormGroup, Content, PageTitle } from "./styles";

const NewTournament = () => {
  const navigate = useNavigate();
  const { getToken } = useAuthenticate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [disabled, setDisabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = (data: any) => {
    setDisabled(true);
    setLoading(true);

    const { name, descriptions, location, date } = data;

    api
      .post(
        "/managers/tournaments",
        {
          name,
          descriptions,
          location,
          date,
          status: true,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then(() => {
        toast.success("Torneio foi cadastrado com sucesso!");

        setTimeout(() => {
          navigate("/");
        }, 2000);
      })
      .catch(() => {
        setDisabled(false);
        setLoading(false);
        toast.error("Não foi possível o cadastro do torneio!");
      });
  };

  if (loading)
    return <Loading text="Aguarde, estamos processando seu cadastro." />;

  return (
    <>
      <NavBar />
      <Content>
        <PageTitle>Cadastro Evento</PageTitle>
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Input
              label="Nome do Evento"
              name="name"
              type="text"
              register={register}
              required
              hasError={!!errors.name}
              error="Este campo é obrigatório"
            />
          </FormGroup>
          <FormGroup>
            <Input
              label="Descrição do Evento"
              name="descriptions"
              type="textarea"
              register={register}
              required
              hasError={!!errors.descriptions}
              error="Este campo é obrigatório"
            />
          </FormGroup>
          <FormGroup>
            <Input
              label="Local"
              name="location"
              type="text"
              register={register}
              required
              hasError={!!errors.location}
              error="Este campo é obrigatório"
            />
          </FormGroup>
          <FormGroup>
            <Input
              label="Data do Evento"
              name="date"
              placeholder={`10 de agosto de ${new Date().getFullYear()}`}
              type="date"
              register={register}
              required
              hasError={!!errors.date}
              error="Este campo é obrigatório"
            />
          </FormGroup>

          <Button type="submit" label="Cadastrar Evento" disabled={disabled} />
        </FormContainer>
      </Content>
    </>
  );
};

export default NewTournament;
