import React, { ChangeEvent } from "react";
import styled from "styled-components";

interface FilterProps {
  filterValue: string;
}

interface SelectProps {
  selectedCategory: string | null;
}

type InputProps = {
  label?: string;
  type: "text";
  placeholder?: string;
  value?: string | number;
  transformToLowerCase?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void; // adicionado
};

interface FilterProps {
  filterValue: string;
}

export const InputContainer = styled.div`
  display: flex;
  width: 100vw;
  flex-direction: column;
  margin-bottom: 14px;
`;

export const Label = styled.label`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #33303e;
  text-align: left;
`;

export const Field = styled.input<FilterProps>`
  border: 1px solid rgba(140, 138, 151, 0.4);
  border-radius: 8px;
  padding: 14px;
`;

export const ValidateHelp = styled.p`
  text-align: left;
  font-size: 13px;
  margin: 8px 0;
  color: #ff5e5e;
`;

export const InputContainerWithButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const FilterInput = styled.input<FilterProps>`
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  flex-grow: 1;

  &:focus {
    outline: none;
    border: 1px solid blue;
  }
`;

export const FilterButton = styled.button`
  border: 1px solid rgba(140, 138, 151, 0.4);
  border-radius: 8px;
  padding: 14px;
  margin-left: 10px;
  width: 20vw;

  background-color: #007bff;
  color: #fff;
  cursor: pointer;

  &:hover {
    background-color: #0069d9;
  }
`;

export const FilterSelect = styled.select<SelectProps>`
  margin-top: 10px;
  padding: 14px;
  border-radius: 8px;
  border: 1px solid #ccc;
  width: 100%;

  background-color: #fff;
  color: #000;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const RadioGroup = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const RadioButton = styled.div<{ selected: boolean }>`
  border: 1px solid rgba(140, 138, 151, 0.4);
  border-radius: 8px;
  padding: 14px;
  margin-right: 10px;

  background-color: ${({ selected }) => (selected ? "#007bff" : "#fff")};
  color: ${({ selected }) => (selected ? "#fff" : "#000")};
  cursor: pointer;

  &:hover {
    background-color: ${({ selected }) => (selected ? "#0069d9" : "#f5f5f5")};
  }
`;

export const FilterOption = styled.option``;

const InputFilter: React.FC<InputProps> = ({
  label,
  type = "text",
  placeholder,
  value,
  transformToLowerCase,
  onChange,
}) => {
  const handleInput = (event: React.FormEvent<HTMLInputElement>) => {
    if (transformToLowerCase) {
      event.currentTarget.value = event.currentTarget.value.toLowerCase();
    }
  };

  return (
    <InputContainerWithButton>
      <InputContainer>
        <Label>{label}</Label>
        <Field
          filterValue=""
          type={type}
          value={value}
          placeholder={placeholder}
          onInput={handleInput}
          onChange={onChange}
        />
      </InputContainer>
    </InputContainerWithButton>
  );
};

export default InputFilter;
