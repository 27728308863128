import { useEffect, useState } from "react";
import NavBar from "../../components/organisms/navbar";
import {
  Container,
  Content,
  InfoContainerStatus,
  InfoContainerText,
  PageLocationTitle,
  PageTitle,
  PageTitleContainer,
  StatusButton,
  SubscriptionContainer,
  SubscriptionContent,
  SubscriptionDescription,
  SubscriptionItem,
  SubscriptionList,
  SubscriptionTitle,
} from "../profile/subscriptions/styles";
import api from "../../services/api";
import { toast } from "react-toastify";
import useAuthenticate from "../../hooks/useAuthenticate";
import Loading from "../../components/atoms/loading";
import React from "react";
import {
  Field,
  FilterButton,
  FilterOption,
  FilterSelect,
  InputContainer,
  InputContainerWithButton,
  Label,
} from "../../components/atoms/input/inputText";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

const Check = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { getToken } = useAuthenticate();

  const [filterValue, setFilterValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("name");
  const [showData, setShowData] = useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .get("/tournaments/enrollments/list-all/public/III-OPEN-TERRA-SANTA-DE-JIU-JITSU", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then(({ data }) => {
        setLoading(false);
        const sortedArray = data.sort((a: any, b: any) => a.id - b.id);
        setData(sortedArray);
      })
      .catch(() => {
        setLoading(false);
        toast.error("Não foi carregar os dados das inscrições.");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loading text="Aguarde, estamos processando os dados." />;

  const handleFilterClick = () => {
    const filteredArray = data.filter((value: any) => {
      const shouldInclude =
        value.status &&
        ((value.fighters
          ? value.fighters.name.toLowerCase().includes(filterValue.toLowerCase())
          : value.user.name.toLowerCase().includes(filterValue.toLowerCase())) ||
          value.category.toLowerCase().includes(filterValue.toLowerCase()));
      return shouldInclude;
    });
    setFilteredData(filteredArray);
    setSelectedCategory("");
    setShowData(true);
  };

  // obter todas as categorias únicas
  const categories = Array.from(new Set(data.map((value: any) => value.category)));

  // função para filtrar os dados por categoria
  const filterByCategory = (category: string) => {
    const filteredArray = data.filter(
      (value: any) => value.category === category && value.status
    );
    setFilteredData(filteredArray);
    setSelectedCategory(category);
  };

  // obter todas as academias únicas
  const club = Array.from(new Set(data.map((value: any) => value.user.gym)));

  // função para filtrar os dados por academia
  const filterByClub = (gym: string) => {
    const filteredArray = data.filter(
      (value: any) => value.user.gym === gym && value.status
    );

    setFilteredData(filteredArray);
    setSelectedCategory("");
  };

  const handleFilterChange = (event: React.FormEvent<HTMLInputElement>) => {
    setFilterValue(event.currentTarget.value);
    setShowData(true);
  };

  return (
    <>
      <NavBar />
      <Container>
        <Content>
          <PageTitleContainer>
            <PageLocationTitle>Checagem</PageLocationTitle>
            <PageTitle>{filteredData.length} inscrições encontradas</PageTitle>
            <RadioGroup
              aria-label="Filtrar por"
              value={selectedFilter}
              onChange={(e: any) => {
                setSelectedFilter(e.target.value);
                setFilterValue("");
                setSelectedCategory("");
                setShowData(false);
              }}
            >
              <FormControlLabel value="name" control={<Radio />} label="NOME" />
              <FormControlLabel value="category" control={<Radio />} label="CATEGORIA" />
              <FormControlLabel value="gym" control={<Radio />} label="ACADEMIA" />
              <FormControlLabel value="belt" control={<Radio />} label="FAIXA" />
              <FormControlLabel value="weight" control={<Radio />} label="PESO" />
            </RadioGroup>

            {selectedFilter === "name" && (
              <InputContainerWithButton>
                <InputContainer>
                  <Label>FILTRAR POR NOME</Label>
                  <Field
                    filterValue=""
                    type="text"
                    value={filterValue}
                    placeholder="FILTRAR POR NOME"
                    onChange={handleFilterChange}
                  />
                </InputContainer>
                <FilterButton onClick={handleFilterClick}>Filtrar</FilterButton>
              </InputContainerWithButton>
            )}

            {selectedFilter === "category" && (
              <>
                <Label>FILTRAR POR CATEGORIA</Label>
                <FilterSelect
                  selectedCategory={selectedCategory}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    setSelectedCategory(selectedValue);
                    filterByCategory(selectedValue ?? "");
                    setShowData(true);
                  }}
                >
                  <FilterOption value="">SELECIONE UMA CATEGORIA</FilterOption>
                  {categories.map((category) => (
                    <FilterOption key={category} value={category}>
                      {category.toUpperCase()}
                    </FilterOption>
                  ))}
                </FilterSelect>
              </>
            )}

            {selectedFilter === "gym" && (
              <>
                <Label>FILTRAR POR ACADEMIA</Label>
                <FilterSelect
                  selectedCategory={selectedCategory}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    setSelectedCategory(selectedValue);
                    filterByClub(selectedValue ?? "");
                    setShowData(true);
                  }}
                >
                  <FilterOption value="">SELECIONE UMA ACADEMIA</FilterOption>
                  {club.map((gym) => (
                    <FilterOption key={gym} value={gym}>
                      {gym.toUpperCase()}
                    </FilterOption>
                  ))}
                </FilterSelect>
              </>
            )}

            {selectedFilter === "weight" && (
              <>
                <Label>FILTRAR POR ACADEMIA</Label>
                <FilterSelect
                  selectedCategory={selectedCategory}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    setSelectedCategory(selectedValue);
                    filterByClub(selectedValue ?? "");
                    setShowData(true);
                  }}
                >
                  <FilterOption value="">SELECIONE UMA ACADEMIA</FilterOption>
                  {club.map((gym) => (
                    <FilterOption key={gym} value={gym}>
                      {gym.toUpperCase()}
                    </FilterOption>
                  ))}
                </FilterSelect>
              </>
            )}

            {selectedFilter === "belt" && (
              <>
                <Label>FILTRAR POR ACADEMIA</Label>
                <FilterSelect
                  selectedCategory={selectedCategory}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    setSelectedCategory(selectedValue);
                    filterByClub(selectedValue ?? "");
                    setShowData(true);
                  }}
                >
                  <FilterOption value="">SELECIONE UMA ACADEMIA</FilterOption>
                  {club.map((gym) => (
                    <FilterOption key={gym} value={gym}>
                      {gym.toUpperCase()}
                    </FilterOption>
                  ))}
                </FilterSelect>
              </>
            )}
          </PageTitleContainer>

          <SubscriptionContainer>
            {/* renderizar as inscrições para cada categoria */}
            {categories.map((category) => {
              // filtrar os dados para mostrar apenas os atletas da categoria atual
              const filteredCategoryData = filteredData.filter(
                (value: any) => value.category === category
              );

              // mostrar as inscrições apenas se houver atletas na categoria atual
              if (filteredCategoryData.length > 0 && showData) {
                return (
                  <React.Fragment key={category}>
                    <PageTitle>{category}</PageTitle>
                    {filteredCategoryData.map((value: any) => (
                      <SubscriptionList key={value.id}>
                        <SubscriptionItem>
                          <SubscriptionContent>
                            <SubscriptionTitle>
                              Inscrição Nº {value.id} - III OPEN TERRA SANTA DE JIU-JITSU
                            </SubscriptionTitle>
                            <br />
                            <SubscriptionDescription>
                              <InfoContainerText>
                                <span>
                                  <b>Nome:</b>{" "}
                                  {value.fighters ? value.fighters.name : value.user.name}
                                </span>
                                <span>
                                  <b>Equipe: </b>{" "}
                                  {value.fighters ? value.fighters.gym : value.user.gym}
                                </span>
                                {/* <span>Professor: ORLEY LOBATO</span><br/> */}
                                <span>
                                  <b>Categoria(s):</b> {value.genre.toUpperCase()} (
                                  {value.category.toUpperCase()}) /{" "}
                                  {value.belt.toUpperCase()} / {value.weight}                                  
                                </span>
                              </InfoContainerText>
                              <InfoContainerStatus>
                                <StatusButton status={value.status}>
                                  <span>
                                    {value.status
                                      ? "Pagamento efetuado"
                                      : "Pendente de pagamento"}
                                  </span>
                                </StatusButton>
                              </InfoContainerStatus>
                            </SubscriptionDescription>
                          </SubscriptionContent>
                        </SubscriptionItem>
                      </SubscriptionList>
                    ))}
                  </React.Fragment>
                );
              } else {
                // não há atletas nesta categoria, então não renderizar nada
                return null;
              }
            })}
          </SubscriptionContainer>
        </Content>
      </Container>
    </>
  );
};

export default Check;
