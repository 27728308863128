import React, { ChangeEvent, useEffect } from "react";
import { Field, InputContainer, Label, ValidateHelp } from "./styles";

type SelectProps = {
  label: string;
  placeholder?: string;
  value?: string | number;
  name?: string;
  hasError?: boolean;
  error?: string;
  register: any;
  required: any;
  options: any;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

const SelectCategories: React.FC<SelectProps> = ({
  label,
  placeholder,
  value,
  name,
  hasError,
  error,
  register,
  required,
  options,
  onChange
}) => {
  const [messageError, setMessageError] = React.useState("");

  useEffect(() => {
    if (hasError && error) {
      setMessageError(error);
    } else {
      setMessageError("");
    }
  }, [hasError, error]);

  return (
    <InputContainer>
      <Label>{label}</Label>
      <Field
        hasError={hasError && error}
        value={value}
        placeholder={placeholder}
        {...register(name, { required })}
        onChange={onChange}
      >
        {options &&
          options.map((option: any, key: any) => (
            <option key={key} value={option.option}>
              {option.title}
            </option>
          ))}
      </Field>
      <ValidateHelp>{messageError || ""}</ValidateHelp>
    </InputContainer>
  );
};

export default SelectCategories;
