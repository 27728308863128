import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import Collapse from "../../components/atoms/colapso";
import Footer from "../../components/organisms/footer";
import NavBar from "../../components/organisms/navbar";
import { FaMapMarkerAlt } from 'react-icons/fa';
//import useAuthenticate from "../../hooks/useAuthenticate";
import {
  Container,
  Content,
  H3,
  H5,
  Location,
  P,
  QRCodeImg,
  Span,
  Title,
  TournamentActions,
  TournamentBanner,
  TournamentContainer,
  TournamentContainerBanner,
  TournamentContent,
  TournamentDescription,
  TournamentEnrollmentLink,
  TournamentEnrollmentLinkEndpage,
  TournamentLink,
  EditalContainer,
  EditalContent,
  EditalTitle
} from "./styles";
// import tabelaPeso from "../../assets/images/tournaments/toledo-international-open-brazilian-jiu-jitsu-2023/tabepla-de-peso.jpeg";
import tabelaPeso from "../../assets/images/tournaments/copa-lobo-bravo-cresol-de-jiu-jitsu/tabela-copa-lobo-bravo-cresol-de-jiu-jitsu.jpg";
import qrCodecopaAmizadeDeJiuJitsu2024 from "../../assets/images/tournaments/copa-lobo-bravo-cresol-de-jiu-jitsu/qr-coce-copa-lobo-bravo-cresol-de-jiu-jitsu.jpg";


//Patrocinio
import torneio from "../../assets/images/sponsors/torneio-facil.png";
import expressiva from "../../assets/images/sponsors/expressiva.jpg";
import qslAutopeças from "../../assets/images/sponsors/qsl-autopeças.jpg";
import jordanaBier from "../../assets/images/sponsors/jordana_bier.jpg";
import realizaVeiculos from "../../assets/images/sponsors/realiza-veiculos.jpg";
import gauchaCostelaria from "../../assets/images/sponsors/gaucha-costelaria.jpg";
import adriferCentroAutomotivo from "../../assets/images/sponsors/adrifer-centro-automotivo.jpg";
import okoAgricola from "../../assets/images/sponsors/oko-agricola.jpg";
import mixSupermercados from "../../assets/images/sponsors/mix-supermercados.jpg";
import epaxConstrutora from "../../assets/images/sponsors/epax-construtora.jpg";
import escolaSssuncaoDeNossaSenhora from "../../assets/images/sponsors/escola-assunção-de-nossa-senhora.jpg";
import zupoImobiliaria from "../../assets/images/sponsors/zupo-imobiliaria.jpg";
import ictusProdutosParaSaude from "../../assets/images/sponsors/ictus-produtos-para-saude.jpeg";
import yamaKimonos from "../../assets/images/sponsors/yama-kimonos.jpg";
import contudoAcabamentos from "../../assets/images/sponsors/contudo-acabamentos.jpg";
import dalba from "../../assets/images/sponsors/dalba.jpg";
import academiaUsina from "../../assets/images/sponsors/academia-usina.jpg";
import ConcretizeConcretoEArgamassa from "../../assets/images/sponsors/concretize-concreto-e-argamassa.jpg";
import colegioLobo from "../../assets/images/sponsors/colegio-lobo.jpg";
import specialrteUnique from "../../assets/images/sponsors/specialrte-unique.jpg";
import onoSushiSushiSashimi from "../../assets/images/sponsors/ono-sushi-sushi-&-sashimi.jpg";


//Apoio
import trevusMartialArts from "../../assets/images/sponsors/trevus-martial-arts.jpg";


import { SponsorContainer, SponsorList } from "src/components/atoms/sponsor-area/styles";
import SponsorButton from "src/components/atoms/sponsor-area";
import useAuthenticate from "../../hooks/useAuthenticate";


const folderTorneio = require("../../assets/images/tournaments/i-copa-black-belt-de-jiu-jitsu/i-copa-black-belt-de-jiu-jitsu-2024.jpg");

const rotaInscriçãoGi = "/enrollment/copa-lobo-bravo-cresol-de-jiu-jitsu-ginasio-assuncao-de-nossa-senhora-guarapuava-pr"
const rotaInscriçãoNoGi = "/enrollment/copa-lobo-bravo-cresol-de-jiu-jitsu-ginasio-assuncao-de-nossa-senhora-guarapuava-pr"
const rotaChecagemGi = "/check/copa-lobo-bravo-cresol-de-jiu-jitsu-ginasio-assuncao-de-nossa-senhora-guarapuava-pr/search"
const rotaChecagemNoGi = "/check/copa-lobo-bravo-cresol-de-jiu-jitsu-ginasio-assuncao-de-nossa-senhora-guarapuava-pr/search"



const rotaChavesGI = "/enrollment/copa-lobo-bravo-cresol-de-jiu-jitsu-ginasio-assuncao-de-nossa-senhora-guarapuava-pr"
const rotaChavesNoGI = "/enrollment/copa-lobo-bravo-cresol-de-jiu-jitsu-ginasio-assuncao-de-nossa-senhora-guarapuava-pr"

const dataEvento = "31 de agosto de 2024"
const horaEvento = "13:00 horas"
const tituloEvento = "Copa Lobo Bravo Cresol de Jiu Jistu"
const localEvento = "Ginásio Assunção de Nossa Senhora"
const endereçoEvento = "Rua Cinco de Outubro, 1465 - Trianon, Guarapuava - PR, CEP 85012-050"
const localizacao = "https://maps.app.goo.gl/dCt55ZdeZY9qm9DU8?g_st=ac"

const dataDeEncerramentoIncricao = "27/08/2024"
const horaDeEncerramentoIncricao = "23h:59min"

const dataDeEncerramentoCorrecao = "27/08/2024"
const horaDeEncerramentoCorrecao = "23h:59min"

const dataDeEncerramentoInscricaoLote1 = "24/08/2024"
const dataDeEncerramentoInscricaoLote2 = "27/08/2024"

const valorinscricaoCategoriaLote1 = "100,00 (cem reais)"
const valorinscricaoCategoriaLote2 = "120,00 (cento e vinte reais)"

// const valorinscricaoAbsolutoLote1 = "30,00 (trinta reais)"
// const valorinscricaoAbsolutoLote2 = "40,00 (quarenta e cinco reais)"

const dataDeDivulgacaoChaves = "30/08/2024"
const horaDeDivulgacaoChaves = "23h:59min"

const instituicaoBancaria = "NuBank"
const tipoDeChavePix = "Telefone"
const ChavePix = "92 98129-1027"
const BeneficiarioDaChavePix = "Thiago Souza Dellarmelina"

const dataDeDivulgacaoCronograma = "SERÁ DIVULGADO DIA 27/09/2024"
const horaDeDivulgacaoCCronograma = " ATÉ AS 23H:59MIN "

const contatoWhatsApp = "(92) 98129-1027"


//Patrocinio
const sponsors = [
  { img: torneio, url: "http://www.torneiofacil.com" },
  { img: expressiva, url: "#" },
  { img: qslAutopeças, url: "#" },
  { img: jordanaBier, url: "#" },
  { img: realizaVeiculos, url: "#" },
  { img: gauchaCostelaria, url: "#" },
  { img: adriferCentroAutomotivo, url: "#" },
  { img: okoAgricola, url: "#" },
  { img: mixSupermercados, url: "#" },
  { img: epaxConstrutora, url: "#" },
  { img: escolaSssuncaoDeNossaSenhora, url: "#" },
  { img: zupoImobiliaria, url: "#" },
  { img: ictusProdutosParaSaude, url: "#" },
  { img: yamaKimonos, url: "#" },
  { img: contudoAcabamentos, url: "#" },
  { img: dalba, url: "#" },
  { img: academiaUsina, url: "#" },
  { img: ConcretizeConcretoEArgamassa, url: "#" },
  { img: colegioLobo, url: "#" },
  { img: specialrteUnique, url: "#" },
  { img: onoSushiSushiSashimi, url: "#" },
];



//Apoio
const support = [
  //{ img: esporteNaPeriferia },
  { img: torneio },
];

const CopaLoboBravoCresolDeJiuJitsu: React.FC = () => {
  const { authenticated } = useAuthenticate();

  return (
    <>
      <NavBar />

      <Container>
        <TournamentContainer image={folderTorneio} >
          <TournamentContainerBanner>
            <TournamentBanner image={folderTorneio} />
          </TournamentContainerBanner>
        </TournamentContainer>
        <TournamentContent>
          <Content>
            <Title>{tituloEvento}</Title>
            <Location>
              <Span>Data: {dataEvento}</Span>
              <Span>Início: {horaEvento}</Span>
              <Span>Local: {localEvento}</Span>
              <Span>Endereço: {endereçoEvento}</Span>
              <Span>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                  <FaMapMarkerAlt style={{ marginRight: '8px', color: "red" }} />
                  <a
                    target={"blank"}
                    style={{ color: "#000", textDecoration: "none", fontWeight: "bold" }}
                    href={localizacao}
                  >
                    Ver no Google Maps
                  </a>
                </div>
              </Span>
            </Location>
            <P></P>
            <br />

          </Content>
        </TournamentContent>
        <TournamentDescription>
          <TournamentActions>
            <Content>
              <h1 style={{ textAlign: "center" }}>PATROCINADORES</h1>
              <SponsorContainer>
                <SponsorList>
                  {sponsors && sponsors.map((buttons: any, index: number) => (
                    <SponsorButton
                      key={index} // Adicionei uma chave única para cada botão
                      img={buttons.img}
                      url={buttons.url}
                      hasError={false}
                      error="Este campo é obrigatório"
                      target="_blank" // Adicione este atributo
                    />
                  ))}
                </SponsorList>
              </SponsorContainer>
              <h1 style={{ textAlign: "center" }}>APOIO</h1>
              <SponsorContainer>
                <SponsorList>
                  {support &&
                    support.map((buttons: any) => (
                      <SponsorButton
                        img={buttons.img}
                        url={buttons.url}
                        hasError={false}
                        error="Este campo é obrigatório"
                      />
                    ))}
                </SponsorList>
              </SponsorContainer>
            </Content>

            <Content style={{
              marginTop: '32px'
            }}>

              <EditalContainer>
                <EditalTitle>EDITAL {tituloEvento.toLocaleUpperCase()}</EditalTitle>
                <EditalContent>
                  <Span>
                    A {tituloEvento} é um evento tradicional que fomentando e incentiva
                    a prática do jiu jitsu
                    no estado do Paraná . As lutas serão com GI (com kimono), o
                    evento disponibilizará categorias para todas as idades e faixas,
                    conforme tabela de peso por categoria deste edital. Os
                    competidores terão a oportunidade de testar suas habilidades em
                    um ambiente controlado e com acompanhamento de profissionais
                    qualificados.
                  </Span>
                  <br />
                  <br />
                  <br />
                </EditalContent>

                <Collapse title="PROTOCOLO DE SEGURANÇA CONTRA COVID-19">
                  <P>
                    Devido a dinâmica da situação atual, estamos avaliando diariamente as
                    recomendações dos Órgãos de Saúde e este protocolo pode ser atualizado a
                    qualquer momento caso novas determinações sejam publicadas.
                  </P>
                  <P>
                    Caso o evento seja cancelado por força maior, todas as inscrições serão
                    automaticamente reembolsadas.
                  </P>
                  <P>
                    A organização não é responsável pelos demais custos dos participantes,
                    tais como transporte e hospedagem.
                  </P>
                  <P>
                    Diretrizes do ginásio: Para a segurança de todos, por favor verifique
                    sua temperatura antes de se dirigir ao evento.{" "}
                  </P>
                  <P>Caso haja qualquer sintoma, procure um médico.</P>{" "}
                  <P>
                    {" "}
                    Caso você se sinta indisposto ou esteve em contato com um paciente
                    diagnosticado com Covid-19, por favor fique em casa e procure seu
                    médico.
                  </P>
                  {/* <P>
                  Todo nosso material será desinfetado antes e durante o evento.
                </P> */}
                </Collapse>
                <Collapse title="PREMIAÇÕES EM MEDALHAS🥇 e DINHEIRO💰">
                  <br />
                  <H3>Absoluto Masculino:</H3>
                  <H5>Juvenil Branca</H5>
                  <P>1° lugar: Kimono</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>
                  <H5>Juvenil Azul e Roxa (juntos)</H5>
                  <P>1° lugar: Kimono</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>
                  <H5>Adulto Branca</H5>
                  <P>1° lugar: R$250,00</P>
                  <P>2° lugar: R$150,00</P>
                  <P>3° lugar: R$100,00</P>
                  <H5>Adulto Azul</H5>
                  <P>1° lugar: R$300,00</P>
                  <P>2° lugar: R$200,00</P>
                  <P>3° lugar: R$100,00</P>
                  <H5>Adulto roxa</H5>
                  <P>1° lugar: R$400,00</P>
                  <P>2° lugar: R$200,00</P>
                  <P>3° lugar: R$100,00</P>
                  <H5>Adulto Marrom</H5>
                  <P>1° lugar: R$500,00</P>
                  <P>2° lugar: R$200,00</P>
                  <P>3° lugar: R$100,00</P>
                  <H5>Adulto Preta</H5>
                  <P>1° lugar: R$600,00</P>
                  <P>2° lugar: R$300,00</P>
                  <P>3° lugar: R$100,00</P>
                  <br />

                  <H3>Absoluto Feminino:</H3>
                  <H5>Juvenil Branca</H5>
                  <P>1° lugar: Kimono</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>
                  <H5>Juvenil Azul e Roxa (juntos)</H5>
                  <P>1° lugar: Kimono</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>
                  <H5>Adulto Branca</H5>
                  <P>1° lugar: R$250,00</P>
                  <P>2° lugar: R$150,00</P>
                  <P>3° lugar: R$100,00</P>
                  <H5>Adulto Azul e Roxa (Juntas)</H5>
                  <P>1° lugar: R$400,000</P>
                  <P>2° lugar: R$200,00</P>
                  <P>3° lugar: R$100,00</P>
                  <H5>Adulto Marrom e Preta (Juntas)</H5>
                  <P>1° lugar: R$600,000</P>
                  <P>2° lugar: R$300,00</P>
                  <P>3° lugar: R$100,00</P>

                  <br />

                  <H3>Demais Categorias</H3>
                  <P>1° lugar: medalha</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>
                  <br />
                  <p>OBS: Estas premiações só irão valer para os Absolutos que tiverem 8 (OITO)
                    ou mais atletas inscritos.</p>
                  <p>Em caso com menos de 8 (OITO) inscritos no Absoluto, a premiação para o campeão, para segundo
                    e terceiro será apenas a medelha.
                  </p>
                  <p>Mais informações sobre as inscriçoes para o absoluto esta disponivel na seção
                    INSCRIÇÕES, paragrafo Inscrição de Absoluto deste edital.
                  </p>
                  <br />

                  <H3>Demais Categorias GI</H3>
                  <P>1° lugar: medalha</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>
                </Collapse>

                <Collapse title="INSCRIÇÕES">
                  <Span>
                    As inscrições para o evento serão realizadas exclusivamente online, por
                    meio do site www.torneiofacil.com.br
                  </Span>
                  <P>
                    Durante o processo de inscrição, o atleta ou seu responsável legal
                    deverá selecionar corretamente a categoria referente idade que o atleta
                    completará no ano de 2024, bem como sua faixa e peso.
                  </P>
                  <P>
                    A idade de competição do atleta é a idade máxima que ele terá em 2024,
                    conforme regra da CBJJ.
                  </P>
                  <P>
                    Para os adultos e masters, haverá apenas inscrição nos Absolutos via site, seguindo o valor
                    referente ao Lote 1 ou Lote 2 conforme a data de pagamento prevista nesse edital,
                    não serão realizadas inscrições durante o evento.
                  </P>
                  <P>
                    Às inscrições para os Absolutos Juvenis serão feitos durante o evento,
                    após a realização das categorias de peso, se limitando apenas aos três primeiros colocados de cada categoria.
                  </P>
                  <br />

                  <H3>Prazo de Inscrição</H3>
                  <P>
                    O prazo final para inscrições e pagamento será até o dia {dataDeEncerramentoIncricao}, às
                    {horaDeEncerramentoIncricao}min.
                  </P>
                  <br />
                  <P>As inscrições que não forem pagas serão canceladas.</P>
                  <br></br>
                  <br />
                  <H3>Correção</H3>

                  <P>
                    A correção da inscrição poderá ser realizada somente após a confirmação
                    do pagamento.
                  </P>
                  <P>
                    Em caso de inscrição equivocada, o atleta ou professor responsável
                    poderá corrigir as informações no site www.torneiofacil.com.br,
                    acessando a opção Meu Perfil em seguida Minhas Inscrições.
                  </P>
                  <P>
                    A correção da inscrição ficará disponivel até o encerramento das
                    inscrições {dataDeEncerramentoCorrecao} as {horaDeEncerramentoCorrecao}.
                  </P>
                  <P>
                    Após prazo para correção das inscrições, não serão aceitas modificações
                    nas inscrições.
                  </P>
                  <P>
                    Os atletas que se inscreverem incorretamente estarão sujeitos a
                    desclassificação <b>sem direito a reembolso.</b>
                  </P>

                  <br />

                  <H3>Prazo de Correção</H3>
                  <P>
                    A correção da inscrição fica disponível após a confirmação do pagamento
                    da mesma.
                  </P>

                  <P>
                    A correção da inscrição ficará disponivel até o encerramento das
                    inscrições no {dataDeEncerramentoCorrecao} as {horaDeEncerramentoCorrecao}.
                  </P>
                  <H3>Checagem</H3>

                  <P>
                    O sorteio e divulgação das chaves de lutas será no dia {dataDeDivulgacaoChaves}4 até as {horaDeDivulgacaoChaves}.
                  </P>

                  <br />
                  <H3>Inscrição de Absoluto</H3>
                  <P>
                    Somente atletas inscritos na categoria poderão se increver e participar
                    do absoluto.
                  </P>
                  <P>
                    As inscrições para os absolutos Juvenil são gratuitas, podendo ter no máximo 03 (TRÊS)
                    atletas da mesma equipe por absoluto.
                  </P>
                  <P>
                    As inscrições para os absolutos Juvenil serão realizadas pelo professor
                    responsável pelo atleta, junto a organização do evento no dia do torneio
                    após a finalização das lutas da categoria.
                  </P>
                  <P>
                    Os atletas só poderão se inscrever no absoluto referente a categoria da
                    sua inscrição no torneio. Exceção apenas para os master que poderá se
                    inscrever como absoluto adulto.
                  </P>
                </Collapse>
                <Collapse title="VALOR DA INSCRIÇÃO">
                  <Span>
                    As inscrições serão divididas em dois lotes
                  </Span>
                  <br />
                  <H3>Primeiro Lote até {dataDeEncerramentoInscricaoLote1}</H3>
                  <P>Inscrição categoria R$ {valorinscricaoCategoriaLote1}</P>
                  {/* <P>Inscrição absoluto R$ {valorinscricaoAbsolutoLote1} (trinta reais)</P> */}
                  <br />
                  <H3>Segundo Lote até {dataDeEncerramentoInscricaoLote2}</H3>
                  <P>Inscrição categoria R$ {valorinscricaoCategoriaLote2}</P>
                  {/* <P>Inscrição absoluto R$ {valorinscricaoAbsolutoLote2} (quarenta e cinco reais)</P> */}
                  <br />
                  <br />
                </Collapse>
                <Collapse title="PAGAMENTO">
                  <H3>
                    A confirmação de pagamento deverá ser realizada via WhatsApp enviando o
                    comprovante para o contato abaixo:
                  </H3>
                  <P>Banco: {instituicaoBancaria}</P>
                  <P>Titular: {BeneficiarioDaChavePix}</P>
                  <P>Chave PIX: {tipoDeChavePix}</P>
                  <P>Chave PIX: {ChavePix}</P>
                  {/* <QRCodeImg src={qrCodecopaAmizadeDeJiuJitsu2024} alt="qrCode" width={300} />
                  <P>
                    <a
                      target={"blank"}
                      style={{ color: "white" }}
                      href="https://whatsa.me/5592981291027/?t=Ol%C3%A1+Thiago_Dellarmelina%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+na+%2ACOPA+ORLEY+LOBATO+DE+JIU+JITSU+2024%2A"
                    >
                      <strong>WhatsApp: +55 (92) 98129-1027 </strong>
                      <FaWhatsapp />
                    </a>
                  </P> */}
                  <p />
                  {/* <QRCodeImg src={qrCodeCopaParana} alt="qrCode" width={300} /> */}
                </Collapse>

                <Collapse title="REGRAS">
                  <Span>
                    Todas as regras para o evento estão devidamente estipuladas e detalhadas neste edital.
                  </Span>
                  <Span>
                    Em casos de omissão de regras no presente edital, as normas a serem observadas serão aquelas estabelecidas pela Confederação
                    Brasileira de Jiu-Jitsu (CBJJ), em conformidade com as diretrizes da CBJJ/IBJJF.
                  </Span>
                  <Span>
                    A tabela de peso por categoria, que estabelece os limites de peso para cada categoria, encontra-se disponível no site oficial do evento,
                    acessível por meio do botão denominado "Tabela de Peso".
                  </Span>
                  <Span>
                    As categorias de peso e idade, de faixa laranja/ verde serão inscritos em mesma categoria.
                  </Span>
                  <Span>
                    Para as categorias de  pré mirim a Juvenil , dos inscritos que não tiverem luta na categoria,
                    poderão ser feitas lutas casadas desde que com concordância do professor do inscrito com a
                    Organização do Evento.
                  </Span>
                  <Span>
                    As lutas dos Absolutos de todas as faixas terão tempo de luta fixado em 5 minutos.
                  </Span>
                  <Span>
                    As lutas serão com GI (com kimonos de cores Oficiais pela cbjj branco,
                    azul e preto ) os kimonos devem estar limpos sem rasgos e sem odor .
                  </Span>
                  <Span>
                    Para categoria com 3 lutadores, os atletas farão a melhor deda chave estará automaticamente na final.
                    três para definição do campão, segundo e terceiro lugar conforme regra da CBJJ
                  </Span>
                  <Span>
                    A pesagem será realizada no dia do evento. O competidor deverá
                    apresentar documento oficial com foto no momento da pesagem.
                  </Span>
                  <Span>
                    A pesagem dos atletas serão realizadas antes do inicio da categoria.
                  </Span>
                  <Span>
                    Atletas que ultrapasarem o limite de peso da categoria que entiverem
                    inscritos serão automaticamente desclassificados e não poderão lutar o
                    Absoluto.
                  </Span>
                  <Span>Será realizada apenas uma pesagem oficial por atleta.</Span>
                  <Span>
                    Teremos uma balança para pesagem livre (não oficial) para os atletas.
                  </Span>
                  <Span>Teremos duas balanças para pesagem oficial dos atletas.</Span>
                  <Span>
                    Atletas que lutaram em outros eventos com faixa superior não poderão
                    lutar com a faixa abaixo. Se descoberto, o competidor será eliminado do
                    torneio sem direito a reembolso.
                  </Span>
                  <br />
                  <P>
                    O que não estiver neste edital, será acatado o que estiver no livro de
                    regras da CBJJ.
                  </P>
                  <br />
                  <P>
                    <a
                      target={"blank"}
                      href="https://cbjj.com.br/books-videos"
                      style={{ color: "white" }}
                    >
                      <strong>LIVRO DE REGRAS DA CBJJ/IBJJF</strong>
                    </a>
                  </P>
                </Collapse>
                <Collapse title={"CRONOGRAMA " + dataDeDivulgacaoCronograma + horaDeDivulgacaoCCronograma}>
                {/* <Collapse title={"CRONOGRAMA SERÁ LIBERADO ATÉ O DIA " + dataDeDivulgacaoCronograma + " AS " + horaDeDivulgacaoCCronograma}> */}
                  {/* (EM BREVE) */}
                  <Container>
                    {/* <b><Span>Obs: O cronograma definitivo será atualizado no dia 11/11/2023.</Span></b>
                  <br />
                  <Span>Data: 14 de abril de 2024</Span>
                  <Span>Início: 9:00h da manhã</Span>
                  <Span>Local: Ginásio de Esportes Amário Vieira da Costa, Umuarama PR</Span>
                  <Span>Endereço: Av. Gov. Parigot de Souza, 4284-4454 - Zona I, Umuarama - PR, CEP: 87503-680</Span>
                  <br />
                  <H5>9:30h - Abertura do evento</H5>
                  <br />
                  <H3>Lutas GI</H3>
                  <H5>9:45h - 04, 05, 06, 07, 08, 09, 10 e 11 anos (Faixa branca e Graduado) masculino e feminino.</H5>
                  <H5>10:45h - 12, 13, 14 e 15 anos (Faixa branca e Graduado) masculino e feminino.</H5>
                  <H5>11:30h - 16 e 17 anos (Faixa Branca) masculino e feminino.</H5>
                  <H5>11:30h - 16 e 17 anos (Faixa Branca) masculino e feminino.</H5>
                  <H5>12:00h - Adulto (Faixa Branca) masculino e feminino.</H5>
                  <H5>12:40h - Master (Branca) masculino e feminino.</H5>
                  <H5>12:40h - Adulto (Faixa Azul) masculino e feminino.</H5>
                  <H5>13:40h - Adulto (Faixa Roxa) masculino e feminino.</H5>
                  <H5>13:40h - Master (Faixa Azul) masculino e feminino.</H5>
                  <H5>15:00 - Adulto e Master (Faixa Preta ) Masculino e Feminino</H5>
                  <br /> */}
                    <H5>
                      Todos os atletas devem estar 01(uma) hora antes do tempo de sua
                      categoria na área de aquecimento podendo lutar até 30 min antes do
                      horário previsto no cronograma
                    </H5>
                    <br />
                    <H5>
                      Obs.1: O atleta deverá comparecer no ginasio com uma (01) hora de antecedência do
                      horário previsto no cronograma.
                    </H5>

                    <Span>Usaremos 6 áreas de luta</Span>
                    <Span>
                      O cronograma pode ser adiantado em até 1 hora, e atrasar conforme a
                      necessidade do evento.
                    </Span>
                    <Span>
                      O atleta que não estiver presente no momento da chamada para a pesagem
                      ou luta ser desclassificado por W.O.
                    </Span>
                  </Container>
                </Collapse>
                <Collapse title="ENTRADA">
                  <P>Entrada liberada ao público.</P>
                  <br />
                  {/* <P>
                  Será cobrado 1kg de alimento não perecível na entrada para atletas e
                  público.
                </P> */}
                  {/* <br />
                <P>Crianças até 5 anos ficam isentas de qualquer tipo de pagamento.</P>
                <br /> */}
                </Collapse>
                <Collapse title="CONTATO">
                  <P>
                    Duvidas sobre cadastro, inscrição, pagamento, correção, checagem
                    ou sugestão de melhorias entre em contato com a equipe do Torneio
                    Fácil por telefone ou WhatsApp.
                  </P>
                  <P>
                    Melhor horário para atendimento das 18:00h as 23:59h.
                  </P>
                  <a target={"blank"} href="https://whatsa.me/+5592981291027/?t=Ol%C3%A1+Thiago+Dellarmelina+%2APreciso+de+ajuda+com+minha+inscrição+na+XXXII+COPA+ORLEY+LOBATO+DE+JIU+JITSU+2024%2A*"
                  >
                    <strong>Telefone/WhatsApp: {contatoWhatsApp} </strong>
                    <FaWhatsapp />
                  </a>

                  <br />
                </Collapse>
              </EditalContainer>
            </Content>
            <br />
            <Content>
              {authenticated ? (
                <>
                  <TournamentEnrollmentLinkEndpage to={rotaInscriçãoGi}>
                    Inscrição
                  </TournamentEnrollmentLinkEndpage>

                  <TournamentEnrollmentLinkEndpage to="/profile/subscription">
                    Correção da Inscrição
                  </TournamentEnrollmentLinkEndpage>
                </>
              ) : (
                <>
                  <TournamentEnrollmentLinkEndpage to="/login">
                    Inscrição
                  </TournamentEnrollmentLinkEndpage>

                  <TournamentEnrollmentLinkEndpage to="/login">
                    Correção da Inscrição
                  </TournamentEnrollmentLinkEndpage>
                </>
              )}
              {/* <TournamentLink href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.767B171E&u=teste" target="_blank">Chaves</TournamentLink> */}
              {/* // /check/16-COPA-PARANA-DE-JIU-JITSU */}

              <TournamentLink
                // href="#"
                // target="_blank"
                href={rotaChecagemGi}
              //href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.D7A54E37&u=teste"
              >Checagem
              </TournamentLink>

              {/* 
              <TournamentLink
                href="/check/toledo-international-open-brazilian-jiu-jitsu-nogi-2023-ginasio-de-esportes-alcides-pan-toledo-pr/search"
                target="_blank"
              >
                Checagem de atletas NO GI
              </TournamentLink>  */}

              {/*               <TournamentLink
                href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.D7A54E37&u=teste"
                target="_blank"
              >
                Chaves GI
              </TournamentLink> */}

              {/*               <TournamentLink
                href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.5B5B1C9A&u=teste"
                target="_blank"
              >
                Chaves NO GI
              </TournamentLink> */}

              <TournamentLink
                target="blank"
                href={tabelaPeso}>
                Tabela de pesos
              </TournamentLink>
              {/* <TournamentLink href="#">Resultados (EM BREVE)</TournamentLink> */}
            </Content>
          </TournamentActions>
        </TournamentDescription>
      </Container>
      <Footer />
    </>
  );
};

export default CopaLoboBravoCresolDeJiuJitsu;
