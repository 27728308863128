import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import Collapse from "../../components/atoms/colapso";
import Footer from "../../components/organisms/footer";
import NavBar from "../../components/organisms/navbar";
//import useAuthenticate from "../../hooks/useAuthenticate";
import {
  Container,
  Content,
  H3,
  H5,
  Location,
  P,
  QRCodeImg,
  Span,
  Title,
  TournamentActions,
  TournamentBanner,
  TournamentContainer,
  TournamentContainerBanner,
  TournamentContent,
  TournamentDescription,
  TournamentEnrollmentLink,
  TournamentEnrollmentLinkEndpage,
  TournamentLink,
} from "./styles";
// import tabelaPeso from "../../assets/images/tournaments/international-circuit/tabepla-de-peso.jpeg";
import tabelaPeso from "../../assets/images/tournaments/xxxi-copa-orley-lobato/TABELA-XXIXX-COPA-ORLEY-LOBATO-2023.jpg";

import qrCodeCopaParana from "../../assets/images/tournaments/international-circuit/qrCodeCopaParana.png";

import torneio from "../../assets/images/sponsors/torneio-facil.png";
import twr from "../../assets/images/sponsors/twr.png";
import goldStar from "../../assets/images/sponsors/gold-star.jpeg";
import acai72 from "../../assets/images/sponsors/acai72.jpeg";
import toledo from "../../assets/images/sponsors/toledo.png";
import keikosports from "../../assets/images/sponsors/keikosports.jpeg";
import prime from "../../assets/images/sponsors/LOGO-PRIME.png";
import yaraCountry from "../../assets/images/sponsors/yara-country.png";
import Zaeli from "../../assets/images/sponsors/ZaeliLogo.png";

import {
  SponsorContainer,
  SponsorList,
} from "src/components/atoms/sponsor-area/styles";
import SponsorButton from "src/components/atoms/sponsor-area";
import useAuthenticate from "../../hooks/useAuthenticate";

const sponsors = [
  {
    img: twr,
  },
  {
    img: goldStar,
  },
  // {
  //   img: acai72,
  // },
  // {
  //   img: toledo,
  // },
  { img: keikosports },
  { img: prime },
  { img: yaraCountry },
  // { img: Zaeli },
  {
    img: torneio,
  },
];

const TournamentInternationalCircuit: React.FC = () => {
  const { authenticated } = useAuthenticate();

  return (
    <>
      <NavBar />

      <Container>
        <TournamentContainer
          image={require("../../assets/images/tournaments/international-circuit/international-circuit.jpeg")}
        >
          <TournamentContainerBanner>
            <TournamentBanner
              image={require("../../assets/images/tournaments/international-circuit/international-circuit.jpeg")}
            />
          </TournamentContainerBanner>
        </TournamentContainer>
        <TournamentContent>
          <Content>
            <Title>INTERNATIONAL CIRCUIT GI E NO GI DE JIU JITSU</Title>
            <Location>
              <Span>Data: 10 de setembro de 2023</Span>
              {/* <Span>Início: 9h</Span> */}
              <Span>Local: Ginásio Ciro Nardi, Cascavel - PR</Span>
            </Location>
            <P></P>
            <br />
            <Collapse title="EDITAL INTERNATIONAL CIRCUIT GI E NO GI DE JIU JITSU">
              <Span>
                O International Circuit GI e NO GI de Jiu Jitsu é o evento mais
                tradicional do Oeste Paranaense fomentando e incentivando a
                prática do jiu jitsu no Paraná . As lutas serão com GI (com
                kimono) e NO GI (sem kimono), o evento disponibilizará
                categorias para todas as idades e faixas, conforme as tabelas de
                peso deste edital categoria deste edital. Os competidores terão
                a oportunidade de testar suas habilidades em um ambiente
                controlado e com acompanhamento de profissionais qualificados.
              </Span>
              <br />
              <br />
              <br />
              <Collapse title="PROTOCOLO DE SEGURANÇA CONTRA COVID-19">
                <P>
                  Devido a dinâmica da situação atual, estamos avaliando
                  diariamente as recomendações dos Órgãos de Saúde e este
                  protocolo pode ser atualizado a qualquer momento caso novas
                  determinações sejam publicadas.
                </P>
                <P>
                  Caso o evento seja cancelado por força maior, todas as
                  inscrições serão automaticamente reembolsadas.
                </P>
                <P>
                  A organização não é responsável pelos demais custos dos
                  participantes, tais como transporte e hospedagem.
                </P>
                <P>
                  Diretrizes do ginásio: Para a segurança de todos, por favor
                  verifique sua temperatura antes de se dirigir ao evento.{" "}
                </P>
                <P>Caso haja qualquer sintoma, procure um médico.</P>{" "}
                <P>
                  {" "}
                  Caso você se sinta indisposto ou esteve em contato com um
                  paciente diagnosticado com Covid-19, por favor fique em casa e
                  procure seu médico.
                </P>
                <P>
                  Todo nosso material será desinfetado antes e durante o evento.
                </P>
              </Collapse>
              <Collapse title="PREMIAÇÕES ABSOLUTO GI">
                {/* <H3>Por Equipes </H3>
                <P>1° lugar: Troféu + 2 kimonos</P>
                <P>2° lugar: Troféu + 2 kimonos</P>
                <P>3° lugar: Troféu + 1 kimono</P>
                <P>4° lugar: Troféu + 1 kimono</P>
                <P>5° lugar: Troféu + 1 kimono</P> */}
                <H3>Absoluto Masculino:</H3>
                <H5>Infanto Juvenil Graduado (Médio a Pesadissimo)</H5>
                <P>1° lugar: R$200,00 + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <H5>Juvenil Branca</H5>
                <P>1° lugar: R$200,00 + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <H5>Juvenil Azul</H5>
                <P>1° lugar: R$200,00 + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <H5>Adulto Branca</H5>
                <P>1° lugar: R$300,00 + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <H5>Master Branca</H5>
                <P>1° lugar: R$200,00 + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <H5>Adulto Azul</H5>
                <P>1° lugar: R$300,00 + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <H5>Master Azul</H5>
                <P>1° lugar: R$300,00 + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <H5>Adulto Roxa</H5>
                <P>1° lugar: R$400,00 + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <H5>Adulto Marrom</H5>
                <P>1° lugar: R$500,00 + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <H5>Adulto Preta</H5>
                <P>1° lugar: R$700,00 + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <br />
                <H3>Absoluto Feminino:</H3>

                <H5>Juvenil Branca</H5>
                <P>1° lugar: R$200,00 + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <H5>Juvenil Graduado (Todas as faixas juntas)</H5>
                <P>1° lugar: R$200,00 + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <H5>Adulto Branca</H5>
                <P>1° lugar: R$200,00 + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <H5>Adulto Azul</H5>
                <P>1° lugar: R$300,00 + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <H5>Adulto Roxa/Marrom/Preta (Todas as faixas juntas)</H5>
                <P>1° lugar: R$700,00 + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>

                <br />

                <P>
                  Mínimo de 6 atletas por absoluto para valer a premiação total.
                </P>

                <P>
                  Número menor que 6 atletas a premiação será de 50% do valor
                  total.
                </P>
                <br />

                <H3>Demais Categorias</H3>
                <P>1° lugar: medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
              </Collapse>
              <Collapse title="PREMIAÇÕES ABSOLUTO NO GI">
                {/* <H3>Por Equipes </H3>
                <P>1° lugar: Troféu + 2 kimonos</P>
                <P>2° lugar: Troféu + 2 kimonos</P>
                <P>3° lugar: Troféu + 1 kimono</P>
                <P>4° lugar: Troféu + 1 kimono</P>
                <P>5° lugar: Troféu + 1 kimono</P> */}
                <H3>Absoluto Masculino:</H3>
                <H5>Adulto Branca</H5>
                <P>1° lugar: R$300,00 + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <H5>Adulto Azul</H5>
                <P>1° lugar: R$300,00 + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <H5>Master Azul</H5>
                <P>1° lugar: R$300,00 + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <H5>Adulto Roxa/Marrom/Preta (Todas as faixas juntas)</H5>
                <P>1° lugar: R$500,00 + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>

                <br />

                <H3>Absoluto Feminino:</H3>

                <H5>Adulto Branca</H5>
                <P>1° lugar: R$200,00 + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <H5>Adulto Azul</H5>
                <P>1° lugar: R$300,00 + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <H5>Adulto Roxa/Marrom/Preta (Todas as faixas juntas)</H5>
                <P>1° lugar: R$500,00 + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>

                <br />

                <P>Mínimo de 6 atletas para valer a premiação;</P>

                <P>
                  MÍNIMO DE 4 ATLETAS PARA VALER A PREMIAÇÃO, NÚMERO MENOR QUE 4
                  ATLETAS A PREMIAÇÃO SERÁ DE 50% DO VALOR TOTAL.
                </P>

                <H3>Demais Categorias</H3>
                <P>1° lugar: medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
              </Collapse>
              <Collapse title="INSCRIÇÕES">
                <Span>
                  As inscrições para o evento serão realizadas exclusivamente
                  online, por meio do site www.torneiofacil.com.br.
                </Span>
                <P>
                  Durante o processo de inscrição, o atleta ou seu responsável
                  legal deverá selecionar corretamente a categoria referente
                  idade que o atleta completará no ano de 2023, bem como sua
                  faixa e peso.
                </P>
                <br />

                <H3>Prazo de Inscrição</H3>
                <P>
                  O prazo final para inscrições e pagamento será até o dia
                  04/09/2023, às 23h59min.
                </P>
                <br />
                <P>As inscrições que não forem pagas serão canceladas.</P>
                <br></br>
                {/* <Span>
                  No momento da inscrição, o atleta ou responsável legal deverá
                  selecionar a categoria e idade corretamente.
                </Span> */}
                <H3>Valor</H3>
                <P>Inscrição GI é de R$ 100,00 (cem reais)</P>
                <P>Inscrição NO GI é de R$ 100,00 (cem reais)</P>

                <P>
                  Inscrição GI + NO GI é de R$ 150,00 (cento e cinquenta reais)
                </P>

                <br />
                <H3>Correção</H3>

                <P>
                  A correção da inscrição poderá ser realizada somente após a
                  confirmação do pagamento.
                </P>
                <P>
                  Em caso de inscrição equivocada, o atleta ou professor
                  responsável poderá corrigir as informações no site
                  www.torneiofacil.com.br, acessando a opção Meu Perfil em
                  seguida Minhas Inscrições.
                </P>
                <P>
                  A correção da inscrição ficará disponivel até o encerramento
                  das inscrições.
                </P>
                <P>
                  Após prazo para correção das inscrições, não serão aceitas
                  modificações nas inscrições.
                </P>
                <P>
                  Os atletas que se inscreverem incorretamente estarão sujeitos
                  a desclassificação sem direito a reembolso.
                </P>

                <br />

                <H3>Prazo de Correção</H3>
                <P>
                  A correção da inscrição fica disponível após a confirmação do
                  pagamento da mesma.
                </P>

                <P>
                  A correção da inscrição podera ser feita até o dia 05/09/2023
                  às 23h59min.
                </P>
                <br />
                <H3>Inscrição de Absoluto</H3>
                <P>
                  Somente atletas inscritos na categoria poderão se increver e
                  participar do absoluto.
                  {/* Atlestas inscritos no absoluto que não
                  estiverem inscritos na categoria serão automaticamente
                  tranferidos para a categoria pessadissimo referente a seu
                  sexo, idade e faixa. */}
                </P>
                <P>
                  As inscrições para os absolutos são gratuitas, podendo ter no
                  máximo 4 atletas da mesma equipe por absoluto.
                </P>
                <P>
                  As inscrições para os absolutos serão realizadas pelo
                  professor responsável pelo atleta, junto a organização do
                  evento no dia do torneio após a finalização das lutas da
                  categoria.
                </P>
                <P>
                  Os atletas só poderão se inscrever no absoluto referente a
                  categoria da sua inscrição no torneio. Exceção apenas para os
                  master que poderá se inscrever como absoluto adulto.
                </P>
              </Collapse>
              <Collapse title="PAGAMENTO">
                <H3>
                  A confirmação de pagamento deverá ser realizada via WhatsApp
                  enviando o comprovante para o contato abaixo:
                </H3>
                <P>Caixa Econômica:</P>
                <P>Titular: Juan Cesar de Oliveira Aracati</P>
                <P>Chave PIX do tipo E-MAIL:</P>{" "}
                <P>juancesardeoliveiraaracati@gmail.com</P>
                <P>
                  <a
                    target={"blank"}
                    href="https://whatsa.me/5545999073832/?t=Ol%C3%A1+Raphaela+Franco%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+na+%2AINTERNATIONAL+CIRCUIT+GI+E+NO+GI+DE+JIU+JITSU%2A"
                  >
                    <strong>WhatsApp: (45) 99907-3832 </strong>
                    <FaWhatsapp />
                  </a>
                </P>
                <p />
                {/* <QRCodeImg src={qrCodeCopaParana} alt="qrCode" width={300} /> */}
              </Collapse>

              {/* <Collapse title="REGRAS">
                <Span>
                  As regras do evento são as da CBJJ/IBJJF. A tabela de peso por
                  categoria está disponível no botão "Tabela de Peso" no site do
                  evento.
                </Span>
                <Span>
                  Para categoria com 3 lutadores, os atletas farão a melhor de
                  três para definição conforme regra da CBJJ
                </Span>
                <Span>
                  A pesagem será realizada no dia do evento. O competidor deverá
                  apresentar documento oficial com foto no momento da pesagem.
                </Span>
                <Span>
                  Atletas que lutaram em outros eventos com faixa superior não
                  poderão lutar com a faixa abaixo. Se descoberto, o competidor
                  será eliminado do torneio sem direito a reembolso.
                </Span>
                <br />
                <H3>Pontuação por equipe</H3>
                <H5>Categoria</H5>
                <P>1° lugar: 9 pontos</P>
                <P>2° lugar: 3 pontos</P>
                <P>3° lugar: 1 ponto</P>
                <H3>Pontuação por equipe</H3>
                <H5>Absoluto</H5>

                <P>1° lugar: 13.5 pontos</P>
                <P>2° lugar: 4.5 pontos</P>
                <P>3° lugar: 1.5 ponto</P>
              </Collapse> */}
              <Collapse title="REGRAS">
                <Span>
                  As regras do evento são as da CBJJ/IBJJF. A tabela de peso por
                  categoria está disponível no botão "Tabela de Peso" no site do
                  evento.
                </Span>
                <Span>
                  Para categoria com 3 lutadores, os atletas farão a melhor de
                  três para definição do campão, segundo e terceiro lugar
                  conforme regra da CBJJ
                </Span>
                <Span>
                  A pesagem será realizada no dia do evento. O competidor deverá
                  apresentar documento oficial com foto no momento da pesagem.
                </Span>
                <Span>
                  Atletas que lutaram em outros eventos com faixa superior não
                  poderão lutar com a faixa abaixo. Se descoberto, o competidor
                  será eliminado do torneio sem direito a reembolso.
                </Span>
                <br />
                <H3>Pontuação por equipe</H3>
                <H5>Categoria</H5>
                <P>1° lugar: 9 pontos</P>
                <P>2° lugar: 3 pontos</P>
                <P>3° lugar: 1 ponto</P>
                <H5>Absoluto</H5>

                <P>1° lugar: 13.5 pontos</P>
                <P>2° lugar: 4.5 pontos</P>
                <P>3° lugar: 1.5 ponto</P>
                <br />

                <P>
                  A contagem dos pontos será realizada de forma geral
                  contabilizando os pontos de todas as faixas.
                </P>
                <P>
                  Campeão direto não contam pontos, chaves com apenas um
                  inscrito.
                </P>
                <P>
                  Campeão por WO, devido a desistencia do oponente, contará
                  pontos desde que na chave tenha mais de um inscrito.
                </P>
                <P>
                  Categorias com apenas dois atletas da mesma academia não
                  contam pontos para a disputa por academias.
                </P>
                <P>
                  O que não estiver neste edital, será acatado o que estiver no
                  livro de regras da CBJJ.
                </P>
                <br />
                <P>
                  <a
                    target={"blank"}
                    href="https://cbjj.com.br/books-videos"
                    style={{ color: "white" }}
                  >
                    <strong>LIVRO DE REGRAS DA CBJJ/IBJJF</strong>
                  </a>
                </P>
              </Collapse>
              <Collapse title="CRONOGRAMA">
                <H3>Em Breve</H3>
                {/* <Container>
                  <Span>Data: 10 de setembro de 2023</Span>
                  {/* <Span>Início: 9:40h da manhã</Span> * /}
                  <Span>Local: Ginásio Ciro Nardi, Cascavel - PR</Span>
                  <H3>Abertura do evento</H3>
                  <P>9.00h</P>
                  <H3>Lutas</H3>
                  <H5>Mirim e infantil</H5>
                  <P>9.40 hrs</P>
                  <H5>Infantil 12 e13 e infanto-juvenil</H5>
                  <P>10.30 hrs</P>
                  <H5>Juvenil e adulto branca</H5>
                  <P>11.30. hrs</P>
                  <H5>Master branca e azul adulto</H5>
                  <P>12.40 hrs</P>
                  <H5>Roxa adulto e azul Master</H5>
                  <P>13.40 hrs</P>
                  <H5>Marrom e Preta</H5>
                  <P>15.00 hrs</P>
                  <H5>Absolutos Brancas (todos)</H5>
                  <P>15.00 hrs</P>
                  <H5>Absolutos Inf-juv e Juvenil</H5>
                  <P>15.30 hrs</P>
                  <H5>Absolutos Azul e Roxa </H5>
                  <P>16.00 hrs</P>
                  <H5>Marrom e preta</H5>
                  <P>16.30 hrs</P>
                  <H3>Premiação por equipe</H3>
                  <P>17.30 hrs</P>
                  <H3>Encerramento</H3>
                  <P>18.00 hrs</P>
                  <Span>
                    Usaremos 6 áreas de luta, podendo ser acrescentadas 2 a mais
                    para chegar até 8 áreas .
                  </Span>
                  <Span>
                    O atleta deverá estar presente no ginásio pelo menos 1 hora
                    antes da sua luta.
                  </Span>
                  <Span>
                    O cronograma pode ser adiantado em até 30 minutos ou atrasar
                    conforme a necessidade do evento.
                  </Span>
                </Container> */}
              </Collapse>
              <Collapse title="ENTRADA">
                <P>
                  Será cobrado 1kg de alimento não perecível na entrada para
                  atletas e público.
                </P>
                <br />
                <P>
                  Crianças até 5 anos ficam isentas de qualquer tipo de
                  pagamento.
                </P>
                <br />
              </Collapse>
            </Collapse>
          </Content>
        </TournamentContent>
        <TournamentDescription>
          <TournamentActions>
            <Content>
              <h1>PATROCINADORES</h1>
              <SponsorContainer>
                <SponsorList>
                  {sponsors &&
                    sponsors.map((buttons: any) => (
                      <SponsorButton
                        img={buttons.img}
                        url={buttons.url}
                        hasError={false}
                        error="Este campo é obrigatório"
                      />
                    ))}
                </SponsorList>
              </SponsorContainer>
            </Content>
            <br />
            <Content>
              {authenticated ? (
                <>
                  <TournamentEnrollmentLinkEndpage to="/enrollment/international-circuit-gi-de-jiu-jitsu-cascavel-pr">
                    Inscrição GI
                  </TournamentEnrollmentLinkEndpage>

                  <TournamentEnrollmentLinkEndpage to="/enrollment/international-circuit-no-gi-de-jiu-jitsu-cascavel-pr">
                    Inscrição NO GI
                  </TournamentEnrollmentLinkEndpage>
                </>
              ) : (
                <>
                  <TournamentEnrollmentLinkEndpage to="/login">
                    Inscrição GI
                  </TournamentEnrollmentLinkEndpage>

                  <TournamentEnrollmentLinkEndpage to="/login">
                    Inscrição NO GI
                  </TournamentEnrollmentLinkEndpage>
                </>
              )}
              {/* <TournamentLink href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.767B171E&u=teste" target="_blank">Chaves</TournamentLink> */}
              {/* // /check/16-COPA-PARANA-DE-JIU-JITSU */}
              <TournamentLink
                href="/check/international-circuit-gi-de-jiu-jitsu-cascavel-pr/search"
                target="_blank"
              >
                Checagem de atletas GI
              </TournamentLink>
              <TournamentLink
                href="/check/international-circuit-no-gi-de-jiu-jitsu-cascavel-pr/search"
                target="_blank"
              >
                Checagem de atletas NO GI
              </TournamentLink>
              <TournamentLink
                href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.D7A54E37&u=teste"
                target="_blank"
              >
                Chaves GI
              </TournamentLink>
              <TournamentLink
                href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.584C262E&u=teste"
                target="_blank"
              >
                Chaves NO GI
              </TournamentLink>
              <TournamentLink target="blank" href={tabelaPeso}>
                Tabela de pesos
              </TournamentLink>
              <TournamentLink href="#">Resultados (EM BREVE)</TournamentLink>
            </Content>
          </TournamentActions>
        </TournamentDescription>
      </Container>
      <Footer />
    </>
  );
};

export default TournamentInternationalCircuit;
