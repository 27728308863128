import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import Collapse from "../../components/atoms/colapso";
import Footer from "../../components/organisms/footer";
import NavBar from "../../components/organisms/navbar";
import useAuthenticate from "../../hooks/useAuthenticate";
import {
  Container,
  Content,
  H3,
  H5,
  Location,
  P,
  QRCodeImg,
  Span,
  Title,
  TournamentActions,
  TournamentBanner,
  TournamentContainer,
  TournamentContainerBanner,
  TournamentContainerBannerCarrosel,
  TournamentContent,
  TournamentDescription,
  TournamentEnrollmentLink,
  TournamentEnrollmentLinkEndpage,
  TournamentLink,
} from "./styles";
import tabelaPeso from "../../assets/images/tournaments/1-copa-arena-parintins/tabela-de-peso-1-copa-arena-parintins.jpg";
import qrCodeOrlay from "../../assets/images/tournaments/1-copa-arena-parintins/qrCode-1-copa-arena-parintins.jpeg";
import SponsorButton from "../../components/atoms/sponsor-area";
import {
  SponsorContainer,
  SponsorList,
} from "../../components/atoms/sponsor-area/styles";

//Patrocinio
import torneio from "../../assets/images/sponsors/torneio-facil.png";
import alexGarciaVereador from "../../assets/images/sponsors/alex-garcia-vereador.png";
import navioPrincipeDoAmazonas from "../../assets/images/sponsors/navio-principe-do-amazonas.jpeg";
import netoBrelaz from "../../assets/images/sponsors/neto-brelaz.jpeg";
import parisAutoescola from "../../assets/images/sponsors/paris-auto-escola.jpeg";
import semjuv from "../../assets/images/sponsors/semjuv.jpeg";


const sponsors = [
  {
    //url: "#",
    img: torneio,
  },
  { img: alexGarciaVereador },
  { img: navioPrincipeDoAmazonas },
  { img: netoBrelaz },
  { img: parisAutoescola },
  { img: semjuv },
];

const _1CopaArenaParintins: React.FC = () => {
  const { authenticated } = useAuthenticate();

  return (
    <>
      <NavBar />

      <Container>
        <TournamentContainer
          image={require("../../assets/images/tournaments/1-copa-arena-parintins/1-copa-arena-parintins.jpeg")}
        >
          <TournamentContainerBanner>
            {/* <Carrosel images={["../../assets/images/tournaments/gfteam-amazonas/gfteam-amazonas.jpeg"]}> */}

            <TournamentBanner
              image={require("../../assets/images/tournaments/1-copa-arena-parintins/1-copa-arena-parintins.jpeg")}
            />

            {/* 
<div><TournamentBanner
              image={require("../../assets/images/tournaments/gfteam-amazonas/gfteam-amazonas.jpeg")}
            /></div>
<div><TournamentBanner
              image={require("../../assets/images/tournaments/gfteam-amazonas/gfteam-amazonas.jpeg")}
            /></div>
             */}
            {/* </Carrosel> */}
          </TournamentContainerBanner>
        </TournamentContainer>
        <TournamentContent>
          <Content>
            <Title>{"1º Copa Arena Parintins de Jiu Jitsu".toLocaleUpperCase()}</Title>
            <Location>
              <Span>Data: 25 de novembro de 2023</Span>
              {/* <Span>Início: 8:30h</Span> */}
              <Span>
                Local: Ginasio de Espostes Elias Assayag
                Cidade: Parintins - Amazonas
              </Span>
            </Location>

            <P></P>
            <br />
            <Collapse title={"edital 1º Copa Arena Parintins de Jiu Jitsu".toLocaleUpperCase()}>
              <Span>
                A 1ª Copa Arena Parintins de Jiu Jitsu é um evento
                do norte do Brasil fomentando e incentivando a prática do
                Jiu-Jitsu no estado do Amazonas . As lutas serão com GI (com
                kimono), o evento disponibilizará categorias para todas as
                idades e faixas, conforme tabela de peso por categoria deste
                edital. Os competidores terão a oportunidade de testar suas
                habilidades em um ambiente controlado e com acompanhamento de
                profissionais qualificados.
              </Span>
              <br />
              <br />
              <br />
              <Collapse title="PROTOCOLO DE SEGURANÇA CONTRA COVID-19">
                <P>
                  Devido a dinâmica da situação atual, estamos avaliando
                  diariamente as recomendações dos Órgãos de Saúde e este
                  protocolo pode ser atualizado a qualquer momento caso novas
                  determinações sejam publicadas.
                </P>
                <P>
                  Caso o evento seja cancelado por força maior, todas as
                  inscrições serão automaticamente reembolsadas.
                </P>
                <P>
                  A organização não é responsável pelos demais custos dos
                  participantes, tais como transporte e hospedagem.
                </P>
                <P>
                  Diretrizes do ginásio: Para a segurança de todos, por favor
                  verifique sua temperatura antes de se dirigir ao evento.{" "}
                </P>
                <P>Caso haja qualquer sintoma, procure um médico.</P>{" "}
                <P>
                  {" "}
                  Caso você se sinta indisposto ou esteve em contato com um
                  paciente diagnosticado com Covid-19, por favor fique em casa e
                  procure seu médico.
                </P>
              </Collapse>
              <Collapse title="PREMIAÇÕES">
                <H5>Por Equipes</H5>
                <P>1° lugar: R$ 1500 + Troféu</P>
                <P>2° lugar: Troféu </P>
                <P>3° lugar: Troféu </P>
                <br />
                <H3>Absoluto Masculino:</H3>
                <H5>Juvenil Azul</H5>
                <P>1° lugar: R$250 + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <H5>Adulto Azul/Roxa juntos</H5>
                <P>1° lugar: R$350,00 + medalha </P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <H5>Adulto Marrom e Preta</H5>
                <P>1° lugar: R$800,00 </P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <br />

                <H3>Absoluto Feminino:</H3>
                <H5>Juvenil Azul</H5>
                <P>1° lugar: R$150,00 + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <H5>Adulto Azul/Roxa juntos</H5>
                <P>1° lugar: R$250,00 + medalha </P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <H5>Adulto Marrom e preta</H5>
                <P>1° lugar: R$400,00</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <br />
                <p>
                  OBS: Estas premiações só irão valer para os Absolutos que
                  tiverem 06 (seis) ou mais atletas inscritos.
                </p>
                <p>
                  Em caso de apenas 05 (cinto) ou menos inscritos no Absoluto, a
                  premiação ficará a critério da organição do evento, podendo ser
                  apenas as medalhas.
                </p>
              </Collapse>
              <Collapse title="INSCRIÇÕES">
                <Span>
                  As inscrições para o evento serão realizadas exclusivamente
                  online, por meio do site www.torneiofacil.com.br.
                </Span>
                <P>
                  Durante o processo de inscrição, o atleta ou seu responsável
                  legal deverá selecionar corretamente a categoria referente
                  idade que o atleta completará no ano de 2023, bem como sua
                  faixa e peso.
                </P>
                <br />

                <H3>Prazo de Inscrição</H3>
                <P>
                  O prazo final para inscrições e pagamento será até o dia
                  20/11/2023, às 23h59min. As inscrições que não forem pagas
                  serão canceladas.
                </P>
                <br />

                <H3>Valor</H3>
                <P>Lote 1 até dia 10/11/2023</P>
                <P>Valor categoria R$ 60,00</P>
                <P>Valor absoluto R$ 60,00</P>
                <br />
                <P>Lote Final até dia 20/11/2023</P>
                <P>Valor categoria R$ 70,00</P>
                <P>Valor absoluto R$ 70,00</P>

                <br />
                <H3>Inscrição de Absoluto</H3>
                <P>
                  Somente atletas inscritos na categoria poderão se increver e
                  participar do absoluto. Atlestas inscritos no absoluto que não
                  estiverem inscritos na categoria serão automaticamente
                  tranferidos para a categoria pessadissimo referente a seu
                  sexo, idade e faixa.
                </P>
                <P>
                  Os atletas só poderão se inscrever no absoluto referente a sua
                  idade de luta no ano de 2023 conforme regras da CBJJ.
                </P>
                <P>
                  Os atletas Master (acima de 29 anos) poderão se inscrever no absoluto aduto.
                </P>
                <br />

                <H3>Correção</H3>
                <P>
                  A correção da inscrição poderá ser realizada somente após a
                  confirmação do pagamento.
                </P>
                <P>
                  Em caso de inscrição equivocada, o atleta ou professor
                  responsável poderá corrigir as informações no site
                  www.torneiofacil.com.br, acessando a opção Meu Perfil em
                  seguida Minhas Inscrições, Alterar Inscrição.
                </P>
                <P>
                  A correção da inscrição ficará disponivel até o encerramento
                  das inscrições.
                </P>
                <P>
                  Após prazo para correção das inscrições, não serão aceitas
                  modificações nas inscrições.
                </P>
                <P>
                  Os atletas que se inscreverem incorretamente estarão sujeitos
                  a desclassificação <b>sem direito a reembolso.</b>
                </P>
                <br />

                <H3>Prazo de Correção</H3>
                <P>A correção da inscrição ficará disponivel até o encerramento
                  das inscrições no dia 20/11/2023
                  às 23h59min..</P>
              </Collapse>
              <Collapse title="PAGAMENTO">
                <H3>
                  A confirmação de pagamento deverá ser realizada via WhatsApp
                  enviando o comprovante de pagamento e o número da inscrição 
                  para o contato abaixo:
                </H3>
                <P>Banco Bradesco:</P>
                <P>Titular: José Michel Tavares Pio</P>
                <P>Chave PIX: Chave do tipo Telefone</P>
                <P>(92) 99468-5161</P>
                <br />
                <QRCodeImg src={qrCodeOrlay} alt="qrCode" width={300} />
                <br />
                <br />
               <a target={"blank"} href="https://whatsa.me/5592994685161/?t=Ol%C3%A1%20Michel%20Tavares,%20vou%20enviar%20o%20comprovante%20de%20pagamento%20referente%20a%20inscri%C3%A7%C3%A3o%20no%20*1%C2%BA%20COPA%20ARENA%20PARINTINS%20DE%20JIU%20JITSU*"
               >
                  <strong>WhatsApp: (92) 99468-5161 </strong>
                  <FaWhatsapp />
                </a>
                
              </Collapse>
              <Collapse title="CRONOGRAMA PROVISÓRIO">
                {/* (EM BREVE) */}
                <Container>
                <b><Span>Obs: O cronograma definitivo será atualizado no dia 24/11/2023.</Span></b>
                <br/>
                 <Span>Data: 25 de novembro de 2023</Span>
                  <Span>Início: 8:30h da manhã</Span>
                  <Span>
                    Local: Ginasio de Espostes Elias Assayag
                    Cidade: Parintins - Amazonas
                  </Span>
                  <br/>
                  <H3>Abertura do evento</H3>
                  <H5>8:30h - Abertura do evento</H5>
                  <br/>
                  <H3>Lutas</H3>
                  <H5>9:00h - Adulto e Máster (Faixa Preta ) masculino e feminino.</H5>
                  <H5>09:30h - 04, 05, 06 e 07 anos (Faixa branca e Graduado) masculino e feminino.</H5>
                  <H5>10:00h - 08 e 09 anos (Faixa branca e Graduado) masculino e feminino.</H5>
                  <H5>10:30h -  10 e 11 anos (Faixa branca e Graduado) masculino e feminino.</H5>
                  <H5>11:00h - 12 e 13 anos (Faixa branca e Graduado) feminino e masculino.</H5>
                  <H5>12:00h - 14 e 15 anos (Faixa branca e Graduado) masculino e feminino. </H5>
                  <H5>13:00h - 16 e 17 anos (Faixa branca e Graduado) masculino e feminino.</H5>
                  <H5>14:00h - Adulto e Máster (Faixa Marrom) masculino e feminino. </H5>
                  <H5>15:00h - Adulto e Máster (Faixa Roxa ) masculino e feminino.</H5>
                  <H5>16:00h - Adulto e Máster (Faixa Azul) masculino e feminino.</H5>
                  <H5>17:00h - Adulto e Máster (Faixa Branca ) masculino e feminino. </H5>
                  <H5>18:00 - Encerramento</H5>
                  <H5>Obs.1: O atleta deverá comparecer com uma (01) hora de antecedência do horário previsto no cronograma.</H5>
                  <H5>Obs.2- Os absolutos serão disputados após o término das suas respectivas categorias,
                      lembrando que deverá ter no mínimo seis atletas inscritos para validar a premiação.</H5>
                   <Span>
                    Usaremos 3 áreas de luta
                  </Span>


                  <Span>
                    O atleta deverá estar presente no ginásio pelo menos 1 hora
                    antes da sua luta.
                  </Span>
                  <Span>
                    O cronograma pode ser adiantado em até 1 hora, e atrasar
                    conforme a necessidade do evento.
                  </Span>
                </Container>
              </Collapse>
              <Collapse title="REGRAS">
                {/* (EM BREVE) */}

                <P>
                  O que não estiver neste edital, será acatado o que estiver no
                  livro de regras da CBJJ.
                </P>
                <Span>
                  As regras do evento são as da CBJJ/IBJJF.
                </Span>
                <Span>
                  A tabela de peso por gênero, idade e categoria está disponível no botão
                  "Tabela de Peso" no site do evento.
                </Span>
              
                <Span>
                  Para categoria com 3 lutadores, os atletas farão a melhor de
                  três para definição do campão, segundo e terceiro lugar
                  conforme regra da CBJJ
                </Span>
                <Span>
                  A pesagem será realizada no dia do evento. O competidor deverá
                  apresentar documento oficial com foto no momento da pesagem.
                </Span>
                <Span>
                  Atletas que lutaram em outros eventos com faixa superior não
                  poderão lutar com a faixa abaixo. Se descoberto, o competidor
                  será eliminado do torneio sem direito a reembolso.
                </Span>
                <br />
                <H3>Pontuação por equipe</H3>
                <H5>Categoria e Absoluto</H5>
                <P>1° lugar: 9 pontos</P>
                <P>2° lugar: 3 pontos</P>
                <P>3° lugar: 1 ponto</P>
                <br />

                <P>
                  A contagem dos pontos será realizada de forma geral
                  contabilizando os pontos de todas as faixas.
                </P>
                <P>
                  Campeão direto não contam pontos, chaves com apenas um
                  inscrito.
                </P>
                <P>
                  Campeão por WO, devido a desistencia do oponente, contará
                  pontos desde que na chave tenha mais de um inscrito.
                </P>
                <P>
                  Categorias com apenas dois atletas da mesma academia não
                  contam pontos para a disputa por academias.
                </P>
                <P>
                  O que não estiver neste edital, será acatado o que estiver no
                  livro de regras da CBJJ.
                </P>
                <br />
                <P>
                  <a
                    target={"blank"}
                    href="https://cbjj.com.br/books-videos"
                    style={{ color: "white" }}
                  >
                    <strong>LIVRO DE REGRAS DA CBJJ/IBJJF</strong>
                  </a>
                </P>
              </Collapse>
              <Collapse title="ENTRADA">
                <Span>Será cobrado 1kg de alimento não perecível na entrada.</Span>
                <Span>Atletas que comprovem a sua incrição neste evento ficam isentas 
                      de qualquer tipo de pagamento na entrada no evento.</Span> 
                <Span>Crianças até 10 anos ficam isentas de qualquer tipo de pagamento.</Span>
                <br />
              </Collapse>
              {/* <Collapse title="CONTATO">
                <P>
                  Duvidas sobre cadastro, inscrição, pagamento, correção, checagem
                  ou sugestão de melhorias entre em contato com a equipe do Torneio
                  Fácil por telefone ou WhatsApp.
                  </P>
                <P>
                  Melhor horário para atendimento das 18:00h as 23:59h.
                  </P>
                <a target={"blank"} href="https://whatsa.me/5592981291027/?t=Ol%C3%A1%20Thiago%20Souza%20Dellarmelina,%20vou%20enviar%20o%20comprovante%20de%20pagamento%20referente%20a%20inscri%C3%A7%C3%A3o%20no%20*XXXI%20COPA%20ORLEY%20LOBATO%20DE%20JIU-JITSU*"
                  >
                    <strong>Telefone/WhatsApp: (92) 98129-1027 </strong>
                    <FaWhatsapp />
                </a>
                
                <br />
              </Collapse> */}
            </Collapse>
          </Content>
        </TournamentContent>
        <TournamentDescription>
          <TournamentActions>
            <Content>
              <h1>PATROCINADORES</h1>
              <SponsorContainer>
                <SponsorList>
                  {sponsors &&
                    sponsors.map((buttons: any) => (
                      <SponsorButton
                        img={buttons.img}
                        url={buttons.url}
                        hasError={false}
                        error="Este campo é obrigatório"
                      />
                    ))}
                </SponsorList>
              </SponsorContainer>
            </Content>
            <br />

            <Content>
              {authenticated ? (
                <TournamentEnrollmentLinkEndpage to="/enrollment/1o-copa-arena-parintins-de-jiu-jitsu-ginasio-elias-assayag-parintins-am">
                  Inscrição Encerrada
                </TournamentEnrollmentLinkEndpage>
              ) : (
                <TournamentEnrollmentLinkEndpage to="/login">
                  Inscrição Encerrada
                </TournamentEnrollmentLinkEndpage>
              )}
              {/* <TournamentEnrollmentLinkEndpage to="#">
                Inscrição Encerrada
                </TournamentEnrollmentLinkEndpage> */}
              {/* <TournamentLink href="#">Chaves (EM BREVE)</TournamentLink> */}
              {/* <TournamentLink href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.767B171E&u=teste" target="_blank">Chaves</TournamentLink>
               */}
              {/* <TournamentLink href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.D7A54E37&u=teste">
                Checagem de atletas
              </TournamentLink> */}


              {/* <TournamentLink href="/check/1o-copa-arena-parintins-de-jiu-jitsu-ginasio-elias-assayag-parintins-am/search">
                Checagem de atletas
              </TournamentLink> */}

              <TournamentLink href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.D7A54E37&u=teste">
                Chaves
              </TournamentLink> 

              {/* <TournamentLink href="https://torneiofacil.com/profile/subscription">
                Correção da inscrição
              </TournamentLink> */}

              



              {/* <TournamentLink href="https://www.instagram.com/torneiofacil/">
                {/* /check/1o-copa-arena-parintins-de-jiu-jitsu-ginasio-elias-assayag-parintins-am/search */}
                {/* Instagram Torneio Fácil */}
              {/* </TournamentLink> */}


              {/* <TournamentLink href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.D7A54E37&u=teste">
                /check/1o-copa-arena-parintins-de-jiu-jitsu-ginasio-elias-assayag-parintins-am/search
                Chaves
              </TournamentLink> */}


              <TournamentLink target="blank" href={tabelaPeso}>
                Tabela de pesos
              </TournamentLink>
              {/* <TournamentLink href="#">Resultados (EM BREVE)</TournamentLink> */}
              {/* <TournamentLink href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.767B171E&u=teste">Resultadis Individuais</TournamentLink> */}
            </Content>
          </TournamentActions>
        </TournamentDescription>
      </Container>
      <Footer />
    </>
  );
};

export default _1CopaArenaParintins;
