import styled from "styled-components";

export const Container = styled.div`
  padding: 40px 0;
`;

export const PageTitleContainer = styled.h1`
  padding: 0 20px;
`;

export const PageLocationTitle = styled.h1`
  color: rgb(25, 31, 40);
  text-transform: uppercase;
  font-size: 24px;
  width: 100%;
  font-weight: 600;
`;

export const PageTitle = styled.h1`
  color: rgb(132, 140, 155);
  text-transform: uppercase;
  font-size: 14px;
  width: 100%;
  font-weight: 600;
`;

export const Content = styled.div`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
`;

export const ProfileContainer = styled.div`
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: row;
`;

export const ProfileList = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: max-content;
  gap: 16px;

  @media (max-width: 370px) {
    grid-template-columns: repeat(1, 1fr);
  }
  
  @media (min-width: 370px) and (max-width: 450px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 450px) and (max-width: 561px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 561px) and (max-width: 900px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const ProfileItem = styled.div`
  background-color: #ffffff;
  color: #223354;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  transition: all 0.5s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0px 9px 16px rgba(159, 162, 191, 0.18),
      0px 2px 2px rgba(159, 162, 191, 0.32);
  }
`;

export const StyledLink = styled.a`
  display: block;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
`;

export const StyledCardBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0.75rem;
  background-color: #ffffff;  
  border-radius: 5px;
  
`;

export const StyledCardText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;
  line-height: 1.4;
  color: #4a4a4a;
  text-align: center;
`;

export const StyledImg = styled.img`
  margin-bottom: 0.5rem;
`;
