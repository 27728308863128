import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import Collapse from "../../components/atoms/colapso";
import Footer from "../../components/organisms/footer";
import NavBar from "../../components/organisms/navbar";
import { FaMapMarkerAlt } from 'react-icons/fa';
//import useAuthenticate from "../../hooks/useAuthenticate";
import {
  Container,
  Content,
  H3,
  H5,
  Location,
  P,
  QRCodeImg,
  Span,
  Title,
  TournamentActions,
  TournamentBanner,
  TournamentContainer,
  TournamentContainerBanner,
  TournamentContent,
  TournamentDescription,
  TournamentEnrollmentLink,
  TournamentEnrollmentLinkEndpage,
  TournamentLink,
  EditalContainer,
  EditalContent,
  EditalTitle
} from "./styles";

import { SponsorContainer, SponsorList } from "src/components/atoms/sponsor-area/styles";
import SponsorButton from "src/components/atoms/sponsor-area";
import useAuthenticate from "../../hooks/useAuthenticate";

//Patrocinio
import torneio from "../../assets/images/sponsors/torneio-facil.png";
import bonyAcai from "../../assets/images/sponsors/bony-acai.jpg";
import amafil from "../../assets/images/sponsors/costa-e-mota-advocacia.jpg";
import costaEMotaAdvocacia from "../../assets/images/sponsors/dhon-talento-marketing-e-comunicacao.jpg";
import igorDolzanisAdvogado from "../../assets/images/sponsors/igor-dolzanis-advogado.jpg";
import mov from "../../assets/images/sponsors/mov.jpg";
import novoLarMoveisEEletros from "../../assets/images/sponsors/novo-lar-moveis-e-eletros.jpg";
import revestTapecariaAutomotiva from "../../assets/images/sponsors/revest-tapecaria-automotiva.jpg";
import tapajosSuplementosAtacadao from "../../assets/images/sponsors/tapajos-suplementos-atacadao.jpg";


//Apoio
import trevusMartialArts from "../../assets/images/sponsors/trevus-martial-arts.jpg";

import tabelaPeso from "../../assets/images/tournaments/vi-rei-da-praia-de-jiu-jitsu-no-gi/tabela-vi-rei-da-praia-de-jiu-jitsu-no-gi.jpg";
import qrCodecopaAmizadeDeJiuJitsu2024 from "../../assets/images/tournaments/copa-amizade-de-jiu-jitsu-2024/qr-coce--copa-amizade-de-jiu-jitsu-2024.jpg";

const folderTorneio = require("../../assets/images/tournaments/vi-rei-da-praia-de-jiu-jitsu-no-gi/vi-rei-da-praia-de-jiu-jitsu-no-gi.jpeg");
const rotaInscriçãoGi = "/enrollment/vi-rei-da-praia-de-jiu-jitsu-santarem-pa-no-gi"
const rotaInscriçãoNoGi = "/enrollment/vi-rei-da-praia-de-jiu-jitsu-santarem-pa-nogi"
const rotaChecagemGi = "/check/vi-rei-da-praia-de-jiu-jitsu-santarem-pa-nogi/search"
const rotaChecagemNoGi = "/check/copa-fronteira-de-jiu-jitsu-ginasio-de-esportes-amario-vieira-da-costa-umuarama-pr/search"
const rotaChavesGI = "#"//""/enrollment/copa-cianorte-de-jiu-jitsu-cianorte-pr-gi"
const rotaChavesNoGI = "/enrollment/copa-fronteira-de-jiu-jitsu-ginasio-de-esportes-amario-vieira-da-costa-umuarama-pr"


const dataEvento = "05 de outubro de 2024"
const horaEvento = "16:00h da tarde"
const tituloEvento = "VI Rei da Praia No Gi 2024"
const localEvento = "Em Frente ao Museu na Orla da Cidade "
const endereçoEvento = "Praça Avenida Adriano Pimentel,s/n, R. Adriano Pímentel, S/N - Prainha, Santarém - PA, CEP 68005-270"
const dataDeEncerramentoIncricao = "30/09/2024"
const horaDeEncerramentoIncricao = "23h:59min"

const dataDeEncerramentoCorrecao = "01/10/2024"
const horaDeEncerramentoCorrecao = "23h:59min"

const dataDeEncerramentoInscricaoLote1 = "15/09/2024"
const dataDeEncerramentoInscricaoLote2 = "25/09/2024"
const dataDeEncerramentoInscricaoLote3 = "30/09/2024"

const valorinscricaoCategoriaLote1 = "R$70,00 (Setenta reais)"
const valorinscricaoCategoriaLote2 = "R$80.00 (Oitenta reais)"
const valorinscricaoCategoriaLote3 = "R$90.00 (Noventa reais)"

const valorinscricaoAbsolutoLote1 = "R$45,00 (Quarenta e cinco reais)"
const valorinscricaoAbsolutoLote2 = "R$50,00 (Cinquenta reais)"
const valorinscricaoAbsolutoLote3 = "R$60,00 (Sessenta reais)"

const dataDeDivulgacaoChaves = "04/10/2024"
const horaDeDivulgacaoChaves = "23h:59min"

const instituicaoBancaria = "Caixa Econômica"
const tipoDeChavePix = "CPF"
const ChavePix = "691.807.841-00"
const BeneficiarioDaChavePix = "José Carlos Lima Lopes"

const dataDeDivulgacaoCronograma = ""
const horaDeDivulgacaoCCronograma = ""

const contatoWhatsApp = "+55 (93) 99131-1576"

const sponsors = [
  { img: torneio, url: "#" },
  { img: bonyAcai, url: "#" },
  { img: amafil, url: "#" },
  { img: costaEMotaAdvocacia, url: "#" },
  { img: igorDolzanisAdvogado, url: "#" },
  { img: mov, url: "#" },
  { img: novoLarMoveisEEletros },
  { img: revestTapecariaAutomotiva },
  { img: tapajosSuplementosAtacadao },
];

const support = [
  /*   { img: trevusMartialArts }, */
  { img: torneio },
];

const ViReiDaPraiaDeJiuJitsuNoGi: React.FC = () => {
  const { authenticated } = useAuthenticate();

  return (
    <>
      <NavBar />

      <Container>
        <TournamentContainer
          image={folderTorneio}
        >
          <TournamentContainerBanner>
            <TournamentBanner
              image={folderTorneio}
            />
          </TournamentContainerBanner>
        </TournamentContainer>
        <TournamentContent>
          <Content>
            <Title>{tituloEvento}</Title>
            <Location>
              <Span>Data: {dataEvento}</Span>
              <Span>Início: {horaEvento}</Span>
              <Span>Local: {localEvento}</Span>
              <Span>Endereço: {endereçoEvento}</Span>
              <Span>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                  <FaMapMarkerAlt style={{ marginRight: '8px', color: "red" }} />
                  <a
                    target={"blank"}
                    style={{ color: "#000", textDecoration: "none", fontWeight: "bold" }}
                    href="https://maps.app.goo.gl/Yk8qUtA6X4wECiaq7"
                  >
                    Ver no Google Maps
                  </a>
                </div>
              </Span>
            </Location>
            <P></P>
            <br />

          </Content>
        </TournamentContent>
        <TournamentDescription>
          <TournamentActions>
            <Content>
              <h1 style={{ textAlign: "center" }}>PATROCINADORES</h1>
              <SponsorContainer>
                <SponsorList>
                  {sponsors && sponsors.map((buttons: any, index: number) => (
                    <SponsorButton
                      key={index} // Adicionei uma chave única para cada botão
                      img={buttons.img}
                      url={buttons.url}
                      hasError={false}
                      error="Este campo é obrigatório"
                      target="_blank" // Adicione este atributo
                    />
                  ))}
                </SponsorList>
              </SponsorContainer>
              <h1 style={{ textAlign: "center" }}>APOIO</h1>
              <SponsorContainer>
                <SponsorList>
                  {support &&
                    support.map((buttons: any) => (
                      <SponsorButton
                        img={buttons.img}
                        url={buttons.url}
                        hasError={false}
                        error="Este campo é obrigatório"
                      />
                    ))}
                </SponsorList>
              </SponsorContainer>
            </Content>

            <Content style={{
              marginTop: '32px'
            }}>

              <EditalContainer>
                <EditalTitle>EDITAL {tituloEvento.toLocaleUpperCase()}</EditalTitle>
                <EditalContent>
                  <Span>
                    O {tituloEvento} é um evento de lutas que fomenta e incentiva
                    a prática do Jiu Jitsu no estado do Pará . As lutas
                    serão exclusivamente NO GI (sem kimono), o evento disponibilizará
                    categorias para as idades e faixas, conforme as tabelas de peso
                    deste edital. Os competidores terão a oportunidade de testar suas
                    habilidades em um ambiente controlado e com acompanhamento de
                    profissionais qualificados.
                  </Span>
                  <br />
                  <br />
                  <br />
                </EditalContent>

                <Collapse title="PROTOCOLO DE SEGURANÇA CONTRA COVID-19">
                  <P>
                    Devido a dinâmica da situação atual, estamos avaliando diariamente as
                    recomendações dos Órgãos de Saúde e este protocolo pode ser atualizado a
                    qualquer momento caso novas determinações sejam publicadas.
                  </P>
                  <P>
                    Caso o evento seja cancelado por força maior, todas as inscrições serão
                    automaticamente reembolsadas.
                  </P>
                  <P>
                    A organização não é responsável pelos demais custos dos participantes,
                    tais como transporte e hospedagem.
                  </P>
                  <P>
                    Diretrizes do ginásio: Para a segurança de todos, por favor verifique
                    sua temperatura antes de se dirigir ao evento.{" "}
                  </P>
                  <P>Caso haja qualquer sintoma, procure um médico.</P>{" "}
                  <P>
                    {" "}
                    Caso você se sinta indisposto ou esteve em contato com um paciente
                    diagnosticado com Covid-19, por favor fique em casa e procure seu
                    médico.
                  </P>
                  {/* <P>
                  Todo nosso material será desinfetado antes e durante o evento.
                </P> */}
                </Collapse>
                <Collapse title="PREMIAÇÕES MEDALHAS🥇">

                  <H3>Absoluto Masculino NO GI:</H3>
                  <H5>Adulto/Master(JUNTOS) Branca</H5>
                  <P>1° lugar: Cinturão + R$ 200,00 + medalha🥇</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <H5>Adulto/Master(JUNTOS) Azul </H5>
                  <P>1° lugar: Cinturão + R$ 200,00 + medalha🥇</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <H5>Adulto/Master(juntos) Roxa</H5>
                  <P>1° lugar: Cinturão + R$ 200,00 + medalha🥇</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <H5>Adulto/master(juntos) Marrom e Preta</H5>
                  <P>1° lugar: Cinturão + R$ 200,00 + medalha🥇</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <br />
                  <br />
                  <H3>Absoluto Feminino NO GI:</H3>
                  <H5>Adulto/Master(JUNTOS) Branca</H5>
                  <P>1° lugar: Cinturão + R$ 200,00 + medalha🥇</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <H5>Adulto/Master(JUNTOS) Azul </H5>
                  <P>1° lugar: Cinturão + R$ 200,00 + medalha🥇</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <H5>Adulto/master(juntos) Roxa, Marrom e Preta </H5>
                  <P>1° lugar: Cinturão + R$ 200,00 + medalha🥇</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <br />
                  <br />
                  <H3>Demais Categorias NO GI</H3>
                  <P>1° lugar: medalha🥇</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <br />
                  <p>
                    Estas premiações só irão valer para os Absolutos que
                    tiverem 03 (TRÊS) ou mais atletas inscritos.
                  </p>
                  <p>
                    Nos absolutos com menos de 03 (TRÊS) inscritos,
                    não haverá premiação em dinheiro , somente o cinturão e medalha.
                  </p>
                  <P>
                    Para a realização de lutas na categoria absoluto,
                    é exigido um mínimo de 3 atletas inscritos.
                  </P>
                  <p>
                    Mais informações sobre as inscrições para o absoluto está
                    disponível na seção INSCRIÇÕES, parágrafo Inscrição de
                    Absoluto deste edital.
                  </p>
                  <br />
                </Collapse>

                <Collapse title="INSCRIÇÕES">
                  <Span>
                    As inscrições para o evento serão realizadas exclusivamente online, por
                    meio do site www.torneiofacil.com.br
                  </Span>
                  <P>
                    Durante o processo de inscrição, o atleta ou seu responsável legal
                    deverá selecionar corretamente a categoria referente idade que o atleta
                    completará no ano de 2024, bem como sua faixa e peso.
                  </P>
                  <P>
                    A idade de competição do atleta é a idade máxima que ele terá em 2024,
                    conforme regra da CBJJE.
                  </P>
                  <br />

                  <H3>Prazo de Inscrição</H3>
                  <P>
                    O prazo final para inscrições e pagamento será até o dia {dataDeEncerramentoIncricao}, às
                    {horaDeEncerramentoIncricao}.
                  </P>
                  <br />
                  <P>As inscrições que não forem pagas serão canceladas.</P>
                  <br></br>

                  <H3>Valor  </H3>
                  <P>Primeiro Lote até {dataDeEncerramentoInscricaoLote1}</P>
                  <P>Inscrição categoria R$ {valorinscricaoCategoriaLote1}</P>
                  <P>Inscrição absoluto R$ {valorinscricaoAbsolutoLote1}</P>
                  <br />
                  <P>Segundo Lote até {dataDeEncerramentoInscricaoLote2}</P>
                  <P>Inscrição categoria R$ {valorinscricaoCategoriaLote2}</P>
                  <P>Inscrição absoluto R$ {valorinscricaoAbsolutoLote2}</P>

                  <br />
                  <P>Terceiro Lote até {dataDeEncerramentoInscricaoLote3}</P>
                  <P>Inscrição categoria R$ {valorinscricaoCategoriaLote3}</P>
                  <P>Inscrição absoluto R$ {valorinscricaoAbsolutoLote3}</P>

                  <br />
                  <H3>Correção</H3>

                  <P>
                    A correção da inscrição poderá ser realizada somente após a confirmação
                    do pagamento.
                  </P>
                  <P>
                    Em caso de inscrição equivocada, o atleta ou professor responsável
                    poderá corrigir as informações no site www.torneiofacil.com.br,
                    acessando a opção Meu Perfil em seguida Minhas Inscrições.
                  </P>
                  <P>
                    A correção da inscrição ficará disponível até {dataDeEncerramentoCorrecao} às {horaDeEncerramentoCorrecao}.
                  </P>
                  <P>
                    Após prazo para correção das inscrições, não serão aceitas modificações
                    nas inscrições.
                  </P>
                  <P>
                    Os atletas que se inscreverem incorretamente estarão sujeitos a
                    desclassificação <b>sem direito a reembolso.</b>
                  </P>

                  <br />

                  <H3>Prazo de Correção</H3>
                  <P>
                    A correção da inscrição fica disponível após a confirmação do pagamento
                    da mesma.
                  </P>

                  <P>
                    A correção da inscrição ficará disponível até {dataDeEncerramentoCorrecao} às {horaDeEncerramentoCorrecao}.
                  </P>
                  <H3>Chaves</H3>

                  <P>
                    O sorteio e divulgação das chaves de lutas será no dia {dataDeDivulgacaoChaves} até às {horaDeDivulgacaoChaves}.
                  </P>

                  <br />
                  <H3>Inscrição de Absoluto</H3>
                  <P>
                    Somente atletas inscritos na categoria poderão se increver e participar
                    do absoluto.
                  </P>
                  {/*     <P>
                    As inscrições para os absolutos são gratuitas, podendo ter no máximo 4 (QUATRO)
                    atletas da mesma equipe por absoluto.
                  </P>
{/*                   <P>
                    As inscrições para os absolutos serão realizadas pelo professor
                    responsável pelo atleta, junto a organização do evento no dia do torneio
                    após a finalização das lutas da categoria.
                  </P> */}
                  {/*       <P>
                    Os atletas só poderão se inscrever no absoluto referente a categoria da
                    sua inscrição no torneio. Exceção apenas para os master que poderá se
                    inscrever como absoluto adulto.
                  </P> */}

                  <P>
                    Apenas os atletas inscritos em suas respectivas categorias poderão competir no
                    absoluto. Caso um atleta se inscreva apenas no absoluto, ele será desclassificado
                    no dia do evento, sem possibilidade de reembolso da inscrição.
                  </P>
                </Collapse>
                <Collapse title="VALOR DA INSCRIÇÃO">
                  <Span>
                    As inscrições serão divididas em três lotes
                  </Span>
                  <br />
                  <H3>Primeiro Lote até {dataDeEncerramentoInscricaoLote1}</H3>
                  <P>Inscrição categoria R$ {valorinscricaoCategoriaLote1}</P>
                  <P>Inscrição absoluto R$ {valorinscricaoAbsolutoLote1}</P>
                  <br />
                  <H3>Segundo Lote até {dataDeEncerramentoInscricaoLote2}</H3>
                  <P>Inscrição categoria R$ {valorinscricaoCategoriaLote2}</P>
                  <P>Inscrição absoluto R$ {valorinscricaoAbsolutoLote2}</P>
                  <br />
                  <H3>Terceiro Lote até {dataDeEncerramentoInscricaoLote3}</H3>
                  <P>Inscrição categoria R$ {valorinscricaoCategoriaLote3}</P>
                  <P>Inscrição absoluto R$ {valorinscricaoAbsolutoLote3}</P>
                  <br />
                </Collapse>
                <Collapse title="PAGAMENTO">
                  <H3>
                    A confirmação de pagamento deverá ser realizada via WhatsApp enviando o
                    comprovante para o contato abaixo:
                  </H3>
                  <P>Banco: {instituicaoBancaria}</P>
                  <P>Titular: {BeneficiarioDaChavePix}</P>
                  <P>Tipo de chave PIX: {tipoDeChavePix}</P>
                  <P>Chave PIX: {ChavePix}</P>
                  {/* <QRCodeImg src={qrCodecopaAmizadeDeJiuJitsu2024} alt="qrCode" width={300} />
                  <P>
                    <a
                      target={"blank"}
                      style={{ color: "white" }}
                      href="https://whatsa.me/5592981291027/?t=Ol%C3%A1+Thiago_Dellarmelina%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+na+%2ACOPA+ORLEY+LOBATO+DE+JIU+JITSU+2024%2A"
                    >
                      <strong>WhatsApp: +55 (92) 98129-1027 </strong>
                      <FaWhatsapp />
                    </a>
                  </P> */}
                  <p />
                  {/* <QRCodeImg src={qrCodeCopaParana} alt="qrCode" width={300} /> */}
                </Collapse>

                <Collapse title="CATEGORIAS COM APENAS UM COMPETIDOR (SEM LUTA)">

                  <Span>Atletas que estiverem sozinhos na categoria terão 03 opções:</Span>
                  <P>Mudar de categoria até a data de encerramento das inscrições;</P>
                  <P>Pedir o reembolso do valor da inscrição até a data de encerramento das inscrições.</P>
                  <P>NÃO HAVERÁ LUTAS CASADAS NESSE EVENTO</P>

                </Collapse>

                <Collapse title="REEMBOLSO DAS INSCRIÇÕES">
                  <H3>
                    Em caso de reembolso sera cobrado uma taxa de manutenção de 20% do valor total da inscrição.
                    Após o fim do perido de inscrição não será possivel o reembolso das inscrições pagas.
                  </H3>

                </Collapse>

                <Collapse title="REGRAS">
                  <Span>
                    Todas as regras para o evento estão devidamente estipuladas e detalhadas neste edital.
                  </Span>
                  <span>
                    As mulheres poderão se inscrever e competir nas categorias masculinas; porém, será necessário avisar o organizador do evento para que a inscrição seja liberada. Homens não poderão se inscrever na categoria feminina.
                  </span>

                  <Span>
                    Em casos de omissão de regras no presente edital, as normas a serem observadas serão aquelas estabelecidas pela Confederação
                    Brasileira de Jiu-Jitsu Esportiva (CBJJE), em conformidade com as diretrizes da CBJJE.
                  </Span>
                  <Span>
                    A tabela de peso por categoria, que estabelece os limites de peso para cada categoria, encontra-se disponível no site oficial do evento,
                    acessível por meio do botão denominado "Tabela de Peso".
                  </Span>
                  <Span>
                    As lutas serão com NO GI (sem kimonos ) com vestimentas adequadas que devem estar limpos sem rasgos e sem odor .
                  </Span>
                  <Span>
                    Haverá premiação de primeiro, segundo, terceiro lugar.
                  </Span>
                  <Span>
                    O atleta que perdeu a semifinal para o Campeão ficará em terceiro lugar e o atleta que perdeu a semifinal
                    para o Vice-Campeão ficará em quarto lugar.
                  </Span>
                  <Span>
                    Para categoria com 3 lutadores, os atletas farão a melhor de
                    três para definição do campeão, segundo e terceiro lugar conforme regra da CBJJE
                  </Span>
                  <Span>
                    A pesagem será realizada um dia antes do evento ou no dia do evento, antes da primeira luta. O competidor deverá apresentar documento oficial com foto no momento da pesagem.
                  </Span>
                  <Span>Atletas que ultrapassarem o limite de peso da categoria serão automaticamente desclassificados, porém os mesmos poderão lutar o Absoluto.</Span>
                  {/*                   <Span>
                    A pesagem dos atletas serão realizadas antes do inicio da categoria.
                  </Span> */}
                  <Span>
                    Atletas que ultrapassarem o limite de peso da categoria que estiverem
                    inscritos serão automaticamente desclassificados e não poderão lutar o
                    Absoluto.
                  </Span>
                  <Span>Será realizada apenas uma pesagem oficial por atleta.</Span>
                  {/*  <Span>
                    Teremos uma balança para pesagem livre (não oficial) para os atletas.
                  </Span>
                  <Span>
                    Teremos uma balança para pesagem oficial dos atletas.</Span> */}
                  <Span>
                    Atletas que lutaram em outros eventos com faixa superior não poderão
                    lutar com a faixa abaixo. Se descoberto, o competidor será eliminado do
                    torneio sem direito a reembolso.
                  </Span>
                  <br />
                  {/* <H3>Pontuação por equipe</H3>
                <H5>Categoria</H5>
                <P>1° lugar: 9 pontos</P>
                <P>2° lugar: 3 pontos</P>
                <P>3° lugar: 1 ponto</P>
                <H5>Absoluto</H5>

                <P>1° lugar: 13.5 pontos</P>
                <P>2° lugar: 4.5 pontos</P>
                <P>3° lugar: 1.5 ponto</P>
                <br /> */}

                  {/* <P>
                    A contagem dos pontos será realizada de forma geral contabilizando os
                    pontos de todas as faixas.
                  </P>
                  <P>Campeão direto não contam pontos, chaves com apenas um inscrito.</P>
                  <P>
                    Campeão por WO, devido a desistencia do oponente, contará pontos desde
                    que na chave tenha mais de um inscrito.
                  </P>
                  <P>
                    Categorias com apenas dois atletas da mesma academia não contam pontos
                    para a disputa por academias.
                  </P>
                  <P>
                    O que não estiver neste edital, será acatado o que estiver no livro de
                    regras da CBJJ.
                  </P>
                  <br />
                  <P>
                    O que não estiver neste edital, será acatado o que estiver no livro de
                    regras da CBJJ.
                  </P> */}
                  <br />
                  <P>
                    <a
                      target={"blank"}
                      href="https://www.cbjje.com.br/assets/regras/regras-cbjje-portugues-2024-06.pdf"
                      style={{ color: "white" }}
                    >
                      <strong>LIVRO DE REGRAS DA CBJJE</strong>
                    </a>
                  </P>
                </Collapse>
                <Collapse title={"CRONOGRAMA " + dataDeDivulgacaoCronograma + horaDeDivulgacaoCCronograma}>
                  (EM BREVE)
                  <Container>

                    {/* <Span>Data: 22 de setembro de 2024</Span>
                  <Span>Início: 9:30h da manhã</Span>

                    <H3>Lutas GI</H3>
                    <H5>09:30 - Abertura do evento </H5>
                    <H5>09:50 - Categorias Mirim A e B - Todas as Faixas masculino/feminino </H5>
                    <H5>10:20 - Categorias Infantil A e B - Todas as Faixas masculino/feminino </H5>
                    <H5>11:00 -  Categoria Infanto-Juvenil - Todas as Faixas masculino/feminino 11: 40 - Categoria Juvenil- Todas as Faixas masculino/feminino </H5>
                    <H5>12:10 - Faixa Branca Masculino e Feminino Adulto e Masters</H5>
                    <H5>13:00 - Faixa Preta Masculino e Feminino Adulto e Masters</H5>
                    <H5>13:30 - Faixa Azul Masculino e Feminino Adulto e Masters</H5>
                    <H5>14:10 - Faixa Roxa Masculino e Feminino Adulto e Masters</H5>
                    <H5>14:30 - Faixa Marrom Masculino e Feminino Adulto e Masters</H5>
                    <H5>15:00 - Inscrições para Absoluto - Todas as faixas</H5>
                    <H5>15:10 - Ínicio dos Absolutos</H5>
                    <br />

                    <Span>*** As inscrições do Absoluto deverão ser feitas pelo o professor responsável pela sua equipe, máximo 03 atletas por Equipe para cada Absoluto. ...</Span>

                    <Span>
                      O cronograma pode ser adiantado em até 1 hora, e atrasar conforme a
                      necessidade do evento.
                    </Span>
                    <Span>
                      O atleta deverá estar presente no ginásio pelo menos 1 hora antes da
                      sua luta.
                    </Span>
                    <Span>
                      O atleta que não estiver presente no momento da chamada para a pesagem
                      ou luta ser desclassificado por W.O.
                    </Span>
                    <Span>A pesagem será feita antes da 1º luta do atleta, em caso do atleta não bater
                      o peso, este será imediatamente desclassificado, será verificado também as condições
                      do kiimono do atleta antes da luta, podendo ocorrer desclassificação.
                    </Span>
                    <Span> Todos os atletas devem estar 45.00 minutos antes do tempo de sua categoria na
                      área de aquecimento podendo lutar até 30 min antes do horário previsto no cronograma. </Span>
                    <Span>Seguiremos normas e regras da CBJJ, com exceção nas chaves com 03 atletas. </Span>

                    <H5>
                      Os absolutos serão disputados após o término das suas
                      respectivas categorias.
                    </H5>
                    <br />
                    <b><Span>Obs: </Span></b>
                    <H3> Desejamos a todos uma boa viagem e um excelente campeonato!!!</H3>
                    <H3>Ossssssssss</H3>
                    <br /> */}
                  </Container>
                </Collapse>
                <Collapse title="ENTRADA">
                  <P>Entrada liberada ao público.</P>
                  <br />
                  {/* <P>
                  Será cobrado 1kg de alimento não perecível na entrada para atletas e
                  público.
                </P> */}
                  {/* <br />
                <P>Crianças até 5 anos ficam isentas de qualquer tipo de pagamento.</P>
                <br /> */}
                </Collapse>
                <Collapse title="CONTATO">
                  <P>
                    Duvidas sobre cadastro, inscrição, pagamento, correção, checagem
                    ou sugestão de melhorias entre em contato com a equipe do Torneio
                    Fácil por telefone ou WhatsApp.
                  </P>
                  <P>
                    Melhor horário para atendimento das 18:00h as 23:59h.
                  </P>
                  <a target={"blank"} href="https://whatsa.me/5593991311576/?t=Ol%C3%A1%0A%0APreciso%20de%20ajuda%20com%20minha%20inscri%C3%A7%C3%A3o%20no%20*VI%20REI%20DA%20PRAIA%20NO%20GI%202024*"
                  >
                    <strong>Telefone/WhatsApp: {contatoWhatsApp} </strong>
                    <FaWhatsapp />
                  </a>

                  <br />
                </Collapse>
              </EditalContainer>
            </Content>
            <br />
            <Content>
              {authenticated ? (
                <>
                  <TournamentEnrollmentLinkEndpage to={rotaInscriçãoNoGi}>
                    Inscrição
                  </TournamentEnrollmentLinkEndpage>

                  <TournamentEnrollmentLinkEndpage to="/profile/subscription">
                    Correção da Inscrição
                  </TournamentEnrollmentLinkEndpage>
                </>
              ) : (
                <>
                  <TournamentEnrollmentLinkEndpage to="/login">
                    Inscrição
                  </TournamentEnrollmentLinkEndpage>

                  <TournamentEnrollmentLinkEndpage to="/login">
                    Correção da Inscrição
                  </TournamentEnrollmentLinkEndpage>
                </>
              )}

              <TournamentLink
                // target="_blank"
                href={rotaChecagemGi}
              >Checagem
              </TournamentLink>

              <TournamentLink
                // target="_blank"
                href={rotaChavesGI}
              >Chaves NO GI
              </TournamentLink>

              <TournamentLink
                target="blank"
                href={tabelaPeso}>
                Tabela de pesos
              </TournamentLink>
              {/* <TournamentLink href="#">Resultados (EM BREVE)</TournamentLink> */}
            </Content>
          </TournamentActions>
        </TournamentDescription>
      </Container>
      <Footer />
    </>
  );
};

export default ViReiDaPraiaDeJiuJitsuNoGi;
