import { useEffect, useState } from "react";
import Highlighter from "react-highlight-words"; // Importando a biblioteca
import NavBar from "../../../components/organisms/navbar";
import {
  Container,
  Content,
  FilterContainer,
  InfoContainerStatus,
  InfoContainerText,
  PageLocationTitle,
  PageTitle,
  PageTitleBold,
  PageTitleContainer,
  StatusButton,
  SubscriptionContainer,
  SubscriptionContent,
  SubscriptionDescription,
  SubscriptionItem,
  SubscriptionList,
  SubscriptionTitle,
} from "./styles";
import api from "../../../services/api";
import { toast } from "react-toastify";
import useAuthenticate from "../../../hooks/useAuthenticate";
import Loading from "../../../components/atoms/loading";
import Swal from "sweetalert2";
import whatsapp from "../../../assets/images/wpp.png";
import {
  InputContainer,
  Label,
  Field,
  FilterOption,
  FilterSelect,
  FilterInput,
} from "src/components/atoms/input/inputText";
import CollapseText from "src/components/atoms/colapso-text";

const SubscriptionEvents = () => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const { getToken } = useAuthenticate();
  const [filter, setFilter] = useState<string | null>(null);
  const [tournaments, setTournaments] = useState<any[]>([]);
  const [search, setSearch] = useState<string>("");

  const changePayments = (slug: any, status: boolean, fighter: number) => {
    Swal.fire({
      title: "Tem certeza que deseja mudar a status de pagamento?",
      icon: "warning",
      showDenyButton: true,
      showCancelButton: false,
      html: '<input type="text" id="paymentAmount" placeholder="Digite o valor do pagamento" class="swal2-input" required>',
      confirmButtonText: "Sim",
      denyButtonText: "Não",
    }).then(async function (result) {
      if (result.isConfirmed) {
        const paymentAmountInput = document.getElementById(
          "paymentAmount"
        ) as HTMLInputElement;
        if (paymentAmountInput && paymentAmountInput.checkValidity()) {
          const paymentAmount = paymentAmountInput.value;

          await api
            .patch(
              `managers/tournaments/${slug}/payments`,
              {
                status: !status,
                paymentId: fighter,
                valueSubscription: paymentAmount,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${getToken()}`,
                },
              }
            )
            .then((response) => {
              if (response.status === 200) {
                const newList: any = data.map((item: any) => {
                  if (item.id === fighter) {
                    const updatedItem = {
                      ...item,
                      status: !item.status,
                      valueSubscription: paymentAmount
                    };

                    return updatedItem;
                  }

                  return item;
                });

                setData(newList);

                toast.success("Status de pagamento alterado com sucesso!");
              } else {
                toast.error("Não foi possível mudar status de pagamento!");
              }
            })
            .catch(() =>
              toast.error("Não foi possível mudar status de pagamento!")
            );
        } else {
          toast.error("Status não alterado, informe o valor de pagamento");
        }
      }
    });
  };

  const renderItems = (
    slug: string,
    status: any,
    fighter: number,
    index: number
  ) => {
    return (
      <InfoContainerStatus key={index}>
        <StatusButton
          onClick={() => changePayments(slug, status, fighter)}
          status={status}
        >
          <span>{status ? "Pagamento efetuado" : "Pendente de pagamento"}</span>
        </StatusButton>
      </InfoContainerStatus>
    );
  };

  useEffect(() => {
    const fetchPost = async () => {
      setLoading(true);
      api
        .get("/managers/tournaments", {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        })
        .then(async ({ data: res }) => {
          const fighters: any = [];
          for await (const item of res) {
            const response = await api.get(
              `/managers/tournaments/${item.slug}/subscriptions`,
              {
                headers: {
                  Authorization: `Bearer ${getToken()}`,
                },
              }
            );
            const fightersItem = response.data.map((value: any) => {
              const res = {
                ...value,
                slug: item.slug,
                tournamentName: item.name,
              };
              return res;
            });
            fighters.push(fightersItem);
          }

          const arrayFighters: any = [];
          fighters.forEach((item: any) => {
            item.forEach((value: any) => {
              arrayFighters.push(value);
            });
          });

          const sortedArray = arrayFighters.sort(
            (a: any, b: any) => a.id - b.id
          );

          setData(sortedArray);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          toast.error("Não foi carregar os dados de inscrições.");
        });
    };

    const getTournaments = async () => {
      setLoading(true);
      api
        .get("/managers/tournaments", {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        })
        .then(({ data }) => {
          setTournaments(data);
        });
    };
    getTournaments();

    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loading text="Aguarde, estamos processando os dados." />;

  const handleFilter = (event: any) => {
    const value = event.target.value;
    if (value === "1") {
      setFilter("1");
    } else {
      setFilter(value === "" ? null : value || "asas");
    }
  };

  const filteredData = filter
    ? data.filter((item: any) => {

      if (filter === "all")
        return true;

      if (filter === "1") {
        return item.tournamentId === 1;
      } else {
        return item.tournamentId === parseInt(filter) || !item.tournamentId;
      }
    })
    : [];

  const filteredArrayStatusTrue = filteredData.filter(
    (value: any) => value.status === true
  );

  const filteredArrayStatusFalse = filteredData.filter(
    (value: any) => value.status === false
  );

  const openWhatsapp = (phone: string) => {
    window.open(`https://whatsa.me/${phone}`, "_blank");
  };

  const informacoesDePagamento = filteredArrayStatusTrue.reduce((acm, cur) => {
    const value = Number.parseFloat(cur.valueSubscription);
    //Verificar se o valor é diferente de Not A Number (Não é um número)
    // Se for feito dessa forma -> if (value) se o valor que for passado for 0 ele entenderá como falso, logo exibirá na condição else
    if (!isNaN(value)) {
      acm.total += value;
      acm.count++;

      if (acm.valuesPaid[String(value)]) {
        acm.valuesPaid[String(value)]++;
      } else {
        acm.valuesPaid[String(value)] = 1;
      }
    }
    else {
      if (acm.others[cur.valueSubscription.toUpperCase()]) {
        acm.others[cur.valueSubscription.toUpperCase()]++;
      } else {
        acm.others[cur.valueSubscription.toUpperCase()] = 1;
      }
    }
    return acm;
  }, { total: 0, count: 0, others: {}, valuesPaid: {} });

  const moneyFormat = (value: number) => {
    return new Intl.NumberFormat('pt-br', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  }

  return (
    <>
      <NavBar />
      <Container>
        <Content>
          <PageTitleContainer>
            <PageLocationTitle>Gerenciar Pagamentos</PageLocationTitle>
            <FilterSelect selectedCategory={filter} onChange={handleFilter}>
              <FilterOption value="">SELECIONE UM CAMPEONATO</FilterOption>
              {tournaments.map((tournament) => (
                <FilterOption value={tournament.id}>
                  {tournament.name.toUpperCase()}
                </FilterOption>
              ))}
              <FilterOption value={"all"}>
                TODOS
              </FilterOption>
            </FilterSelect>
            <PageTitle>{filteredData.length} inscrições encontrados</PageTitle>
            <PageTitle>
              {filteredArrayStatusFalse.length} inscrições não pagas
            </PageTitle>
            <PageTitle>
              <CollapseText title={`${filteredArrayStatusTrue.length} inscrições pagas`} styleWrapper={{
                'margin-bottom': "16px"
              }}>
                {Object.entries(informacoesDePagamento.others).map(([key, value]) => (
                  <PageTitle key={key}>
                    {`${value} - ${key}`}
                  </PageTitle>
                ))}
                {Object.entries(informacoesDePagamento.valuesPaid).map(([key, value]) => (
                  <PageTitle key={key}>
                    {`${value} - R$ ${moneyFormat(Number(key))} = R$ ${moneyFormat(Number(key) * Number(value))}`}
                  </PageTitle>
                ))}
                <PageTitleBold>
                  <b style={{
                    fontWeight: 'bold'
                  }}>Valor Total:</b>
                </PageTitleBold>
                <PageTitle>
                  {informacoesDePagamento.count} - R$ {new Intl.NumberFormat('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(informacoesDePagamento.total)}
                </PageTitle>
              </CollapseText>
            </PageTitle>

            {filteredData.length ?
              <FilterContainer>
                <Field
                  filterValue=""
                  type="text"
                  value={search}
                  placeholder="REALIZAR FILTRO"
                  onChange={e => setSearch(e.target.value)}
                  style={
                    {
                      width: '100%'
                    }
                  }
                />
              </FilterContainer> : null}
          </PageTitleContainer>

          <SubscriptionContainer>
            {filteredData &&
              filteredData
                .slice(0)
                .reverse()
                .filter(data => search ?
                  String(data.id).includes(search) ||
                  (data.fighters ?
                    data.fighters?.name.toLowerCase().includes(search.toLowerCase()) :
                    data.user?.name.toLowerCase().includes(search.toLowerCase())) ||
                  (data.fighters ?
                    data.fighters?.phone.includes(search) :
                    data.user?.phone.includes(search)
                  ) ||
                  data.genre.toLowerCase().includes(search.toLowerCase()) ||
                  (data.fighters ? false : data?.age === search) ||
                  data.category.toLowerCase().includes(search.toLowerCase()) ||
                  data.belt.toLowerCase().includes(search.toLowerCase()) ||
                  data.weight.toLowerCase().includes(search.toLowerCase()) ||
                  (data.fighters ?
                    data.fighters?.gym.toLowerCase().includes(search.toLowerCase()) :
                    data.user?.gym.toLowerCase().includes(search.toLowerCase())
                  )
                  : true)
                .map((value: any, index: number) => (
                  <SubscriptionList key={value.id}>
                    <SubscriptionItem>
                      <SubscriptionContent>
                        <SubscriptionTitle>
                          Inscrição Nº <Highlighter
                            highlightClassName="highlight"
                            searchWords={[search]}
                            autoEscape={true}
                            textToHighlight={String(value.id)}
                          /> -{" "}
                          {value.tournamentName.toUpperCase()}
                        </SubscriptionTitle>
                        <br />
                        <SubscriptionDescription>
                          <InfoContainerText>
                            <span>
                              <b>Nome:</b>{" "}
                              <Highlighter
                                highlightClassName="highlight"
                                searchWords={[search]}
                                autoEscape={true}
                                textToHighlight={value.fighters
                                  ? value.fighters.name
                                  : value.user?.name}
                              />
                            </span>
                            <span>
                              <b>Contato:</b>{" "}
                              {value.fighters !== null ? (
                                value.fighters.phone === "" ? (
                                  "Sem contato "
                                ) : (
                                  <a
                                    href="#"
                                    onClick={(
                                      e: React.MouseEvent<HTMLAnchorElement>
                                    ) => {
                                      e.preventDefault();
                                      openWhatsapp(value.fighters.phone);
                                    }}
                                  >
                                    <img
                                      src={whatsapp}
                                      alt="wpp"
                                      width={16}
                                      height={16}
                                      style={{ padding: "0 10px 0 10px" }}
                                    />
                                    <Highlighter
                                      highlightClassName="highlight"
                                      searchWords={[search]}
                                      autoEscape={true}
                                      textToHighlight={value.fighters.phone}
                                    />
                                  </a>
                                )
                              ) : value.user.phone === "" ? (
                                "Sem contato "
                              ) : (
                                <a
                                  href="#"
                                  onClick={(
                                    e: React.MouseEvent<HTMLAnchorElement>
                                  ) => {
                                    e.preventDefault();
                                    openWhatsapp(value.user.phone);
                                  }}
                                >
                                  <img
                                    src={whatsapp}
                                    alt="wpp"
                                    width={16}
                                    height={16}
                                    style={{ padding: "0 10px 0 10px" }}
                                  />
                                  <Highlighter
                                    highlightClassName="highlight"
                                    searchWords={[search]}
                                    autoEscape={true}
                                    textToHighlight={value.user.phone}
                                  />
                                </a>
                              )}{" "}
                            </span>
                            <span>
                              <b>Gênero:</b>{" "}
                              <Highlighter
                                highlightClassName="highlight"
                                searchWords={[search]}
                                autoEscape={true}
                                textToHighlight={value.genre?.toUpperCase()}
                              />
                            </span>
                            {value.fighters ? (
                              value.fighters.age
                            ) : (
                              <span>
                                <b>Idade de Luta:</b>{" "}
                                <Highlighter
                                  highlightClassName="highlight"
                                  searchWords={[search]}
                                  autoEscape={true}
                                  textToHighlight={`${value.age} anos`}
                                />
                              </span>
                            )}
                            <span>
                              <b>Equipe: </b>{" "}
                              <Highlighter
                                highlightClassName="highlight"
                                searchWords={[search]}
                                autoEscape={true}
                                textToHighlight={value.fighters
                                  ? value.fighters.gym
                                  : value.user.gym}
                              />
                            </span>
                            <span>
                              <b>Categoria(s):</b>{" "}
                              <Highlighter
                                highlightClassName="highlight"
                                searchWords={[search]}
                                autoEscape={true}
                                textToHighlight={`${value.genre.toUpperCase()} (${value.category.toUpperCase()}) / ${value.belt.toUpperCase()} / ${value.weight}`}
                              />
                            </span>
                          </InfoContainerText>
                          {renderItems(
                            value.slug,
                            value.status,
                            value.id,
                            index
                          )}
                        </SubscriptionDescription>
                      </SubscriptionContent>
                    </SubscriptionItem>
                  </SubscriptionList>
                ))}
          </SubscriptionContainer>
        </Content>
      </Container>
    </>
  );
};

export default SubscriptionEvents;
