import { useEffect, useState } from "react";
import NavBar from "../../../components/organisms/navbar";
import { FaEdit } from "react-icons/fa";
import {
  AthletesLink,
  Container,
  Content,
  PageLocationTitle,
  PageTitle,
  PageTitleContainer,
  SubscriptionContainer,
  SubscriptionContent,
  SubscriptionDescription,
  SubscriptionItem,
  SubscriptionList,
} from "./styles";
import api from "../../../services/api";
import { toast } from "react-toastify";
import useAuthenticate from "../../../hooks/useAuthenticate";
import Loading from "../../../components/atoms/loading";

const Athletes = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { getToken } = useAuthenticate();

  useEffect(() => {
    setLoading(true);
    api
      .get("/fighters", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then(({ data }) => {
        setLoading(false);
        setData(data);
      })
      .catch(() => {
        setLoading(false);
        toast.error("Não foi carregar os dados de inscrições.");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loading text="Aguarde, estamos processando os dados." />;

  const belt = [
    { title: "Branca", option: 1 },
    { title: "Cinza", option: 2 },
    { title: "Amarela", option: 3 },
    { title: "Laranja", option: 4 },
    { title: "Verde", option: 5 },
    { title: "Azul", option: 6 },
    { title: "Roxa", option: 7 },
    { title: "Marrom", option: 8 },
    { title: "Preta", option: 9 },
  ]

  return (
    <>
      <NavBar />
      <Container>
        <Content>
          <PageTitleContainer>
            <PageLocationTitle>Meus Atletas</PageLocationTitle>
            <PageTitle>{data.length} atletas encontrados</PageTitle>
            <AthletesLink to={"/athletes"}>Cadastrar Atleta</AthletesLink>
          </PageTitleContainer>
          <SubscriptionContainer>
            {data &&
              data.map((value: any) => (
                <SubscriptionItem>
                  <SubscriptionContent>
                    <SubscriptionDescription>
                      <span>
                        <strong>Nome: {value.name.toUpperCase()}</strong>
                      </span>
                      <br />
                      <span>
                        Nome da mãe: {value.mother_name.toUpperCase()}
                      </span>
                      <br />
                      <span>Data Nascimento: {value.birth_date}</span>
                      <br />
                      <span>Telefone: {value.phone}</span>
                      <br />
                      <span>Genero: {value.genre.toUpperCase()}</span>
                      <br />
                      <span>Faixa: {value.belt ? belt.filter(item => item.option === value.belt)[0].title : ''}</span>
                      <br />
                      <SubscriptionList
                        key={value.id}
                        href={`/profile/athletes/${value.id}`}
                      >
                        Editar <FaEdit />
                      </SubscriptionList>
                      {/* <span>{value.status ? ('Ativo') : ('Desativado')}</span> */}
                    </SubscriptionDescription>
                  </SubscriptionContent>
                </SubscriptionItem>

              ))}
          </SubscriptionContainer>
        </Content>
      </Container>
    </>
  );
};

export default Athletes;
