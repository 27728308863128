import React, { useState } from "react";
import { FaMapMarkerAlt, FaWhatsapp } from "react-icons/fa";
import NavBar from "../../components/organisms/navbar";
import Footer from "../../components/organisms/footer";
import {
  Container,
  Content,
  H3,
  P,
  Span,
  Title,
  TournamentActions,
  TournamentContainer,
  TournamentDescription,
  TournamentEnrollmentLinkEndpage,
  TournamentLink,
} from "./styles";
import useAuthenticate from "../../hooks/useAuthenticate";
import torneio from "../../assets/images/sponsors/torneio-facil.png";

const sponsors = [
  { img: torneio, url: "http://www.torneiofacil.com" },
  // ... outros patrocinadores
];

const enrollmentUrl = "https://s1.hmg.torneiofacil.com./enrollment/copa-amizade-de-jiu-jitsu-2024-ginasio-de-esportes-amario-vieira-da-costa-umuarama-pr";

const InstrucaoTestePagbank: React.FC = () => {
  const { authenticated } = useAuthenticate();
  const [athlete, setAthlete] = useState("");
  const [category, setCategory] = useState("");
  const [weight, setWeight] = useState("");
  const [classification, setClassification] = useState("");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Lógica para redirecionar para a página de pagamento
    window.location.href = enrollmentUrl;
  };

  return (
    <>
      <NavBar />
      <Container>
        <TournamentDescription>
          <TournamentActions>
            <Content>
              <h1 style={{ textAlign: "center" }}>INSTRUÇÃO PARA TESTE DE PAGAMENTO</h1>
              <ol>
                <li>
                  Acesse: <a href="https://s1.hmg.torneiofacil.com./login" target="_blank" rel="noopener noreferrer">https://s1.hmg.torneiofacil.com./login</a><br/>
                  Usuário: m4rciete@gmail.com<br/>
                  Senha: TestePagSeguro123@
                </li>
                <br/>
                <li>
                  Para fazer e pagar uma inscrição acesse: <a href="https://s1.hmg.torneiofacil.com./enrollment/copa-amizade-de-jiu-jitsu-2024-ginasio-de-esportes-amario-vieira-da-costa-umuarama-pr" target="_blank" rel="noopener noreferrer">link para inscrição</a>
                </li>
                <br/>
                <li>Na opção: Escolha um atleta para esse evento</li>
                <br/>
                <li>Selecione a opção MARCIETE FERNADES DELLARMELINA</li>
                <br/>
                <li>Escolha uma categoria</li>
                <br/>
                <li>Escolha um Peso</li>
                <br/>
                <li>Escolha uma Classificação Funcional</li>
                <br/>
                <li>Clique em INSCREVER. Nesse momento você será redirecionado para o ambiente de pagamento de teste</li>
              </ol>
            </Content>
          </TournamentActions>
        </TournamentDescription>
      </Container>
      <Footer />
    </>
  );
};

export default InstrucaoTestePagbank;
