import { useEffect, useState } from "react";
import NavBar from "../../../components/organisms/navbar";
import {
  Container,
  Content,
  InfoContainerStatus,
  InfoContainerText,
  PageLocationTitle,
  PageTitle,
  PageTitleContainer,
  StatusButton,
  SubscriptionContainer,
  SubscriptionContent,
  SubscriptionDescription,
  SubscriptionItem,
  SubscriptionList,
  SubscriptionTitle,
} from "../../profile/subscriptions/styles";
import api from "../../../services/api";
import { toast } from "react-toastify";
import useAuthenticate from "../../../hooks/useAuthenticate";
import Loading from "../../../components/atoms/loading";
import React from "react";

import { useParams } from "react-router-dom";
import { StringToSlug, StringToSlugPraia } from "../../../utils/SlugConverter";

const AllCheckCategoriesCheckViiiReiERainhaDaPraiaBeachWrestiling2024 = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { getToken } = useAuthenticate();

  const [showData, setShowData] = useState(false);

  const { slug } = useParams();
  const [tournament, setTournament] = useState<any>({});
  const [subscription, setSubscription] = useState<any>([]);

  function converterSubscriptions(array: any) {
    const subscriptions: {
      id: any;
      weight: any;
      age: any;
      gender: any;
      genderBool: string;
      belt: any;
      category: any;
      name: any;
      gymAbre: any;
      gym: any;
      status: any;
      value: any;
      categoryw: any;
    }[] = [];
    array.forEach((item: any) => {
     
      
      let category;
       item.category === "SUB 09 (MISTO)" || "SUB 11 (MISTO)"
          ? // Quando a categoria é  "SUB 09 (MISTO)" || "SUB 11 (MISTO)"", combine "masculino" e "feminino"
            (category = StringToSlug(
              `${item.category}O/${item.weight}`
            ))
          : (category = StringToSlugPraia(
              `$${item.genre}/${item.category}/${item.weight}`
            ))
        

      if (item.fighters && item.status) {
        subscriptions.push({
          id: item.id,
          weight: item.weight,
          age: item.age,
          gender: item.fighters.genre,
          genderBool:
            item.fighters.genre === "Masculino" ? "Falso" : "Verdadeiro",
          belt: item.belt,
          category: item.category,
          name: item.fighters.name,
          gymAbre: item.fighters.gym.substring(0, 12),
          gym: item.fighters.gym,
          status: item.status,
          categoryw: category,
          value: item.valueSubscription,
        });
      }

      if (!item.fighters && item.status) {
        subscriptions.push({
          id: item.id,
          weight: item.weight,
          age: item.age,
          gender: item.user.genre,
          genderBool: item.user.genre === "masculino" ? "Falso" : "Verdadeiro",
          belt: item.belt,
          category: item.category,
          name: item.user.name,
          gymAbre: item.user.gym.substring(0, 12),
          gym: item.user.gym,
          status: item.status,
          categoryw: category,
          value: item.valueSubscription,
        });
      }
      // order by age and weight
    });

    function categorizeParticipants(participants: any) {
      let categories: any = {};

      participants.forEach((participant: any) => {
        
        
    
        let category = {
                        title: StringToSlug(
                          `${participant.genre}/${participant.weight}/${participant.category}`
                        ),
                      }
                

        // If this category doesn't exist, create it
        if (!categories[category.title]) {
          categories[category.title] = [];
        }

        // Add participant to the correct category
        categories[category.title].push(participant);
      });

      // Convert categories object into array of objects with structure: {title: title, data: participants}
      let categoriesArray = Object.keys(categories).map((title) => {
        return {
          title: title,
          data: categories[title],
          age: +categories[title][0].age,
        };
      });
      // Sort categories by age and weight
      categoriesArray.sort(function (a: { age: number }, b: { age: number }) {
        if (a.age > b.age) {
          return 1;
        }
        if (a.age < b.age) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });

      setSubscription(categoriesArray);
    }

    categorizeParticipants(array);
  }

  useEffect(() => {
    setLoading(true);

    api
      .get("/tournaments/" + slug)
      .then(({ data }) => {
        setTournament(data);
      })
      .catch(() => {
        toast.error("Não foi carregar os dados do torneio.");
      });

    api
      .get("/tournaments/enrollments/list-all/public/" + slug, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then(({ data }) => {
        setLoading(false);
        const sortedArray = data
          .sort((a: any, b: any) => a.id - b.id)
          .filter((item: any) => item.status === true);
        setData(sortedArray);
        if (data && subscription.length === 0)
          converterSubscriptions(sortedArray);

        setShowData(true);
      })
      .catch(() => {
        setLoading(false);
        toast.error("Não foi carregar os dados das inscrições.");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

 

  if (loading) return <Loading text="Aguarde, estamos processando os dados." />;

  return (
    <>
      <NavBar />
      <Container>
        <Content>
          <PageTitleContainer>
            <PageLocationTitle>Checagem</PageLocationTitle>
            <h2 style={{ color: "red" }}> OBS: SOMENTE INSCRIÇÕES PAGAS APARECEM NA  CHECAGEM</h2>
          </PageTitleContainer>

          <SubscriptionContainer>
            {/* renderizar as inscrições para cada categoria */}
            {subscription.map((item: any) => {
              // mostrar as inscrições apenas se houver atletas na categoria atual

              item.data.sort(function (a: { age: number }, b: { age: number }) {
                if (a.age > b.age) {
                  return 1;
                }
                if (a.age < b.age) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              });

              if (showData) {
                return (
                  <React.Fragment key={item.title}>
                    <PageTitle>{item.title}</PageTitle>
                    {item.data.map((value: any, index: number) => (
                      <SubscriptionList key={value.id}>
                        <SubscriptionItem>
                          <SubscriptionContent>
                            <SubscriptionTitle>
                              Inscrição Nº {value.id} -{tournament.name}
                            </SubscriptionTitle>
                            {/* <br /> */}
                            <SubscriptionDescription>
                              <InfoContainerText>
                                <b>Inscrição: {index + 1}°</b>{" "}
                                <span>
                                  <b>Nome:</b>{" "}
                                  {value.fighters
                                    ? value.fighters.name
                                    : value.user.name}
                                </span>
                                <span>
                                  <b>Equipe: </b>{" "}
                                  {value.fighters
                                    ? value.fighters.gym
                                    : value.user.gym}
                                </span>
                              </InfoContainerText>
                              <InfoContainerStatus>
                                <StatusButton status={value.status}>
                                  <span>
                                    {value.status
                                      ? "Pagamento efetuado"
                                      : "Pendente de pagamento"}
                                  </span>
                                </StatusButton>
                              </InfoContainerStatus>
                            </SubscriptionDescription>
                          </SubscriptionContent>
                        </SubscriptionItem>
                      </SubscriptionList>
                    ))}
                  </React.Fragment>
                );
              } else {
                // não há atletas nesta categoria, então não renderizar nada
                return null;
              }
            })}
          </SubscriptionContainer>
        </Content>
      </Container>
    </>
  );
};
export default AllCheckCategoriesCheckViiiReiERainhaDaPraiaBeachWrestiling2024 ;
