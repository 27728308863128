import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import Collapse from "../../components/atoms/colapso";
import Footer from "../../components/organisms/footer";
import NavBar from "../../components/organisms/navbar";
//import useAuthenticate from "../../hooks/useAuthenticate";
import {
  Container,
  Content,
  H3,
  H5,
  Location,
  P,
  QRCodeImg,
  Span,
  Title,
  TournamentActions,
  TournamentBanner,
  TournamentContainer,
  TournamentContainerBanner,
  TournamentContent,
  TournamentDescription,
  TournamentEnrollmentLink,
  TournamentEnrollmentLinkEndpage,
  TournamentLink,
  EditalContainer,
  EditalContent,
  EditalTitle
} from "./styles";
// import tabelaPeso from "../../assets/images/tournaments/toledo-international-open-brazilian-jiu-jitsu-2023/tabepla-de-peso.jpeg";
import tabelaPeso from "../../assets/images/tournaments/iv-open-terra-santa-de-jiu-jitsu/tabela-iv-open-terra-santa-de-jiu-jitsu.jpg";
import qrCodeIvOpemTerraSanta from "../../assets/images/tournaments/iv-open-terra-santa-de-jiu-jitsu/qr-code-iv-open-terra-santa-de-jiu-jitsu.png";
import chavesGiIvOpemTerraSanta from "../../files/iv-open-terra-santa-de-jiu-jitsu/Chaves IV Open Terra Santa de Jiu Jitsu GI.pdf";
import chavesNoGiIvOpemTerraSanta from "../../files/iv-open-terra-santa-de-jiu-jitsu/Chaves IV Open Terra Santa de Jiu Jitsu NOGI.pdf";



//Patrocinio
import torneio from "../../assets/images/sponsors/torneio-facil.png";
import pontão from "../../assets/images/sponsors/pontao-ch.jpeg";


//Apoio
import trevusMartialArts from "../../assets/images/sponsors/trevus-martial-arts.jpg";


import { SponsorContainer, SponsorList } from "src/components/atoms/sponsor-area/styles";
import SponsorButton from "src/components/atoms/sponsor-area";
import useAuthenticate from "../../hooks/useAuthenticate";



const dataEvento = "20 de julho de 2024"
const horaEvento = "9:00h da manhã"
const tituloEvento = "IV Open Terra Santa de Jiu Jitsu"
const localEvento = "Ginásio Bráulio Nelson da Conceição"
const endereçoEvento = "Terra Santa - Pará"
const dataDeEncerramentoIncricao = "15/07/2024"
const horaDeEncerramentoIncricao = "23h:59min"

const dataDeEncerramentoCorrecao = "15/07/2024"
const horaDeEncerramentoCorrecao = "23h:59min"

const dataDeEncerramentoInscricaoLote1 = "05/07/2024"
const dataDeEncerramentoInscricaoLote2 = "15/07/2024"

const valorinscricaoCategoriaLote1 = "50,00 (cinquenta reais)"
const valorinscricaoCategoriaLote2 = "70,00 (setenta reais)"

const valorinscricaoAbsolutoLote1 = "30,00 (trinta reais)"
const valorinscricaoAbsolutoLote2 = "40,00 (quarenta e cinco reais)"

const dataDeDivulgacaoChaves = "19/07/2024"
const horaDeDivulgacaoChaves = "23h:59min"



const instituicaoBancaria = "Caixa Econômica"
const tipoDeChavePix = "CPF"
const ChavePix = "827.839.422-91"
const BeneficiarioDaChavePix = "Raimundo Sérgio Corrêa Cardoso"

const dataDeDivulgacaoCronograma = "EM BREVE"
const horaDeDivulgacaoCCronograma = "EM BREVE"

const contatoWhatsApp = "(92) 98129-1027"


const sponsors = [
  { img: pontão, url: "#" },
  { img: pontão, url: "#" },
  { img: pontão, url: "#" },
  { img: pontão, url: "#" },
  { img: pontão, url: "#" },
  { img: pontão, url: "#" },
  { img: pontão, url: "#" },
  { img: pontão, url: "#" },
  { img: pontão, url: "#" },
  { img: pontão, url: "#" },

];


const support = [
  { img: torneio },
];

const IvOpenTerrSantaDeJiuJitsu: React.FC = () => {
  const { authenticated } = useAuthenticate();

  return (
    <>
      <NavBar />

      <Container>
        <TournamentContainer
          image={require("../../assets/images/tournaments/iv-open-terra-santa-de-jiu-jitsu/iv-open-terra-santa-de-jiu-jitsu.jpg")}
        >
          <TournamentContainerBanner>
            <TournamentBanner
              image={require("../../assets/images/tournaments/iv-open-terra-santa-de-jiu-jitsu/iv-open-terra-santa-de-jiu-jitsu.jpg")}
            />
          </TournamentContainerBanner>
        </TournamentContainer>
        <TournamentContent>
          <Content>
            <Title>{tituloEvento}</Title>
            <Location>
              <Span>Data: {dataEvento}</Span>
              <Span>Início: {horaEvento}</Span>
              <Span>Local: {localEvento}</Span>
              <Span>Endereço: {endereçoEvento}</Span>
            </Location>
            <P></P>
            <br />

          </Content>
        </TournamentContent>
        <TournamentDescription>
          <TournamentActions>
            <Content>
              <h1 style={{ textAlign: "center" }}>PATROCINADORES</h1>
              <SponsorContainer>
                <SponsorList>
                  {sponsors && sponsors.map((buttons: any, index: number) => (
                    <SponsorButton
                      key={index} // Adicionei uma chave única para cada botão
                      img={buttons.img}
                      url={buttons.url}
                      hasError={false}
                      error="Este campo é obrigatório"
                      target="_blank" // Adicione este atributo
                    />
                  ))}
                </SponsorList>
              </SponsorContainer>
              <h1 style={{ textAlign: "center" }}>APOIO</h1>
              <SponsorContainer>
                <SponsorList>
                  {support &&
                    support.map((buttons: any) => (
                      <SponsorButton
                        img={buttons.img}
                        url={buttons.url}
                        hasError={false}
                        error="Este campo é obrigatório"
                      />
                    ))}
                </SponsorList>
              </SponsorContainer>
            </Content>

            <Content style={{
              marginTop: '32px'
            }}>

              <EditalContainer>
                <EditalTitle>EDITAL COMPLETO EM BREVE {tituloEvento.toLocaleUpperCase()}</EditalTitle>
                <EditalContent>
                  <Span>
                    A {tituloEvento}  é um evento de lutas GI e NOGi (com e sem kimono) que tem como objetivo promover o esporte
                    e os atletas. O evento disponibilizará categorias para todas as idades
                    e faixas, conforme tabela de peso por categoria conforme deste edital.
                    Os competidores terão a oportunidade de testar suas habilidades em um
                    ambiente controlado e com acompanhamento de profissionais qualificados.
                  </Span>
                  <br />
                  <br />
                  <br />
                </EditalContent>

                <Collapse title="PROTOCOLO DE SEGURANÇA CONTRA COVID-19">
                  <P>
                    Devido a dinâmica da situação atual, estamos avaliando diariamente as
                    recomendações dos Órgãos de Saúde e este protocolo pode ser atualizado a
                    qualquer momento caso novas determinações sejam publicadas.
                  </P>
                  <P>
                    Caso o evento seja cancelado por força maior, todas as inscrições serão
                    automaticamente reembolsadas.
                  </P>
                  <P>
                    A organização não é responsável pelos demais custos dos participantes,
                    tais como transporte e hospedagem.
                  </P>
                  <P>
                    Diretrizes do ginásio: Para a segurança de todos, por favor verifique
                    sua temperatura antes de se dirigir ao evento.{" "}
                  </P>
                  <P>Caso haja qualquer sintoma, procure um médico.</P>{" "}
                  <P>
                    {" "}
                    Caso você se sinta indisposto ou esteve em contato com um paciente
                    diagnosticado com Covid-19, por favor fique em casa e procure seu
                    médico.
                  </P>
                  {/* <P>
                  Todo nosso material será desinfetado antes e durante o evento.
                </P> */}
                </Collapse>
                <Collapse title="PREMIAÇÕES">
                  <H3>Por Equipes </H3>
                  <P>
                    1° lugar: R$ 500,00 + troféu
                  </P>
                  <P>
                    2° lugar: R$ 300,00 + troféu
                  </P>
                  <P>
                    3° lugar: R$ 200,00 + troféu
                  </P>
                  <br />
                  <br />
                  <H3>Absoluto Marrom/Preta (adulto):</H3>
                  <P>1° lugar: R$ 1.000,00 + cinturão</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>

                  <H3>Absoluto Azul/Roxa (adulto):</H3>
                  <P>1° lugar: R$ 500,00 + cinturão</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>

                  <H3>Absoluto Branca (adulto):</H3>
                  <P>1° lugar: o que arrecadar + cinturão</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>

                  <H3>Absoluto Feminino - Todas as faixas (adulto):</H3>
                  <P>1° lugar: o que arrecadar + cinturão</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>

                  <p>OBS: Estas premiações só irão valer para os Absolutos que tiverem 06 (SEIS)
                    ou mais atletas inscritos.</p>
                  <p>Em caso com menos de 6 (SEIS) inscritos no Absoluto, a premiação ficará a critério da organização do evento, para segundo
                    e terceiro permanece a premição normal.
                  </p>

                  <p>Mais informações sobre as inscriçoes para o absoluto esta disponivel na seção
                    INSCRIÇÕES, paragrafo Inscrição de Absoluto deste edital.
                  </p>
                  <br />

                  <H3>Demais Categorias GI e NOGI</H3>
                  <P>1° lugar: medalha</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>
                </Collapse>

                <Collapse title="INSCRIÇÕES">
                <Span>
                    As inscrições para o evento serão realizadas exclusivamente online, por
                    meio do site www.torneiofacil.com.br
                  </Span>
                  <P>
                    Durante o processo de inscrição, o atleta ou seu responsável legal
                    deverá selecionar corretamente a categoria referente idade que o atleta
                    completará no ano de 2024, bem como sua faixa e peso.
                  </P>
                  <P>
                    A idade de competição do atleta é a idade máxima que ele terá em 2024,
                    conforme regra da CBJJ.
                  </P>
                  <br />

                  <H3>Prazo de Inscrição</H3>
                  <P>
                    O prazo final para inscrição e pagamento será até o dia {dataDeEncerramentoIncricao}, 
                    às {horaDeEncerramentoIncricao}.
                  </P>
                  <br />
                  <P>As inscrições que não forem pagas serão canceladas.</P>
                  <br />
                  <br />
                  <H3>Inscrição de Absoluto</H3>
                  <P>
                    Somente atletas inscritos na categoria poderão se increver e participar
                    do absoluto.
                  </P>
                  <P>
                    As inscrições para os absolutos são gratuitas, podendo ter no máximo 4
                    atletas da mesma equipe por absoluto.
                  </P>
                  <P>
                    As inscrições para os absolutos serão realizadas pelo professor
                    responsável pelo atleta, junto a organização do evento no dia do torneio
                    após a finalização das lutas da categoria.
                  </P>
                  <P>
                    Os atletas só poderão se inscrever no absoluto referente a categoria da
                    sua inscrição no torneio. Exceção apenas para os master que poderá se
                    inscrever como absoluto adulto.
                  </P>
                  <span><b>INSCRIÇÃO DO ABSOLUTO SOMENTE NO DIA DO EVENTO </b></span>
                  <br />
                  <br />
                  <H3>Correção</H3>
                  <P>
                    A correção da inscrição poderá ser realizada somente após a confirmação
                    do pagamento.
                  </P>
                  <P>
                    Em caso de inscrição equivocada, o atleta ou professor responsável
                    poderá corrigir as informações no site www.torneiofacil.com.br,
                    acessando a opção Meu Perfil em seguida Minhas Inscrições.
                  </P>
                  <br />
                  <br />
                  <H3>Prazo de Correção</H3>
                  <P>
                    A correção da inscrição ficará disponivel até o encerramento das
                    inscrições no {dataDeEncerramentoCorrecao} as {horaDeEncerramentoCorrecao}.
                  </P>

                  <P>
                    Após prazo para correção das inscrições, não serão aceitas modificações
                    nas inscrições.
                  </P>
                  <P>
                    Os atletas que se inscreverem incorretamente estarão sujeitos a
                    desclassificação <b>sem direito a reembolso.</b>
                  </P>
                  <br />
                  <br />
                  <H3>Checagem</H3>
                  <P>
                    O sorteio e divulgação das chaves de lutas será no dia {dataDeDivulgacaoChaves} até as {horaDeDivulgacaoChaves}.
                  </P>
                  <br />



                </Collapse>

                <Collapse title="VALOR DA INSCRIÇAO">
                  <span><b>GI (COM KIMONO) </b></span>
                  <br />
                  <span>VALOR R$ 70,00</span>
                  <br />
                  <br />
                  <span><b>NO GI (SEM KIMONO) </b></span>
                  <br />
                  <span>VALOR R$ 70,00</span>
                  <br />
                  <br />
                  <span><b>COMBO GI + NO GI  </b></span>
                  <br />
                  <span>VALOR R$ 120,00</span>
                  <br />
                  <br />
                  <span><b>ABSOLUTO</b></span>
                  <br />
                  <span>VALOR R$ 70,00</span>
                  <br />
                  <span>Inscrição para o absoluto somente no dia do evento</span>
                </Collapse>

                <Collapse title="PAGAMENTO">
                  <H3>
                    A confirmação de pagamento deverá ser realizada via WhatsApp enviando o
                    comprovante para o contato abaixo:
                  </H3>
                  <P>Banco: {instituicaoBancaria}</P>
                  <P>Titular: {BeneficiarioDaChavePix}</P>
                  <P>Chave PIX: {tipoDeChavePix}</P>
                  <P>Chave PIX: {ChavePix}</P>
                  <QRCodeImg src={qrCodeIvOpemTerraSanta} alt="qrCode" width={300} />
                  <P>
                    <a
                      target={"blank"}
                      style={{ color: "white" }}
                      href="https://whatsa.me/5592994386161/?t=Ol%C3%A1%20Sergio%20Cardoso,%20vou%20enviar%20o%20comprovante%20de%20pagamento%20referente%20a%20inscri%C3%A7%C3%A3o%20no%20*IV%20OPEN%20TERRA%20SANTA%20DE%20JIU-JITSU*"
                    >
                      <strong>WhatsApp: {contatoWhatsApp} </strong>
                      <FaWhatsapp />
                    </a>
                  </P>
                  <p />
                  {/* <QRCodeImg src={qrCodeCopaParana} alt="qrCode" width={300} /> */}
                </Collapse>
                  <Collapse title={`PROGRAMAÇÃO ${dataDeDivulgacaoCronograma}`} >
                  <span>Data: {dataEvento}</span>
                  <br />
                  <span>Início: {horaEvento}</span>
                  <br />
                  <span>Local: {localEvento}</span>
                  <br />
                </Collapse>
                <Collapse title="REGRAS">
                  <span>As regras do evento são as da CBJJ/IBJJF.</span>
                  <br />
                  <span>
                    A tabela de peso por categoria está disponível no botão
                    "Tabela de Peso" no site do evento.
                  </span>
                  <br />
                  <span>
                    O competidor deverá apresentar documento oficial com foto no
                    momento da checagem.
                  </span>
                  <br />
                  <span>
                    O atleta deverá estar presente no ginásio pelo menos 1 hora
                    antes da sua luta.
                  </span>
                  <br />
                  <span>
                    O cronograma pode ser adiantado em até uma hora ou atrasar
                    conforme a necessidade do evento, podendo ser adiantado em até
                    uma hora ou atrasado conforme a necessidade do evento.
                  </span>
                  <br />
                  <span>
                    Atletas que lutaram em outros eventos com faixa superior não
                    poderão lutar com a faixa abaixo. Se descoberto, o competidor
                    será eliminado do torneio sem direito a reembolso.
                  </span>
                  <br />
                  <span>
                    O aluno deve apresentar um documento de identificação no ato
                    da pesagem.
                  </span>
                  <br />
                </Collapse>
                <Collapse title="ENTRADA">
                  <span>
                    Será cobrado um valor simbólico na entrada para o público,
                    sendo <b>5 reais</b> por pessoa.{" "}
                  </span>{" "}
                  <br />
                  <span>
                    {" "}
                    Crianças acima de 5 anos pagarão a entrada integral.
                  </span>{" "}
                  <br />
                  <span>
                    Não será permitida a entrada de alimentos e bebidas
                    alcoólicas.
                  </span>
                </Collapse>


                <Collapse title="CONTATO">
                  <P>
                    Duvidas sobre cadastro, inscrição, pagamento, correção, checagem
                    ou sugestão de melhorias entre em contato com a equipe do Torneio
                    Fácil por telefone ou WhatsApp.
                  </P>
                  <P>
                    Melhor horário para atendimento das 18:00h as 23:59h.
                  </P>
                  <a target={"blank"} href="https://whatsa.me/+5592981291027/?t=Ol%C3%A1+Thiago+Dellarmelina+%2APreciso+de+ajuda+com+minha+inscrição+na+XXXII+COPA+ORLEY+LOBATO+DE+JIU+JITSU+2024%2A*"
                  >
                    <strong>Telefone/WhatsApp: {contatoWhatsApp} </strong>
                    <FaWhatsapp />
                  </a>

                  <br />
                </Collapse>

                <Collapse title="RESULTADO DA COMPETIÇÃO POR EQUIPE/ACADEMIA ">
                <P>
                    1° lugar: Academia Ségio Corrêa com 215 pontos
                  </P>
                  <P>
                    2° lugar: Associação Parintins com 167 pontos
                  </P>
                  <P>
                    3° lugar: Equipe Faro com 142 pontos
                  </P>
                </Collapse>


              </EditalContainer>
            </Content>
            <br />
            <Content>
              {authenticated ? (
                <>
                  <TournamentEnrollmentLinkEndpage to="/enrollment/iv-opem-terra-santa-de-jiu-jistu-ginasio-braulio-nelson-da-conceicao-gi">
                    Inscrição GI
                  </TournamentEnrollmentLinkEndpage>
                  <TournamentEnrollmentLinkEndpage to="/enrollment/iv-opem-terra-santa-de-jiu-jistu-ginasio-braulio-nelson-da-conceicao-nogi">
                    Inscrição NO GI
                  </TournamentEnrollmentLinkEndpage>

                  <TournamentEnrollmentLinkEndpage to="/profile/subscription">
                    Correção da Inscrição
                  </TournamentEnrollmentLinkEndpage>
                </>
              ) : (
                <>
                  <TournamentEnrollmentLinkEndpage to="/login">
                    Inscrição
                  </TournamentEnrollmentLinkEndpage>

                  <TournamentEnrollmentLinkEndpage to="/login">
                    Correção da Inscrição
                  </TournamentEnrollmentLinkEndpage>
                </>
              )}

              <TournamentLink
                // target="_blank"
                href="/check/iv-opem-terra-santa-de-jiu-jistu-ginasio-braulio-nelson-da-conceicao-gi/search"
              >
                Checagem GI
              </TournamentLink>

              <TournamentLink
                // target="_blank"
                href="/check/iv-opem-terra-santa-de-jiu-jistu-ginasio-braulio-nelson-da-conceicao-nogi/search"
              >
                Checagem NOGI
              </TournamentLink>

              <TournamentLink
                href={chavesGiIvOpemTerraSanta}
                target="_blank"
              >
                Chaves GI
              </TournamentLink>

              <TournamentLink
                href={chavesNoGiIvOpemTerraSanta}
                target="_blank"
              >
                Chaves NOGI
              </TournamentLink>

              <TournamentLink target="blank" href={tabelaPeso}>
                Tabela de pesos
              </TournamentLink>
              {/* <TournamentLink href="#">Resultados (EM BREVE)</TournamentLink> */}
            </Content>
          </TournamentActions>
        </TournamentDescription>
      </Container>
      <Footer />
    </>
  );
};

export default IvOpenTerrSantaDeJiuJitsu;
