import styled from "styled-components";

export const Container = styled.div``;

export const Content = styled.div`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 80px 0;
`;

export const PageTitle = styled.h1`
  margin: 0 0 14px 0;
  padding: 0;
`;

export const PageDescription = styled.p`
  margin: 0 0 40px 0;
  padding: 0;
`;

export const FormContainer = styled.form`
  padding: 40px;
  background-color: #fff;
  border-radius: 10px;
`;
