import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import Collapse from "../../components/atoms/colapso";
import Footer from "../../components/organisms/footer";
import NavBar from "../../components/organisms/navbar";
import useAuthenticate from "../../hooks/useAuthenticate";
import { FaMapMarkerAlt } from 'react-icons/fa';
import {
  Container,
  Content,
  H3,
  H5,
  Location,
  P,
  QRCodeImg,
  Span,
  Title,
  TournamentActions,
  TournamentBanner,
  TournamentContainer,
  TournamentContainerBanner,
  TournamentContent,
  TournamentDescription,
  TournamentEnrollmentLink,
  TournamentEnrollmentLinkEndpage,
  TournamentLink,
  EditalContainer,
  EditalTitle,
  EditalContent,
} from "./styles";
import tabelaPeso from "../../assets/images/tournaments/ii-rei-e-rainha-da-praia-de-beach-boxing/tabelade-peso-ii-rei-e-rainha-da-praia-de-beach-boxing.jpg";
import chavesPdf from "../../files/vii-rei-e-rainha-da-praia-beach-wrestiling/chaves-vii-rei-e-rainha-da-praia-beach-wrestiling.pdf";
import chavesIiReiDaPraiaBeachBoxing from "../../files/ii-rei-e-rainha-da-praia-de-beach-boxing/chaves-ii-rei-da-praia-de-beach-boxing.pdf";


import qrCodePixViiReiERainhaDaPraiaBeachWrestiling from "../../assets/images/tournaments/i-rei-e-rainha-da-praia-beach-boxing-manaus-amazonas-2023/pix-i-rei-e-rainha-da-praia-beach-boxing-manaus-amazonas-2023.jpg";
import SponsorButton from "../../components/atoms/sponsor-area";
import {
  SponsorContainer,
  SponsorList,
} from "../../components/atoms/sponsor-area/styles";

//Arquivos
import editalViiReiERainhaDaPraiaBeachWrestiling from "../../files/ii-rei-e-rainha-da-praia-de-beach-boxing/edital-ii-rei-e-rainha-da-praia-de-beach-boxing.pdf";

//Patrocinio

import torneio from "../../assets/images/sponsors/torneio-facil.png";


const sponsors = [

  {
    //url: "#",
    img: torneio,
  },
];

const IReiERainhaDaPraiaBeachBoxingManausAmazonas2023ManausAmazonas: React.FC = () => {
  const { authenticated } = useAuthenticate();

  return (
    <>
      <NavBar />

      <Container>
        <TournamentContainer
          image={require("../../assets/images/tournaments/ii-rei-e-rainha-da-praia-de-beach-boxing/ii-rei-e-rainha-da-praia-de-beach-boxing.jpg")}
        >
          <TournamentContainerBanner>
            <TournamentBanner
              image={require("../../assets/images/tournaments/ii-rei-e-rainha-da-praia-de-beach-boxing/ii-rei-e-rainha-da-praia-de-beach-boxing.jpg")}
            />
          </TournamentContainerBanner>
        </TournamentContainer>
        <TournamentContent>
          <Content>
            <Title> II REI DA PRAIA - BEACH BOXING</Title>
            <Location>
              <Span>Data: 20 de julho de 2024</Span>
              {/* <Span>Início: 18:00</Span> */}
              <Span>Cidade: Manaus-AM</Span>
              <Span>Local: Ipanema Beach Sports</Span>
              <Span>Endereço: Rua Presidente João Batista de Figueiredo, 28 - Flores, Manaus - AM, 69058-695</Span>
              <Span>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                <FaMapMarkerAlt style={{ marginRight: '8px', color:"red" }} />
                <a
                  target={"blank"}
                  style={{ color: "#000", textDecoration: "none", fontWeight: "bold"}}
                  href="https://maps.app.goo.gl/8mbzE95ipPmwAdP26"
                >
                  Ver no Google Maps
                </a>
              </div>
             </Span>
            </Location>

            <br />
            <Content style={{
              marginTop: '32px'
            }}>

              <EditalContainer title="II REI DA PRAIA - BEACH BOXING">
              <EditalTitle>EDITAL II REI DA PRAIA - BEACH BOXING</EditalTitle>
                <EditalContent title="II REI DA PRAIA - BEACH BOXING">
                  <Span>
                    O I REI DA PRAIA - BEACH BOXING é um evento
                    tradicional do norte do Brasil fomentando e incentivando a
                    prática do esporte no estado do Amazonas . As lutas serão na
                    areia, o evento disponibilizará categorias para todas as idades,
                    conforme tabela de peso por categoria deste edital. Os
                    competidores terão a oportunidade de testar suas habilidades em
                    um ambiente controlado e com acompanhamento de profissionais
                    qualificados.
                    <br />
                    <br />
                    <P>
                      <a
                        target={"blank"}
                        href={editalViiReiERainhaDaPraiaBeachWrestiling}
                        style={{ color: "white" }}
                      >
                        <strong>DOWNLOAD DO EDITAL</strong>
                      </a>{" "}
                    </P>
                  </Span>
                  <br />

                </EditalContent>
                                  <Collapse title="PROTOCOLO DE SEGURANÇA CONTRA COVID-19">
                    <P>
                      Devido a dinâmica da situação atual, estamos avaliando
                      diariamente as recomendações dos Órgãos de Saúde e este
                      protocolo pode ser atualizado a qualquer momento caso novas
                      determinações sejam publicadas. Caso o evento seja cancelado
                      por força maior, todas as inscrições serão automaticamente
                      reembolsadas. A organização não é responsável pelos demais
                      custos dos participantes, tais como transporte e hospedagem.
                      Diretrizes do ginásio: Para a segurança de todos, por favor
                      verifique sua temperatura antes de se dirigir ao evento. Caso
                      haja qualquer sintoma, procure um médico. Caso você se sinta
                      indisposto ou esteve em contato com um paciente diagnosticado
                      com Covid-19, por favor fique em casa e procure seu médico.
                      Todo nosso material será desinfetado antes e durante o evento.
                    </P>
                    {/* </Collapse>
              <Collapse title="PREMIAÇÕES">

                <H5>INICIANTES</H5>
                <P>1° lugar: medalha </P>
                <P>2° lugar: medalha </P>
                <P>3° lugar: medalha </P>


                <H5>INTERMEDIÁRIO/AMADOR</H5>
                <P>1° lugar: medalha + kit de suplemento </P>
                <P>2° lugar: medalha </P>
                <P>3° lugar: medalha </P>

                <H5>AVANÇADO/PROFISSIONAL</H5>
                <P>1° lugar: cinturão + dinheiro </P>
                <P>2° lugar: medalha </P>
                <P>3° lugar: medalha </P>

                <Span>
                   Premiação em dinheiro somente para categorias com no mínimo 4 participantes inscritos.
                </Span>
              </Collapse>
              <Collapse title="INSCRIÇÕES">
                <Span>
                  As inscrições para o evento serão realizadas exclusivamente
                  online, por meio do site www.torneiofacil.com.br.
                </Span>
                <br />

                <H3>Valor</H3>
                <P>Categoria R$ 90,00</P>
                <br />

                <P>
                  Durante o processo de inscrição, o atleta ou seu responsável
                  legal deverá selecionar corretamente a categoria referente
                  idade que o atleta completará no ano de 2023, bem como seu
                  peso.
                </P> */}
                    <br />


                    {/* <H3>Prazo de Inscrição</H3>
                <P>
                  O prazo final para inscrições e pagamento será até o dia
                  12/12/2023, às 23h59min. As inscrições que não forem pagas
                  serão canceladas.
                </P>
                <br />
                <H3>Correção</H3>
                <P>
                  A correção da inscrição poderá ser realizada somente após a
                  confirmação do pagamento.
                </P>
                <P>
                  Em caso de inscrição equivocada, o atleta ou professor
                  responsável poderá corrigir as informações no site
                  www.torneiofacil.com.br, acessando a opção Meu Perfil em
                  seguida Minhas Inscrições.
                </P>
                <P>
                  A correção da inscrição ficará disponivel até o encerramento
                  das inscrições.
                </P>
                <br />

                <H3>Prazo de Correção</H3>
                <P>
                  As correções das inscrições podem ser feitas até o dia
                  12/12/2023 às 23h59min.
                </P>
                <P>
                  Após prazo para correção das inscrições, não serão aceitas
                  modificações nas inscrições. Os atletas que se inscreverem
                  incorretamente estarão sujeitos a desclassificação.
                </P> */}
                  </Collapse>
                  <Collapse title="PAGAMENTO">
                    <H3>
                      A confirmação de pagamento deverá ser realizada via WhatsApp
                      enviando o comprovante para o contato abaixo:
                    </H3>
                    <P>Itau:</P>
                    <P>Tasso Oliveira Alves da Rocha</P>
                    <P>Chave PIX: Tipo telefone </P>
                    <P>(92) 98182-1004</P>
                    <br />
                    <QRCodeImg
                      src={qrCodePixViiReiERainhaDaPraiaBeachWrestiling}
                      alt="qrCode"
                      width={300}
                    />
                    <br />
                    <br />
                    <a
                      target={"blank"}
                      href="https://whatsa.me/5592981821004/?t=Ol%C3%A1%20Tasso%20Oliveira%20Alves%20da%20Rocha,%20vou%20enviar%20o%20comprovante%20de%20pagamento%20referente%20a%20inscri%C3%A7%C3%A3o%20no%20*II%20REI%20DA%20PRAIA%20-%20BEACH%20BOX*%20"
                    >
                      <strong>WhatsApp: (92) 98182-1004 </strong>
                      <FaWhatsapp />
                    </a>
                  </Collapse>
                  <Collapse title="CRONOGRAMA (Em breve)">
                    {/* (EM BREVE)
                <Container>
                  <Span>Data: 16 de julho de 2023</Span>
                  <Span>Início: 8:30h da manhã</Span>
                  <Span>
                    Local: Ginasio Ninimberg Guerra
                    Endereço: R. Vicente Torres Reis, 657 - São Jorge.
                    Manaus - AM, 69033-030
                  </Span>
                  <br/>
                  
                  
                  <H3>Lutas</H3>
                  
                  <H5>8:30h - Abertura do evento</H5>
                  <H5>9:00h - 04, 05, 06 e 07 anos (Faixa branca e Graduado) masculino e feminino.</H5>
                  <H5>09:30h - 08 e 09 anos (Faixa branca e Graduado) masculino e feminino.</H5>
                  <H5>10:00h - 10 e 11 anos (Faixa branca e Graduado) masculino e feminino.</H5>
                  <H5>10:30h - 12 e 14 anos (Faixa branca e Graduado) feminino e masculino.</H5>
                  <H5>11:00h - 14 e 15 anos (Faixa branca e Graduado) masculino e feminino. </H5>
                  <H5>12:00h - 16 e 17 anos (Faixa branca e Graduado) masculino e feminino.</H5>
                  <H5>13:00h - Adulto e Máster (Faixa Preta ) masculino e feminino.</H5>
                  <H5>14:00h - Adulto e Máster (Faixa Marrom) masculino e feminino. </H5>
                  <H5>15:00h - Adulto e Máster (Faixa Roxa ) masculino e feminino.</H5>
                  <H5>16:00h - Adulto e Máster (Faixa Azul) masculino e feminino.</H5>
                  <H5>17:00h - Adulto e Máster (Faixa Branca ) masculino e feminino. </H5>
                  <H5>18:00 - Encerramento</H5>
                  <H5>Obs.1: O atleta deverá comparecer com uma (01) hora de antecedência do horário previsto no cronograma.</H5>
                  <H5>Obs.2- Os absolutos serão disputados após o término das suas respectivas categorias,
                      lembrando que deverá ter no mínimo quatro atletas inscritos para validar a premiação.</H5>
                   <Span>
                    Usaremos 8 áreas de luta
                  </Span>


                  <Span>
                    O atleta deverá estar presente no ginásio pelo menos 1 hora
                    antes da sua luta.
                  </Span>
                  <Span>
                    O cronograma pode ser adiantado em até 1 hora, e atrasar
                    conforme a necessidade do evento.
                  </Span>
                </Container> */}
                    {/* </Collapse>
              <Collapse title="REGRAS (Em breve)"> */}
                    {/* (EM BREVE) */}
                    {/* <Span>
                  As regras do evento são as da CBJJ/IBJJF. A tabela de peso por
                  categoria está disponível no botão "Tabela de Peso" no site do
                  evento.
                </Span>
                <Span>
                  Para categoria com 3 lutadores, os atletas farão a melhor de
                  três para definição do campão, segundo e terceiro lugar conforme regra da CBJJ
                </Span>
                <Span>
                  A pesagem será realizada no dia do evento. O competidor deverá
                  apresentar documento oficial com foto no momento da pesagem.
                </Span>
                <Span>
                  Atletas que lutaram em outros eventos com faixa superior não
                  poderão lutar com a faixa abaixo. Se descoberto, o competidor
                  será eliminado do torneio sem direito a reembolso.
                </Span>
                <br />
                <H3>Pontuação por equipe</H3>
                <H5>Categoria e Absoluto</H5>
                <P>1° lugar: 9 pontos</P>
                <P>2° lugar: 3 pontos</P>
                <P>3° lugar: 1 ponto</P>
                <br />
                              
                <P>A contagem dos pontos será realizada de forma geral contabilizando os pontos de todas as faixas.</P>
                <P>Campeão direto não contam pontos, chaves com apenas um inscrito.</P>
                <P>Campeão por WO, devido a desistencia do oponente, contará pontos desde que na chave tenha mais de um inscrito.</P> 
                <P>Categorias com apenas dois atletas da mesma academia não contam pontos para a disputa por academias.</P> 
                <P>O que não estiver neste edital, será acatado o que estiver no livro de regras da CBJJ.</P> 
                <br/>
                <P><a
                  target={"blank"}
                  href="https://cbjj.com.br/books-videos"
                  style={{color: "white"}}
                >
                 <strong>LIVRO DE REGRAS DA CBJJ/IBJJF</strong>
                </a>
                  
                  </P>     */}

                    {/* </Collapse>
              <Collapse title="ENTRADA (Em breve)"> */}
                    {/* <Span>
                  Entrada liberada ao publico
                </Span>
                <br />
              </Collapse>
              <Collapse title="CONTATO">
                <P>
                  Duvidas sobre cadastro, inscrição, pagamento, correção, checagem
                  ou sugestão de melhorias entre em contato com a equipe do Torneio
                  Fácil por telefone ou WhatsApp.
                  </P>
                <P>
                  Melhor horário para atendimento das 18:00h as 23:59h.
                  </P>
                <a target={"blank"} href="https://whatsa.me/5592981291027/?t=Ol%C3%A1%20Thiago%20Souza%20Dellarmelina,%20vou%20enviar%20o%20comprovante%20de%20pagamento%20referente%20a%20inscri%C3%A7%C3%A3o%20no%20*XXXI%20COPA%20ORLEY%20LOBATO%20DE%20JIU-JITSU*"
                  >
                    <strong>Telefone/WhatsApp: (92) 98129-1027 </strong>
                    <FaWhatsapp />
                </a>
                
                <br /> */}
                  </Collapse>


              </EditalContainer>

            </Content>

          </Content>
        </TournamentContent>
        <TournamentDescription>
          <TournamentActions>
            <Content>
              <h1>PATROCINADORES</h1>
              <SponsorContainer>
                <SponsorList>
                  {sponsors &&
                    sponsors.map((buttons: any, index) => (
                      <SponsorButton
                        key={index}
                        img={buttons.img}
                        url={buttons.url}
                        hasError={false}
                        error="Este campo é obrigatório"
                      />
                    ))}
                </SponsorList>
              </SponsorContainer>
            </Content>
            <br />

            <Content>
              {authenticated ? (
                <TournamentEnrollmentLinkEndpage to="/enrollment/i-rei-e-rainha-da-praia-beach-boxing-manaus-amazonas-2023-manaus-amazonas">
                  {/* <TournamentEnrollmentLinkEndpage to="#"> */}
                  Inscrição
                </TournamentEnrollmentLinkEndpage>
              ) : (
                <TournamentEnrollmentLinkEndpage to="/login">
                  Inscrição
                </TournamentEnrollmentLinkEndpage>
              )}



              {/* <TournamentEnrollmentLinkEndpage to="#">
                Inscrição Encerrada
                </TournamentEnrollmentLinkEndpage> */}
              {/* <TournamentLink href="#">Chaves (EM BREVE)</TournamentLink> */}
              {/* <TournamentLink href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.D7A54E37&u=teste" target="_blank">Chaves atualizada em 06/10/2023 as 23h</TournamentLink> */}
              {/* <TournamentLink href={chavesPdf} target="_blank">Chaves</TournamentLink> */}

              {/* <TournamentLink href="/check/i-rei-e-rainha-da-praia-beach-boxing-manaus-amazonas-2023-manaus-amazonas"> */}


              {/* <TournamentLink
                href="/check/i-rei-e-rainha-da-praia-beach-boxing-manaus-amazonas-2023-manaus-amazonas/search"
                target="_blank"
              >
                Checagem
              </TournamentLink> */}


              {/* <TournamentLink
                href={chavesGiIiReiDaPraiaBeachBoxing}
                target="_blank"
              >
                Chaves GI
              </TournamentLink>*/}

              <TournamentLink
                href={chavesIiReiDaPraiaBeachBoxing}
                target="_blank"
              >
                Chaves
              </TournamentLink> 
           


              <TournamentLink href="/check/i-rei-e-rainha-da-praia-beach-boxing-manaus-amazonas-2023-manaus-amazonas/search">
                Checagem de atletas
              </TournamentLink>

              <TournamentLink target="blank" href={tabelaPeso}>
                Tabela de pesos
              </TournamentLink>
              {/* <TournamentLink href="#">Resultados (EM BREVE)</TournamentLink> */}
              {/* <TournamentLink href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.767B171E&u=teste">Resultadis Individuais</TournamentLink> */}
            </Content>
          </TournamentActions>
        </TournamentDescription>
      </Container>
      <Footer />
    </>
  );
};

export default IReiERainhaDaPraiaBeachBoxingManausAmazonas2023ManausAmazonas;
