import NavBar from "../../components/organisms/navbar";
import { Content, PaymentContainer } from "./styles";
import { FaWhatsapp } from "react-icons/fa";
import qrCode from "../../assets/images/qrCode.png";

import qrCodeCopaParana from "../../assets/images/tournaments/xxvi-copa-parana/qrCodeCopaParana.png";
import qrCodeOrlay from "../../assets/images/tournaments/xxxi-copa-orley-lobato/qrcodeOrlayLobato.png";
import { useParams } from "react-router-dom";
// import PaymentEnrollmentSeletivaManausOlimpica2024 from "./tournament/payment-enrollment-seletiva-manaus-olimpica-2024";
// import PaymentEnrollmentXxxiiCopaOrleyLobatoDeJiuJitsu from "./tournament/payment-enrollment-xxxii-orley-lobato-de-jiu-jitsu";
// import PaymentEnrollmentIvOpenTerrSantaDeJiuJitsu from "./tournament/payment-enrollment-iv-open-terra-santa-de-jiu-jitsu";
import PaymentEnrollmentEventosAntigos from "./tournament/payment-enrollment-eventos-antigos";
import PaymentEnrollmentXviiCopaParanaDeJiuJitsu from "./tournament/payment-enrollment-xvii-copa-parana-de-jiu-jitsu";
import PaymentEnrollmentIOrixicombatOpenJiuJitsu from "./tournament/payment-enrollment-i-orixicombat-open-jiu-jitsu";
import PaymentEnrollmentIiCopaArenaParintinsDeJiuJitsu from "./tournament/payment-enrollment-ii-copa-arena-parintins-de-jiu-jitsu";
import PaymentEnrollmentCopaThomasiDeJiuJitsu from "./tournament/payment-enrollment-copa-thomasi-de-jiu-jitsu";
import PaymentEnrollmentCopaLoboBravoCresolDeJiuJitsu from "./tournament/payment-enrollment-copa-lobo-bravo-cresol-de-jiu-jitsu";
import PaymentEnrollmentCopaFronteiraDeJiuJitsu from "./tournament/payment-enrollment-copa-fronteira-de-jiu-jitsu";
import PaymentEnrollmentIvOpenTerraSantaDeJiuJistu from "./tournament/payment-enrollment-iv-open-terra-santa-de-jiu-jitsu";
import PaymentEnrollmentXxxiiCopaOrleyLobatoDeJiuJistu from "./tournament/payment-enrollment-xxxii-orley-lobato-de-jiu-jitsu";
import PaymentEnrollmentViiOpenAmazonasTopTeamDeJiuJitsu from "./tournament/vii-open-amazonas-top-team-de-jiu-jitsu";
import PaymentEnrollmentCopaCianorteDeJiuJitsu from "./tournament/payment-enrollment-copa-cianorte-de-jiu-jitsu";
import PaymentEnrollmentViReiDaPraiaDeJiuJitsuNoGi from "./tournament/payment-enrollment-vi-rei-da-praia-de-jiu-jitsu-no-gi";
import PaymentEnrollmentIiCopaChampionsKidsDeJiuJitsu from "./tournament/payment-enrollment-ii-copa-champions-kids-de-jiu-jitsu";

const PaymentEnrollment = () => {
  const { slug } = useParams();
  console.log(slug);
  switch (slug) {
   // case "xxxii-copa-orley-lobato-de-jiu-jitsu-cel-nininberg-guerra-manaus-am":
    case "copa-mestre-acai-de-jiu-jitsu-2024-ginasio-arrudao-francisco-beltao-pr":
    case "seletiva-manaus-olimpica-2024-ginasio-ninimberg-guerra-sao-jorge-manaus-am":
    case "viii-rei-e-rainha-da-praia-beach-wrestling-2024-nossa-praia-sports":
    case "copa-amizade-de-jiu-jitsu-2024-ginasio-de-esportes-amario-vieira-da-costa-umuarama-pr":
    case "open-guaraniacu-brazilian-jiu-jitsu-2024-ginasio-de-esportes-onofre-garbasheski-guaraniacu-pr":
    case "i-rei-e-rainha-da-praia-beach-boxing-manaus-amazonas-2023-manaus-amazonas":
    case "1o-copa-arena-parintins-de-jiu-jitsu-ginasio-elias-assayag-parintins-am":
    case "toledo-international-open-brazilian-jiu-jitsu-nogi-2023-ginasio-de-esportes-alcides-pan-toledo-pr":
    case "toledo-international-open-brazilian-jiu-jitsu-gi-2023-ginasio-de-esportes-alcides-pan-toledo-pr":
    case "vii-rei-e-rainha-da-praia-beach-wrestling-manaus-amazonas":
    case "xvi-copa-parana-de-jiu-jitsu":
    case "torneio-estadual-gfteam-amazonas-de-jiu-jitsu-manaus-am":
    case "iii-open-terra-santa-de-jiu-jitisu":
    case "xxxi-copa-orley-lobato-de-jiu-jitsu-ninimbergue-guerra":
      return <PaymentEnrollmentEventosAntigos />
    // case 'xxxii-copa-orley-lobato-de-jiu-jitsu-cel-nininberg-guerra-manaus-am':
    //   return  <PaymentEnrollmentXxxiiCopaOrleyLobatoDeJiuJitsu/>
    // case 'xxxii-copa-orley-lobato-de-jiu-jitsu-cel-nininberg-guerra-manaus-am':
    //   return  <PaymentEnrollmentXxxiiCopaOrleyLobatoDeJiuJitsu/>
    // case 'iv-opem-terra-santa-de-jiu-jistu-ginasio-braulio-nelson-da-conceicao-gi':
    //   return  <PaymentEnrollmentIvOpenTerrSantaDeJiuJitsu/>
    case 'xvii-copa-parana-de-jiu-jitsu-ginasio-de-esportes-alcides-pan-toledo-pr-nogi':
    case 'xvii-copa-parana-de-jiu-jitsu-ginasio-de-esportes-alcides-pan-toledo-pr-gi':
      return <PaymentEnrollmentXviiCopaParanaDeJiuJitsu />
    case 'i-orixicombat-open-jiu-jitsu-ginasio-municipal-de-oriximina-pa':
      return <PaymentEnrollmentIOrixicombatOpenJiuJitsu />
    case 'ii-copa-arena-parintins-de-jiu-jitsu-arena-olimpica-rubens-dos-santos-gi':
    case 'ii-copa-arena-parintins-de-jiu-jitsu-arena-olimpica-rubens-dos-santos-nogi':
      return <PaymentEnrollmentIiCopaArenaParintinsDeJiuJitsu />
    case 'i-copa-thomasi-de-jiu-jitsu-parque-do-japao-maringa-pr':
      return <PaymentEnrollmentCopaThomasiDeJiuJitsu />
    case 'copa-lobo-bravo-cresol-de-jiu-jitsu-ginasio-assuncao-de-nossa-senhora-guarapuava-pr':
      return <PaymentEnrollmentCopaLoboBravoCresolDeJiuJitsu />
    case 'copa-fronteira-de-jiu-jitsu-ginasio-de-esportes-amario-vieira-da-costa-umuarama-pr':
      return <PaymentEnrollmentCopaFronteiraDeJiuJitsu />
    case "iv-opem-terra-santa-de-jiu-jistu-ginasio-braulio-nelson-da-conceicao-gi":
    case 'iv-opem-terra-santa-de-jiu-jistu-ginasio-braulio-nelson-da-conceicao-nogi':
      return <PaymentEnrollmentIvOpenTerraSantaDeJiuJistu />
    case "xxxii-copa-orley-lobato-de-jiu-jitsu-cel-nininberg-guerra-manaus-am":
      return <PaymentEnrollmentXxxiiCopaOrleyLobatoDeJiuJistu />
    case "vii-open-amazonas-top-team-de-jiu-jitsu-manaus-am-gi":
      return <PaymentEnrollmentViiOpenAmazonasTopTeamDeJiuJitsu />
    case "copa-cianorte-de-jiu-jitsu-cianorte-pr-gi":
      return <PaymentEnrollmentCopaCianorteDeJiuJitsu />
    case "vi-rei-da-praia-de-jiu-jitsu-santarem-pa-nogi":
      return <PaymentEnrollmentViReiDaPraiaDeJiuJitsuNoGi />
    case "ii-copa-champions-kids-de-jiu-jitsu-maringa-pr-gi":
      return <PaymentEnrollmentIiCopaChampionsKidsDeJiuJitsu />
    default:
      return <h1>Inscrição para esse Torneio não encontrado!</h1>
  }
}
export default PaymentEnrollment;