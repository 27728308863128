import { useState } from "react";
import api from "../../../services/api";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useAuthenticate from "../../../hooks/useAuthenticate";

import Input from "../../../components/atoms/input";
import Loading from "../../../components/atoms/loading";
import NavBar from "../../../components/organisms/navbar";
import Button from "../../../components/atoms/button";

import { FormContainer } from "../../enrollment/styles";
import { FormGroup, Content, PageTitle } from "./styles";
import InputMaskCustom from "../../../components/atoms/input-mask-custom";
import Select from "../../../components/atoms/select";

const NewAthletes = () => {
  const navigate = useNavigate();
  const { getToken } = useAuthenticate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [disabled, setDisabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const belts = [
    { title: "Branca", option: 1 },
    { title: "Cinza", option: 2 },
    { title: "Amarela", option: 3 },
    { title: "Laranja", option: 4 },
    { title: "Verde", option: 5 },
    { title: "Azul", option: 6 },
    { title: "Roxa", option: 7 },
    { title: "Marrom", option: 8 },
    { title: "Preta", option: 9 },
  ]

  const onSubmit = (data: any) => {
    setDisabled(true);
    setLoading(true);

    api
      .post(
        "/fighters",
        {
          ...data,

          name: data.name.trim(),
          mother_name: data.mother_name.trim(),
          genre: data.genre.trim(),
          gym: data.gym.toLowerCase().trim(),


          belt: +data.belt
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then(() => {
        toast.success("Cadastro do atleta foi realizado com sucesso!");

        setTimeout(() => {
          navigate("/profile");
        }, 2000);
      })
      .catch(() => {
        setDisabled(false);
        setLoading(false);
        toast.error("Não foi possível cadastrar atleta!");
      });
  };

  if (loading) return <Loading text="Aguarde, estamos processando seu cadastro." />;

  return (
    <>
      <NavBar />
      <Content>
        <PageTitle>Cadastro Atleta</PageTitle>
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Input
              label="Nome"
              name="name"
              type="text"
              register={register}
              required
              hasError={!!errors.name}
              error="Este campo é obrigatório"
            />
          </FormGroup>
          <FormGroup>
            <Input
              label="Nome da mãe"
              name="mother_name"
              type="text"
              register={register}
              required
              hasError={!!errors.mother_name}
              error="Este campo é obrigatório"
            />
          </FormGroup>
          <FormGroup>
            <Select
              label="Sexo"
              name="genre"
              register={register}
              required
              hasError={!!errors.genre}
              error="Este campo é obrigatório"
              options={[
                { title: "Masculino", option: "Masculino" },
                { title: "Feminino", option: "Feminino" },
              ]}
            />
          </FormGroup>
          <FormGroup>
            <InputMaskCustom
              label="Telefone"
              name="phone"
              mask="(99) 99999-9999"
              register={register}
              required
              hasError={!!errors.phone}
              error="Este campo é obrigatório"
            />
          </FormGroup>
          <FormGroup>
            <InputMaskCustom
              label="Data de nascimento"
              name="birth_date"
              mask="99/99/9999"
              register={register}
              required
              hasError={!!errors.birth_date}
              error="Este campo é obrigatório"
            />
          </FormGroup>
          <FormGroup>
            <Input
              label="Academia"
              name="gym"
              type="text"
              register={register}
              required
              hasError={!!errors.gym}
              error="Este campo é obrigatório"
            />
          </FormGroup>
          <FormGroup>
            <Select
              label="Faixa"
              name="belt"
              register={register}
              required
              hasError={!!errors.belt}
              error="Este campo é obrigatório"
              options={belts}
            />
          </FormGroup>
          <Button type="submit" label="Cadastrar Atleta" disabled={disabled} />
        </FormContainer>
      </Content>
    </>
  );
};

export default NewAthletes;
