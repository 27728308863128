import React, { useEffect } from "react";
import { Field, InputContainer, Label, ValidateHelp } from "./styles";

type InputMaskCustomProps = {
  mask: string;
  hasError?: boolean;
  error?: string;
  name?: string;
  register: any;
  required: any;
  placeholder?: any;
  label?: any;
};

const InputMaskCustom: React.FC<InputMaskCustomProps> = ({
  mask,
  name,
  hasError,
  error,
  register,
  required,
  placeholder,
  label,
}) => {
  const [messageError, setMessageError] = React.useState("");

  useEffect(() => {
    if (hasError && error) {
      setMessageError(error);
    } else {
      setMessageError("");
    }
  }, [hasError, error]);

  return (
    <InputContainer>
      <Label>{label}</Label>
      <Field
        {...register(name, { required })}
        hasError={hasError && error}
        mask={mask}
        placeholder={placeholder}
      />
      <ValidateHelp>{messageError || ""}</ValidateHelp>
    </InputContainer>
  );
};

export default InputMaskCustom;
