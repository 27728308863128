import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import Collapse from "../../components/atoms/colapso";
import Footer from "../../components/organisms/footer";
import NavBar from "../../components/organisms/navbar";
import { FaMapMarkerAlt } from 'react-icons/fa';
//import useAuthenticate from "../../hooks/useAuthenticate";
import {
  Container,
  Content,
  H3,
  H5,
  Location,
  P,
  QRCodeImg,
  Span,
  Title,
  TournamentActions,
  TournamentBanner,
  TournamentContainer,
  TournamentContainerBanner,
  TournamentContent,
  TournamentDescription,
  TournamentEnrollmentLink,
  TournamentEnrollmentLinkEndpage,
  TournamentLink,
  EditalContainer,
  EditalContent,
  EditalTitle
} from "./styles";
// import tabelaPeso from "../../assets/images/tournaments/toledo-international-open-brazilian-jiu-jitsu-2023/tabepla-de-peso.jpeg";
import tabelaPeso from "../../assets/images/tournaments/i-copa-thomasi-de-jiu-jitsu/tabela-i-copa-thomasi-de-jiu-jitsu.jpg";
import qrCodecopaAmizadeDeJiuJitsu2024 from "../../assets/images/tournaments/copa-amizade-de-jiu-jitsu-2024/qr-coce--copa-amizade-de-jiu-jitsu-2024.jpg";

//Patrocinio
import torneio from "../../assets/images/sponsors/torneio-facil.png";
import thomasiHotel from "../../assets/images/sponsors/thomasi-hotel.jpg";



//Apoio
import trevusMartialArts from "../../assets/images/sponsors/trevus-martial-arts.jpg";


import { SponsorContainer, SponsorList } from "src/components/atoms/sponsor-area/styles";
import SponsorButton from "src/components/atoms/sponsor-area";
import useAuthenticate from "../../hooks/useAuthenticate";

const sponsors = [
  { img: thomasiHotel, url: "#" },
  { img: thomasiHotel, url: "#" },
  { img: thomasiHotel, url: "#" },
  { img: thomasiHotel, url: "#" },
  { img: thomasiHotel, url: "#" },
];

const dataEvento = "14 de julho de 2024"
const horaEvento = "9:00h da manhã"
const tituloEvento = "I Copa Thomasi de Jiu Jitsu"
const localEvento = "Parque do Japão"
const endereçoEvento = "R. Tulípa, 987 - Jardim Industrial, Maringá - PR, CEP 87065-320"
const dataDeEncerramentoIncricao = "10/07/2024"
const horaDeEncerramentoIncricao = "23h:59min"

const dataDeEncerramentoCorrecao = "10/08/2024"
const horaDeEncerramentoCorrecao = "23h:59min"

const dataDeEncerramentoInscricaoLote1 = "30/06/2024"
const dataDeEncerramentoInscricaoLote2 = "10/07/2024"

const valorinscricaoCategoriaLote1 = "R$100,00 (Cem reais)"
const valorinscricaoCategoriaLote2 = "R$130.00 (Cento e trinta reais)"


const valorinscricaoAbsolutoLote1 = "R$30,00 (trinta reais)"
const valorinscricaoAbsolutoLote2 = "40,00 (quarenta e cinco reais)"

const dataDeDivulgacaoChaves = "13/07/2024"
const horaDeDivulgacaoChaves = "23h:59min"

const instituicaoBancaria = "NuBank"
const tipoDeChavePix = "E-mail"
const ChavePix = "copathomasi@gmail.com"
const BeneficiarioDaChavePix = "Juares Lima dos Santos"

const dataDeDivulgacaoCronograma = "EM BREVE"
const horaDeDivulgacaoCCronograma = "EM BREVE"

const contatoWhatsApp = "+55 (44) 99909-6269"



const support = [
  //{ img: esporteNaPeriferia },
  { img: torneio },
];

const ICopaThomasiDeJiuJitsu: React.FC = () => {
  const { authenticated } = useAuthenticate();

  return (
    <>
      <NavBar />

      <Container>
        <TournamentContainer
          image={require("../../assets/images/tournaments/i-copa-thomasi-de-jiu-jitsu/i-copa-thomasi-de-jiu-jitsu.jpg")}
        >
          <TournamentContainerBanner>
            <TournamentBanner
              image={require("../../assets/images/tournaments/i-copa-thomasi-de-jiu-jitsu/i-copa-thomasi-de-jiu-jitsu.jpg")}
            />
          </TournamentContainerBanner>
        </TournamentContainer>
        <TournamentContent>
          <Content>
            <Title>{tituloEvento}</Title>
            <Location>
              <Span>Data: {dataEvento}</Span>
              <Span>Início: {horaEvento}</Span>
              <Span>Local: {localEvento}</Span>
              <Span>Endereço: {endereçoEvento}</Span>
              <Span>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                  <FaMapMarkerAlt style={{ marginRight: '8px', color: "red" }} />
                  <a
                    target={"blank"}
                    style={{ color: "#000", textDecoration: "none", fontWeight: "bold" }}
                    href="https://maps.app.goo.gl/HVWqRWfmEkM8Q49d9"
                  >
                    Ver no Google Maps
                  </a>
                </div>
              </Span>
            </Location>
            <P></P>
            <br />

          </Content>
        </TournamentContent>
        <TournamentDescription>
          <TournamentActions>
            <Content>
              <h1 style={{ textAlign: "center" }}>PATROCINADORES</h1>
              <SponsorContainer>
                <SponsorList>
                  {sponsors && sponsors.map((buttons: any, index: number) => (
                    <SponsorButton
                      key={index} // Adicionei uma chave única para cada botão
                      img={buttons.img}
                      url={buttons.url}
                      hasError={false}
                      error="Este campo é obrigatório"
                      target="_blank" // Adicione este atributo
                    />
                  ))}
                </SponsorList>
              </SponsorContainer>
              <h1 style={{ textAlign: "center" }}>APOIO</h1>
              <SponsorContainer>
                <SponsorList>
                  {support &&
                    support.map((buttons: any) => (
                      <SponsorButton
                        img={buttons.img}
                        url={buttons.url}
                        hasError={false}
                        error="Este campo é obrigatório"
                      />
                    ))}
                </SponsorList>
              </SponsorContainer>
            </Content>

            <Content style={{
              marginTop: '32px'
            }}>

              <EditalContainer>
                <EditalTitle>EDITAL {tituloEvento.toLocaleUpperCase()}</EditalTitle>
                <EditalContent>
                  <Span>
                    A {tituloEvento} é um evento Paranaense que fomenta e incentiva
                    a prática do jiu jitsu no Paraná . As lutas
                    serão exclusivamente com GI (com kimono), o evento disponibilizará
                    categorias para todas as idades e faixas, conforme as tabelas de peso
                    deste edital. Os competidores terão a oportunidade de testar suas
                    habilidades em um ambiente controlado e com acompanhamento de
                    profissionais qualificados.
                  </Span>
                  <br />
                  <br />
                  <br />
                </EditalContent>

                <Collapse title="PROTOCOLO DE SEGURANÇA CONTRA COVID-19">
                  <P>
                    Devido a dinâmica da situação atual, estamos avaliando diariamente as
                    recomendações dos Órgãos de Saúde e este protocolo pode ser atualizado a
                    qualquer momento caso novas determinações sejam publicadas.
                  </P>
                  <P>
                    Caso o evento seja cancelado por força maior, todas as inscrições serão
                    automaticamente reembolsadas.
                  </P>
                  <P>
                    A organização não é responsável pelos demais custos dos participantes,
                    tais como transporte e hospedagem.
                  </P>
                  <P>
                    Diretrizes do ginásio: Para a segurança de todos, por favor verifique
                    sua temperatura antes de se dirigir ao evento.{" "}
                  </P>
                  <P>Caso haja qualquer sintoma, procure um médico.</P>{" "}
                  <P>
                    {" "}
                    Caso você se sinta indisposto ou esteve em contato com um paciente
                    diagnosticado com Covid-19, por favor fique em casa e procure seu
                    médico.
                  </P>
                  {/* <P>
                  Todo nosso material será desinfetado antes e durante o evento.
                </P> */}
                </Collapse>
                <Collapse title="PREMIAÇÕES MEDALHAS🥇, DINHEIRO💰, Kimono🥋, kit suplemento💊">
                  <br />
                  <H3>Absoluto Masculino:</H3>
                  <H5>Juvenil Branca</H5>
                  <P>1° lugar: medalha🥇</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <H5>Juvenil AzuL</H5>
                  <P>1° lugar: medalha🥇</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <H5>Branca Adulto e Master 1 (JUNTOS)</H5>
                  <P>1° lugar: medalha🥇 + Kimono🥋 + kit suplemento💊</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <H5>Azul Adulto e Master 1 (JUNTOS)</H5>
                  <P>1° lugar: medalha🥇 + Kimono🥋 + kit suplemento💊</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <H5>Roxa Adulto e Master 1 (JUNTOS)</H5>
                  <P>1° lugar: medalha🥇 + Kimono🥋 + kit suplemento💊</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <H5>Marrom Adulto e Master 1 (JUNTOS)</H5>
                  <P>1° lugar: medalha🥇 + Kimono🥋 + kit suplemento💊</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <H5>Preta Adulto e Master 1 (JUNTOS)</H5>
                  <P>1° lugar: medalha🥇 + R$ 2.500,00💰 + kit suplemento💊</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <H5>Branca Master 2, 3, 4 e 5 (JUNTOS)</H5>
                  <P>1° lugar: medalha🥇 + Kimono🥋 + kit suplemento💊</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <H5>Azul Master 2, 3, 4 e 5 (JUNTOS)</H5>
                  <P>1° lugar: medalha🥇 + Kimono🥋 + kit suplemento💊</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <H5>Roxa Master 2, 3, 4 e 5 (JUNTOS)</H5>
                  <P>1° lugar: medalha🥇 + Kimono🥋 + kit suplemento💊</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <H5>Marrom Master 2, 3, 4 e 5 (JUNTOS)</H5>
                  <P>1° lugar: medalha🥇 + Kimono🥋 + kit suplemento💊</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <H5>Preta Master 2, 3, 4 e 5 (JUNTOS)</H5>
                  <P>1° lugar: medalha🥇 + R$ 500,00💰 + kit suplemento💊</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>

                  <br />
                  <H3>Absoluto Femnino:</H3>
                  <H5>Juvenil Branca e Azul(JUNTAS)</H5>
                  <P>1° lugar: medalha🥇</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <H5>Adulto e Master - Branca e Azul(JUNTAS)</H5>
                  <P>1° lugar: medalha🥇 + kit suplemento💊</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <H5>Adulto e Master - Roxa, Marrom e Preta(JUNTAS)</H5>
                  <P>1° lugar: medalha🥇+ kit suplemento💊</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>

                  <br />
                  <H3>Demais Categorias</H3>
                  <P>1° lugar: medalha🥇</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <br />
                  <p>OBS: Estas premiações só irão valer para os Absolutos que tiverem 08 (OITO)
                    ou mais atletas inscritos.</p>
                  <p>Em caso com menos de 08 (OITO) inscritos no Absoluto, não haverá lutas nem premiação.
                  </p>
                  <p>Mais informações sobre as inscriçoes para o absoluto esta disponivel na seção
                    INSCRIÇÕES, paragrafo Inscrição de Absoluto deste edital.
                  </p>
                  <br />
                </Collapse>

                <Collapse title="INSCRIÇÕES">
                  <Span>
                    As inscrições para o evento serão realizadas exclusivamente online, por
                    meio do site www.torneiofacil.com.br
                  </Span>
                  <P>
                    Durante o processo de inscrição, o atleta ou seu responsável legal
                    deverá selecionar corretamente a categoria referente idade que o atleta
                    completará no ano de 2024, bem como sua faixa e peso.
                  </P>
                  <P>
                    A idade de competição do atleta é a idade máxima que ele terá em 2024,
                    conforme regra da CBJJ.
                  </P>
                  <br />

                  <H3>Prazo de Inscrição</H3>
                  <P>
                    O prazo final para inscrições e pagamento será até o dia {dataDeEncerramentoIncricao}, às
                    {horaDeEncerramentoIncricao}min.
                  </P>
                  <br />
                  <P>As inscrições que não forem pagas serão canceladas.</P>
                  <br></br>

                  <H3>Valor  </H3>
                  <P>Primeiro Lote até {dataDeEncerramentoInscricaoLote1}</P>
                  <P>Inscrição categoria R$ {valorinscricaoCategoriaLote1}</P>
                  {/* <P>Inscrição absoluto R$ {valorinscricaoAbsolutoLote1} (trinta reais)</P> */}
                  <br />
                  <P>Segundo Lote até {dataDeEncerramentoInscricaoLote2}</P>
                  <P>Inscrição categoria R$ {valorinscricaoCategoriaLote2}</P>
                  {/* <P>Inscrição absoluto R$ {valorinscricaoAbsolutoLote2} (quarenta e cinco reais)</P> */}

                  <br />
                  <br />
                  <H3>Correção</H3>

                  <P>
                    A correção da inscrição poderá ser realizada somente após a confirmação
                    do pagamento.
                  </P>
                  <P>
                    Em caso de inscrição equivocada, o atleta ou professor responsável
                    poderá corrigir as informações no site www.torneiofacil.com.br,
                    acessando a opção Meu Perfil em seguida Minhas Inscrições.
                  </P>
                  <P>
                    A correção da inscrição ficará disponivel até o encerramento das
                    inscrições {dataDeEncerramentoCorrecao} as {horaDeEncerramentoCorrecao}.
                  </P>
                  <P>
                    Após prazo para correção das inscrições, não serão aceitas modificações
                    nas inscrições.
                  </P>
                  <P>
                    Os atletas que se inscreverem incorretamente estarão sujeitos a
                    desclassificação <b>sem direito a reembolso.</b>
                  </P>

                  <br />

                  <H3>Prazo de Correção</H3>
                  <P>
                    A correção da inscrição fica disponível após a confirmação do pagamento
                    da mesma.
                  </P>

                  <P>
                    A correção da inscrição ficará disponivel até o encerramento das
                    inscrições no {dataDeEncerramentoCorrecao} as {horaDeEncerramentoCorrecao}.
                  </P>
                  <H3>Checagem</H3>

                  <P>
                    O sorteio e divulgação das chaves de lutas será no dia {dataDeDivulgacaoChaves}4 até as {horaDeDivulgacaoChaves}.
                  </P>

                  <br />
                  <H3>Inscrição de Absoluto</H3>
                  <P>
                    Somente atletas inscritos na categoria poderão se increver e participar
                    do absoluto.
                  </P>
                  As inscrições para os absolutos são gratuitas, podendo ter no máximo 2 (DOIS)
                  atletas da mesma equipe por absoluto.
                  <P>
                    As inscrições para os absolutos serão realizadas pelo professor
                    responsável pelo atleta, junto a organização do evento no dia do torneio
                    após a finalização das lutas da categoria.
                  </P>
                  <P>
                    Os atletas só poderão se inscrever no absoluto referente a categoria da
                    sua inscrição no torneio. Exceção apenas para os master que poderá se
                    inscrever como absoluto adulto.
                  </P>
                </Collapse>
                <Collapse title="VALOR DA INSCRIÇÃO">
                  <Span>
                    As inscrições serão divididas em dois lotes
                  </Span>
                  <br />
                  <H3>Primeiro Lote até {dataDeEncerramentoInscricaoLote1}</H3>
                  <P>Inscrição categoria R$ {valorinscricaoCategoriaLote1}</P>
                  {/* <P>Inscrição absoluto R$ {valorinscricaoAbsolutoLote1}</P> */}
                  <br />
                  <H3>Segundo Lote até {dataDeEncerramentoInscricaoLote2}</H3>
                  <P>Inscrição categoria R$ {valorinscricaoCategoriaLote2}</P>
                  {/* <P>Inscrição absoluto R$ {valorinscricaoAbsolutoLote2}</P> */}
                  <br />
                </Collapse>
                <Collapse title="PAGAMENTO">
                  <H3>
                    A confirmação de pagamento deverá ser realizada via WhatsApp enviando o
                    comprovante para o contato abaixo:
                  </H3>
                  <P>Banco: {instituicaoBancaria}</P>
                  <P>Titular: {BeneficiarioDaChavePix}</P>
                  <P>Tipo de chave PIX: {tipoDeChavePix}</P>
                  <P>Chave PIX: {ChavePix}</P>
                  {/* <QRCodeImg src={qrCodecopaAmizadeDeJiuJitsu2024} alt="qrCode" width={300} />
                  <P>
                    <a
                      target={"blank"}
                      style={{ color: "white" }}
                      href="https://whatsa.me/5592981291027/?t=Ol%C3%A1+Thiago_Dellarmelina%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+na+%2ACOPA+ORLEY+LOBATO+DE+JIU+JITSU+2024%2A"
                    >
                      <strong>WhatsApp: +55 (92) 98129-1027 </strong>
                      <FaWhatsapp />
                    </a>
                  </P> */}
                  <p />
                  {/* <QRCodeImg src={qrCodeCopaParana} alt="qrCode" width={300} /> */}
                </Collapse>

                <Collapse title="REEMBOLSO DAS INSCRIÇÕES">
                  <H3>
                    Após o fim do perido de inscrição não será possivel o reembolso das inscrições pagas.
                  </H3>

                </Collapse>

                <Collapse title="REGRAS">
                  <Span>
                    Todas as regras para o evento estão devidamente estipuladas e detalhadas neste edital.
                  </Span>
                  <Span>
                    Em casos de omissão de regras no presente edital, as normas a serem observadas serão aquelas estabelecidas pela Confederação
                    Brasileira de Jiu-Jitsu (CBJJ), em conformidade com as diretrizes da CBJJ/IBJJF.
                  </Span>
                  <Span>
                    A tabela de peso por categoria, que estabelece os limites de peso para cada categoria, encontra-se disponível no site oficial do evento,
                    acessível por meio do botão denominado "Tabela de Peso".
                  </Span>
                  <Span>
                    As lutas serão com GI (com kimonos de cores Oficiais pela cbjj branco,
                    azul e preto ) os kimonos devem estar limpos sem rasgos e sem odor .
                  </Span>
                  <Span>
                    Havera premiação de primeiro, segundo, terceiro e quarto lugar para atletas até 9 anos, a partir de 10 anos somente haverá premiação de
                    primeiro, segundo e terceiro lugar.
                  </Span>
                  <Span>
                    Crianças de 4 a 7 anos o torneio será no modo festival, ou seja, todas ganham mealhas, mas apenas os 4 (Quatros)
                    primeiros colocados sobem ao pódio.
                  </Span>
                  <Span>
                    O atleta que pedeu a semifinal para o Campeão ficará em terceiro lugar e o atleta que perdeu a semifinal
                    para o Vice-Campeão ficará em quarto lugar.
                  </Span>
                  <Span>
                    Para categoria com 3 lutadores, os atletas farão a melhor de
                    três para definição do campão, segundo e terceiro lugar conforme regra da CBJJ
                  </Span>
                  <Span>
                    A pesagem será realizada no dia do evento. O competidor deverá
                    apresentar documento oficial com foto no momento da pesagem.
                  </Span>
                  <Span>
                    A pesagem dos atletas serão realizadas antes do inicio da categoria.
                  </Span>
                  <Span>
                    Atletas que ultrapasarem o limite de peso da categoria que entiverem
                    inscritos serão automaticamente desclassificados e não poderão lutar o
                    Absoluto.
                  </Span>
                  <Span>Será realizada apenas uma pesagem oficial por atleta.</Span>
                  <Span>
                    Teremos uma balança para pesagem livre (não oficial) para os atletas.
                  </Span>
                  <Span>
                    Teremos uma balança para pesagem oficial dos atletas.</Span>
                  <Span>
                    Atletas que lutaram em outros eventos com faixa superior não poderão
                    lutar com a faixa abaixo. Se descoberto, o competidor será eliminado do
                    torneio sem direito a reembolso.
                  </Span>
                  <br />
                  <P>
                    O que não estiver neste edital, será acatado o que estiver no livro de
                    regras da CBJJ.
                  </P>
                  <br />
                  <P>
                    <a
                      target={"blank"}
                      href="https://cbjj.com.br/books-videos"
                      style={{ color: "white" }}
                    >
                      <strong>LIVRO DE REGRAS DA CBJJ/IBJJF</strong>
                    </a>
                  </P>
                </Collapse>
                <Collapse title= "CRONOGRAMA">
                {/* <Collapse title={"CRONOGRAMA SERÁ LIBERADO ATÉ O DIA " + dataDeDivulgacaoCronograma + " AS " + horaDeDivulgacaoCCronograma}>  */}
                  {/* (EM BREVE) */}
                  <Container>
                    {/* <b><Span>Obs: O cronograma definitivo será atualizado no dia 11/11/2023.</Span></b>
                  <br />
                  <Span>Data: 14 de abril de 2024</Span>
                  <Span>Início: 9:00h da manhã</Span>
                  <Span>Local: Ginásio de Esportes Amário Vieira da Costa, Umuarama PR</Span>
                  <Span>Endereço: Av. Gov. Parigot de Souza, 4284-4454 - Zona I, Umuarama - PR, CEP: 87503-680</Span>
                  <br />
                  <H5>9:30h - Abertura do evento</H5>
                  <br />
                  <H3>Lutas GI</H3>
                  <H5>9:45h - 04, 05, 06, 07, 08, 09, 10 e 11 anos (Faixa branca e Graduado) masculino e feminino.</H5>
                  <H5>10:45h - 12, 13, 14 e 15 anos (Faixa branca e Graduado) masculino e feminino.</H5>
                  <H5>11:30h - 16 e 17 anos (Faixa Branca) masculino e feminino.</H5>
                  <H5>11:30h - 16 e 17 anos (Faixa Branca) masculino e feminino.</H5>
                  <H5>12:00h - Adulto (Faixa Branca) masculino e feminino.</H5>
                  <H5>12:40h - Master (Branca) masculino e feminino.</H5>
                  <H5>12:40h - Adulto (Faixa Azul) masculino e feminino.</H5>
                  <H5>13:40h - Adulto (Faixa Roxa) masculino e feminino.</H5>
                  <H5>13:40h - Master (Faixa Azul) masculino e feminino.</H5>
                  <H5>15:00 - Adulto e Master (Faixa Preta ) Masculino e Feminino</H5>
                  <br /> */}
                    <H5>08:40h -CATEGORIAS MIRIM A – Todas as faixas do Masculino e Feminino na área de aquecimento</H5>
                    <H5>08:50h – ABERTURA COM O HINO NACIONAL</H5>
                    <H5>09:00h – INICIO DO EVENTO</H5>
                    <H5>09:30h - CATEGORIA MIRIM B - Todas as faixas do Masculino e Feminino</H5>
                    <H5>10:00h - INFANTIL A - Todas as faixas do Masculino e Feminino</H5>
                    <H5>10:30h - INFANTIL B - Todas as faixas do Masculino e Feminino</H5>
                    <H5>11:00h - INFANTO-JUVENIL- Todas as faixas do Masculino e Feminino</H5>
                    <H5>11:30h - JUVENIL - Todas as faixas do Masculino e Feminino</H5>
                    <H5>12:00h - FAIXA BRANCA ADULTO - Masculino e Feminino</H5>
                    <H5>12:40h - FAIXA BRANCA MASTER - Masculino e Feminino</H5>
                    <H5>13:30h - FAIXA AZUL ADULTO E MASTER - Masculino e Feminino</H5>
                    <H5>14:20h - FAIXA ROXA ADULTO E MASTER - Masculino e Feminino</H5>
                    <H5>15:00h - FAIXA MARRON E PRETA ADULTO E MASTER - Masculino e Feminino</H5>
                    <H5>15:30h - INICIO DAS INSCRIÇÕES DOS ABSOLUTOS</H5>
                    <H5>
                      Todos os atletas devem estar 01(uma) hora antes do tempo de sua
                      categoria na área de aquecimento podendo lutar até 30 min antes do
                      horário previsto no cronograma
                    </H5>
                    <br />
                    <H5>
                      Obs.1: O atleta deverá comparecer no ginasio com uma (01) hora de antecedência do
                      horário previsto no cronograma.
                    </H5>

                    <Span>Usaremos 5 áreas de luta</Span>
                    <Span>
                      O cronograma pode ser adiantado em até 1 hora, e atrasar conforme a
                      necessidade do evento.
                    </Span>
                    <Span>
                      O atleta que não estiver presente no momento da chamada para a pesagem
                      ou luta ser desclassificado por W.O.
                    </Span>
                  </Container>
                </Collapse>
                <Collapse title="ENTRADA">
                  <P>Entrada liberada ao público.</P>
                  <br />
                  {/* <P>
                  Será cobrado 1kg de alimento não perecível na entrada para atletas e
                  público.
                </P> */}
                  {/* <br />
                <P>Crianças até 5 anos ficam isentas de qualquer tipo de pagamento.</P>
                <br /> */}
                </Collapse>
                <Collapse title="CONTATO">
                  <P>
                    Duvidas sobre cadastro, inscrição, pagamento, correção, checagem
                    ou sugestão de melhorias entre em contato com a equipe do Torneio
                    Fácil por telefone ou WhatsApp.
                  </P>
                  <P>
                    Melhor horário para atendimento das 18:00h as 23:59h.
                  </P>
                  <a target={"blank"} href="https://whatsa.me/5544999096269/?t=Ol%C3%A1%20Rui%20Ven%C3%A2ncio!%2A%0A%0APreciso%20de%20ajuda%20com%20minha%20inscri%C3%A7%C3%A3o%20na%20*I%20COPA%20THOMASI%20DE%20JIU%20JITSU*"
                  >
                    <strong>Telefone/WhatsApp: {contatoWhatsApp} </strong>
                    <FaWhatsapp />
                  </a>

                  <br />
                </Collapse>
              </EditalContainer>
            </Content>
            <br />
            <Content>
              {authenticated ? (
                <>
                  <TournamentEnrollmentLinkEndpage to="/enrollment/i-copa-thomasi-de-jiu-jitsu-parque-do-japao-maringa-pr">
                    Inscrição
                  </TournamentEnrollmentLinkEndpage>

                  <TournamentEnrollmentLinkEndpage to="/profile/subscription">
                    Correção da Inscrição
                  </TournamentEnrollmentLinkEndpage>
                </>
              ) : (
                <>
                  <TournamentEnrollmentLinkEndpage to="/login">
                    Inscrição
                  </TournamentEnrollmentLinkEndpage>

                  <TournamentEnrollmentLinkEndpage to="/login">
                    Correção da Inscrição
                  </TournamentEnrollmentLinkEndpage>
                </>
              )}
              {/* <TournamentLink href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.767B171E&u=teste" target="_blank">Chaves</TournamentLink> */}
              {/* // /check/16-COPA-PARANA-DE-JIU-JITSU */}

              <TournamentLink
                // href="#"
                // target="_blank"
                //href="/check/#/search"
                href="/check/i-copa-thomasi-de-jiu-jitsu-parque-do-japao-maringa-pr/search"
              //href="#"
              //href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.D7A54E37&u=teste"
              >Checagem</TournamentLink>


              <TournamentLink
                // href="#"
                // target="_blank"
                //href="/check/#/search"
                href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.D7A54E37&u=teste"
              >Chaves</TournamentLink>


              {/* 
              <TournamentLink
                href="/check/toledo-international-open-brazilian-jiu-jitsu-nogi-2023-ginasio-de-esportes-alcides-pan-toledo-pr/search"
                target="_blank"
              >
                Checagem de atletas NO GI
              </TournamentLink>  */}

              {/*               <TournamentLink
                href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.D7A54E37&u=teste"
                target="_blank"
              >
                Chaves GI
              </TournamentLink> */}

              {/*               <TournamentLink
                href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.5B5B1C9A&u=teste"
                target="_blank"
              >
                Chaves NO GI
              </TournamentLink> */}

              <TournamentLink target="blank" href={tabelaPeso}>
                Tabela de pesos
              </TournamentLink>
              {/* <TournamentLink href="#">Resultados (EM BREVE)</TournamentLink> */}
            </Content>
          </TournamentActions>
        </TournamentDescription>
      </Container>
      <Footer />
    </>
  );
};

export default ICopaThomasiDeJiuJitsu;
