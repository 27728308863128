import styled from "styled-components";

export const StyledButton = styled.button`
  cursor: pointer;
  background: #373737;
  border-radius: 8px;
  padding: 18px;
  border: none;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: #ffffff;
  width: 100%;
`;
