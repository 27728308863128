import React, { useEffect } from "react";
import { Field, FieldSecret, InputBox, InputContainer, Label, ValidateHelp } from "./styles";
import { IoEyeSharp, IoEyeOffSharp } from "react-icons/io5";

type InputProps = {
  label: string;
  type:
  | "text"
  | "password"
  | "number"
  | "email"
  | "tel"
  | "date"
  | "time"
  | "datetime-local"
  | "hidden"
  | "month"
  | "week"
  | "url"
  | "search"
  | "color"
  | "range"
  | "file"
  | "image"
  | "radio"
  | "checkbox"
  | "button"
  | "submit"
  | "reset"
  | "textarea"
  | "select"
  | "select-multiple"
  | "select-one"
  | "tel"
  | "text"
  | "time"
  | "url"
  | "week"
  | "textarea";
  placeholder?: string;
  value?: string | number;
  name?: string;
  hasError?: boolean;
  error?: string;
  register?: any;
  required: any;
  transformToLowerCase?: boolean; // adicionado
  pattern?: any;
  trim?: boolean;
};

const Input: React.FC<InputProps> = ({
  label,
  type = "text",
  placeholder,
  value,
  name,
  hasError,
  error,
  register,
  required,
  transformToLowerCase, // adicionado
  trim
}) => {
  const [messageError, setMessageError] = React.useState("");
  const [hide, setHide] = React.useState<boolean>(true);

  useEffect(() => {
    if (hasError && error) {
      setMessageError(error);
    } else {
      setMessageError("");
    }
  }, [hasError, error]);

  const handleInput = (event: React.FormEvent<HTMLInputElement>) => {
    if (transformToLowerCase) {
      event.currentTarget.value = event.currentTarget.value.toLowerCase();
    }

    if(trim){
      event.currentTarget.value = event.currentTarget.value.trim();
    }
  };

  const handleHide = () => {
    setHide(h => !h);
  }

  return (
    <InputContainer>
      <Label>{label}</Label>

      {
        type !== "password" ?
          <Field
            hasError={hasError && error}
            type={type}
            value={value}
            placeholder={placeholder}
            {...register(name, { required })}
            onInput={handleInput} // adicionado
          /> :
          <InputBox>
            <FieldSecret
              hasError={hasError && error}
              type={hide ? type : "text"}
              value={value}
              placeholder={placeholder}
              {...register(name, { required })}
              onInput={handleInput} // adicionado
            >
            </FieldSecret>
            {
              hide ?
              <IoEyeSharp size={25} style={{
                position: 'absolute',
                top: 10,
                right: 10
              }} onClick={() => handleHide()} />:
                <IoEyeOffSharp size={25} style={{
                  position: 'absolute',
                  top: 10,
                  right: 10
                }} onClick={() => handleHide()} /> 
            }
          </InputBox>
      }

      <ValidateHelp>{messageError || ""}</ValidateHelp>
    </InputContainer>
  );
};

export default Input;
