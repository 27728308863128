import React, { useState } from "react";
import styled, { ThemedStyledProps } from "styled-components";
import { AiOutlineCaretDown } from "react-icons/ai";
import { AiOutlineCaretUp } from "react-icons/ai";

const CollapseWrapper = styled.div`
  border: 1px solid #ccc;
  padding: 16px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background: rgb(55, 55, 55);
  border-radius: 8px;
`;

const CollapseButton = styled.button`
  background: rgb(55, 55, 55);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  font-size: 16px;
  width: 100%;
  text-align: start;
  cursor: pointer;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: rgb(255, 255, 255);
  text-decoration: none;
  font-weight: bold; ;
`;

type CollapseContentProps = ThemedStyledProps<
  {
    isOpen: boolean;
  },
  any
>;

const CollapseContent = styled.div<CollapseContentProps>`
  margin-top: 16px;
  display: ${(props) => (props.isOpen ? "block" : "none")};
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: rgb(255, 255, 255);
  > a {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.01em;
    text-decoration: none;
    color: rgb(255, 255, 255);
  }
  > h3 {
    line-height: 24px;
  }

`;

const Collapse = ({ title, children }: any) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <CollapseWrapper>
      <CollapseButton onClick={handleToggle}><span style={{
        flex: 1
      }}>{title}</span> { isOpen ? <AiOutlineCaretUp size={16}/> : <AiOutlineCaretDown size={16}/>}</CollapseButton>
      <CollapseContent isOpen={isOpen}>{children}</CollapseContent>
    </CollapseWrapper>
  );
};

export default Collapse;
