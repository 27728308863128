import React from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import logoImg from "../../../assets/images/logo.png";
import Button from "../../../components/atoms/button";
import Input from "../../../components/atoms/input";

import api from "../../../services/api";
import {
  AccountExists,
  Container,
  Content,
  FormContent,
  FormGroup,
  PageDescription,
  PageLogo,
  PageTitle,
  SectionBanner,
  SectionForm,
} from "./styles";
import Swal from "sweetalert2";

const ChangePassword: React.FC = () => {
  const navigate = useNavigate();
  const { code } = useParams()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    if (data.password !== data.confirmPassword) {
      Swal.fire({
        title: 'Atenção!',
        text: `A senha e a confirmação de senha devem ser iguais.`,
        icon: 'error',
      })
      return toast.error("A senha e a confirmação de senha devem ser iguais.");
    }

    api
      .post("/auth/password/recovery/change-password", {
        ...data,
        code
      })
      .then((response) => {
        if (response.data.status) {
          toast.success(response.data.message);
          Swal.fire({
            title: 'Sucesso!',
            text: `${response.data.message}`,
            icon: 'success',
          })

          setTimeout(() => {
            navigate("/login");
          }, 2000);
        } else {
          Swal.fire({
            title: 'Atenção!',
            html: "Verifique seu email. </br> Email de recuperação expirou ou já foi utilizado.",
            icon: 'error',
            showCancelButton: true,
            confirmButtonText: `Aguarde o email mais recente`,
            cancelButtonText: `Solicitar novo email de recuperação`,
          }).then((result) => {
            if (result.isConfirmed) {
              navigate('/login');
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              navigate('/password-recovery');
            }
          })
          toast.error(response.data.message);
        }
      })
      .catch(() =>
        toast.error(
          "Não foi possível recuperar a sua senha, verifique seu e-mail!"
        )
      );
  };

  return (
    <Container>
      <SectionForm>
        <Content>
          <Link to={"/"}>
            <PageLogo src={logoImg} />
          </Link>
          <PageTitle>Alterar a senha</PageTitle>
          <PageDescription>
            Bem-vindo ao torneio fácil. Para alterar <br />a sua senha preencha
            os dados abaixo.
          </PageDescription>

          <FormContent onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Input
                label="Senha"
                name="password"
                type="password"
                register={register}
                required
                hasError={!!errors.password}
                error="Este campo é obrigatório"
                trim={true}
              />
            </FormGroup>
            <FormGroup>
              <Input
                label="Confirmar senha"
                name="confirmPassword"
                type="password"
                register={register}
                required
                hasError={!!errors.confirmPassword}
                error="Este campo é obrigatório"
                trim={true}
              />
            </FormGroup>
            <FormGroup>
              <Button type="submit" label="Alterar senha" />
            </FormGroup>
            <FormGroup>
              <AccountExists href="/login">
                Já possui conta? Acesse aqui.
              </AccountExists>
            </FormGroup>
          </FormContent>
        </Content>
      </SectionForm>
      <SectionBanner></SectionBanner>
    </Container>
  );
};

export default ChangePassword;
