import styled from "styled-components";

interface NavbarMenuProps {
  isOpen: boolean;
}

export const NavbarLogo = styled.img`
  height: 50px;

  @media screen and (max-width: 768px) {
    margin: auto;
  }
`;

export const Navbar = styled.nav`
  background-color: #ffffff;
  color: #000000;
  padding: 1rem;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
`;

export const NavbarMenu = styled.ul<NavbarMenuProps>`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  @media screen and (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
    flex-direction: column;
    position: absolute;
    top: 0;
    right: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
    height: 100vh;
    width: 80%;
    padding: 1rem;
    background-color: #ffffff;
    z-index: 1;
    transition: all 0.3s ease-in-out;
  }
`;

export const CloseMenuButton = styled.button`
  font-size: 1.5rem;
  background-color: transparent;
  border: none;
  color: #000000;
  cursor: pointer;
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const NavbarMenuItem = styled.li`
  margin: 0 1rem;

  @media screen and (max-width: 768px) {
    margin: 1rem 0;
  }
`;

export const NavbarMenuLink = styled.a`
  font-size: 1rem;
  text-decoration: none;
  color: #000000;

  &:hover {
    text-decoration: underline;
  }
`;

export const NavbarMenuIcon = styled.button`
  display: none;
  font-size: 1.5rem;
  background-color: transparent;
  border: none;
  color: #000000;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    display: block;
  }
`;
