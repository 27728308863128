import styled from "styled-components";

export const Dotted = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #171717;
  flex-direction: column;

  h1 {
    font-weight: normal;
    font-size: 18px;
    color: #fff;
    margin-bottom: 40px;
  }

  li {
    display: inline-block;
    list-style: none;
    height: 25px;
    width: 25px;
    background: #0fa;
    border-radius: 100%;
    margin: 0 0.2em;
    animation: anm 1s linear infinite alternate;
  }

  @keyframes anm {
    0% {
      transform: scale(0.1);
      filter: hue-rotate(0deg);
      opacity: 0;
    }
    50% {
      transform: scale(0.4);
    }
    100% {
      transform: scale(1);
      filter: hue-rotate(360deg);
    }
  }

  li:nth-child(2) {
    animation-delay: 0.4s;
  }

  li:nth-child(3) {
    animation-delay: 0.8s;
  }
`;
