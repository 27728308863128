import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  padding: 40px 0;
`;

export const PageTitleContainer = styled.h1`
  padding: 0 20px;
`;

export const PageLocationTitle = styled.h1`
  color: rgb(25, 31, 40);
  text-transform: uppercase;
  font-size: 24px;
  width: 100%;
  font-weight: 600;
`;

export const PageTitle = styled.h1`
  color: rgb(132, 140, 155);
  text-transform: uppercase;
  font-size: 14px;
  width: 100%;
  font-weight: 600;
`;

export const PageTitleBold = styled.h1`
  color: black;
  text-transform: uppercase;
  font-size: 14px;
  width: 100%;
  font-weight: bold;
`;

export const Content = styled.div`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
`;

export const Panel = styled.div`
  background-color: #ffffff;
  color: #223354;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 10px;
  box-shadow: none;
  padding: 0;
  box-shadow: 0px 9px 16px rgba(159, 162, 191, 0.18),
    0px 2px 2px rgba(159, 162, 191, 0.32);
  overflow: hidden;
`;

export const FilterContainer = styled.div`
  width: 100%;
  display: flex;
`;

export const PanelTitleContainer = styled.div`
  padding: 16px;
  border-bottom: 1px solid rgb(34 51 84 / 10%);
`;

export const PanelTitle = styled.h1`
  font-size: 15px;
  margin: 0;
  padding: 0;
`;

export const SubscriptionContainer = styled.div`
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: row;
`;

export const SubscriptionList = styled.div`
  width: 100%;
  display: grid;  
  grid-auto-rows: max-content;
  gap: 16px;
  margin-bottom: 10px;

  @media (max-width: 560px) {
    grid-template-columns: repeat(1, 1fr);
  }

`;

export const SubscriptionItem = styled.div`
  background-color: #ffffff;
  color: #223354;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  transition: all 0.5s;

  &:hover {
    box-shadow: 0px 9px 16px rgba(159, 162, 191, 0.18),
      0px 2px 2px rgba(159, 162, 191, 0.32);
  }
`;

export const SubscriptionContent = styled.div`
  padding: 10px;  
`;

export const SubscriptionImageContent = styled.div``;

export const SubscriptionImage = styled.div`
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 250px;
  width: 100%;  
`;

export const SubscriptionTitle = styled.h1`
  font-weight: 700;
  font-size: 17px;  
  margin: 0px 0px 0px 10px;  
  padding: 0;  
`;

interface StatusProps {
  status: boolean;
}

export const SubscriptionDescription = styled.p`
  margin: 0;  
  display: flex;
  justify-content: space-between;  
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const InfoContainerText = styled.div `
display: flex; 
flex-direction: column;
flex: 1;
margin-right: 10px;
margin-left: 10px;
`;

export const InfoContainerStatus = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;  
  margin-left: 10px;
`;

export const StatusButton = styled.div<StatusProps> `
  display: flex; 
  align-items: center;
  cursor: pointer;
  justify-content: center;
  padding: 5px;  
  background-color: ${props => props.status ? '#7CFC00' : '#FF0000'};
  color: #fff;    
  border-radius: 8px;
  padding: 7px 13px;
  border: none;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.01em;
  @media screen and (max-width: 768px) {
    margin: 16px auto 8px;
  }
`;

export const SubscriptionActions = styled.div`
  padding: 5px;    
`;

export const SubscriptionLink = styled(Link)`
  cursor: pointer;
  background: ${({ disabled }: { disabled?: boolean }) =>
    disabled ? "red" : "rgb(55, 55, 55)"};
  border-radius: 8px;
  padding: 12px 18px;
  border: none;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: rgb(255, 255, 255);
  display: inline-block;
  text-decoration: none;   
  margin: 5px;  
`;
