import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../components/atoms/button";
import Loading from "../../components/atoms/loading";
import Select from "../../components/atoms/select";
import NavBar from "../../components/organisms/navbar";
import rule from "../../data/rules-xxxi-copa-orley-lobato2.json";



import rulesSeletivaManausOlimpica2024 from "../../data/rules-seletiva-manaus-olimpica-2024.json"
import rulesViiiReiERainhaDaPraiaBeachWrestilingManausAmazonas2024 from "../../data/rules-viii-rei-e-rainha-da-praia-beach-wrestiling-manaus-amazonas-2024.json"
import rulescopaAmizadeDeJiuJitsu2024 from "../../data/rules-copa-amizade-de-jiu-jitsu-2024.json";
import rulesOpenGuaraniacuJiuJitsu2024 from "../../data/rules-open-guaraniacu-jiu-jitsu-2024.json";
import rulesIReiERainhaDaPraiaBeachBoxingManausAmazonas2023 from "../../data/rules-i-rei-e-rainha-da-praia-beach-boxing-manaus-amazonas-2023.json";
import _1copaArenaParintinsDeJiuJitsu from "../../data/rules-1-copa-arena-parintins-de- jiu-jitsu.json";
import rulesGFTeam from "../../data/rules-estadual-gfteam-amazonas2.json";
import reiRainha from "../../data/rules-vii-rei-e-rainha-da-praia-beach-wrestling-manaus-amazonas.json";
import rulesToledoOpen2023 from "../../data/rules-toledo-international-open-brazilian-jiu-jitsu-gi-2023-ginasio-de-esportes-alcides-pan-toledo-pr.json";
import rulesCircuit from "../../data/rules-international-circuit2.json";
import useAuthenticate from "../../hooks/useAuthenticate";
import api from "../../services/api";
import { Content, FormContainer, PageDescription, PageTitle } from "./styles";
import SelectFighters from "../../components/atoms/selectFighters";
import SelectCategories from "src/components/atoms/selectCategories";
import loading from "../../components/atoms/loading";
import EnrollmentSeletivaManausOlimpica2024 from "./tournament/enrollment-seletiva-manaus-olimpica-2024";
import EnrollmentViiiReiERainhaDaPraiaBeachWrestiling2024 from "./tournament/enrollment-viii-rei-e-rainha-da-praia-beach-wrestiling-manaus-amazonas-2024";
import EnrollmentCopaAmizadeDeJiuJitsu2024 from "./tournament/enrollment-copa-amizade-de-jiu-jitsu-2024";
import EnrollmentCopaMestreAcaiDeJiuJitsu2024 from "./tournament/enrollment-copa-mestre-acai-de-jiu-jitsu-2024";
import EnrollmentXxxiiCopaOrleyLobatoDeJiuJitsu from "./tournament/enrollment-xxxii-orley-lobato-de-jiu-jitsu";
import EnrollmentIvOpenTerrSantaDeJiuJitsu from "./tournament/enrollment-iv-open-terra-santa-de-jiu-jitsu";
import EnrollmentXviiCopaParanaDeJiuJitsu from "./tournament/enrollment-xvii-copa-parana-de-jiu-jitsu";
import EnrollmentIOrixicombatOpenJiuJitsu from "./tournament/enrollment-i-orixicombat-open-jiu-jitsu";
import EnrollmentIiCopaArenaParintinsDejiujitsu from "./tournament/enrollment-ii-copa-arena-parintins-de-jiu-jitsu";
import EnrollmentCopaThomasiDeJiuJitsu from "./tournament/enrollment-copa-thomasi-de-jiu-jitsu";
import EnrollmentCopaLoboBravoCresolDeJiuJitsu from "./tournament/enrollment-copa-lobo-bravo-cresol-de-jiu-jitsu";
import EnrollmentCopaFronteiraDeJiuJitsu from "./tournament/enrollment-copa-fronteira-de-jiu-jitsu";
import EnrollmentViiOpenAmazonasTopTeamDeJiuJitsu from "./tournament/enrollment-vii-open-amazonas-top-team-de-jiu-jitsu";
import EnrollmentCopaCianorteDeJiuJitsu from "./tournament/enrollment-copa-cianorte-de-jiu-jitsu";
import EnrollmentViReiDaPraiaDeJiuJitsuNoGi from "./tournament/enrollment-vi-rei-da-praia-de-jiu-jitsu-no-gi";
import EnrollmentIiCopaChampionsKidsDeJiuJitsu from "./tournament/enrollment-ii-copa-champions-kids-de-jiu-jitsu";


const Enrollment = () => {
  const { slug } = useParams();
  console.log(slug)

  switch (slug) {
    case 'seletiva-manaus-olimpica-2024-ginasio-ninimberg-guerra-sao-jorge-manaus-am':
      return <EnrollmentSeletivaManausOlimpica2024 />
    case 'viii-rei-e-rainha-da-praia-beach-wrestling-2024-nossa-praia-sports':
    case 'i-rei-e-rainha-da-praia-beach-boxing-manaus-amazonas-2023-manaus-amazonas':
      return <EnrollmentViiiReiERainhaDaPraiaBeachWrestiling2024 />
    case 'copa-amizade-de-jiu-jitsu-2024-ginasio-de-esportes-amario-vieira-da-costa-umuarama-pr':
      return <EnrollmentCopaAmizadeDeJiuJitsu2024 />
    case 'copa-mestre-acai-de-jiu-jitsu-2024-ginasio-arrudao-francisco-beltao-pr':
      return <EnrollmentCopaMestreAcaiDeJiuJitsu2024 />
    case 'xxxii-copa-orley-lobato-de-jiu-jitsu-cel-nininberg-guerra-manaus-am':
      return <EnrollmentXxxiiCopaOrleyLobatoDeJiuJitsu />
    case 'iv-opem-terra-santa-de-jiu-jistu-ginasio-braulio-nelson-da-conceicao-gi':
    case 'iv-opem-terra-santa-de-jiu-jistu-ginasio-braulio-nelson-da-conceicao-nogi':
      return <EnrollmentIvOpenTerrSantaDeJiuJitsu />
    case 'xvii-copa-parana-de-jiu-jitsu-ginasio-de-esportes-alcides-pan-toledo-pr-nogi':
    case 'xvii-copa-parana-de-jiu-jitsu-ginasio-de-esportes-alcides-pan-toledo-pr-gi':
      return <EnrollmentXviiCopaParanaDeJiuJitsu />
    case 'i-orixicombat-open-jiu-jitsu-ginasio-municipal-de-oriximina-pa':
      return <EnrollmentIOrixicombatOpenJiuJitsu />
    case 'ii-copa-arena-parintins-de-jiu-jitsu-arena-olimpica-rubens-dos-santos-gi':
    case 'ii-copa-arena-parintins-de-jiu-jitsu-arena-olimpica-rubens-dos-santos-nogi':
      return <EnrollmentIiCopaArenaParintinsDejiujitsu />
    case 'i-copa-thomasi-de-jiu-jitsu-parque-do-japao-maringa-pr':
      return <EnrollmentCopaThomasiDeJiuJitsu />
    case 'copa-lobo-bravo-cresol-de-jiu-jitsu-ginasio-assuncao-de-nossa-senhora-guarapuava-pr':
      return <EnrollmentCopaLoboBravoCresolDeJiuJitsu />
    case 'copa-fronteira-de-jiu-jitsu-ginasio-de-esportes-amario-vieira-da-costa-umuarama-pr':
      return <EnrollmentCopaFronteiraDeJiuJitsu />
    case 'vii-open-amazonas-top-team-de-jiu-jitsu-manaus-am-gi':
      return <EnrollmentViiOpenAmazonasTopTeamDeJiuJitsu />
    case 'copa-cianorte-de-jiu-jitsu-cianorte-pr-gi':
      return <EnrollmentCopaCianorteDeJiuJitsu />
    case 'vi-rei-da-praia-de-jiu-jitsu-santarem-pa-nogi':
      return <EnrollmentViReiDaPraiaDeJiuJitsuNoGi />
    case 'ii-copa-champions-kids-de-jiu-jitsu-maringa-pr-gi':
      return <EnrollmentIiCopaChampionsKidsDeJiuJitsu />
    default:
      return <h1>Inscrição para esse Torneio não encontrado!</h1>
  }
};
export default Enrollment;
