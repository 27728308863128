import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GlobalStyle } from "./GlobalStyles";
import ActiveAccount from "./pages/auth/active-account";
import ChangePassword from "./pages/auth/change-password";
import Login from "./pages/auth/login";
import PasswordRecovery from "./pages/auth/password-recovery";
import Register from "./pages/auth/register";
import Enrollment from "./pages/enrollment";
import Home from "./pages/home";
import PaymentEnrollment from "./pages/payment-enrollment";
import Profile from "./pages/profile";
import Subscription from "./pages/profile/subscriptions";
import Athletes from "./pages/profile/athletes";
import Tournament from "./pages/tournament";
import NewAthletes from "./pages/profile/newAthletes";
import SubscriptionAdm from "./pages/profile/subscriptionsAdm";
import SubscriptionEvent from "./pages/profile/subscriptionsEvent";
import EnrollmentUpdate from "./pages/enrollment-update";
import Check from "./pages/check";
import Tournament2 from "./pages/tournament/index2";
import Tournament3 from "./pages/tournament/index3";
import ViiReiDaPraiaBeachWrestiling from "./pages/tournament/vii-rei-da-praia-beach-wrestiling";
//import Check2 from "./pages/check/index2";
import NewTournament from "./pages/tournament/newTournament";
import { EditAthletes } from "./pages/profile/editAthletes";
import AllCheck from "./pages/check/allCheck";
import { EditUser } from "./pages/profile/editUser";
import AllCheckCategories from "./pages/check/allCheckAllCategory";
import TournamentInternationalCircuit from "./pages/tournament/international-circuit";
import GfTeamAmazonas from "./pages/tournament/gfteam-amazonas";
import ToledoInternationalOpenBrazilianJiuJitsu from "./pages/tournament/toledo-international-open-brazilian-jiu-jitsu-gi-2023";
import _1CopaArenaParintins from "./pages/tournament/1-copa-arena-parintins";
import IReiERainhaDaPraiaBeachBoxingManausAmazonas2023ManausAmazonas from "./pages/tournament/i-rei-e-rainha-da-praia-beach-boxing-manaus-amazonas-2023";
import OpenGuaraniacuJiuJitsu2024 from "./pages/tournament/open-guaraniacu-jiu-jitsu-2024";
import CopaAmizadeDeJiuJitsu2024 from "./pages/tournament/copa-amizade-de-jiu-jitsu-2024";
import ViiiReiERainhaDaPraiaBeachWrestling2024 from "./pages/tournament/viii-rei-e-rainha-da-praia-beach-wrestiling-manaus-amazonas-2024";
import SeletivaManausOlimpica2024 from "./pages/tournament/seletiva-manaus-olimpica-2024";
import CopaMestreAcaiDeJiuJitsu2024 from "./pages/tournament/copa-mestre-acai-de-jiu-jitsu-2024";
import XxxiiCopaOrleyLobatoDeJiuJitsu from "./pages/tournament/xxxii-copa-orley-lobato-de-jiu-jitsu";
import IvOpenTerrSantaDeJiuJitsu from "./pages/tournament/iv-open-terra-santa-de-jiu-jitsu";
import XviiCopaParanaDeJiuJitsu from "./pages/tournament/xvii-copa-parana-de-jiu-jitsu";
import IOrixicombatOpenJiuJitsu from "./pages/tournament/i-orixicombat-open-jiu-jitsu";
import IiCopaArenaParintinsDeJiuJitsu from "./pages/tournament/ii-copa-arena-parintins-de-jiu-jitsu";
import ICopaThomasiDeJiuJitsu from "./pages/tournament/i-copa-thomasi-de-jiu-jitsu";
import CopaLoboBravoCresolDeJiuJitsu from "./pages/tournament/copa-lobo-bravo-cressol-de-jiu-jitsu";
import CopaFronteiraDeJiuJitsu from "./pages/tournament/copa-fronteira-de-jiu-jitsu";
import ViiOpenAmazonasTopTeamDeJiuJitsu from "./pages/tournament/vii-open-amazonas-top-team-de-jiu-jitsu"
import ICopaBlackBeltDeJiuJitsu from "./pages/tournament/i-copa-black-belt-de-jiu-jitsu";


import InstrucaoTestePagbank from "./pages/tournament/instrucao-teste-pagbank";
import EnrollmentViiOpenAmazonasTopTeamDeJiuJitsu from "./pages/enrollment/tournament/enrollment-vii-open-amazonas-top-team-de-jiu-jitsu";
import CopaCianorteDeJiuJitsu from "./pages/tournament/copa-cianorte-de-jiu-jitsu";
import ViReiDaPraiaDeJiuJitsuNoGi from "./pages/tournament/vi-rei-da-praia-de-jiu-jitsu-no-gi";
import IiCopaChampionsKidsDeJiuJitsu from "./pages/tournament/ii-copa-champions-kids-de-jiu-jitsu";




const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/profile/subscription",
    element: <Subscription />,
  },
  {
    path: "/check",
    element: <Check />,
  },
  // {
  //   path: "/check/16-COPA-PARANA-DE-JIU-JITSU",
  //   element: <Check2 />,
  // },
  {
    path: "/check/:slug/search",
    element: <AllCheck />,
  },
  {
    path: "/check/:slug/all",
    element: <AllCheckCategories />,
  },
  {
    path: "/profile/athletes",
    element: <Athletes />,
  },
  {
    path: "/profile/athletes/:id",
    element: <EditAthletes />,
  },
  {
    path: "/profile/edit/user",
    element: <EditUser />,
  },
  {
    path: "/athletes",
    element: <NewAthletes />,
  },
  {
    path: "/tournament/:id",
    element: <Tournament />,
  },
  {
    path: "/tournament/16-COPA-PARANA-DE-JIU-JITSU",
    element: <Tournament2 />,
  },
  {
    path: "/tournament/XXXI-COPA-ORLEY-LOBATO-DE-JIU-JITSU",
    element: <Tournament3 />,
  },
  {
    path: "/tournament/INTERNATIONAL-CIRCUIT",
    element: <TournamentInternationalCircuit />,
  },
  {
    path: "/tournament/ESTADUAL-GFTEAM-AMAZONAS",
    element: <GfTeamAmazonas />,
  },
  {
    path: "/tournament/vii-open-amazonas-top-team-de-jiu-jitsu",
    element: <ViiOpenAmazonasTopTeamDeJiuJitsu />,
  },
  {
    path: "/tournament/copa-cianorte-de-jiu-jitsu",
    element: <CopaCianorteDeJiuJitsu />,
  },
  {
    path: "/tournament/VII-REI-DA-PRAIA---BEACH-WRESTLING",
    element: <ViiReiDaPraiaBeachWrestiling />,
  },

  {
    path: "/tournament/toledo-international-open-brazilian-jiu-jitsu-2023",
    element: <ToledoInternationalOpenBrazilianJiuJitsu />,
  },
  {
    path: "/tournament/1º-Copa-Arena-Parintins-de-Jiu-Jitsu",
    element: <_1CopaArenaParintins/>,
  },
  {
    path: "/tournament/i-rei-e-rainha-da-praia-beach-boxing-manaus-amazonas-2023-manaus-amazonas",
    element: <IReiERainhaDaPraiaBeachBoxingManausAmazonas2023ManausAmazonas/>,
  },

  {
    path: "/tournament/open-guaraniacu-brazilian-jiu-jitsu-2024-ginasio-de-esportes-onofre-garbasheski-guaraniacu-pr",
    element: <OpenGuaraniacuJiuJitsu2024/>,
  },
  {
    path: "/tournament/copa-amizade-de-jiu-jitsu-2024-ginasio-de-esportes-amario-vieira-da-costa-umuarama-pr",
    element: <CopaAmizadeDeJiuJitsu2024/>,
  },

  {
    path: "/tournament/viii-rei-e-rainha-da-praia-beach-wrestling-2024-nossa-praia-sports",
    element: <ViiiReiERainhaDaPraiaBeachWrestling2024/>,
  },

  {
    path: "/tournament/seletiva-manaus-olimpica-2024",
    element: <SeletivaManausOlimpica2024/>,
  },
  {
    path: "/tournament/copa-mestre-acai-de-jiu-jitsu-2024",
    element: <CopaMestreAcaiDeJiuJitsu2024/>,
    
  },
  {
    path: "/tournament/xxxii-copa-orley-lobato-de-jiu-jitsu",
    element: <XxxiiCopaOrleyLobatoDeJiuJitsu/>,
    
  },
  {
    path: "/tournament/iv-open-terra-santa-de-jiu-jitsu",
    element: <IvOpenTerrSantaDeJiuJitsu/>,
    
  },
  {
    path: "/tournament/xvii-copa-parana-de-jiu-jitsu",
    element: <XviiCopaParanaDeJiuJitsu/>,
    
  },
  {
    path: "/tournament/i-orixicombat-open-jiu-jitsu",
    element: <IOrixicombatOpenJiuJitsu/>,
    
  },
  {
    path: "/tournament/vi-rei-da-praia-de-jiu-jitsu-no-gi",
    element: <ViReiDaPraiaDeJiuJitsuNoGi />,
  },
  {
    path: "/tournament/ii-copa-champions-kids-de-jiu-jitsu",
    element: <IiCopaChampionsKidsDeJiuJitsu />,
  },
  {
    path: "/tournament/ii-copa-arena-parintins-de-jiu-jitsu",
    element: <IiCopaArenaParintinsDeJiuJitsu/>,
  },
  {
    path: "/tournament/i-copa-thomasi-de-jiu-jitsu",
    element: <ICopaThomasiDeJiuJitsu/>,
  },
  {
    path: "/tournament/copa-lobo-bravo-cresol-de-jiu-jitsu",
    element: <CopaLoboBravoCresolDeJiuJitsu/>,
  },
  {
    path: "/tournament/copa-fronteira-de-jiu-jitsu",
    element: <CopaFronteiraDeJiuJitsu/>,
  },

  {
    path: "/tournament/i-copa-black-belt-de-jiu-jitsu",
    element: < ICopaBlackBeltDeJiuJitsu/>,
  },


 
  





  {
    path: "/tournament/instrucao-teste-pagbank",
    element: <InstrucaoTestePagbank/>,
  },

  
  
  


  {
    path: "/enrollment/:slug",
    element: <Enrollment />,
  },
  {
    path: "/enrollment-update/:id/:slug",
    element: <EnrollmentUpdate />,
  },
  {
    path: "/payment-instructions/:slug",
    element: <PaymentEnrollment />,
  },
  {
    path: "/payment-instruction/:slug/:id",
    element: <PaymentEnrollment />,
  },
  {
    path: "/auth/active-account/:code",
    element: <ActiveAccount />,
  },
  {
    path: "/password-recovery",
    element: <PasswordRecovery />,
  },
  {
    path: "/auth/password-recovery/:code",
    element: <ChangePassword />,
  },
  {
    path: "/admin/subscriptions",
    element: <SubscriptionAdm />,
  },
  {
    path: "/tournament/subscriptions",
    element: <SubscriptionEvent />,
  },
  {
    path: "/tournament/new",
    element: <NewTournament />,
  },
]);

root.render(
  <React.Fragment>
    <ToastContainer />
    <GlobalStyle />
    <RouterProvider router={router} />
  </React.Fragment>
);
