import { useEffect, useState } from "react";
import NavBar from "../../../components/organisms/navbar";
import {
  Container,
  Content,
  InfoContainerStatus,
  InfoContainerText,
  PageLocationTitle,
  PageTitle,
  PageTitleContainer,
  StatusButton,
  SubscriptionContainer,
  SubscriptionContent,
  SubscriptionDescription,
  SubscriptionItem,
  SubscriptionList,
  SubscriptionTitle,
} from "../../profile/subscriptions/styles";
import api from "../../../services/api";
import { toast } from "react-toastify";
import useAuthenticate from "../../../hooks/useAuthenticate";
import Loading from "../../../components/atoms/loading";
import React from "react";
import {
  Field,
  FilterButton,
  FilterOption,
  FilterSelect,
  InputContainer,
  InputContainerWithButton,
  Label,
} from "../../../components/atoms/input/inputText";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { useParams } from "react-router-dom";
import rulesViiiReiERainhaDaPraiaBeachWrestilingManausAmazonas2024 from "../../../data/rules-viii-rei-e-rainha-da-praia-beach-wrestiling-manaus-amazonas-2024.json";

import { log } from "console";

const AllCheckViiiReiERainhaDaPraiaBeachWrestilingManausAmazonas2024 = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { getToken } = useAuthenticate();

  const [filterValue, setFilterValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("name");
  const [showData, setShowData] = useState(false);

  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [weight, setWeight] = useState("");
  const [belt, setBelt] = useState("");

  const { slug } = useParams();
  const [tournament, setTournament] = useState<any>({});
  let rules = rulesViiiReiERainhaDaPraiaBeachWrestilingManausAmazonas2024;
  useEffect(() => {
    setLoading(true);

    api
      .get("/tournaments/" + slug)
      .then(({ data }) => {
        setTournament(data);
      })
      .catch(() => {
        toast.error("Não foi carregar os dados do torneio.");
      });

    api
      .get("/tournaments/enrollments/list-all/public/" + slug, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then(({ data }) => {
        setLoading(false);
        const sortedArray = data.sort((a: any, b: any) => a.id - b.id);
        setData(sortedArray.filter((s: any) => s.status));
      })
      .catch(() => {
        setLoading(false);
        toast.error("Não foi carregar os dados das inscrições.");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loading text="Aguarde, estamos processando os dados." />;

  const handleFilterClick = () => {
    console.log("clickando");
    const filteredArray = data
      .filter((value: any) => {
        const shouldInclude =
          value.status &&
          (value.fighters
            ? value.fighters.name
                .toLowerCase()
                .includes(filterValue.toLowerCase())
            : value.user.name
                .toLowerCase()
                .includes(filterValue.toLowerCase()));
        return shouldInclude;
      })
      .sort((a: any, b: any) => {
        const nameA = a.fighters?.name || a.user?.name || "";
        const nameB = b.fighters?.name || b.user?.name || "";
        return nameA.localeCompare(nameB); // Ordena em ordem alfabética
      });
    setFilteredData(filteredArray);
    // setSelectedCategory("");
    setShowData(true);
  };

  // obter todas as categorias únicas
  const categories = Array.from(
    new Set(data.map((value: any) => value.category))
  );

  const idadeArr = data
    .filter((item: any) => item.genre === gender && item.status)
    .map((item: any) => item)
    .sort((a, b) => parseInt(a.age) - parseInt(b.age))
    .map((item) => item.category);

  const ageArr = Array.from(new Set(idadeArr));

  const weigthArr = data
    .filter((item: any) => {
      return (
        item.genre.toUpperCase() === gender.toUpperCase() &&
        item.category.toUpperCase() === age.toUpperCase() &&
        item.status
      );
    })
    .map((item: any) => item)
    .map((item) => item.weight)
    .sort();

  const weigthNoRepeatArr = Array.from(new Set(weigthArr));

  const sexos = ["Masculino", "Feminino"];

  // função para filtrar os dados por categoria
  const filterByCategory = (category: string) => {
    const filteredArray = data.filter(
      (value: any) => value.category === category && value.status
    );
    setFilteredData(filteredArray);
    setSelectedCategory(category);
  };

  // obter todas as academias únicas
  const club = Array.from(
    new Set(
      data.map((value: any) => {
        if (value.status === true) {
          if (value.fighters && value.fighters.gym) {
            return value.fighters.gym.toUpperCase();
          }

          if (value.user && value.user.gym) {
            return value.user.gym.toUpperCase();
          }
        }
      })
    )
  ).sort();
  const getMaxWeight = (weight: string, category: string, gender: string) => {
    const filtered = rules.filter(
      (item) =>
        item.peso.descricao.toLowerCase() === weight.toLowerCase() &&
        item.categoria.toLowerCase() === category.toLowerCase() &&
        item.genero.toLowerCase() === gender.toLowerCase()
    );

    return filtered[0].peso.max;
  };
  // função para filtrar os dados por academia
  const filterByClub = (gym: string) => {
    const filteredArray = data.filter((value: any) => {
      if (value.fighters) {
        return (
          value.fighters.gym.toUpperCase() === gym.toUpperCase() && value.status
        );
      } else {
        return (
          value.user.gym.toUpperCase() === gym.toUpperCase() && value.status
        );
      }
    });
    setFilteredData(filteredArray);
    setSelectedCategory("");
  };

  const handleFilterChange = (event: React.FormEvent<HTMLInputElement>) => {
    setFilterValue(event.currentTarget.value);
    setShowData(true);
  };

  const handleFilterCategory = () => {
    console.log("aqui");
    const filteredData = data.filter((item: any) => {
      // return condition;
      return (
        item.genre.toUpperCase() === gender.toUpperCase() &&
        item.category.toUpperCase() === age.toUpperCase() &&
        item.weight.toUpperCase() === weight.toUpperCase() &&
        item.status
      );
    });

    // console.log(filteredData)
    setFilteredData(filteredData);
    setShowData(true);
  };

  function changeLastPartOfPathname(newPath: string) {
    let pathname = window.location.pathname;
    let parts = pathname.split("/");

    // Replace the last part of the pathname
    parts[parts.length - 1] = newPath;

    // Join the parts back together into a new pathname
    let newPathname = parts.join("/");

    window.history.pushState({}, "", newPathname);
    console.log({ newPathname });
    return newPathname;
  }
  return (
    <>
      <NavBar />
      <Container>
        <Content>
          <PageTitleContainer>
            <PageLocationTitle>Checagem</PageLocationTitle>

            <h2 style={{ color: "red" }}>
              {" "}
              OBS: SOMENTE INSCRIÇÕES PAGAS APARECEM NA CHECAGEM
            </h2>
            <PageTitle>{filteredData.length} inscrições encontradas</PageTitle>
            <PageTitle>{filteredData.length} inscrições pagas</PageTitle>
            <RadioGroup
              aria-label="Filtrar por"
              value={selectedFilter}
              onChange={(e: any) => {
                setSelectedFilter(e.target.value);
                setFilterValue("");
                setSelectedCategory("");
                setShowData(false);
              }}
            >
              <FormControlLabel value="name" control={<Radio />} label="NOME" />
              <FormControlLabel
                value="category"
                control={<Radio />}
                label="CATEGORIA"
              />
              <FormControlLabel
                value="gym"
                control={<Radio />}
                label="ACADEMIA"
              />
              <FormControlLabel
                value="all"
                control={<Radio />}
                label="TODAS AS CATEGORIAS"
              />
            </RadioGroup>

            {selectedFilter === "name" && (
              <InputContainerWithButton>
                <InputContainer>
                  <Label>FILTRAR POR NOME</Label>
                  <Field
                    filterValue=""
                    type="text"
                    value={filterValue}
                    placeholder="FILTRAR POR NOME"
                    onChange={handleFilterChange}
                  />
                </InputContainer>
                <FilterButton onClick={handleFilterClick}>Filtrar</FilterButton>
              </InputContainerWithButton>
            )}

            {selectedFilter === "category" && (
              <>
                <Label>FILTRAR POR SEXO</Label>
                <FilterSelect
                  selectedCategory={selectedCategory}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    setGender(selectedValue);
                    setSelectedCategory(selectedValue);
                    setShowData(false);
                    filterByCategory(selectedValue ?? "");
                  }}
                >
                  <FilterOption value="">SELECIONE O SEXO</FilterOption>
                  {sexos.map((sexo) => (
                    <FilterOption key={sexo} value={sexo.toLowerCase()}>
                      {sexo.toUpperCase()}
                    </FilterOption>
                  ))}
                </FilterSelect>

                {gender && (
                  <>
                    <Label>FILTRAR POR IDADE</Label>
                    <FilterSelect
                      selectedCategory={age}
                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        setSelectedCategory(selectedValue);
                        setAge(selectedValue);
                        setBelt("peso");
                        setShowData(false);
                        filterByCategory(selectedValue ?? "");
                      }}
                    >
                      <FilterOption value="">
                        SELECIONE UMA FAIXA DE IDADE
                      </FilterOption>
                      {ageArr.map((idade) => (
                        <FilterOption key={idade} value={idade}>
                          {idade.toUpperCase()}
                        </FilterOption>
                      ))}
                    </FilterSelect>
                  </>
                )}

                {age && (
                  <>
                    <Label>FILTRAR POR PESO</Label>
                    <FilterSelect
                      selectedCategory={selectedCategory}
                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        setWeight(selectedValue);
                        setShowData(false);
                        setSelectedCategory(selectedValue);
                        filterByClub(selectedValue ?? "");
                      }}
                    >
                      <FilterOption value="">
                        SELECIONE UMA FAIXA DE PESO
                      </FilterOption>
                      {weigthNoRepeatArr.map((weight) => (
                        <FilterOption key={weight} value={weight.toLowerCase()}>
                          {weight.toUpperCase()}
                        </FilterOption>
                      ))}
                    </FilterSelect>
                  </>
                )}

                {weight && (
                  <FilterButton onClick={handleFilterCategory}>
                    Filtrar
                  </FilterButton>
                )}
              </>
            )}

            {selectedFilter === "gym" && (
              <>
                <Label>FILTRAR POR ACADEMIA</Label>
                <FilterSelect
                  selectedCategory={selectedCategory}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    setSelectedCategory(selectedValue);
                    filterByClub(selectedValue ?? "");
                    setShowData(true);
                  }}
                >
                  <FilterOption value="">SELECIONE UMA ACADEMIA</FilterOption>
                  {club.map((gym) => (
                    <FilterOption key={gym} value={gym}>
                      {gym}
                    </FilterOption>
                  ))}
                </FilterSelect>
              </>
            )}

            {selectedFilter === "all" && (
              <a href={changeLastPartOfPathname("all")}>
                <FilterButton
                  onClick={() => {
                    // change page
                  }}
                >
                  Ver todas as categorias
                </FilterButton>
              </a>
            )}
          </PageTitleContainer>

          <SubscriptionContainer>
            {/* renderizar as inscrições para cada categoria */}
            {selectedFilter === "name"
              ? filteredData.map((value: any) => (
                  <SubscriptionList key={value.id}>
                    <SubscriptionItem>
                      <SubscriptionContent>
                        <SubscriptionTitle>
                          Inscrição Nº {value.id} -{tournament.name}
                        </SubscriptionTitle>

                        {/* <br /> */}
                        <SubscriptionDescription>
                          <InfoContainerText>
                            <span>
                              <b>Nome:</b>{" "}
                              {value.fighters
                                ? value.fighters.name.toUpperCase()
                                : value.user.name.toUpperCase()}
                            </span>
                            <span>
                              <b>Sexo:</b>{" "}
                              {value.fighters
                                ? value.fighters.genre.toUpperCase()
                                : value.user.genre.toUpperCase()}
                            </span>
                            <span>
                              <b>Equipe: </b>{" "}
                              {value.fighters
                                ? value.fighters.gym.toUpperCase()
                                : value.user.gym.toUpperCase()}
                            </span>

                            <span>
                              <b>Categoria: </b> {value.category.toUpperCase()}
                            </span>
                            <span>
                              <b>Peso: </b>{" "}
                              {value.weight.toUpperCase() +
                                " " +
                                getMaxWeight(
                                  value.weight,
                                  value.category,
                                  value.genre
                                )}
                            </span>
                            {/* <span>Professor: ORLEY LOBATO</span><br/> */}
                          </InfoContainerText>
                          <InfoContainerStatus>
                            <StatusButton status={value.status}>
                              <span>
                                {value.status
                                  ? "Pagamento efetuado"
                                  : "Pendente de pagamento"}
                              </span>
                            </StatusButton>
                          </InfoContainerStatus>
                        </SubscriptionDescription>
                        {/* <SubscriptionTitle>
                          {value.genre.toUpperCase()} - {value.category.toUpperCase()} - {value.belt.toUpperCase()} - {value.weight.toUpperCase()}
                        </SubscriptionTitle> */}
                      </SubscriptionContent>
                    </SubscriptionItem>
                  </SubscriptionList>
                ))
              : categories.map((category) => {
                  // filtrar os dados para mostrar apenas os atletas da categoria atual
                  const filteredCategoryData = filteredData.filter(
                    (value: any) => value.category === category
                  );
                  // mostrar as inscrições apenas se houver atletas na categoria atual
                  if (filteredCategoryData.length > 0 && showData) {
                    return (
                      <React.Fragment key={category}>
                        <PageTitle>
                          {selectedFilter === "name"
                            ? ""
                            : selectedFilter !== "category"
                            ? category
                            : gender + " " + age + " " + weight}
                        </PageTitle>
                        {filteredCategoryData.map((value: any) => (
                          <SubscriptionList key={value.id}>
                            <SubscriptionItem>
                              <SubscriptionContent>
                                <SubscriptionTitle>
                                  Inscrição Nº {value.id} -{tournament.name}
                                </SubscriptionTitle>
                                {/* <br /> */}
                                <SubscriptionDescription>
                                  <InfoContainerText>
                                    <span>
                                      <b>Nome:</b>{" "}
                                      {value.fighters
                                        ? value.fighters.name.toUpperCase()
                                        : value.user.name.toUpperCase()}
                                    </span>
                                    <span>
                                      <b>Equipe: </b>{" "}
                                      {value.fighters
                                        ? value.fighters.gym.toUpperCase()
                                        : value.user.gym.toUpperCase()}
                                    </span>

                                    {/* <span>Professor: ORLEY LOBATO</span><br/> */}
                                  </InfoContainerText>
                                  <InfoContainerStatus>
                                    <StatusButton status={value.status}>
                                      <span>
                                        {value.status
                                          ? "Pagamento efetuado"
                                          : "Pendente de pagamento"}
                                      </span>
                                    </StatusButton>
                                  </InfoContainerStatus>
                                </SubscriptionDescription>
                              </SubscriptionContent>
                            </SubscriptionItem>
                          </SubscriptionList>
                        ))}
                      </React.Fragment>
                    );
                  } else {
                    // não há atletas nesta categoria, então não renderizar nada
                    return null;
                  }
                })}
          </SubscriptionContainer>
        </Content>
      </Container>
    </>
  );
};
export default AllCheckViiiReiERainhaDaPraiaBeachWrestilingManausAmazonas2024;
