import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import Collapse from "../../components/atoms/colapso";
import Footer from "../../components/organisms/footer";
import NavBar from "../../components/organisms/navbar";
import useAuthenticate from "../../hooks/useAuthenticate";
import {
  Container,
  Content,
  H3,
  H5,
  Location,
  P,
  QRCodeImg,
  Span,
  Title,
  TournamentActions,
  TournamentBanner,
  TournamentContainer,
  TournamentContainerBanner,
  TournamentContent,
  TournamentDescription,
  TournamentEnrollmentLink,
  TournamentEnrollmentLinkEndpage,
  TournamentLink,
} from "./styles";
import tabelaPeso from "../../assets/images/tournaments/xxxi-copa-orley-lobato/TABELA-XXIXX-COPA-ORLEY-LOBATO-2023.jpg";
import qrCodeOrlay from "../../assets/images/tournaments/xxxi-copa-orley-lobato/qrcodeOrlayLobato.png"
import SponsorButton from "../../components/atoms/sponsor-area";
import {
  SponsorContainer,
  SponsorList,
} from "../../components/atoms/sponsor-area/styles";

//Patrocinio
// import cmda from "../../assets/images/sponsors/cmdca.jpeg";
// import agroSC from "../../assets/images/sponsors/agro-santa-clara.jpeg";
// import dasa from "../../assets/images/sponsors/dasa.jpeg";
// import imperio from "../../assets/images/sponsors/imperio-academia-fitness.jpeg";
// import lojaG from "../../assets/images/sponsors/lojq-gabrielly-moveis.jpeg";
// import mrn from "../../assets/images/sponsors/mrn.jpeg";
// import pmts from "../../assets/images/sponsors/PMTS.png";
// import pontao from "../../assets/images/sponsors/pontao-ch.jpeg";
// import sendel from "../../assets/images/sponsors/SENDEL.png";
import torneio from "../../assets/images/sponsors/torneio-facil.png";
// import siqueira from "../../assets/images/sponsors/siqueira.png";
import alexMartinsBjj from "../../assets/images/sponsors/alex-martins-bjj.jpeg";
//import cardosoNavegacao from "../../assets/images/sponsors/cardoso-navegacao.jpeg";
// import consturbContrucoesEComercio from "../../assets/images/sponsors/consturb-contrucoes-e-comercio.jpeg";
import danielAlmeidaDeputado from "../../assets/images/sponsors/daniel-almeida-deputado.jpeg";
import drTeamFigthWear from "../../assets/images/sponsors/dr-team-figth-wear.jpeg";
import extravaganza from "../../assets/images/sponsors/extravaganza.jpeg";
import fmcSolocoes from "../../assets/images/sponsors/fmc-solocoes.jpeg";
//import iasMedicalELife from "../../assets/images/sponsors/ias-medical-e-life.jpeg";
//import joaoCarlosVereador from "../../assets/images/sponsors/joao-carlos-vereador.jpeg";
import lionTraining from "../../assets/images/sponsors/lion-training.jpeg";
import masaMoldsPlastics from "../../assets/images/sponsors/masa-molds-plastics.jpeg";
import manausEsportiva from "../../assets/images/sponsors/manaus-esportiva.jpeg";
import rodriguesAdvocaciaCriminal from "../../assets/images/sponsors/rodrigues-advocacia-criminal.jpeg";
import sfSacFiscal from "../../assets/images/sponsors/sf-sac-fiscal.jpeg";
import crEstilo from "../../assets/images/sponsors/cr-estilo.png";
import edsonSales from "../../assets/images/sponsors/edsonSales.png";
import lopes from "../../assets/images/sponsors/lopes.png";
import madim from "../../assets/images/sponsors/madim.png";
import magistral from "../../assets/images/sponsors/magistral.png";
import mesquitaDetetizacao from "../../assets/images/sponsors/mesquita-detetizacao.png";
import naraChalub from "../../assets/images/sponsors/nara-chalub.png";
import projetoPenseAntes from "../../assets/images/sponsors/projeto-pense-antes.png";
import delegadoPericles from "../../assets/images/sponsors/delegado-pericles.png";
import yara from "../../assets/images/sponsors/yara-agua-mineral.png";
import kSupplementsNutrition from "../../assets/images/sponsors/k-supplements-nutrition.png";
import fjjam from "../../assets/images/sponsors/fjjam.jpg";
import manausMotors from "../../assets/images/sponsors/manaus-motors.png";
import { colors } from "@material-ui/core";


const sponsors = [
  //   {
  //     //url: "#",
  //     img: cmda,
  //   },
  //   {
  //     //url: "#",
  //     img: agroSC,
  //   },
  //   {
  //     //url: "#",
  //     img: dasa,
  //   },
  //   {
  //     //url: "#",
  //     img: imperio,
  //   },
  //   {
  //     //url: "#",
  //     img: mrn,
  //   },
  //   {
  //     //url: "#",
  //     img: lojaG,
  //   },
  //   {
  //     //url: "#",
  //     img: pmts,
  //   },
  //   {
  //     //url: "#",
  //     img: pontao,
  //   },
  //   {
  //     //url: "#",
  //     img: sendel,
  //   },
  {
    //url: "#",
    img: torneio,
  },
  { img: alexMartinsBjj },
 // { img: cardosoNavegacao },
  // { img: consturbContrucoesEComercio },
  { img: danielAlmeidaDeputado },
  { img: drTeamFigthWear },
  { img: extravaganza },
  { img: fmcSolocoes },
// { img: iasMedicalELife },
// { img: joaoCarlosVereador },
  { img: lionTraining },
  { img: masaMoldsPlastics },
  { img: manausEsportiva },
  { img: rodriguesAdvocaciaCriminal },
  { img: sfSacFiscal },
  { img: crEstilo },
  { img: edsonSales },
  { img: lopes },
  { img: madim },
  { img: magistral },
  { img: mesquitaDetetizacao },
  { img: naraChalub },
  { img: projetoPenseAntes },
  { img: delegadoPericles },
  { img: yara },
  { img: fjjam },
  { img: manausMotors },
  { img: kSupplementsNutrition },
  
  //   {
  //     //url:"#",
  //     img: siqueira,
  //   },
];

const Tournament3: React.FC = () => {
  const { authenticated } = useAuthenticate();

  return (
    <>
      <NavBar />

      <Container>
        <TournamentContainer
          image={require("../../assets/images/tournaments/xxxi-copa-orley-lobato/xxxi-copa-orley-lobato.jpg")}
        >
          <TournamentContainerBanner>
            <TournamentBanner
              image={require("../../assets/images/tournaments/xxxi-copa-orley-lobato/xxxi-copa-orley-lobato.jpg")}
            />
          </TournamentContainerBanner>
        </TournamentContainer>
        <TournamentContent>
          <Content>
            <Title>XXXI COPA ORLEY LOBATO DE JIU-JITSU</Title>
            <Location>
              <Span>Data: 16 de julho de 2023</Span>
              <Span>Início: 8:30h</Span>
              <Span>Local: Ninimbergue Guerra</Span>
            </Location>
            
            <P></P>
            <br />
            <Collapse title="EDITAL XXXI COPA ORLEY LOBATO DE JIU-JITSU">
              <Span>
                A XXXI Copa Orley Lobato de Jiu Jitsu é um evento tradicional do
                norte do Brasil fomentando e incentivando a prática do jiu jitsu
                no estado do Amazonas . As lutas serão com GI (com kimono), o
                evento disponibilizará categorias para todas as idades e faixas,
                conforme tabela de peso por categoria deste edital. Os
                competidores terão a oportunidade de testar suas habilidades em
                um ambiente controlado e com acompanhamento de profissionais
                qualificados.
              </Span>
              <br />
              <br />
              <br />
              <Collapse title="PROTOCOLO DE SEGURANÇA CONTRA COVID-19">
                <P>
                  Devido a dinâmica da situação atual, estamos avaliando
                  diariamente as recomendações dos Órgãos de Saúde e este
                  protocolo pode ser atualizado a qualquer momento caso novas
                  determinações sejam publicadas. Caso o evento seja cancelado
                  por força maior, todas as inscrições serão automaticamente
                  reembolsadas. A organização não é responsável pelos demais
                  custos dos participantes, tais como transporte e hospedagem.
                  Diretrizes do ginásio: Para a segurança de todos, por favor
                  verifique sua temperatura antes de se dirigir ao evento. Caso
                  haja qualquer sintoma, procure um médico. Caso você se sinta
                  indisposto ou esteve em contato com um paciente diagnosticado
                  com Covid-19, por favor fique em casa e procure seu médico.
                  Todo nosso material será desinfetado antes e durante o evento.
                </P>
              </Collapse>
              <Collapse title="PREMIAÇÕES">
                <H3>Por Equipes </H3>
                <P>
                  1° lugar: R$ 800 + 2 kimonos + troféu + cesta de suplementos
                </P>
                <P>
                  2° lugar: R$ 600 + 2 kimonos + troféu + cesta de suplementos
                </P>
                <P>
                  3° lugar: R$ 500 + 2 kimonos + troféu + cesta de suplementos
                </P>
                <P>
                  4° lugar: R$ 400 + 1 kimonos + troféu + cesta de suplementos
                </P>
                <P>
                  5° lugar: R$ 300 + 1 kimonos + troféu + cesta de suplementos
                </P>
                <br />

                <H3>Absoluto Masculino:</H3>
                <H5>Juvenil Azul (Galo a Leve)</H5>
                <P>1° lugar: R$80,00 + kimono + cinturão + kit suplemento</P>
                <P>2° lugar: medalha + blusa truda + rash guard</P>
                <P>3° lugar: medalha + brinde da CR Estilo + faixa</P>
                <H5>Juvenil Azul (Medio a Pesadissimo)</H5>
                <P>1° lugar: R$80,00 + kimono + cinturão + kit suplemento</P>
                <P>2° lugar: medalha + blusa truda + rash guard</P>
                <P>3° lugar: medalha + brinde da CR Estilo + faixa</P>
                <H5>Adulto Azul</H5>
                <P>1° lugar: R$100,00 + kimono + cinturão + kit suplemento</P>
                <P>2° lugar: medalha + mochila truda + rash guard</P>
                <P>3° lugar: medalha + brinde da CR Estilo + faixa</P>
                <H5>Adulto roxa</H5>
                <P>1° lugar: R$120,00 + kimono + cinturão + kit suplemento</P>
                <P>2° lugar: medalha + mochila truda + rash guard</P>
                <P>3° lugar: medalha + brinde da CR Estilo + faixa</P>
                <H5>Adulto Marrom</H5>
                <P>1° lugar: R$150,00 + kimono + cinturão + kit suplemento</P>
                <P>2° lugar: medalha + mochila truda + rash guard</P>
                <P>3° lugar: medalha + brinde da CR Estilo + faixa</P>
                <H5>Adulto Preta</H5>
                <P>1° lugar: R$200,00 + kimono + cinturão + kit suplemento</P>
                <P>2° lugar: medalha + mochila truda + rash guard</P>
                <P>3° lugar: medalha + brinde da CR Estilo + faixa</P>
                <H5>Master Preta</H5>
                <P>1° lugar: R$200,00 + kimono + cinturão + kit suplemento</P>
                <P>2° lugar: medalha + mochila truda + rash guard</P>
                <P>3° lugar: medalha + brinde da CR Estilo + faixa</P>
                <br />

                <H3>Absoluto Feminino:</H3>
                <H5>Juvenil Azul</H5>
                <P>1° lugar: R$80,00 + kimono + cinturão + kit suplemento</P>
                <P>2° lugar: medalha + blusa truda + rash guard</P>
                <P>3° lugar: medalha + brinde da CR Estilo + faixa</P>
                <H5>Adulto Azul/Roxa</H5>
                <P>1° lugar: R$150,00 + kimono + cinturão + kit suplemento</P>
                <P>2° lugar: medalha + blusa truda + rash guard</P>
                <P>3° lugar: medalha + brinde da CR Estilo + faixa</P>
                <H5>Adulto Marrom/Preta</H5>
                <P>1° lugar: R$200,00 + kimono + cinturão + kit kit suplemento</P>
                <P>2° lugar: medalha + mochila truda + rash guard</P>
                <P>3° lugar: medalha + brinde da CR Estilo + faixa</P>
                <br />

                <H3>Demais Categorias</H3>
                <P>1° lugar: medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <br />
                <p>OBS: Estas premiações só irão valer para os Absolutos que tiverem 04 (quatro)
                ou mais atletas inscritos.</p>
                <p>Em caso de apenas 03 (três) inscritos no Absoluto, a premiação ficará a critério da organição do evento.</p>
                  
                  
            

              </Collapse>
              <Collapse title="INSCRIÇÕES">
                <Span>
                  As inscrições para o evento serão realizadas exclusivamente
                  online, por meio do site www.torneiofacil.com.br.
                </Span>
                <br />
               
                <H3>Valor</H3>
                <P>
                  Categoria R$ 50,00 
                </P>
                <P>
                  Absoluto R$ 30,00 
                </P>
                <br />

                <P>
                  Durante o processo de inscrição, o atleta ou seu responsável
                  legal deverá selecionar corretamente a categoria referente
                  idade que o atleta completará no ano de 2023, bem como sua
                  faixa e peso.
                </P>
                <br/>
                <H3>Inscrição de Absoluto</H3>
                <P>
                  Somente atletas inscritos na categoria poderão se increver e participar do absoluto. Atlestas
                   inscritos no absoluto que não estiverem inscritos na categoria serão automaticamente tranferidos 
                   para a categoria pessadissimo referente a seu sexo, idade e faixa.
                </P>
                <P>
                  Os atletas só poderão se inscrever no absoluto referente a
                  categoria da sua inscrição no torneio.
                </P>
                <br />

                <H3>Prazo de Inscrição</H3>
                <P>
                  O prazo final para inscrições e pagamento será até o dia
                  10/07/2023, às 23h59min. As inscrições que não forem pagas
                  serão canceladas.
                </P>
                <br />
                <H3>Correção</H3>
                <P>
                  A correção da inscrição poderá ser realizada somente após a
                  confirmação do pagamento.
                </P>
                <P>
                  Em caso de inscrição equivocada, o atleta ou professor
                  responsável poderá corrigir as informações no site
                  www.torneiofacil.com.br, acessando a opção Meu Perfil em
                  seguida Minhas Inscrições.
                </P>
                <P>
                  A correção da inscrição ficará disponivel até o encerramento das inscrições.
                </P>
                <br />

                <H3>Prazo de Correção</H3>
                <P>
                  As correções das inscrições podem ser feitas até o dia
                  10/07/2023 às 23h59min.
                </P>
                <P>
                  Após prazo para correção das inscrições, não serão aceitas
                  modificações nas inscrições. Os atletas que se inscreverem
                  incorretamente estarão sujeitos a desclassificação.
                </P>


              </Collapse>
              <Collapse title="PAGAMENTO">
                <H3>
                  A confirmação de pagamento deverá ser realizada via WhatsApp
                  enviando o comprovante para o contato abaixo:
                </H3>
                <P>Caixa Econômica:</P>
                <P>Titular: Thiago Souza Dellarmelina</P>
                <P>Chave PIX: Chave Aleatória </P>
                <P>91c2ad27-047f-49c9-ba36-cd2a4131609d</P>
                <br />
                <QRCodeImg src={qrCodeOrlay} alt="qrCode" width={300} />
                <br />
                <br />
               <a target={"blank"} href="https://whatsa.me/5592981291027/?t=Ol%C3%A1%20Thiago%20Souza%20Dellarmelina,%20vou%20enviar%20o%20comprovante%20de%20pagamento%20referente%20a%20inscri%C3%A7%C3%A3o%20no%20*XXXI%20COPA%20ORLEY%20LOBATO%20DE%20JIU-JITSU*"
               >
                  <strong>WhatsApp: (92) 98129-1027 </strong>
                  <FaWhatsapp />
                </a>
                
              </Collapse>
              <Collapse title="CRONOGRAMA">
                {/* (EM BREVE) */}
                <Container>
                  <Span>Data: 16 de julho de 2023</Span>
                  <Span>Início: 8:30h da manhã</Span>
                  <Span>
                    Local: Ginasio Ninimberg Guerra
                    Endereço: R. Vicente Torres Reis, 657 - São Jorge.
                    Manaus - AM, 69033-030
                  </Span>
                  <br/>
                  
                  
                  <H3>Lutas</H3>
                  
                  <H5>8:30h - Abertura do evento</H5>
                  <H5>9:00h - 04, 05, 06 e 07 anos (Faixa branca e Graduado) masculino e feminino.</H5>
                  <H5>09:30h - 08 e 09 anos (Faixa branca e Graduado) masculino e feminino.</H5>
                  <H5>10:00h - 10 e 11 anos (Faixa branca e Graduado) masculino e feminino.</H5>
                  <H5>10:30h - 12 e 14 anos (Faixa branca e Graduado) feminino e masculino.</H5>
                  <H5>11:00h - 14 e 15 anos (Faixa branca e Graduado) masculino e feminino. </H5>
                  <H5>12:00h - 16 e 17 anos (Faixa branca e Graduado) masculino e feminino.</H5>
                  <H5>13:00h - Adulto e Máster (Faixa Preta ) masculino e feminino.</H5>
                  <H5>14:00h - Adulto e Máster (Faixa Marrom) masculino e feminino. </H5>
                  <H5>15:00h - Adulto e Máster (Faixa Roxa ) masculino e feminino.</H5>
                  <H5>16:00h - Adulto e Máster (Faixa Azul) masculino e feminino.</H5>
                  <H5>17:00h - Adulto e Máster (Faixa Branca ) masculino e feminino. </H5>
                  <H5>18:00 - Encerramento</H5>
                  <H5>Obs.1: O atleta deverá comparecer com uma (01) hora de antecedência do horário previsto no cronograma.</H5>
                  <H5>Obs.2- Os absolutos serão disputados após o término das suas respectivas categorias,
                      lembrando que deverá ter no mínimo quatro atletas inscritos para validar a premiação.</H5>
                   <Span>
                    Usaremos 8 áreas de luta
                  </Span>


                  <Span>
                    O atleta deverá estar presente no ginásio pelo menos 1 hora
                    antes da sua luta.
                  </Span>
                  <Span>
                    O cronograma pode ser adiantado em até 1 hora, e atrasar
                    conforme a necessidade do evento.
                  </Span>
                </Container>
              </Collapse>
              <Collapse title="REGRAS">
                {/* (EM BREVE) */}
                <Span>
                  As regras do evento são as da CBJJ/IBJJF. A tabela de peso por
                  categoria está disponível no botão "Tabela de Peso" no site do
                  evento.
                </Span>
                <Span>
                  Para categoria com 3 lutadores, os atletas farão a melhor de
                  três para definição do campão, segundo e terceiro lugar conforme regra da CBJJ
                </Span>
                <Span>
                  A pesagem será realizada no dia do evento. O competidor deverá
                  apresentar documento oficial com foto no momento da pesagem.
                </Span>
                <Span>
                  Atletas que lutaram em outros eventos com faixa superior não
                  poderão lutar com a faixa abaixo. Se descoberto, o competidor
                  será eliminado do torneio sem direito a reembolso.
                </Span>
                <br />
                <H3>Pontuação por equipe</H3>
                <H5>Categoria e Absoluto</H5>
                <P>1° lugar: 9 pontos</P>
                <P>2° lugar: 3 pontos</P>
                <P>3° lugar: 1 ponto</P>
                <br />
                              
                <P>A contagem dos pontos será realizada de forma geral contabilizando os pontos de todas as faixas.</P>
                <P>Campeão direto não contam pontos, chaves com apenas um inscrito.</P>
                <P>Campeão por WO, devido a desistencia do oponente, contará pontos desde que na chave tenha mais de um inscrito.</P> 
                <P>Categorias com apenas dois atletas da mesma academia não contam pontos para a disputa por academias.</P> 
                <P>O que não estiver neste edital, será acatado o que estiver no livro de regras da CBJJ.</P> 
                <br/>
                <P><a
                  target={"blank"}
                  href="https://cbjj.com.br/books-videos"
                  style={{color: "white"}}
                >
                 <strong>LIVRO DE REGRAS DA CBJJ/IBJJF</strong>
                </a>
                  
                  </P>    

              </Collapse>
              <Collapse title="ENTRADA">
                <Span>
                  Entrada liberada ao publico
                </Span>
                <br />
              </Collapse>
              <Collapse title="CONTATO">
                <P>
                  Duvidas sobre cadastro, inscrição, pagamento, correção, checagem
                  ou sugestão de melhorias entre em contato com a equipe do Torneio
                  Fácil por telefone ou WhatsApp.
                  </P>
                <P>
                  Melhor horário para atendimento das 18:00h as 23:59h.
                  </P>
                <a target={"blank"} href="https://whatsa.me/5592981291027/?t=Ol%C3%A1%20Thiago%20Souza%20Dellarmelina,%20vou%20enviar%20o%20comprovante%20de%20pagamento%20referente%20a%20inscri%C3%A7%C3%A3o%20no%20*XXXI%20COPA%20ORLEY%20LOBATO%20DE%20JIU-JITSU*"
                  >
                    <strong>Telefone/WhatsApp: (92) 98129-1027 </strong>
                    <FaWhatsapp />
                </a>
                
                <br />
              </Collapse>
            </Collapse>
            
          </Content>
        </TournamentContent>
        <TournamentDescription>
          <TournamentActions>
            <Content>
              <h1>PATROCINADORES</h1>
              <SponsorContainer>
                <SponsorList>
                  {sponsors &&
                    sponsors.map((buttons: any) => (
                      <SponsorButton
                        img={buttons.img}
                        url={buttons.url}
                        hasError={false}
                        error="Este campo é obrigatório"
                      />
                    ))}
                </SponsorList>
              </SponsorContainer>
            </Content>
            <br />



            
            <Content>
            {authenticated ? (
              <TournamentEnrollmentLinkEndpage to="/enrollment/xxxi-copa-orley-lobato-de-jiu-jitsu-ninimbergue-guerra">
                Inscrição
                </TournamentEnrollmentLinkEndpage>
            ) : (
              <TournamentEnrollmentLinkEndpage to="/login">
                Inscrição
              </TournamentEnrollmentLinkEndpage>
            )}
            {/* <TournamentEnrollmentLinkEndpage to="#">
                Inscrição Encerrada
                </TournamentEnrollmentLinkEndpage> */}
              {/* <TournamentLink href="#">Chaves (EM BREVE)</TournamentLink> */}
              <TournamentLink href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.767B171E&u=teste" target="_blank">Chaves</TournamentLink>
              <TournamentLink href="/check/xxxi-copa-orley-lobato-de-jiu-jitsu-ninimbergue-guerra/search">
                Checagem de atletas
              </TournamentLink>
              <TournamentLink target="blank" href={tabelaPeso}>
                Tabela de pesos
              </TournamentLink>
              {/* <TournamentLink href="#">Resultados (EM BREVE)</TournamentLink> */}
              <TournamentLink href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.767B171E&u=teste">Resultadis Individuais</TournamentLink>
            </Content>
          </TournamentActions>
        </TournamentDescription>
      </Container>
      <Footer />
    </>
  );
};

export default Tournament3;
