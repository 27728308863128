import React from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import logoImg from "../../../assets/images/logo.png";
import Button from "../../../components/atoms/button";
import Input from "../../../components/atoms/input";

import api from "../../../services/api";
import {
  AccountExists,
  Container,
  Content,
  FormContent,
  FormGroup,
  PageDescription,
  PageLogo,
  PageTitle,
  SectionBanner,
  SectionForm,
} from "./styles";
import Swal from "sweetalert2";

const PasswordRecovery: React.FC = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    api
      .post("/auth/password/recovery", {
        ...data,
      })
      .then((response) => {
        if (response.data.status) {
          const responseData = JSON.parse(response.config.data);

          const emailDomain = responseData.email.split('@')[1];

          Swal.fire({
            title: 'Sucesso!',
            text: `${response.data.message}`,
            icon: 'success',
            confirmButtonText: `Abrir e-mail para recuperar sua senha`,
          }).then((result) => {
            if (result.isConfirmed) {
              window.open(`https://${emailDomain}`, "_blank");
              navigate("/login");
            }
          })

          toast.success(response.data.message);

        } else {
          Swal.fire({
            title: 'Atenção!',
            text: `${response.data.message}`,
            icon: 'error',
            confirmButtonText: 'Informe um e-mail valido',
          })

          toast.error(response.data.message);
        }
      })
      .catch(() => {
        Swal.fire({
          title: 'Atenção!',
          text: `Erro ao recuperar a senha, e-mail não cadastrado!`,
          icon: 'error',
          confirmButtonText: 'Informe um e-mail valido',
        })

        toast.error(
          "Não foi possível recuperar a sua senha, verifique seu e-mail!"
        )
      });
  };

  return (
    <Container>
      <SectionForm>
        <Content>
          <Link to={"/"}>
            <PageLogo src={logoImg} />
          </Link>
          <PageTitle>Recuperar senha</PageTitle>
          <PageDescription>
            Bem-vindo ao torneio fácil. Recupere <br />a sua conta preenchendo
            os dados abaixo.
          </PageDescription>

          <FormContent onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Input
                label="E-mail"
                name="email"
                type="text"
                register={register}
                required
                hasError={!!errors.email}
                error="Este campo é obrigatório"
                transformToLowerCase={true}
                trim={true}
              />
            </FormGroup>
            <FormGroup>
              <Button type="submit" label="Enviar instruções" />
            </FormGroup>
            <FormGroup>
              <AccountExists href="/login">
                Já possui conta? Acesse aqui.
              </AccountExists>
            </FormGroup>
          </FormContent>
        </Content>
      </SectionForm>
      <SectionBanner></SectionBanner>
    </Container>
  );
};

export default PasswordRecovery;
