import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../../components/atoms/loading";
import api from "../../../services/api";

const ActiveAccount: React.FC = () => {
  const { code } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (code && code?.length) {
      api
        .post("/auth/active-account", {
          code,
        })
        .then((response) => {
          toast.success(
            "Você efetuou a ativação da sua conta com sucesso! Estamos lhe redirecionando."
          );

          setTimeout(() => {
            navigate("/login");
          }, 2000);
        })
        .catch((error) => {
          toast.error(
            "Não foi possível ativar a sua conta, verifique o e-mail ou código!"
          );

          setTimeout(() => {
            navigate("/login");
          }, 2000);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return <Loading text="Estamos ativando a sua conta, aguarde!" />;
};

export default ActiveAccount;
