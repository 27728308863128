import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Cabin:wght@600&family=Inter:wght@300;400;700&family=Montserrat:wght@100;300;400;500;700&display=swap');

  html, body {
    padding: 0;
    margin: 0;
    font-family: 'Inter', sans-serif;
    background-color: #f2f5f9;
  }
`;
