import { useEffect, useState } from "react";
import { User } from "src/utils/interfaces";
import api from "../services/api";

const useAuthenticate = () => {
  const [loading, setLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [account, setAccount] = useState<User>({name: '...'});

  const setToken = (token: string) => {
    window.localStorage.setItem("@torneiofacil/token", token);

    return token;
  };

  const getToken = () => {
    return window.localStorage.getItem("@torneiofacil/token");
  };

  const destroyToken = () => {
    window.localStorage.removeItem("@torneiofacil/token");

    setAuthenticated(false);
    setAccount({name:''});
  };

  useEffect(() => {
    api
      .get("/me", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then(({ data }) => {
        setLoading(false);
        setAuthenticated(true);
        setAccount(data);
      })
      .catch(() => {
        setLoading(false);
        setAuthenticated(false);
        setAccount({name:''});
      });
  }, []);

  return { loading, authenticated, account, setToken, getToken, destroyToken, setLoading };
};

export default useAuthenticate;
