import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import Collapse from "../../components/atoms/colapso";
import Footer from "../../components/organisms/footer";
import NavBar from "../../components/organisms/navbar";
//import useAuthenticate from "../../hooks/useAuthenticate";
import {
  Container,
  Content,
  H3,
  H5,
  Location,
  P,
  QRCodeImg,
  Span,
  Title,
  TournamentActions,
  TournamentBanner,
  TournamentContainer,
  TournamentContainerBanner,
  TournamentContent,
  TournamentDescription,
  TournamentEnrollmentLink,
  TournamentEnrollmentLinkEndpage,
  TournamentLink,
  EditalContainer,
  EditalContent,
  EditalTitle
} from "./styles";
// import tabelaPeso from "../../assets/images/tournaments/toledo-international-open-brazilian-jiu-jitsu-2023/tabepla-de-peso.jpeg";
import tabelaPeso from "../../assets/images/tournaments/copa-amizade-de-jiu-jitsu-2024/tabela-copa-amizade-de-jiu-jitsu-2024.jpg";
import qrCodecopaAmizadeDeJiuJitsu2024 from "../../assets/images/tournaments/copa-amizade-de-jiu-jitsu-2024/qr-coce--copa-amizade-de-jiu-jitsu-2024.jpg";

//Patrocinio
import torneio from "../../assets/images/sponsors/torneio-facil.png";
import shopMundoDigital from "../../assets/images/sponsors/shop-mundo-digital.jpeg";
import jblByHarmanfrom from "../../assets/images/sponsors/jbl-by-harman.jpeg";
import stormStrong from "../../assets/images/sponsors/storm-strong-fundo-branco.jpg";
import gaziny from "../../assets/images/sponsors/gazin.jpg";
import vitalle from "../../assets/images/sponsors/vitalle.jpg";
import lojaAndrea from "../../assets/images/sponsors/loja-andrea.jpeg";
import tittansMecanicaADiesel from "../../assets/images/sponsors/titans-mecanica-a-diesel.jpeg";
import texasLubrificantes5EstrelasDoBrasil from "../../assets/images/sponsors/texas-lubrificantes-5-estrelas-do-brasil.jpeg";
import mundoMedicoProdutosHospitalares from "../../assets/images/sponsors/mundo-medico-produtos-hospitalares.jpg";


//Apoio
import trevusMartialArts from "../../assets/images/sponsors/trevus-martial-arts.jpg";


import { SponsorContainer, SponsorList } from "src/components/atoms/sponsor-area/styles";
import SponsorButton from "src/components/atoms/sponsor-area";
import useAuthenticate from "../../hooks/useAuthenticate";

const sponsors = [
  { img: torneio, url: "http://www.torneiofacil.com" },
  { img: shopMundoDigital, url: "https://www.instagram.com/shopmundodigital?igsh=bGUyZjR2bzA0eGZr" },
  { img: jblByHarmanfrom, url: "https://www.instagram.com/jbl_brasil?igsh=MXI1bjNucHZkMHpxYg==" },
  { img: gaziny, url: "https://www.instagram.com/gazin?igsh=MXdjaGlsem8wY3NzYw==" },
  { img: vitalle, url: "https://www.instagram.com/vitallefarmaciamanipulacao?igsh=d2RtYWpiYXoxZXlp" },
  { img: stormStrong, url: "https://www.instagram.com/stormstrongbr?igsh=MWprMGJrajVvdXhpMg==" },
  { img: lojaAndrea, url: "https://www.instagram.com/lojaandreaumuarama?igsh=YzIzbWxlbzN4MjVy" },
  { img: tittansMecanicaADiesel, url: "#" },
  { img: texasLubrificantes5EstrelasDoBrasil, url: "https://www.instagram.com/texsalubrificante?igsh=amJzeGR0NHAwNmUw" },
  { img: mundoMedicoProdutosHospitalares, url: "https://www.mundomedicoumuarama.com.br/" },
];

const support = [
  //{ img: esporteNaPeriferia },
  { img: torneio },
  { img: trevusMartialArts, url: "https://www.instagram.com/trevusmartialarts?igsh=MXEwbWRobW52ZzQ0Zw==" },
];

const CopaAmizadeDeJiuJitsu2024: React.FC = () => {
  const { authenticated } = useAuthenticate();

  return (
    <>
      <NavBar />

      <Container>
        <TournamentContainer
          image={require("../../assets/images/tournaments/copa-amizade-de-jiu-jitsu-2024/copa-amizade-de-jiu-jitsu-2024.jpg")}
        >
          <TournamentContainerBanner>
            <TournamentBanner
              image={require("../../assets/images/tournaments/copa-amizade-de-jiu-jitsu-2024/copa-amizade-de-jiu-jitsu-2024.jpg")}
            />
          </TournamentContainerBanner>
        </TournamentContainer>
        <TournamentContent>
          <Content>
            <Title>Copa Amizade de Jiu Jitsu 2024</Title>
            <Location>
              <Span>Data: 14 de abril de 2024</Span>
              <Span>Início: 9:15h</Span>
              <Span>Local: Ginásio de Esportes Amário Vieira da Costa</Span>
              {/* <Span>Endereço: Av. Gov. Parigot de Souza, 4284-4454 - Zona I, Umuarama - PR, CEP: 87503-680</Span> */}
              <a
                target={"blank"}
                style={{ color: "black" }}
                href="https://g.co/kgs/7CS3ppB"
              >
                <strong><Span> Endereço: Av. Gov. Parigot de Souza, 4284-4454 - Zona I, Umuarama - PR, CEP: 87503-680</Span></strong>
              </a>
            </Location>
            <P></P>
            <br />

          </Content>
        </TournamentContent>
        <TournamentDescription>
          <TournamentActions>
            <Content>
              <h1 style={{ textAlign: "center" }}>PATROCINADORES</h1>
              <SponsorContainer>
                <SponsorList>
                  {sponsors && sponsors.map((buttons: any, index: number) => (
                    <SponsorButton
                      key={index} // Adicionei uma chave única para cada botão
                      img={buttons.img}
                      url={buttons.url}
                      hasError={false}
                      error="Este campo é obrigatório"
                      target="_blank" // Adicione este atributo
                    />
                  ))}
                </SponsorList>
              </SponsorContainer>
              <h1 style={{ textAlign: "center" }}>APOIO</h1>
              <SponsorContainer>
                <SponsorList>
                  {support &&
                    support.map((buttons: any) => (
                      <SponsorButton
                        img={buttons.img}
                        url={buttons.url}
                        hasError={false}
                        error="Este campo é obrigatório"
                      />
                    ))}
                </SponsorList>
              </SponsorContainer>
            </Content>

            <Content style={{
              marginTop: '32px'
            }}>

              <EditalContainer>
                <EditalTitle>EDITAL COPA AMIZADE DE JIU JITSU 2024</EditalTitle>
                <EditalContent>
                  <Span>
                    A Copa Amizade de Jiu Jitsu 2024 é um evento tradicional Paranaense
                    fomentando e incentivando a prática do jiu jitsu no Paraná . As lutas
                    serão exclusivamente com GI (com kimono), o evento disponibilizará
                    categorias para todas as idades e faixas, conforme as tabelas de peso
                    deste edital. Os competidores terão a oportunidade de testar suas
                    habilidades em um ambiente controlado e com acompanhamento de
                    profissionais qualificados.
                  </Span>
                  <br />
                  <br />
                  <br />
                </EditalContent>

                <Collapse title="PROTOCOLO DE SEGURANÇA CONTRA COVID-19">
                  <P>
                    Devido a dinâmica da situação atual, estamos avaliando diariamente as
                    recomendações dos Órgãos de Saúde e este protocolo pode ser atualizado a
                    qualquer momento caso novas determinações sejam publicadas.
                  </P>
                  <P>
                    Caso o evento seja cancelado por força maior, todas as inscrições serão
                    automaticamente reembolsadas.
                  </P>
                  <P>
                    A organização não é responsável pelos demais custos dos participantes,
                    tais como transporte e hospedagem.
                  </P>
                  <P>
                    Diretrizes do ginásio: Para a segurança de todos, por favor verifique
                    sua temperatura antes de se dirigir ao evento.{" "}
                  </P>
                  <P>Caso haja qualquer sintoma, procure um médico.</P>{" "}
                  <P>
                    {" "}
                    Caso você se sinta indisposto ou esteve em contato com um paciente
                    diagnosticado com Covid-19, por favor fique em casa e procure seu
                    médico.
                  </P>
                  {/* <P>
                  Todo nosso material será desinfetado antes e durante o evento.
                </P> */}
                </Collapse>
                <Collapse title="PREMIAÇÕES MEDALHAS🥇+KIMONOS 🥋 + KIT SUPLEMENTOS🥤+DINHEIRO💰">
                  {/* <H3>Por Equipes </H3>
                <P>1° lugar: Troféu + R$500,00</P>
                <P>2° lugar: Troféu + R$300,00</P>
                <P>3° lugar: Troféu + R$200,00</P> */}

                  <br />
                  <H3>Absoluto Masculino GI:</H3>
                  <H5>Juvenil Branca</H5>
                  <P>1° lugar: 1 Kimono Stormstrong + Kit Suplementos + medalha</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>
                  <H5>Juvenil Azul/Roxa(Juntos)</H5>
                  <P>1° lugar: 1 Kimono Stormstrong + Kit Suplementos + medalha</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>
                  <H5>Adulto Branca</H5>
                  <P>1° lugar: R$ 300,00 + 1 Kimono Stormstrong + Kit Suplementos + medalha</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>
                  <H5>Adulto Azul</H5>
                  <P>1° lugar: R$ 300,00 + 1 Kimono Stormstrong + Kit Suplementos + medalha</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>
                  <H5>Adulto/master(juntos) Roxa</H5>
                  <P>1° lugar: R$ 400,00 + 1 Kimono Stormstrong + Kit Suplementos + medalha</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>
                  <H5>Adulto/master(juntos) Marrom</H5>
                  <P>1° lugar: R$ 500,00 + 1 Kimono Stormstrong + Kit Suplementos + medalha</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>
                  <H5>Adulto/master(juntos) Preta</H5>
                  <P>1° lugar: R$ 600,00 + 1 Kimono Stormstrong + Kit Suplementos + medalha</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>

                  <br />
                  <br />
                  <H3>Absoluto Feminino GI:</H3>
                  <H5>Juvenil (todas as faixas juntas)</H5>
                  <P>1° lugar: 1 Kimono Stormstrong + Kit Suplementos + medalha</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>
                  <H5>Adulto Branca</H5>
                  <P>1° lugar: R$ 200,00 + 1 Kimono Stormstrong + Kit Suplementos + medalha</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>
                  <H5>Adulto/master Azul/Roxa (juntos)</H5>
                  <P>1° lugar: R$ 300,00 + 1 Kimono Stormstrong + Kit Suplementos + medalha</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>
                  <H5>Adulto/Master Marrom/Preta (juntos)</H5>
                  <P>1° lugar: R$ 500,00 + 1 Kimono Stormstrong + Kit Suplementos + medalha</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>
                  <br />

                  <P>Para a realização de lutas na categoria absoluta, é exigido um mínimo de 4 atletas inscritos.</P>
                  <P>A premiação nas categorias absoluto será efetuada somente se o número de participantes atingir o mínimo de 8 inscritos.</P>
                  <P>Número menor que 8 atletas a premiação ficará a criterio da organização do evento, podendo não ter premição. </P>
                  <br />

                  <H3>Demais Categorias GI</H3>
                  <P>1° lugar: medalha</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>
                </Collapse>

                <Collapse title="INSCRIÇÕES">
                  <Span>
                    As inscrições para o evento serão realizadas exclusivamente online, por
                    meio do site www.torneiofacil.com.br.
                  </Span>
                  <P>
                    Durante o processo de inscrição, o atleta ou seu responsável legal
                    deverá selecionar corretamente a categoria referente idade que o atleta
                    completará no ano de 2024, bem como sua faixa e peso.
                  </P>
                  <P>
                    A idade de competição do atleta é a idade máxima que ele terá em 2024,
                    conforme regra da CBJJ.
                  </P>
                  <br />

                  <H3>Prazo de Inscrição</H3>
                  <P>
                    O prazo final para inscrições e pagamento será até o dia 08/04/2024, às
                    23h59min.
                  </P>
                  <br />
                  <P>As inscrições que não forem pagas serão canceladas.</P>
                  <br></br>

                  <H3>Valor</H3>
                  <P>Inscrição GI é de R$ 120,00 (cento e vinte reais)</P>

                  <br />
                  <H3>Checagem</H3>

                  <P>
                    A checagem ficará disponivel até a o dia 14 de abril de 2024. Obs: A
                    correção ficará disponível somente até o dia 08 de abril de 2024
                    (conforme explicado abaixo).
                  </P>
                  <br />
                  <H3>Correção</H3>

                  <P>
                    A correção da inscrição poderá ser realizada somente após a confirmação
                    do pagamento.
                  </P>
                  <P>
                    Em caso de inscrição equivocada, o atleta ou professor responsável
                    poderá corrigir as informações no site www.torneiofacil.com.br,
                    acessando a opção Meu Perfil em seguida Minhas Inscrições.
                  </P>
                  <P>
                    A correção da inscrição ficará disponivel até o encerramento das
                    inscrições 08/04/2024 as 23h59min.
                  </P>
                  <P>
                    Após prazo para correção das inscrições, não serão aceitas modificações
                    nas inscrições.
                  </P>
                  <P>
                    Os atletas que se inscreverem incorretamente estarão sujeitos a
                    desclassificação <b>sem direito a reembolso.</b>
                  </P>

                  <br />

                  <H3>Prazo de Correção</H3>
                  <P>
                    A correção da inscrição fica disponível após a confirmação do pagamento
                    da mesma.
                  </P>

                  <P>
                    A correção da inscrição ficará disponivel até o encerramento das
                    inscrições no 08/04/2024 as 23h59min.
                  </P>
                  <H3>Chaves</H3>

                  <P>
                    O sorteio e divulgação das chaves de lutas será no dia 12 de abril
                    de 2024 até as 23h e 59min.
                  </P>

                  <br />
                  <H3>Inscrição de Absoluto</H3>
                  <P>
                    Somente atletas inscritos na categoria poderão se increver e participar
                    do absoluto.
                  </P>
                  <P>
                    As inscrições para os absolutos são gratuitas, podendo ter no máximo 4
                    atletas da mesma equipe por absoluto.
                  </P>
                  <P>
                    As inscrições para os absolutos serão realizadas pelo professor
                    responsável pelo atleta, junto a organização do evento no dia do torneio
                    após a finalização das lutas da categoria.
                  </P>
                  <P>
                    Os atletas só poderão se inscrever no absoluto referente a categoria da
                    sua inscrição no torneio. Exceção apenas para os master que poderá se
                    inscrever como absoluto adulto.
                  </P>
                </Collapse>
                <Collapse title="PAGAMENTO">
                  <H3>
                    A confirmação de pagamento deverá ser realizada via WhatsApp enviando o
                    comprovante para o contato abaixo:
                  </H3>
                  <P>Banco SICOOB:</P>
                  <P>Titular: Vander Carlini Francisco</P>
                  <P>Chave PIX do tipo CPF:</P> <P>PIX: 029.948.199-96</P>
                  <QRCodeImg src={qrCodecopaAmizadeDeJiuJitsu2024} alt="qrCode" width={300} />
                  <P>
                    <a
                      target={"blank"}
                      style={{ color: "white" }}
                      href="https://whatsa.me/5544998072957/?t=Ol%C3%A1+Vander+Carlini+Francisco%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+na+%2ACOPA+AMIZADE+DE+JIU+JITSU%2A"
                    >
                      <strong>WhatsApp: +55 (44) 99807-2957 </strong>
                      <FaWhatsapp />
                    </a>
                  </P>
                  <p />
                  {/* <QRCodeImg src={qrCodeCopaParana} alt="qrCode" width={300} /> */}
                </Collapse>

                <Collapse title="REGRAS">
                  <Span>
                    Todas as regras para o evento estão devidamente estipuladas e detalhadas neste edital.
                  </Span>
                  <Span>
                    Em casos de omissão de regras no presente edital, as normas a serem observadas serão aquelas estabelecidas pela Confederação
                    Brasileira de Jiu-Jitsu (CBJJ), em conformidade com as diretrizes da CBJJ/IBJJF.
                  </Span>
                  <Span>
                    A tabela de peso por categoria, que estabelece os limites de peso para cada categoria, encontra-se disponível no site oficial do evento,
                    acessível por meio do botão denominado "Tabela de Peso".
                  </Span>
                  <Span>
                    As lutas serão com GI (com kimonos de cores Oficiais pela cbjj branco,
                    azul e preto ) os kimonos devem estar limpos sem rasgos e sem odor .
                  </Span>
                  <Span>
                    Para categoria com 3 lutadores, o lutador que ficar sozinho no sorteio da chave estará automaticamente na final.
                    Em caso de WO de um atleta, os dois lutadores restantes na categoria disputarão a final.
                  </Span>
                  <Span>
                    A pesagem será realizada no dia do evento. O competidor deverá
                    apresentar documento oficial com foto no momento da pesagem.
                  </Span>
                  <Span>
                    A pesagem dos atletas serão realizadas antes do inicio da categoria.
                  </Span>
                  <Span>
                    Atletas que ultrapasarem o limite de peso da categoria que entiverem
                    inscritos serão automaticamente desclassificados e não poderão lutar o
                    Absoluto.
                  </Span>
                  <Span>Será realizada apenas uma pesagem oficial por atleta.</Span>
                  <Span>
                    Teremos uma balança para pesagem livre (não oficial) para os atletas.
                  </Span>
                  <Span>Teremos duas balanças para pesagem oficial dos atletas.</Span>
                  <Span>
                    Atletas que lutaram em outros eventos com faixa superior não poderão
                    lutar com a faixa abaixo. Se descoberto, o competidor será eliminado do
                    torneio sem direito a reembolso.
                  </Span>
                  <br />
                  {/* <H3>Pontuação por equipe</H3>
                <H5>Categoria</H5>
                <P>1° lugar: 9 pontos</P>
                <P>2° lugar: 3 pontos</P>
                <P>3° lugar: 1 ponto</P>
                <H5>Absoluto</H5>

                <P>1° lugar: 13.5 pontos</P>
                <P>2° lugar: 4.5 pontos</P>
                <P>3° lugar: 1.5 ponto</P>
                <br /> */}

                  <P>
                    A contagem dos pontos será realizada de forma geral contabilizando os
                    pontos de todas as faixas.
                  </P>
                  <P>Campeão direto não contam pontos, chaves com apenas um inscrito.</P>
                  <P>
                    Campeão por WO, devido a desistencia do oponente, contará pontos desde
                    que na chave tenha mais de um inscrito.
                  </P>
                  <P>
                    Categorias com apenas dois atletas da mesma academia não contam pontos
                    para a disputa por academias.
                  </P>
                  <P>
                    O que não estiver neste edital, será acatado o que estiver no livro de
                    regras da CBJJ.
                  </P>
                  <br />
                  <P>
                    <a
                      target={"blank"}
                      href="https://cbjj.com.br/books-videos"
                      style={{ color: "white" }}
                    >
                      <strong>LIVRO DE REGRAS DA CBJJ/IBJJF</strong>
                    </a>
                  </P>
                </Collapse>
                <Collapse title="CRONOGRAMA">
                  {/* (EM BREVE) */}
                  <Container>
                    <br />
                    <Span>
                      O cronograma pode ser adiantado em até 1 hora, e atrasar conforme a
                      necessidade do evento.
                    </Span>
                    <Span>
                      O atleta deverá estar presente no ginásio pelo menos 1 hora antes da
                      sua luta.
                    </Span>
                    <Span>
                      O atleta que não estiver presente no momento da chamada para a pesagem
                      ou luta ser desclassificado por W.O.
                    </Span>
                    <Span>A pesagem será feita antes da 1º luta do atleta, em caso do atleta não bater
                      o peso, este será imediatamente desclassificado, será verificado também as condições
                      do kiimono do atleta antes da luta, podendo ocorrer desclassificação.
                    </Span>
                    <Span> Todos os atletas devem estar 45.00 minutos antes do tempo de sua categoria na
                      área de aquecimento podendo lutar até 30 min antes do horário previsto no cronograma. </Span>
                    <Span>Seguiremos normas e regras da CBJJ, com exceção nas chaves com 03 atletas. </Span>
                    

                    <Span>Data: 14 de abril de 2024</Span>
                    <Span>Início: 9:15h da manhã</Span>
                    {/* <Span>Local: Ginásio de Esportes Amário Vieira da Costa, Umuarama PR</Span> */}
                    {/* <Span>Endereço: Av. Gov. Parigot de Souza, 4284-4454 - Zona I, Umuarama - PR, CEP: 87503-680</Span>
                  <a
                    target={"blank"}
                    style={{ color: "white" }}
                    href="https://g.co/kgs/7CS3ppB"
                  >
                    <strong><Span> Endereço: Av. Gov. Parigot de Souza, 4284-4454 - Zona I, Umuarama - PR, CEP: 87503-680</Span></strong>
                  </a>
                  <br /> */}
                    <H3>Lutas GI</H3>
                    <H5>9:15 - Categorias Pré-Mirim e Mirim A - Todas as Faixas masculino/feminino na área de aquecimento </H5>
                    <H5>09:30 - Abertura do evento </H5>
                    <H5>10:10 - Categoria Mirim B - Todas as Faixas masculino/feminino </H5>
                    <H5>10:40 - Infantil A - Todas as Faixas masculino/feminino </H5>
                    <H5>11:00 - Infantil B - Todas as faixas masculino/feminino </H5>
                    <H5>11:30 - Infanto-juvenil Todas as faixas Masculino/Feminino  </H5>
                    <H5>12:00 - Juvenil - Todas as faixas masculino/feminino </H5>
                    <H5>12:50 - Faixa Branca Adulto masculino/feminino </H5>
                    <H5>13:30 - Faixa Branca Masters masculino/feminino </H5>
                    <H5>14:00 - Faixa Azul Adulto e Master Masculino e Feminino </H5>
                    <H5>14:30 - Faixa Roxa Adulto e Master Masculino e Feminino </H5>
                    <H5>15:00 - Faixas Marrom e Preta Adulto e Master Masculino e Feminino </H5>
                    <H5>15:40 - Início das Inscrições dos Absolutos </H5>
                    <H5>16:00 - Ínicio dos Absolutos </H5>
                    <H5>
                      Os absolutos serão disputados após o término das suas
                      respectivas categorias.
                    </H5>
                    <Span>Usaremos 6 áreas de luta</Span>
                    <br />
                    <b><Span>Obs: </Span></b>
                    <H3> Desejamos a todos uma boa viagem e um excelente campeonato!!!</H3>
                    <H3>Ossssssssss</H3>
                    <br />

                  </Container>
                </Collapse>
                <Collapse title="ENTRADA">
                  <P>Entrada liberada ao público.</P>
                  <br />
                  {/* <P>
                  Será cobrado 1kg de alimento não perecível na entrada para atletas e
                  público.
                </P> */}
                  {/* <br />
                <P>Crianças até 5 anos ficam isentas de qualquer tipo de pagamento.</P>
                <br /> */}
                </Collapse>
              </EditalContainer>
            </Content>
            <br />
            <Content>
              {authenticated ? (
                <>
                  <TournamentEnrollmentLinkEndpage to="/enrollment/copa-amizade-de-jiu-jitsu-2024-ginasio-de-esportes-amario-vieira-da-costa-umuarama-pr">
                    Inscrição Encerrada
                  </TournamentEnrollmentLinkEndpage>

                  <TournamentEnrollmentLinkEndpage to="/profile/subscription">
                    Correção da Inscrição
                  </TournamentEnrollmentLinkEndpage>
                </>
              ) : (
                <>
                  <TournamentEnrollmentLinkEndpage to="/login">
                    Inscrição Encerrada
                  </TournamentEnrollmentLinkEndpage>

                  <TournamentEnrollmentLinkEndpage to="/login">
                    Correção da Inscrição
                  </TournamentEnrollmentLinkEndpage>
                </>
              )}
              {/* <TournamentLink href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.767B171E&u=teste" target="_blank">Chaves</TournamentLink> */}
              {/* // /check/16-COPA-PARANA-DE-JIU-JITSU */}

              <TournamentLink
                // href="#"
                // target="_blank"
                href="/check/copa-amizade-de-jiu-jitsu-2024-ginasio-de-esportes-amario-vieira-da-costa-umuarama-pr/search"
                //href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.D7A54E37&u=teste"
                target="_blank">Checagem</TournamentLink>

              <TournamentLink
                // href="#"
                // target="_blank"
                //href="#"
                href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.D7A54E37&u=teste"
                target="_blank">Chaves</TournamentLink>

              {/* 
              <TournamentLink
                href="/check/toledo-international-open-brazilian-jiu-jitsu-nogi-2023-ginasio-de-esportes-alcides-pan-toledo-pr/search"
                target="_blank"
              >
                Checagem de atletas NO GI
              </TournamentLink>  */}

              {/*               <TournamentLink
                href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.D7A54E37&u=teste"
                target="_blank"
              >
                Chaves GI
              </TournamentLink> */}

              {/*               <TournamentLink
                href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.5B5B1C9A&u=teste"
                target="_blank"
              >
                Chaves NO GI
              </TournamentLink> */}

              <TournamentLink target="blank" href={tabelaPeso}>
                Tabela de pesos
              </TournamentLink>
              {/* <TournamentLink href="#">Resultados (EM BREVE)</TournamentLink> */}
            </Content>
          </TournamentActions>
        </TournamentDescription>
      </Container>
      <Footer />
    </>
  );
};

export default CopaAmizadeDeJiuJitsu2024;
