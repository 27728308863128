import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import qrCode from "../../assets/images/qrCode.png";
import Collapse from "../../components/atoms/colapso";
import Footer from "../../components/organisms/footer";
import NavBar from "../../components/organisms/navbar";
import useAuthenticate from "../../hooks/useAuthenticate";
import {
  Container,
  Content,
  Location,
  QRCodeImg,
  Title,
  H3,
  H5,
  Span,
  TournamentActions,
  TournamentBanner,
  TournamentContainer,
  TournamentContainerBanner,
  TournamentContent,
  TournamentDescription,
  TournamentEnrollmentLink,
  TournamentLink,
} from "./styles";
import tabela from "../../assets/images/tournaments/III-open-terra-santa/TABELA-III-open-terra-santa.jpg";
import SponsorButton from "../../components/atoms/sponsor-area";
import {
  SponsorContainer,
  SponsorList,
} from "../../components/atoms/sponsor-area/styles";

//Patrocinio
import cmda from "../../assets/images/sponsors/cmdca.jpeg";
import agroSC from "../../assets/images/sponsors/agro-santa-clara.jpeg";
import dasa from "../../assets/images/sponsors/dasa.jpeg";
import imperio from "../../assets/images/sponsors/imperio-academia-fitness.jpeg";
import lojaG from "../../assets/images/sponsors/lojq-gabrielly-moveis.jpeg";
import mrn from "../../assets/images/sponsors/mrn.jpeg";
import pmts from "../../assets/images/sponsors/PMTS.png";
import pontao from "../../assets/images/sponsors/pontao-ch.jpeg";
import sendel from "../../assets/images/sponsors/SENDEL.png";
import torneio from "../../assets/images/sponsors/torneio-facil.png";
import siqueira from "../../assets/images/sponsors/siqueira.png";

const sponsors = [
  {
    //url: "#",
    img: cmda,
  },
  {
    //url: "#",
    img: agroSC,
  },
  {
    //url: "#",
    img: dasa,
  },
  {
    //url: "#",
    img: imperio,
  },
  {
    //url: "#",
    img: mrn,
  },
  {
    //url: "#",
    img: lojaG,
  },
  {
    //url: "#",
    img: pmts,
  },
  {
    //url: "#",
    img: pontao,
  },
  {
    //url: "#",
    img: sendel,
  },
  {
    //url: "#",
    img: torneio,
  },
  {
    //url:"#",
    img: siqueira,
  },
];

const Tournament: React.FC = () => {
  const { authenticated } = useAuthenticate();

  return (
    <>
      <NavBar />

      <Container>
        <TournamentContainer
          image={require("../../assets/images/tournaments/III-open-terra-santa/campeonato-principal.jpeg")}
        >
          <TournamentContainerBanner>
            <TournamentBanner
              image={require("../../assets/images/tournaments/III-open-terra-santa/campeonato-principal.jpeg")}
            />
          </TournamentContainerBanner>
        </TournamentContainer>
        <TournamentContent>
          <Content>
            <Title>III OPEN TERRA SANTA DE JIU-JITSU</Title>
            <Location>
              <span>Data: 22 de julho de 2023</span>
              <br />
              <span>Início: 9h</span>
              <br />
              <span>Local: Ginásio Bráulio Nelson da Conceição</span>
              <br />
            </Location>
            {authenticated ? (
              <TournamentEnrollmentLink to="/enrollment/iii-open-terra-santa-de-jiu-jitisu">
                Inscrição
              </TournamentEnrollmentLink>
            ) : (
              <TournamentEnrollmentLink to="/login">
                Inscrição
              </TournamentEnrollmentLink>
            )}
            <p></p>
            <Collapse title="EDITAL III OPEN TERRA SANTA DE JIU JITSU - 2023">
              <span>
                O III Open Terra Santa de Jiu Jitsu é um evento de lutas GI (com
                kimono) que tem como objetivo promover o esporte e os atletas.
              </span>
              <br />
              <span>
                O evento disponibilizará categorias para todas as idades e
                faixas, conforme tabela de peso por categoria conforme deste
                edital.
              </span>
              <br />
              <span>
                Os competidores terão a oportunidade de testar suas habilidades
                em um ambiente controlado e com acompanhamento de profissionais
                qualificados.
              </span>
              <br />
              <br />
              <Collapse
                title="PROTOCOLO DE SEGURANÇA CONTRA COVID-19
"
              >
                <p>
                  Devido a dinâmica da situação atual, estamos avaliando
                  diariamente as recomendações dos Órgãos de Saúde e este
                  protocolo pode ser atualizado a qualquer momento caso novas
                  determinações sejam publicadas. Caso o evento seja cancelado
                  por força maior, todas as inscrições serão automaticamente
                  reembolsadas. A organização não é responsável pelos demais
                  custos dos participantes, tais como transporte e hospedagem.
                  Diretrizes do ginásio: Para a segurança de todos, por favor
                  verifique sua temperatura antes de se dirigir ao evento. Caso
                  haja qualquer sintoma, procure um médico. Caso você se sinta
                  indisposto ou esteve em contato com um paciente diagnosticado
                  com Covid-19, por favor fique em casa e procure seu médico.
                  Todo nosso material será desinfetado antes e durante o evento.
                </p>
              </Collapse>
              <Collapse title="PREMIAÇÕES">
                <h3>Por equipe:</h3>
                <p>1° lugar: R$ 1.000,00+troféu</p>
                <p>2° lugar: R$ 500,00+troféu</p>
                <p>3° lugar: R$ 300,00+troféu</p>

                <h3>Absoluto Marrom/Preta (adulto):</h3>
                <p>1° lugar: R$ 1.000,00 + cinturão</p>
                <p>2° lugar: medalha</p>
                <p>3° lugar: medalha</p>

                <h3>Absoluto Azul/Roxa (adulto):</h3>
                <p>1° lugar: R$ 300,00 + cinturão</p>
                <p>2° lugar: medalha</p>
                <p>3° lugar: medalha</p>

                <h3>Absoluto Branca (adulto):</h3>
                <p>1° lugar: o que arrecadar + cinturão</p>
                <p>2° lugar: medalha</p>
                <p>3° lugar: medalha</p>

                <h3>Absoluto Feminino - Todas as faixas (adulto):</h3>
                <p>1° lugar: o que arrecadar + cinturão</p>
                <p>2° lugar: medalha</p>
                <p>3° lugar: medalha</p>

                <h3>Demais categorias:</h3>
                <p>1º, 2º e 3º lugar receberão medalha como premiação.</p>
              </Collapse>

              <Collapse title="INSCRIÇÕES">
                <span>
                  A inscrição será realizada online, e será dividida em dois
                  lotes, sendo que os inscritos no primeiro lote ganharão
                  descontos.
                </span>
                <br></br>
                <span>
                  No momento da inscrição, o atleta ou responsável legal deverá
                  selecionar a categoria e idade corretamente.
                </span>
                <p>1º lote: R$ 60,00 (maio e junho)</p>
                <p>2º lote: R$ 70,00 (julho).</p>

                <h3>Absoluto Masculino (Adulto)</h3>
                <p>Marrom/Preta: R$ 70,00</p>
                <p>Azul/Roxa: R$ 60,00</p>
                <p>Branca: R$ 50,00</p>
                <h3>Absoluto Feminino (Adulto)</h3>
                <p>Todas as faixas(Juntas): R$ 50,00</p>
              </Collapse>
              <Collapse title="PAGAMENTO">
                <h3>
                  A confirmação de pagamento deverá ser realizada via WhatsApp
                  enviando o comprovante para o número informado abaixo:
                </h3>
                <p>Caixa Econômica:</p>
                <p>Agência: 3616</p>
                <p>Conta: 928679542-0</p>
                <p>Titular: Raimundo Sérgio Corrêa Cardoso</p>
                <a
                  target={"blank"}
                  href="https://whatsa.me/5592994386161/?t=Ol%C3%A1%20Sergio%20Cardoso,%20vou%20enviar%20o%20comprovante%20de%20pagamento%20referente%20a%20inscri%C3%A7%C3%A3o%20no%20*III%20OPEN%20TERRA%20SANTA%20DE%20JIU-JITSU*"
                >
                  <strong>PIX: (92) 99438-6161</strong>
                  <FaWhatsapp />
                </a>
                <p />
                <QRCodeImg src={qrCode} alt="qrCode" width={300} />
              </Collapse>
              <Collapse title="CRONOGRAMA">
                <span>Data: 22 de julho de 2023</span>
                <br />
                <span>Início: 8:30h</span>
                <br />
                <span>Local: Ginásio Bráulio Nelson da Conceição</span>
                <br />

                <H3>Lutas</H3>
                <H5>8:30h - Abertura do evento</H5>
                <H5>9:00h - Lutas Casadas </H5>
                <H5>
                  09:30h - 04, 05, 06, 07, 08 e 09 anos (Faixa branca e
                  Graduado) masculino e feminino.
                </H5>
                <H5>
                  10:30h - 10, 11, 12, 13, 14 e 15 anos (Faixa branca e
                  Graduado) feminino e masculino.
                </H5>
                <H5>
                  12:00h - Adulto e Máster (Faixa Preta ) masculino e feminino.
                </H5>
                <H5>
                  13:00h - Adulto e Máster (Faixa Marrom) e 16 e 17 (Faixa
                  branca e Graduado) masculino e feminino.{" "}
                </H5>
                <H5>
                  14:00h - Adulto e Máster (Faixa Azul e Roxa ) masculino e
                  feminino.
                </H5>
                <H5>
                  15:00h - Adulto e Máster (Faixa Branca ) masculino e feminino.{" "}
                </H5>
                <H5>
                  16:00h - Absoluto todas as idades (Todas as faixas ) masculino
                  e feminino.{" "}
                </H5>
                <H5>17:00 - Encerramento</H5>
                <H5>
                  Obs.1: O atleta deverá comparecer com uma (01) hora de
                  antecedência do horário previsto no cronograma.
                </H5>
                <H5>
                  Obs.2- O cronograma pode ser adiantado em até uma hora ou
                  atrasar conforme a necessidade do evento.
                </H5>
                <Span>Usaremos 3 áreas de luta</Span>
                <Span>
                  O atleta deverá estar presente no ginásio pelo menos 1 hora
                  antes da sua luta.
                </Span>
                <Span>
                  O cronograma pode ser adiantado em até 1 hora, e atrasar
                  conforme a necessidade do evento.
                </Span>
              </Collapse>
              <Collapse title="REGRAS">
                <span>As regras do evento são as da CBJJ/IBJJF.</span>
                <br />
                <span>
                  A tabela de peso por categoria está disponível no botão
                  "Tabela de Peso" no site do evento.
                </span>
                <br />
                <span>
                  O competidor deverá apresentar documento oficial com foto no
                  momento da checagem.
                </span>
                <br />
                <span>
                  O atleta deverá estar presente no ginásio pelo menos 1 hora
                  antes da sua luta.
                </span>
                <br />
                <span>
                  O cronograma pode ser adiantado em até uma hora ou atrasar
                  conforme a necessidade do evento, podendo ser adiantado em até
                  uma hora ou atrasado conforme a necessidade do evento.
                </span>
                <br />
                <span>
                  Atletas que lutaram em outros eventos com faixa superior não
                  poderão lutar com a faixa abaixo. Se descoberto, o competidor
                  será eliminado do torneio sem direito a reembolso.
                </span>
                <br />
                <span>
                  O aluno deve apresentar um documento de identificação no ato
                  da pesagem.
                </span>
                <br />
              </Collapse>
              <Collapse title="ENTRADA">
                <span>
                  Será cobrado um valor simbólico na entrada para o público,
                  sendo 5 reais por pessoa.{" "}
                </span>{" "}
                <br />
                <span>
                  {" "}
                  Crianças acima de 5 anos pagarão a entrada integral.
                </span>{" "}
                <br />
                <span>
                  Não será permitida a entrada de alimentos e bebidas
                  alcoólicas.
                </span>
              </Collapse>
            </Collapse>
          </Content>
        </TournamentContent>
        <TournamentDescription>
          <TournamentActions>
            <Content>
              <h1>PATROCINADORES</h1>
              <SponsorContainer>
                <SponsorList>
                  {sponsors &&
                    sponsors.map((buttons: any) => (
                      <SponsorButton
                        img={buttons.img}
                        url={buttons.url}
                        hasError={false}
                        error="Este campo é obrigatório"
                      />
                    ))}
                </SponsorList>
              </SponsorContainer>
            </Content>
            <br />
            {/* <Content>
              <TournamentLink href="!#">Cronograma (EM BREVE)</TournamentLink>
              <TournamentLink href="!#">Chaves (EM BREVE)</TournamentLink>
              <TournamentLink href="/check/iii-open-terra-santa-de-jiu-jitisu/search">
                Checagem de atletas
              </TournamentLink>
              <TournamentLink target="blank" href={tabela}>
                Tabela de pesos
              </TournamentLink>
              <TournamentLink href="!#">Resultados (EM BREVE)</TournamentLink>
            </Content> */}
            <Content>
              {/* <TournamentEnrollmentLinkEndpage to="#">
                Inscrição Encerrada
                </TournamentEnrollmentLinkEndpage> */}
              {/* <TournamentLink href="#">Chaves (EM BREVE)</TournamentLink> */}
              <TournamentLink
                href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.D99D3C1B&u=teste"
                target="_blank"
              >
                Chaves
              </TournamentLink>
              <TournamentLink href="/check/xxxi-copa-orley-lobato-de-jiu-jitsu-ninimbergue-guerra/search">
                Checagem de atletas
              </TournamentLink>
              <TournamentLink target="blank" href={tabela}>
                Tabela de pesos
              </TournamentLink>
              {/* <TournamentLink href="#">Resultados (EM BREVE)</TournamentLink> */}
              {/* <TournamentLink href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.D99D3C1B&u=teste">Resultados Individuais</TournamentLink> */}
              <TournamentLink href="https://lookerstudio.google.com/reporting/3e0e156e-1955-4496-8b51-c8a20e48cdd3/page/pcpXD?s=gci6sn17K6o">
                Pontuação das Academias
              </TournamentLink>
            </Content>
          </TournamentActions>
        </TournamentDescription>
      </Container>
      <Footer />
    </>
  );
};

export default Tournament;
