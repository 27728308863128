import React from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import logoImg from "../../../assets/images/logo.png";

import Button from "../../../components/atoms/button";
import Input from "../../../components/atoms/input";
import InputMaskCustom from "../../../components/atoms/input-mask-custom";
import Select from "../../../components/atoms/select";
import api from "../../../services/api";
import {
  AccountExists,
  Container,
  Content,
  FormContent,
  FormGroup,
  PageDescription,
  PageLogo,
  PageTitle,
  SectionBanner,
  SectionForm,
  TermDescription,
} from "./styles";
import Swal from "sweetalert2";

const Register: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const navigate = useNavigate();

  const password = React.useRef({});
  password.current = watch("password", "");

  const belts = [
    { title: "Branca", option: 1 },
    { title: "Cinza", option: 2 },
    { title: "Amarela", option: 3 },
    { title: "Laranja", option: 4 },
    { title: "Verde", option: 5 },
    { title: "Azul", option: 6 },
    { title: "Roxa", option: 7 },
    { title: "Marrom", option: 8 },
    { title: "Preta", option: 9 },
  ]

  const onSubmit = (data: any) => {
    if (data.password !== data.confirmPassword) {
      Swal.fire({
        title: 'Atenção!',
        text: `A senha e a confirmação de senha devem ser iguais.`,
        icon: 'error',
      })
      return toast.error("A senha e a confirmação de senha devem ser iguais.");
    }

    delete data.confirmPassword;

    api
      .post("/auth/register", {
        ...data,
        name: data.name.trim(),
        mother_name: data.mother_name.trim(),
        gym: data.gym.toLowerCase().trim(),
        belt: +data.belt,
        genre: data.genre.toLowerCase().trim(),
        email: data.email.toLowerCase().trim(),
        password: data.password.trim(),
      })
      .then((response: any) => {
        toast.success("A sua conta foi criada com sucesso!");

        const responseData = JSON.parse(response.config.data);

        const emailDomain = responseData.email.split('@')[1];

        Swal.fire({
          title: 'A sua conta foi criada.',
          text: `Verifique seu e-mail e ativar sua conta. ${responseData.email}`,
          icon: 'info',
          confirmButtonText: 'Ativar conta',
        }).then((willRedirect: any) => {
          if (willRedirect) {
            window.open(`https://${emailDomain}`, "_blank");
            navigate("/login");
          }
        });

      })
      .catch((error) => {
        if (error.response.data.statusCode === 400) {
          Swal.fire({
            title: 'Atenção!',
            text: 'O e-mail informado já está cadastrado! Por favor, informe outro e-mail ou faça o login!',
            icon: 'error',
            showCancelButton: true,
            confirmButtonText: 'Fazer login',
            cancelButtonText: 'Informar outro e-mail',
          }).then((result) => {
            if (result.isConfirmed) {
              navigate('/login');
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              const emailInput = document.querySelector<HTMLInputElement>('input[name="email"]');
              const passwordInput: any = document.querySelector<HTMLInputElement>('input[name="password"]');
              const confirmPasswordInput: any = document.querySelector<HTMLInputElement>('input[name="confirmPassword"]');
              if (emailInput) {
                emailInput.value = '';
                passwordInput.value = '';
                confirmPasswordInput.value = '';
                setTimeout(() => {
                  emailInput.focus();
                }, 1000);
              }
            }
          });
        } else {
          toast.error('Não foi possível criar a sua conta!');

          Swal.fire({
            title: 'Atenção!',
            text: 'Não foi possível criar a sua conta!',
            icon: 'error',
          });
        }
      });
  };

  return (
    <Container>
      <SectionForm>
        <Content>
          <Link to={"/"}>
            <PageLogo src={logoImg} />
          </Link>
          <PageTitle>Cadastre-se</PageTitle>
          <PageDescription>
            Bem-vindo ao Torneio Fácil.<br />
            Para se cadastrar preencha os dados abaixo.
          </PageDescription>
          <FormContent onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Input
                label="Nome completo"
                name="name"
                type="text"
                register={register}
                required
                hasError={!!errors.name}
                error="Este campo é obrigatório"
              />
            </FormGroup>
            <FormGroup>
              <Input
                label="Nome da mãe"
                name="mother_name"
                type="text"
                register={register}
                required
                hasError={!!errors.mother_name}
                error="Este campo é obrigatório"
              />
            </FormGroup>
            <FormGroup>
              <InputMaskCustom
                label="Telefone"
                name="phone"
                mask="(99) 99999-9999"
                register={register}
                required
                hasError={!!errors.phone}
                error="Este campo é obrigatório"
              />
            </FormGroup>
            <FormGroup>
              <InputMaskCustom
                label="Data de nascimento"
                name="birth_date"
                mask="99/99/9999"
                register={register}
                required
                hasError={!!errors.birth_date}
                error="Este campo é obrigatório"
              />
            </FormGroup>
            <FormGroup>
              <Input
                label="Academia"
                name="gym"
                type="text"
                register={register}
                required
                hasError={!!errors.gym}
                error="Este campo é obrigatório"
              // transformToLowerCase={true}
              />
            </FormGroup>
            <FormGroup>
              <Select
                label="Faixa"
                name="belt"
                register={register}
                required
                hasError={!!errors.belt}
                error="Este campo é obrigatório"
                options={belts}
              />
            </FormGroup>
            <FormGroup>
              <Select
                label="Sexo"
                name="genre"
                register={register}
                required
                hasError={!!errors.genre}
                error="Este campo é obrigatório"
                options={[
                  { title: "Masculino", option: "masculino" },
                  { title: "Feminino", option: "feminino" },
                ]}
              />
            </FormGroup>
            <FormGroup>
              <Input
                label="E-mail"
                name="email"
                type="text"
                register={register}
                required
                hasError={!!errors.email}
                error="Este campo é obrigatório"
                transformToLowerCase={true}
                trim={true}
              />
            </FormGroup>
            <FormGroup>
              <Input
                label="Senha"
                name="password"
                type="password"
                register={register}
                required
                hasError={!!errors.password}
                error="Este campo é obrigatório"
                trim={true}
              />
            </FormGroup>
            <FormGroup>
              <Input
                label="Confirmar senha"
                name="confirmPassword"
                type="password"
                register={register}
                required
                hasError={!!errors.confirmPassword}
                error="Este campo é obrigatório"
                trim={true}
              />
            </FormGroup>

            <FormGroup>
              <TermDescription>
                Ao criar a sua conta você estará de acordo com a política e
                termos de uso do Torneio Fácil.
              </TermDescription>
            </FormGroup>
            <FormGroup>
              <Button type="submit" label="Cadastrar" />
            </FormGroup>
            <FormGroup>
              <AccountExists href="/login">
                Já possui conta? Entre aqui
              </AccountExists>
            </FormGroup>
          </FormContent>
        </Content>
      </SectionForm>
      <SectionBanner />
    </Container>
  );
};

export default Register;
