import React from "react";
import { Dotted } from "./styles";

type LoadingProps = {
  text: string;
};

const Loading: React.FC<LoadingProps> = ({ text }) => {
  return (
    <Dotted>
      <div className="instructions">
        <h1>{text}</h1>
      </div>

      <div className="loading">
        <li></li>
        <li></li>
        <li></li>
      </div>
    </Dotted>
  );
};

export default Loading;
