// import styled from "styled-components";

// export const Container = styled.div`
//   font-family: Arial, sans-serif;
// `;

// export const Content = styled.div`
//   max-width: 800px;
//   margin: 0 auto;
//   padding: 50px 20px;
// `;

// export const PaymentContainer = styled.div`
//   background-color: #fff;
//   border-radius: 10px;
//   padding: 40px;
//   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
// `;

// export const StepContainer = styled.div`
//   margin-bottom: 20px;
// `;

// export const Title = styled.h2`
//   margin-bottom: 20px;
//   font-size: 18px;  /* Increased font size for a more modern look */
//   color: #000;   /* Changed color to match the button style */
//   text-align: center;  /* Centered the title */
//   border-bottom: 2px solid #4caf50;  /* Added a bottom border for a visual separator */
//   padding-bottom: 10px;  /* Added padding at the bottom */
// `;

// export const InstructionTitle = styled.h3`
//   margin-bottom: 20px;
//   font-size: 20px;
//   color: #333;
//   text-align: center;
// `;

// export const InstructionList = styled.ul`
//   padding: 0;
//   list-style: none;
// `;

// export const InstructionItem = styled.li`
//   margin-bottom: 20px;
//   font-size: 16px;
//   padding: 20px;
//   background-color: ${({ color }) => color || "#f2f2f2"};
//   border-radius: 5px;
// `;

// export const InstructionStep = styled.div`
//   text-align: center;
//   font-weight: bold;
//   margin-bottom: 10px;
// `;

// export const InstructionItemContent = styled.div`
//   text-align: left;
// `;

// export const StyledLink = styled.a`
//   display: inline-block;
//   background-color: #4caf50;
//   color: #ffffff;
//   text-decoration: none;
//   padding: 12px 24px;
//   border-radius: 5px;
//   font-size: 16px;
//   font-weight: bold;
//   transition: background-color 0.3s;
//   margin-top: 10px;

//   &:hover {
//     background-color: #45a049;
//   }
// `;

// export const HighlightedLink = styled(StyledLink)`
//   background-color: #ff5722; /* Updated the color for "Minhas Inscrições" */
//   &:hover {
//     background-color: #e64a19;
//   }
// `;
















import styled from "styled-components";

export const Container = styled.div`
  font-family: 'Helvetica Neue', Arial, sans-serif;
  overflow-x: hidden; /* Evita o scroll horizontal */
`;

export const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 50px 20px;
  width: 100%; /* Garante que o conteúdo ocupe 100% da largura disponível */

  @media (max-width: 768px) {
    padding: 30px 15px;
  }

  @media (max-width: 480px) {
    padding: 20px 10px;
  }
`;


export const PaymentContainer = styled.div`
  background-color: #fff;
  border-radius: 15px;
  padding: 40px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  width: 100%; /* Garante que o container ocupe 100% da largura disponível */
  box-sizing: border-box; /* Inclui padding e border na largura total */

  @media (max-width: 768px) {
    padding: 30px;
  }

  @media (max-width: 480px) {
    padding: 20px;
  }
`;

export const StepContainer = styled.div`
  margin-bottom: 20px;
`;

export const Title = styled.h2`
  margin-bottom: 0px;
  font-size: 14px;
  // color: #4caf50;
  color: #000;
  text-align: center;
  //border-bottom: 2px solid #4caf50;
  //padding-bottom: 10px;
  letter-spacing: 0px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 28px;
  }

  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

export const Title2= styled.h2`
  margin-top: 5px;
  margin-bottom: 20px;
  font-size: 18px;
  // color: #4caf50;
  color: #000;
  text-align: center;
  border-bottom: 2px solid #4caf50;
  padding-bottom: 10px;
  letter-spacing: 1.5px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 28px;
  }

  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

export const InstructionTitle = styled.h3`
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 24px;
  color: #333;
  text-align: center;
  //border-bottom: 1px solid #ccc;
  padding-bottom: 0px;

  @media (max-width: 768px) {
    font-size: 22px;
  }

  @media (max-width: 480px) {
    font-size: 20px;
  }
`;

export const InstructionList = styled.ul`
  padding: 0;
  list-style: none;
  width: 100%; /* Garante que a lista ocupe 100% da largura disponível */
`;

export const InstructionItem = styled.li`
  margin-top: 0px;
  margin-bottom: 20px;
  font-size: 18px;
  padding: 20px;
  background-color: ${({ color }) => color || "#f2f2f2"};
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  width: 100%; /* Garante que o item ocupe 100% da largura disponível */
  box-sizing: border-box; /* Inclui padding e border na largura total */

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 15px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
    padding: 10px;
  }
`;

export const InstructionStep = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
  color: #333;

  @media (max-width: 768px) {
    font-size: 18px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

export const InstructionItemContent = styled.div`
  text-align: left;
  font-size: 16px;
  color: #666;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

export const StyledLink = styled.a`
      text-align: center;
  display: inline-block;
  background-color: #4caf50;
  color: #ffffff;
  text-decoration: none;
  padding: 12px 24px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s;
  margin-top: 10px;

  &:hover {
    //background-color: #45a049;
    background-color: #5cBf70
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    font-size: 14px;
  }

  @media (max-width: 480px) {
    padding: 8px 16px;
    font-size: 12px;
  }
`;

export const HighlightedLink = styled(StyledLink)`
  //background-color: #ff5722;
  background-color: #373737;

  &:hover {
    //background-color: #e64a19;
    background-color: #575757;
  }
`;
