import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import Collapse from "../../components/atoms/colapso";
import Footer from "../../components/organisms/footer";
import NavBar from "../../components/organisms/navbar";
import { FaMapMarkerAlt } from 'react-icons/fa';
//import useAuthenticate from "../../hooks/useAuthenticate";
import {
  Container,
  Content,
  H3,
  H5,
  Location,
  P,
  QRCodeImg,
  Span,
  Title,
  TournamentActions,
  TournamentBanner,
  TournamentContainer,
  TournamentContainerBanner,
  TournamentContent,
  TournamentDescription,
  TournamentEnrollmentLink,
  TournamentEnrollmentLinkEndpage,
  TournamentLink,
  EditalContainer,
  EditalContent,
  EditalTitle
} from "./styles";
// import tabelaPeso from "../../assets/images/tournaments/toledo-international-open-brazilian-jiu-jitsu-2023/tabepla-de-peso.jpeg";
import tabelaPeso from "../../assets/images/tournaments/xxxii-copa-orley-lobato-de-jiu-jitsu/tabela-xxxii-copa-orley-lobato-de-jiu-jitsu.png";
import qrCodecopaAmizadeDeJiuJitsu2024 from "../../assets/images/tournaments/copa-amizade-de-jiu-jitsu-2024/qr-coce--copa-amizade-de-jiu-jitsu-2024.jpg";

//Patrocinio
import torneio from "../../assets/images/sponsors/torneio-facil.png";
import restauranteFeijao from "../../assets/images/sponsors/restaurante-feijao.png";
import manausOlimpica2024 from "../../assets/images/sponsors/manaus-olimpica-2024.png";
import manausSouthAmericanCityOfSport2024 from "../../assets/images/sponsors/manaus-south-american-city-of-sport-2024.png";
import prefeituraDeManaus2024 from "../../assets/images/sponsors/prefeitura-de-manaus-2024.png";
import manausEsporteFundacaoMunicipal from "../../assets/images/sponsors/manaus-esporte-fundacao-municipal.png";
import alexMartinsBjj from "../../assets/images/sponsors/alex-martins-bjj.jpeg";
import danielAlmeidaDeputado from "../../assets/images/sponsors/daniel-almeida-deputado.jpeg";
import drTeamFigthWear from "../../assets/images/sponsors/dr-team-figth-wear.jpeg";
import extravaganza from "../../assets/images/sponsors/extravaganza.jpeg";
import fmcSolocoes from "../../assets/images/sponsors/fmc-solocoes.jpeg";
import lionTraining from "../../assets/images/sponsors/lion-training.jpeg";
import masaMoldsPlastics from "../../assets/images/sponsors/masa-molds-plastics.jpeg";
import manausEsportiva from "../../assets/images/sponsors/manaus-esportiva.jpeg";
import rodriguesAdvocaciaCriminal from "../../assets/images/sponsors/rodrigues-advocacia-criminal.jpeg";
import sfSacFiscal from "../../assets/images/sponsors/sf-sac-fiscal.jpeg";
import crEstilo from "../../assets/images/sponsors/cr-estilo.png";
import edsonSales from "../../assets/images/sponsors/edsonSales.png";
import lopes from "../../assets/images/sponsors/lopes.png";
import madim from "../../assets/images/sponsors/madim.png";
import magistral from "../../assets/images/sponsors/magistral.png";
import mesquitaDetetizacao from "../../assets/images/sponsors/mesquita-detetizacao.png";
import naraChalub from "../../assets/images/sponsors/nara-chalub.png";
import projetoPenseAntes from "../../assets/images/sponsors/projeto-pense-antes.png";
import delegadoPericles from "../../assets/images/sponsors/delegado-pericles.png";
import yara from "../../assets/images/sponsors/yara-agua-mineral.png";
import kSupplementsNutrition from "../../assets/images/sponsors/k-supplements-nutrition.png";
import fjjam from "../../assets/images/sponsors/fjjam.jpg";
import manausMotors from "../../assets/images/sponsors/manaus-motors.png";
import clubeOrleyLobatoDeJiuJitsu from "../../assets/images/sponsors/clube-orley-lobato-de-jiu-jitsu.jpeg";
import horwin from "../../assets/images/sponsors/horwin.png";
import semuripirSecretariaMunicipal from "../../assets/images/sponsors/semuripir-secretaria-municipal.jpg";



//Apoio
import trevusMartialArts from "../../assets/images/sponsors/trevus-martial-arts.jpg";


import { SponsorContainer, SponsorList } from "src/components/atoms/sponsor-area/styles";
import SponsorButton from "src/components/atoms/sponsor-area";
import useAuthenticate from "../../hooks/useAuthenticate";

const sponsors = [
  { img: torneio, url: "http://www.torneiofacil.com" },
  { img: horwin, url: "#" },
  // { img: manausOlimpica2024, url: "#" },
  // { img: manausSouthAmericanCityOfSport2024, url: "#" },
  { img: prefeituraDeManaus2024, url: "#" },
  { img: manausEsporteFundacaoMunicipal, url: "#" },
  { img: horwin, url: "#" },
  { img: alexMartinsBjj, url: "#" },
  { img: danielAlmeidaDeputado, url: "#" },
  { img: horwin, url: "#" },
  // { img: drTeamFigthWear, url: "#" },
  { img: fmcSolocoes, url: "#" },
  { img: horwin, url: "#" },
  { img: lionTraining, url: "#" },
  { img: rodriguesAdvocaciaCriminal, url: "#" },
  { img: sfSacFiscal, url: "#" },
  { img: crEstilo, url: "#" },
  { img: edsonSales, url: "#" },
  { img: horwin, url: "#" },
  { img: lopes, url: "#" },
  { img: horwin, url: "#" },
  { img: magistral, url: "#" },
  // { img: semuripirSecretariaMunicipal, url: "#" },
  // { img: horwin, url: "#" },
  { img: yara, url: "#" },
  { img: fjjam, url: "#" },
  { img: horwin, url: "#" },
  { img: kSupplementsNutrition, url: "#" },

];

const dataEvento = "10 e 11 de agosto de 2024"
const horaEvento = "8:00h da manhã"
const tituloEvento = "XXXII Copa Orley Lobato de Jiu Jitsu"
const localEvento = "CEL - Nininberg Guerra"
const endereçoEvento = "R. Vicente Torres Reis, 657 - São Jorge, Manaus - AM, CEP 69033-030"
const dataDeEncerramentoIncricao = "05/08/2024"
const horaDeEncerramentoIncricao = "23h:59min"

const dataDeEncerramentoCorrecao = "05/08/2024"
const horaDeEncerramentoCorrecao = "23h:59min"

const dataDeEncerramentoInscricaoLote1 = "05/07/2024"
const dataDeEncerramentoInscricaoLote2 = "05/08/2024"

const valorinscricaoCategoriaLote1 = "50,00 (cinquenta reais)"
const valorinscricaoCategoriaLote2 = "70,00 (setenta reais)"

const valorinscricaoAbsolutoLote1 = "30,00 (trinta reais)"
const valorinscricaoAbsolutoLote2 = "40,00 (quarenta e cinco reais)"

const dataDeDivulgacaoChaves = "09/08/2024"
const horaDeDivulgacaoChaves = "23h:59min"

const instituicaoBancaria = "NuBank"
const tipoDeChavePix = "Telefone"
const ChavePix = "92 98129-1027"
const BeneficiarioDaChavePix = "Thiago Souza Dellarmelina"

const dataDeDivulgacaoCronograma = "EM BREVE"
const horaDeDivulgacaoCCronograma = "EM BREVE"

const contatoWhatsApp = "(92) 98129-1027"



const support = [
  //{ img: esporteNaPeriferia },
  { img: torneio },
  { img: clubeOrleyLobatoDeJiuJitsu, url: "#" },
];

const XxxiiCopaOrleyLobatoDeJiuJitsu: React.FC = () => {
  const { authenticated } = useAuthenticate();

  return (
    <>
      <NavBar />

      <Container>
        <TournamentContainer
          image={require("../../assets/images/tournaments/xxxii-copa-orley-lobato-de-jiu-jitsu/xxxii-copa-orley-lobato-de-jiu-jitsu.png")}
        >
          <TournamentContainerBanner>
            <TournamentBanner
              image={require("../../assets/images/tournaments/xxxii-copa-orley-lobato-de-jiu-jitsu/xxxii-copa-orley-lobato-de-jiu-jitsu.png")}
            />
          </TournamentContainerBanner>
        </TournamentContainer>
        <TournamentContent>
          <Content>
            <Title>{tituloEvento}</Title>
            <Location>
              <Span>Data: {dataEvento}</Span>
              <Span>Início: {horaEvento}</Span>
              <Span>Local: {localEvento}</Span>
              <Span>Endereço: {endereçoEvento}</Span>
              <Span>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                  <FaMapMarkerAlt style={{ marginRight: '8px', color: "red" }} />
                  <a
                    target={"blank"}
                    style={{ color: "#000", textDecoration: "none", fontWeight: "bold" }}
                    href="https://maps.app.goo.gl/LTzi6d1oJY5tH2Lw8"
                  >
                    Ver no Google Maps
                  </a>
                </div>
              </Span>
            </Location>
            <P></P>
            <br />

          </Content>
        </TournamentContent>
        <TournamentDescription>
          <TournamentActions>
            <Content>
              <h1 style={{ textAlign: "center" }}>PATROCINADORES</h1>
              <SponsorContainer>
                <SponsorList>
                  {sponsors && sponsors.map((buttons: any, index: number) => (
                    <SponsorButton
                      key={index} // Adicionei uma chave única para cada botão
                      img={buttons.img}
                      url={buttons.url}
                      hasError={false}
                      error="Este campo é obrigatório"
                      target="_blank" // Adicione este atributo
                    />
                  ))}
                </SponsorList>
              </SponsorContainer>
              <h1 style={{ textAlign: "center" }}>APOIO</h1>
              <SponsorContainer>
                <SponsorList>
                  {support &&
                    support.map((buttons: any) => (
                      <SponsorButton
                        img={buttons.img}
                        url={buttons.url}
                        hasError={false}
                        error="Este campo é obrigatório"
                      />
                    ))}
                </SponsorList>
              </SponsorContainer>
            </Content>

            <Content style={{
              marginTop: '32px'
            }}>

              <EditalContainer>
                <EditalTitle>EDITAL {tituloEvento.toLocaleUpperCase()}</EditalTitle>
                <EditalContent>
                  <Span>
                    A {tituloEvento} é um evento tradicional do
                    norte do Brasil fomentando e incentivando a prática do jiu jitsu
                    no estado do Amazonas . As lutas serão com GI (com kimono), o
                    evento disponibilizará categorias para todas as idades e faixas,
                    conforme tabela de peso por categoria deste edital. Os
                    competidores terão a oportunidade de testar suas habilidades em
                    um ambiente controlado e com acompanhamento de profissionais
                    qualificados.
                  </Span>
                  <br />
                  <br />
                  <br />
                </EditalContent>

                <Collapse title="PROTOCOLO DE SEGURANÇA CONTRA COVID-19">
                  <P>
                    Devido a dinâmica da situação atual, estamos avaliando diariamente as
                    recomendações dos Órgãos de Saúde e este protocolo pode ser atualizado a
                    qualquer momento caso novas determinações sejam publicadas.
                  </P>
                  <P>
                    Caso o evento seja cancelado por força maior, todas as inscrições serão
                    automaticamente reembolsadas.
                  </P>
                  <P>
                    A organização não é responsável pelos demais custos dos participantes,
                    tais como transporte e hospedagem.
                  </P>
                  <P>
                    Diretrizes do ginásio: Para a segurança de todos, por favor verifique
                    sua temperatura antes de se dirigir ao evento.{" "}
                  </P>
                  <P>Caso haja qualquer sintoma, procure um médico.</P>{" "}
                  <P>
                    {" "}
                    Caso você se sinta indisposto ou esteve em contato com um paciente
                    diagnosticado com Covid-19, por favor fique em casa e procure seu
                    médico.
                  </P>
                  {/* <P>
                  Todo nosso material será desinfetado antes e durante o evento.
                </P> */}
                </Collapse>
                <Collapse title="PREMIAÇÕES MOTOCICLETA🏍️, TRFÉUS🏆, CINTURÕES🧣, MEDALHAS🥇, KIMONO 🥋, KIT SUPLEMENTOS🥤e DINHEIRO💰">
                  <H3>Por Equipes </H3>
                  <P>
                    1° lugar: 1 Moto 0 KM + troféu + cesta de suplementos
                  </P>
                  <P>
                    2° lugar: R$ 1000 + 2 kimono + troféu + kit de suplementos
                  </P>
                  <P>
                    3° lugar: R$ 800 + 2 kimonos + troféu + kit de suplementos
                  </P>
                  <P>
                    4° lugar: R$ 500 + 1 kimonos + troféu + kit de suplementos
                  </P>
                  <P>
                    5° lugar: R$ 300 + 1 kimonos + troféu + kit de suplementos
                  </P>
                  <br />

                  <br />
                  <H3>Absoluto Masculino:</H3>
                  <H5>Juvenil Azul (Galo a Leve)</H5>
                  <P>1° lugar: R$100,00 + kimono + cinturão + suplemento</P>
                  <P>2° lugar: medalha + rash guard + faixa</P>
                  <P>3° lugar: medalha + blusa </P>
                  <H5>Juvenil Azul (Medio a Pesadissimo)</H5>
                  <P>1° lugar: R$100,00 + kimono + cinturão + suplemento</P>
                  <P>2° lugar: medalha + rash guard + faixa</P>
                  <P>3° lugar: medalha + blusa  </P>
                  <H5>Adulto Azul</H5>
                  <P>1° lugar: R$120,00 + kimono + cinturão + suplemento</P>
                  <P>2° lugar: medalha + rash guard + faixa</P>
                  <P>3° lugar: medalha + blusa</P>
                  <H5>Adulto roxa</H5>
                  <P>1° lugar: R$150,00 + kimono + cinturão + suplemento</P>
                  <P>2° lugar: medalha + rash guard + faixa</P>
                  <P>3° lugar: medalha + blusa  </P>
                  <H5>Adulto Marrom</H5>
                  <P>1° lugar: R$200,00 + kimono + cinturão + suplemento</P>
                  <P>2° lugar: medalha + mochila + faixa</P>
                  <P>3° lugar: medalha + blusa </P>
                  <H5>Adulto Preta</H5>
                  <P>1° lugar: R$250,00 + kimono + cinturão + suplemento</P>
                  <P>2° lugar: medalha + mochila + faixa</P>
                  <P>3° lugar: medalha + blusa  </P>
                  <H5>Master 1, 2, 3, 4 e 5 Preta (juntos)</H5>
                  <P>1° lugar: R$250,00 + kimono + cinturão + suplemento</P>
                  <P>2° lugar: medalha + mochila + faixa</P>
                  <P>3° lugar: medalha + blusa </P>
                  <br />
                  <H3>Absoluto Feminino:</H3>
                  Juvenil Azul (Galo a Leve)
                  <P>1° lugar: R$100,00 + kimono + cinturão + suplemento</P>
                  <P>2° lugar: medalha + rash guard + faixa</P>
                  <P>3° lugar: medalha + blusa  </P>
                  <H5>Juvenil Azul (Médio a Pesadíssimo)</H5>
                  <P>1° lugar: R$100,00 + kimono + cinturão + suplemento</P>
                  <P>2° lugar: medalha + rash guard + faixa </P>
                  <P>3° lugar: medalha + blusa  </P>
                  <H5>Adulto Azul</H5>
                  <P>1° lugar: R$120,00 + kimono + cinturão + suplemento</P>
                  <P>2° lugar: medalha + rash guard + faixa </P>
                  <P>3° lugar: medalha + blusa  </P>
                  <H5>Adulto roxa</H5>
                  <P>1° lugar: R$150,00 + kimono + cinturão + suplemento</P>
                  <P>2° lugar: medalha + rash guard + faixa </P>
                  <P>3° lugar: medalha + blusa  </P>
                  <H5>Adulto Marrom</H5>
                  <P>1° lugar: R$200,00 + kimono + cinturão + suplemento</P>
                  <P>2° lugar: medalha + mochila + faixa </P>
                  <P>3° lugar: medalha + blusa </P>
                  <H5>Adulto Preta</H5>
                  <P>1° lugar: R$250,00 + kimono + cinturão + suplemento</P>
                  <P>2° lugar: medalha + mochila + faixa </P>
                  <P>3° lugar: medalha + blusa </P>
                  <H5>Master 1, 2, 3, 4 e 5 Preta (juntos)</H5>
                  <P>1° lugar: R$250,00 + kimono + cinturão + suplemento</P>
                  <P>2° lugar: medalha + mochila + faixa </P>
                  <P>3° lugar: medalha + blusa</P>
                  <br />

                  <H3>Demais Categorias</H3>
                  <P>1° lugar: medalha</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>
                  <br />
                  <p>OBS: Estas premiações só irão valer para os Absolutos que tiverem 05 (CINCO)
                    ou mais atletas inscritos.</p>
                  <p>Em caso com menos de 5 (CINCO) inscritos no Absoluto, a premiação para o campeão será troféu e kit suplemento, para segundo
                    e terceiro permanece a premição normal.
                  </p>

                  <p>Mais informações sobre as inscriçoes para o absoluto esta disponivel na seção
                    INSCRIÇÕES, paragrafo Inscrição de Absoluto deste edital.
                  </p>
                  <br />

                  <H3>Demais Categorias GI</H3>
                  <P>1° lugar: medalha</P>
                  <P>2° lugar: medalha</P>
                  <P>3° lugar: medalha</P>
                </Collapse>

                <Collapse title="INSCRIÇÕES">
                  <Span>
                    As inscrições para o evento serão realizadas exclusivamente online, por
                    meio do site www.torneiofacil.com.br
                  </Span>
                  <P>
                    Durante o processo de inscrição, o atleta ou seu responsável legal
                    deverá selecionar corretamente a categoria referente idade que o atleta
                    completará no ano de 2024, bem como sua faixa e peso.
                  </P>
                  <P>
                    A idade de competição do atleta é a idade máxima que ele terá em 2024,
                    conforme regra da CBJJ.
                  </P>
                  <br />

                  <H3>Prazo de Inscrição</H3>
                  <P>
                    O prazo final para inscrições e pagamento será até o dia {dataDeEncerramentoIncricao}, às
                    {horaDeEncerramentoIncricao}min.
                  </P>
                  <br />
                  <P>As inscrições que não forem pagas serão canceladas.</P>
                  <br></br>

                  <H3>Valor  </H3>
                  <P>Primeiro Lote até {dataDeEncerramentoInscricaoLote1}</P>
                  <P>Inscrição categoria R$ {valorinscricaoCategoriaLote1} (cinquenta reais)</P>
                  <P>Inscrição absoluto R$ {valorinscricaoAbsolutoLote1} (trinta reais)</P>
                  <br />
                  <P>Segundo Lote até {dataDeEncerramentoInscricaoLote2}</P>
                  <P>Inscrição categoria R$ {valorinscricaoCategoriaLote2} (setenta reais)</P>
                  <P>Inscrição absoluto R$ {valorinscricaoAbsolutoLote2} (quarenta e cinco reais)</P>

                  <br />
                  <br />
                  <H3>Correção</H3>

                  <P>
                    A correção da inscrição poderá ser realizada somente após a confirmação
                    do pagamento.
                  </P>
                  <P>
                    Em caso de inscrição equivocada, o atleta ou professor responsável
                    poderá corrigir as informações no site www.torneiofacil.com.br,
                    acessando a opção Meu Perfil em seguida Minhas Inscrições.
                  </P>
                  <P>
                    A correção da inscrição ficará disponivel até o encerramento das
                    inscrições {dataDeEncerramentoCorrecao} as {horaDeEncerramentoCorrecao}.
                  </P>
                  <P>
                    Após prazo para correção das inscrições, não serão aceitas modificações
                    nas inscrições.
                  </P>
                  <P>
                    Os atletas que se inscreverem incorretamente estarão sujeitos a
                    desclassificação <b>sem direito a reembolso.</b>
                  </P>

                  <br />

                  <H3>Prazo de Correção</H3>
                  <P>
                    A correção da inscrição fica disponível após a confirmação do pagamento
                    da mesma.
                  </P>

                  <P>
                    A correção da inscrição ficará disponivel até o encerramento das
                    inscrições no {dataDeEncerramentoCorrecao} as {horaDeEncerramentoCorrecao}.
                  </P>
                  <H3>Checagem</H3>

                  <P>
                    O sorteio e divulgação das chaves de lutas será no dia {dataDeDivulgacaoChaves} até as {horaDeDivulgacaoChaves}.
                  </P>

                  <br />
                  <H3>Inscrição de Absoluto</H3>
                  <P>
                    Somente atletas inscritos na categoria poderão se increver e participar
                    do absoluto.
                  </P>
                  <P>
                    As inscrições para os absolutos são gratuitas, podendo ter no máximo 4
                    atletas da mesma equipe por absoluto.
                  </P>
                  <P>
                    As inscrições para os absolutos serão realizadas pelo professor
                    responsável pelo atleta, junto a organização do evento no dia do torneio
                    após a finalização das lutas da categoria.
                  </P>
                  <P>
                    Os atletas só poderão se inscrever no absoluto referente a categoria da
                    sua inscrição no torneio. Exceção apenas para os master que poderá se
                    inscrever como absoluto adulto.
                  </P>
                </Collapse>
                <Collapse title="VALOR DA INSCRIÇÃO">
                  <Span>
                    As inscrições serão divididas em dois lotes
                  </Span>
                  <br />
                  <H3>Primeiro Lote até {dataDeEncerramentoInscricaoLote1}</H3>
                  <P>Inscrição categoria R$ {valorinscricaoCategoriaLote1} (cinquenta reais)</P>
                  <P>Inscrição absoluto R$ {valorinscricaoAbsolutoLote1} (trinta reais)</P>
                  <br />
                  <H3>Segundo Lote até {dataDeEncerramentoInscricaoLote2}</H3>
                  <P>Inscrição categoria R$ {valorinscricaoCategoriaLote2} (setenta reais)</P>
                  <P>Inscrição absoluto R$ {valorinscricaoAbsolutoLote2} (quarenta e cinco reais)</P>
                  <br />
                  <br />
                </Collapse>
                <Collapse title="PAGAMENTO">
                  <H3>
                    A confirmação de pagamento deverá ser realizada via WhatsApp enviando o
                    comprovante para o contato abaixo:
                  </H3>
                  <P>Banco: {instituicaoBancaria}</P>
                  <P>Titular: {BeneficiarioDaChavePix}</P>
                  <P>Chave PIX: {tipoDeChavePix}</P>
                  <P>Chave PIX: {ChavePix}</P>
                  {/* <QRCodeImg src={qrCodecopaAmizadeDeJiuJitsu2024} alt="qrCode" width={300} />
                  <P>
                    <a
                      target={"blank"}
                      style={{ color: "white" }}
                      href="https://whatsa.me/5592981291027/?t=Ol%C3%A1+Thiago_Dellarmelina%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+na+%2ACOPA+ORLEY+LOBATO+DE+JIU+JITSU+2024%2A"
                    >
                      <strong>WhatsApp: +55 (92) 98129-1027 </strong>
                      <FaWhatsapp />
                    </a>
                  </P> */}
                  <p />
                  {/* <QRCodeImg src={qrCodeCopaParana} alt="qrCode" width={300} /> */}
                </Collapse>

                <Collapse title="REEMBOLSO DAS INSCRIÇÕES">
                  <H3>
                    Após o fim do perido de inscrição não será possivel o reembolso das inscrições pagas.
                  </H3>

                </Collapse>

                <Collapse title="REGRAS">
                  <Span>
                    Todas as regras para o evento estão devidamente estipuladas e detalhadas neste edital.
                  </Span>
                  <Span>
                    Em casos de omissão de regras no presente edital, as normas a serem observadas serão aquelas estabelecidas pela Confederação
                    Brasileira de Jiu-Jitsu (CBJJ), em conformidade com as diretrizes da CBJJ/IBJJF.
                  </Span>
                  <Span>
                    A tabela de peso por categoria, que estabelece os limites de peso para cada categoria, encontra-se disponível no site oficial do evento,
                    acessível por meio do botão denominado "Tabela de Peso".
                  </Span>
                  <Span>
                    As lutas serão com GI (com kimonos de cores Oficiais pela cbjj branco,
                    azul e preto ) os kimonos devem estar limpos sem rasgos e sem odor .
                  </Span>
                  <Span>
                    Para as categorias com 3 lutadores, será aplicada a regra da CBJJ.
                    Em caso de WO de um atleta, os dois lutadores restantes na categoria disputarão a final.
                  </Span>
                  <Span>
                    A pesagem será realizada no dia do evento. O competidor deverá
                    apresentar documento oficial com foto no momento da pesagem.
                  </Span>
                  <Span>
                    A pesagem dos atletas serão realizadas antes do inicio da categoria.
                  </Span>
                  <Span>
                    Atletas que ultrapasarem o limite de peso da categoria que entiverem
                    inscritos serão automaticamente desclassificados e não poderão lutar o
                    Absoluto.
                  </Span>
                  <Span>Será realizada apenas uma pesagem oficial por atleta.</Span>
                  <Span>
                    Teremos uma balança para pesagem livre (não oficial) para os atletas.
                  </Span>
                  {/* <Span>Teremos duas balanças para pesagem oficial dos atletas.</Span> */}
                  <Span>
                    Atletas que lutaram em outros eventos com faixa superior não poderão
                    lutar com a faixa abaixo. Se descoberto, o competidor será eliminado do
                    torneio sem direito a reembolso.
                  </Span>
                  <br />
                  <H3>Pontuação por equipe</H3>
                  <H5>Categoria e Absoluto, todas as faixas</H5>
                  <P>1° lugar: 9 pontos</P>
                  <P>2° lugar: 3 pontos</P>
                  <P>3° lugar: 1 ponto</P>


                  <P>
                    A contagem dos pontos será realizada de forma geral contabilizando os
                    pontos de todas as faixas.
                  </P>
                  <P>Campeão direto não contam pontos, chaves com apenas um inscrito.</P>
                  <P>
                    Campeão por WO, devido a desistencia do oponente, contará pontos desde
                    que na chave tenha mais de um inscrito.
                  </P>
                  <P>
                    Categorias com atletas somente da mesma academia não contam pontos
                    para a disputa por academias, para contar pontos é necessario ao menos um
                    atleta de outra academia para validar a chave.
                  </P>
                  <P>
                    O que não estiver neste edital, será acatado o que estiver no livro de
                    regras da CBJJ.
                  </P>
                  <br />
                  <P>
                    <a
                      target={"blank"}
                      href="https://cbjj.com.br/books-videos"
                      style={{ color: "white" }}
                    >
                      <strong>LIVRO DE REGRAS DA CBJJ/IBJJF</strong>
                    </a>
                  </P>
                </Collapse>
                <Collapse title={"CRONOGRAMA"}>
                  {/* (EM BREVE) */}
                  <Container>

                    <H3>TODAS AS LUTAS SERÃO REALIZADAS NO DIA 10/08/2024.</H3>
                    <br />
                    <Span>Início: 08:30 horas</Span>

                    <br />
                    <H5>09:00 horas categoria PRÉ MIRIM e MIRIM - 04/05 e 06/07 anos (Branca e Graduado) masculino e feminino.</H5>
                    <H5>09:30 horas INFANTIL A 08/09 anos (Branca e Graduado) masculino e feminino.</H5>
                    <H5>10:00 horas - INFANTIL B-10/11 anos (Branca e Graduado) masculino e feminino.</H5>
                    <H5>10:30 horas - INFANTO-JUVENIL A 12/13 anos (Branca e Graduado) masculino e feminino.</H5>
                    <H5>11:00 horas - INFANTO-JUVENIL B - 14/15 anos (Branca e Graduado) masculino e feminino.</H5>
                    <H5>12:00 horas - JUVENIL 16/17 anos (Branca e Graduado) masculino e feminino.</H5>
                    <H5>13:00 horas - Faixa PRETA (Adulto/Máster/Sênior) masculino e feminino.</H5>
                    <H5>14:00 horas - Faixa Marrom (Adulto/Máster/Sênior) masculino e feminino.</H5>
                    <H5>15:00 horas - Faixa Roxa (Adulto/Máster/Sênior) masculino e feminino</H5>
                    <H5>16:00 horas - Faixa Azul (Adulto/Máster/Sênior) masculino e feminino.</H5>
                    <H5>17:00 horas - Faixa Branca (Adulto/Máster/Sênior) masculino e feminino.</H5>

                    <br />
                    <H5>
                      Obs.1: O atleta deverá comparecer no ginasio com uma (01) hora de antecedência do
                      horário previsto no cronograma.
                    </H5>
                    <H5>
                      Obs.2: Os absolutos serão iniciados após as respectivas categorias,
                      conforme o andamento do cronograma.
                    </H5>

                    {/* <Span>Usaremos 6 áreas de luta</Span> */}
                    <Span>
                      O cronograma pode ser adiantado em até 1 hora, e atrasar conforme a
                      necessidade do evento.
                    </Span>
                    <Span>
                      O atleta que não estiver presente no momento da chamada para a pesagem
                      ou luta ser desclassificado por W.O.
                    </Span>
                  </Container>
                </Collapse>
                <Collapse title="ENTRADA">
                  <P>Entrada liberada ao público.</P>
                  <br />
                  {/* <P>
                  Será cobrado 1kg de alimento não perecível na entrada para atletas e
                  público.
                </P> */}
                  {/* <br />
                <P>Crianças até 5 anos ficam isentas de qualquer tipo de pagamento.</P>
                <br /> */}
                </Collapse>
                <Collapse title="CONTATO">
                  <P>
                    Duvidas sobre cadastro, inscrição, pagamento, correção, checagem
                    ou sugestão de melhorias entre em contato com a equipe do Torneio
                    Fácil por telefone ou WhatsApp.
                  </P>

                  <h1>OBS:</h1>

                  <P>NÃO ATENDEMOS LIGAÇÃO CONVENCIONAL</P>
                  <P>SOMENTE WHATSAPP</P>
                  <P>O PRAZO PRA RESPOSTA É DE 48H</P>
                  <P>TODAS AS MENSAGENS SERÃO RESPONDIDAS</P>
                  <P>
                    Melhor horário para atendimento das 18:00h as 23:59h.
                  </P>
                  <a target={"blank"} href="https://whatsa.me/+5592981291027/?t=Ol%C3%A1+Thiago+Dellarmelina+%2APreciso+de+ajuda+com+minha+inscrição+na+XXXII+COPA+ORLEY+LOBATO+DE+JIU+JITSU+2024%2A*"
                  >
                    <strong>Telefone/WhatsApp: {contatoWhatsApp} </strong>
                    <FaWhatsapp />
                  </a>

                  <br />
                </Collapse>
              </EditalContainer>
            </Content>
            <br />
            <Content>
              {authenticated ? (
                <>
                  {/* <TournamentEnrollmentLinkEndpage to="/enrollment/xxxii-copa-orley-lobato-de-jiu-jitsu-cel-nininberg-guerra-manaus-am"> */}
                  <TournamentEnrollmentLinkEndpage to="#">

                    Inscrição Encerrada
                  </TournamentEnrollmentLinkEndpage>

                  <TournamentEnrollmentLinkEndpage to="/profile/subscription">
                    Correção da Inscrição
                  </TournamentEnrollmentLinkEndpage>
                </>
              ) : (
                <>
                  <TournamentEnrollmentLinkEndpage to="/login">
                    Inscrição Encerrada
                  </TournamentEnrollmentLinkEndpage>

                  <TournamentEnrollmentLinkEndpage to="/login">
                    Correção da Inscrição
                  </TournamentEnrollmentLinkEndpage>
                </>
              )}
              {/* <TournamentLink href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.767B171E&u=teste" target="_blank">Chaves</TournamentLink> */}
              {/* // /check/16-COPA-PARANA-DE-JIU-JITSU */}

              <TournamentLink
                // href="#"
                // target="_blank"
                href="/check/xxxii-copa-orley-lobato-de-jiu-jitsu-cel-nininberg-guerra-manaus-am/search"
              //href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.D7A54E37&u=teste"
              >Checagem</TournamentLink>

              {/* 
              <TournamentLink
                href="/check/toledo-international-open-brazilian-jiu-jitsu-nogi-2023-ginasio-de-esportes-alcides-pan-toledo-pr/search"
                target="_blank"
              >
                Checagem de atletas NO GI
              </TournamentLink>  */}

              <TournamentLink
                href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.D7A54E37&u=teste"
                target="_blank"
              >
                Chaves GI
              </TournamentLink>

              {/*               <TournamentLink
                href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.5B5B1C9A&u=teste"
                target="_blank"
              >
                Chaves NO GI
              </TournamentLink> */}

              <TournamentLink target="blank" href={tabelaPeso}>
                Tabela de pesos
              </TournamentLink>
              {/* <TournamentLink href="#">Resultados (EM BREVE)</TournamentLink> */}
            </Content>
          </TournamentActions>
        </TournamentDescription>
      </Container>
      <Footer />
    </>
  );
};

export default XxxiiCopaOrleyLobatoDeJiuJitsu;
