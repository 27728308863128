import NavBar from "../../../components/organisms/navbar";
import { Container, Content, PaymentContainer, Title, Title2, InstructionTitle, InstructionList, InstructionItem, InstructionStep, InstructionItemContent, StyledLink, HighlightedLink } from "../styles";
import { FaWhatsapp } from "react-icons/fa";
import { useParams } from "react-router-dom";
import React from "react";

const PaymentEnrollmentIvOpenTerraSantaDeJiuJistu = () => {
    const { slug, id } = useParams();
    switch (slug) {
        case "iv-opem-terra-santa-de-jiu-jistu-ginasio-braulio-nelson-da-conceicao-gi":
            return (
                <Container>
                    <NavBar />
                    <Content>
                        <PaymentContainer>
                            <Title>Obrigado por fazer a sua inscrição na</Title>
                            <Title2>
                                {"IV OPEN TERRA SANTA DE JIU JITSU GI".toLocaleUpperCase()}
                            </Title2>
                            <p style={{ color: "red", marginBottom: "0px" }}><b>Inscrição N° {id}</b></p>
                            <InstructionTitle>Instruções:</InstructionTitle>

                            <InstructionList>
                                {/* <InstructionItem color="#f0f4c3"> */}
                                <InstructionItem color="rgb(210, 210, 210)">
                                    <InstructionStep>Passo 1</InstructionStep>
                                    <InstructionItemContent>
                                        Colocar o número da inscrição ( <b style={{ color: "red", marginBottom: "30px" }}>{id}</b> ) na descrição do PIX.
                                    </InstructionItemContent>
                                </InstructionItem>

                                {/* <InstructionItem color="#e3f2fd"> */}
                                <InstructionItem color="rgb(210, 210, 210)">
                                    <InstructionStep>Passo 2</InstructionStep>
                                    <InstructionItemContent>
                                        Efetuar o pagamento através do PIX para:
                                        <ul style={{ listStyle: "none" }}>
                                            <br />
                                            <li>Caixa econômica</li>
                                            <li>Ag: 3616</li>
                                            <li>Conta: 928679542-0</li>
                                            <li>Tipo de Chave PIX CPF</li>
                                            <li><b>Pix: 827.839.422-91</b></li>
                                            <li>Raimundo Sérgio Corrêa Cardoso</li>
                                        </ul>
                                    </InstructionItemContent>
                                </InstructionItem>

                                <InstructionItem color="rgb(210, 210, 210)">
                                    {/* <InstructionItem color="#ffecb3"> */}
                                    <InstructionStep>Passo 3</InstructionStep>
                                    <InstructionItemContent>
                                        Enviar o comprovante de pagamento (com número da inscrição descrição)  para:
                                        <ul style={{ listStyle: "none" }}>
                                            <li>
                                                <br />
                                                <StyledLink
                                                    target="_blank"
                                                    href={`https://whatsa.me/5592994386161/?t=Ol%C3%A1+Sergio+Cardoso%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+na+%2AIV+OPEN+TERRA+SANTA+DE+JIU+JITSU+GI%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                                                    >
                                                    +55 (92) 99438-6161  <FaWhatsapp />
                                                </StyledLink>
                                            </li>
                                        </ul>
                                    </InstructionItemContent>
                                </InstructionItem>

                                <InstructionItem color="rgb(210, 210, 210)">
                                    {/* <InstructionItem color="#c8e6c9"> */}
                                    <InstructionStep>Passo 4</InstructionStep>
                                    <InstructionItemContent>
                                        Acompanhe o status do pagamento em:
                                        <br />
                                        <ul style={{ listStyle: "none" }}>
                                            <li>
                                                <HighlightedLink href="/profile/subscription">Minhas Inscrições</HighlightedLink>
                                            </li>
                                        </ul>
                                    </InstructionItemContent>
                                </InstructionItem>
                            </InstructionList>
                        </PaymentContainer>
                    </Content>
                </Container>
            )
            case 'iv-opem-terra-santa-de-jiu-jistu-ginasio-braulio-nelson-da-conceicao-nogi':
            return (
                <Container>
                <NavBar />
                <Content>
                    <PaymentContainer>
                        <Title>Obrigado por fazer a sua inscrição na</Title>
                        <Title2>
                            {"IV OPEN TERRA SANTA DE JIU JITSU NOGI".toLocaleUpperCase()}
                        </Title2>
                        <p style={{ color: "red", marginBottom: "0px" }}><b>Inscrição N° {id}</b></p>
                        <InstructionTitle>Instruções:</InstructionTitle>

                        <InstructionList>
                            {/* <InstructionItem color="#f0f4c3"> */}
                            <InstructionItem color="rgb(210, 210, 210)">
                                <InstructionStep>Passo 1</InstructionStep>
                                <InstructionItemContent>
                                    Colocar o número da inscrição ( <b style={{ color: "red", marginBottom: "30px" }}>{id}</b> ) na descrição do PIX.
                                </InstructionItemContent>
                            </InstructionItem>

                            {/* <InstructionItem color="#e3f2fd"> */}
                            <InstructionItem color="rgb(210, 210, 210)">
                                <InstructionStep>Passo 2</InstructionStep>
                                <InstructionItemContent>
                                    Efetuar o pagamento através do PIX para:
                                    <ul style={{ listStyle: "none" }}>
                                        <br />
                                        <li>Caixa econômica</li>
                                        <li>Ag: 3616</li>
                                        <li>Conta: 928679542-0</li>
                                        <li>Tipo de Chave PIX CPF</li>
                                        <li><b>Pix: 827.839.422-91</b></li>
                                        <li>Raimundo Sérgio Corrêa Cardoso</li>
                                    </ul>
                                </InstructionItemContent>
                            </InstructionItem>

                            <InstructionItem color="rgb(210, 210, 210)">
                                {/* <InstructionItem color="#ffecb3"> */}
                                <InstructionStep>Passo 3</InstructionStep>
                                <InstructionItemContent>
                                    Enviar o comprovante de pagamento (com número da inscrição descrição)  para:
                                    <ul style={{ listStyle: "none" }}>
                                        <li>
                                            <br />
                                            <StyledLink
                                                target="_blank"
                                                href={`https://whatsa.me/5592994386161/?t=Ol%C3%A1+Sergio+Cardoso%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+na+%2AIV+OPEN+TERRA+SANTA+DE+JIU+JITSU+NOGI%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                                                >
                                                +55 (92) 99438-6161  <FaWhatsapp />
                                            </StyledLink>
                                        </li>
                                    </ul>
                                </InstructionItemContent>
                            </InstructionItem>
                            

                            <InstructionItem color="rgb(210, 210, 210)">
                                {/* <InstructionItem color="#c8e6c9"> */}
                                <InstructionStep>Passo 4</InstructionStep>
                                <InstructionItemContent>
                                    Acompanhe o status do pagamento em:
                                    <br />
                                    <ul style={{ listStyle: "none" }}>
                                        <li>
                                            <HighlightedLink href="/profile/subscription">Minhas Inscrições</HighlightedLink>
                                        </li>
                                    </ul>
                                </InstructionItemContent>
                            </InstructionItem>
                        </InstructionList>
                    </PaymentContainer>
                </Content>
            </Container>
        )
        default:
            return <h1>Pagamento não liberado para esse torneio</h1>
    }
}
export default PaymentEnrollmentIvOpenTerraSantaDeJiuJistu
