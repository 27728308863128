import { useEffect, useState } from "react";
import NavBar from "../../../components/organisms/navbar";
import {
  Container,
  Content,
  InfoContainerStatus,
  InfoContainerText,
  PageLocationTitle,
  PageTitle,
  PageTitleContainer,
  StatusButton,
  SubscriptionContainer,
  SubscriptionContent,
  SubscriptionDescription,
  SubscriptionItem,
  SubscriptionList,
  SubscriptionTitle,
} from "./styles";
import api from "../../../services/api";
import { toast } from "react-toastify";
import useAuthenticate from "../../../hooks/useAuthenticate";
import Loading from "../../../components/atoms/loading";
import whatsapp from "../../../assets/images/wpp.png";
import {
  FilterOption,
  FilterSelect,
} from "src/components/atoms/input/inputText";
import Swal from "sweetalert2";

const SubscriptionAdm = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { getToken } = useAuthenticate();
  const { account }: any = useAuthenticate();
  const [filter, setFilter] = useState<string | null>(null);
  const [tournaments, setTournaments] = useState<any[]>([]);

  const changePayments = (id: any, status: any) => {
    Swal.fire({
      title: "Tem certeza que deseja mudar a status de pagamento?",
      icon: "warning",
      showDenyButton: true,
      showCancelButton: false,
      html: '<input type="text" id="paymentAmount" placeholder="Digite o valor do pagamento" class="swal2-input" required>',
      confirmButtonText: "Sim",
      denyButtonText: "Não",
    }).then(async function (result) {
      if (result.isConfirmed) {
        const paymentAmountInput = document.getElementById(
          "paymentAmount"
        ) as HTMLInputElement;

        if (paymentAmountInput && paymentAmountInput.checkValidity()) {
          const paymentAmount = paymentAmountInput.value;

          await api
            .patch(
              `/manager/payments/${id}`,
              {
                status: !status,
                valueSubscription: paymentAmount,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${getToken()}`,
                },
              }
            )
            .then((response) => {
              if (response.status === 200) {
                const newList: any = data.map((item: any) => {
                  if (item.id === id) {
                    const updatedItem = {
                      ...item,
                      status: !item.status,
                    };

                    return updatedItem;
                  }

                  return item;
                });

                setData(newList);
                toast.success("Status de pagamento alterado com sucesso!");
              } else {
                toast.error("Não foi possível mudar status de pagamento!");
              }
            })
            .catch(() =>
              toast.error("Não foi possível mudar status de pagamento!")
            );
        } else {
          toast.error("Status não alterado, informe o valor de pagamento");
        }
      }
    });
  };

  const renderItems = (id: number, status: any, index: number) => {
    return (
      <InfoContainerStatus key={index}>
        <StatusButton
          onClick={() => changePayments(id, status)}
          status={status}
        >
          <span>{status ? "Pagamento efetuado" : "Pendente de pagamento"}</span>
        </StatusButton>
      </InfoContainerStatus>
    );
  };

  useEffect(() => {
    const fetchPost = async () => {
      setLoading(true);
      api
        .get("/administrators/tournaments/enrollments", {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        })
        .then(async ({ data }) => {
          const tournament = await api.get(`/tournaments`);
          setLoading(false);
          const sortedArray = data.sort((a: any, b: any) => a.id - b.id);
          const datae = sortedArray.map((item: any) => {
            return {
              ...item,
              tournament: tournament.data.find(
                (tournament: any) => tournament.id === item.tournamentId
              ),
            };
          });
          setData(datae);
        })
        .catch(() => {
          setLoading(false);
          toast.error("Não foi carregar os dados de inscrições.");
        });
    };

    const getTournaments = async () => {
      setLoading(true);
      api
        .get("/tournaments", {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        })
        .then(({ data }) => {
          setTournaments(data);
        });
    };
    getTournaments();

    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loading text="Aguarde, estamos processando os dados." />;

  const handleFilter = (event: any) => {
    const value = event.target.value;
    if (value === "1") {
      setFilter("1");
    } else {
      setFilter(value === "" ? null : value || "asas");
    }
  };

  const filteredData = filter
    ? data.filter((item: any) => {
      console.log("aaaaaaaaaaaaaaaaaaaaquiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii")
      console.log(item)
        if (filter === "1") {

          return item.tournamentId === 1;
        } else {
          return item.tournamentId === parseInt(filter) || !item.tournamentId;
        }
      })
    : [];

  const filteredArrayStatusTrue = filteredData.filter(
    (value: any) => value.status === true
  );

  const filteredArrayStatusFalse = filteredData.filter(
    (value: any) => value.status === false
  );

  const openWhatsapp = (phone: string) => {
    window.open(`https://whatsa.me/${phone}`, "_blank");
  };

  return (
    <>
      <NavBar />
      <Container>
        <Content>
          <PageTitleContainer>
            <PageLocationTitle>Minhas Inscrições</PageLocationTitle>
            <PageTitle>{filteredData.length} inscrições encontrados</PageTitle>
            <PageTitle>
              {filteredArrayStatusTrue.length} inscrições pagas
            </PageTitle>
            <PageTitle>
              {filteredArrayStatusFalse.length} inscrições não pagas
            </PageTitle>
            <FilterSelect selectedCategory={filter} onChange={handleFilter}>
              <FilterOption value="">SELECIONE UM CAMPEONATO</FilterOption>
              {tournaments.map((tournament) => (
                <FilterOption value={tournament.id}>
                  {tournament.name.toUpperCase()}
                </FilterOption>
              ))}
            </FilterSelect>
          </PageTitleContainer>

          <SubscriptionContainer>
            {filteredData &&
              filteredData
                .slice(0)
                .reverse()
                .map((value: any, index: number) => (
                  <SubscriptionList key={value.id}>
                    <SubscriptionItem>
                      <SubscriptionContent>
                        <SubscriptionTitle>
                          Inscrição Nº {value.id} - {value.tournament.name.toUpperCase()}
                        </SubscriptionTitle>
                        <br />
                        <SubscriptionDescription>
                          <InfoContainerText>
                            <span>
                              <b>Nome:</b>{" "}
                              {value.fighters
                                ? value.fighters.name.toUpperCase()
                                : value.user.name.toUpperCase()}
                            </span>
                            <span>
                              <b>Contato:</b>{" "}
                              {value.fighters !== null ? (
                                value.fighters.phone === "" ? (
                                  "Sem contato "
                                ) : (
                                  <a
                                    href="#"
                                    onClick={(
                                      e: React.MouseEvent<HTMLAnchorElement>
                                    ) => {
                                      e.preventDefault();
                                      openWhatsapp(value.fighters.phone);
                                    }}
                                  >
                                    <img
                                      src={whatsapp}
                                      alt="wpp"
                                      width={16}
                                      height={16}
                                      style={{ padding: "0 10px 0 10px" }}
                                    />
                                    {value.fighters.phone}
                                  </a>
                                )
                              ) : value.user.phone === "" ? (
                                "Sem contato "
                              ) : (
                                <a
                                  href="#"
                                  onClick={(
                                    e: React.MouseEvent<HTMLAnchorElement>
                                  ) => {
                                    e.preventDefault();
                                    openWhatsapp(value.user.phone);
                                  }}
                                >
                                  <img
                                    src={whatsapp}
                                    alt="wpp"
                                    width={16}
                                    height={16}
                                    style={{ padding: "0 10px 0 10px" }}
                                  />
                                  {value.user.phone}
                                </a>
                              )}{" "}
                            </span>
                            <span>
                              <b>Gênero:</b> {value.genre.toUpperCase()}
                            </span>
                            <span>
                              <b>Data Nascimento:</b>{" "}
                              {value.fighters
                                ? value.fighters.birth_date
                                : value.user.birth_date}
                            </span>
                            {value.fighters ? (
                              value.fighters.age
                            ) : (
                              <span>
                                <b>Idade calculada:</b> {value.age} anos
                              </span>
                            )}
                            <span>
                              <b>Equipe: </b>{" "}
                              {value.fighters
                                ? value.fighters.gym.toUpperCase()
                                : value.user.gym.toUpperCase()}
                            </span>
                            {/* <span>Professor: ORLEY LOBATO</span><br/> */}
                            <span>
                              <b>Categoria(s):</b> {value.genre.toUpperCase()} (
                              {value.category.toUpperCase()}) /{" "}
                              {value.belt.toUpperCase()} / {value.weight.toUpperCase()}
                            </span>
                          </InfoContainerText>
                          {account?.roles?.includes("manager-payments") === true
                            ? renderItems(value.id, value.status, index)
                            : ""}
                        </SubscriptionDescription>
                      </SubscriptionContent>
                    </SubscriptionItem>
                  </SubscriptionList>
                ))}
          </SubscriptionContainer>
        </Content>
      </Container>
    </>
  );
};

export default SubscriptionAdm;
