import React, { useState } from "react";
import styled, { ThemedStyledProps } from "styled-components";
import { AiOutlineCaretDown } from "react-icons/ai";
import { AiOutlineCaretUp } from "react-icons/ai";

const CollapseWrapper = styled.div`
`;

const CollapseButton = styled.button`
  color: rgb(132, 140, 155);
  display: flex;
  align-items: center;
  border: none;
  font-size: 16px;
  width: 100%;
  text-align: start;
  cursor: pointer;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
  font-weight: bold; 
  background-color: transparent;
  text-transform: uppercase;
  font-size: 14px;
  width: 100%;
  font-weight: 600;
  margin: 0px;
  padding: 0px;
  justify-content: start;
`;

type CollapseContentProps = ThemedStyledProps<
  {
    isOpen: boolean;
  },
  any
>;

const CollapseContent = styled.div<CollapseContentProps>`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: rgb(255, 255, 255);
  padding: 8px 8px;
  > a {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.01em;
    text-decoration: none;
    color: rgb(255, 255, 255);
  }
  > h3 {
    line-height: 24px;
  }

`;

const CollapseText = ({ title, styleWrapper, children }: any) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <CollapseWrapper style={styleWrapper}>
      <CollapseButton onClick={handleToggle}><span style={{
        flex: 1,
        textAlign: "start"
      }}>{title}</span> { isOpen ? <AiOutlineCaretUp size={16}/> : <AiOutlineCaretDown size={16}/>}</CollapseButton>
      <CollapseContent isOpen={isOpen}>{children}</CollapseContent>
    </CollapseWrapper>
  );
};

export default CollapseText;
