import NavBar from "../../../components/organisms/navbar";
import { Container, Content, PaymentContainer, Title, Title2, InstructionTitle, InstructionList, InstructionItem, InstructionStep, InstructionItemContent, StyledLink, HighlightedLink } from "../styles";
import { FaWhatsapp } from "react-icons/fa";
import { useParams } from "react-router-dom";
import React from "react";

const PaymentEnrollmentIiCopaArenaParintinsDeJiuJitsu = () => {
    const { slug, id } = useParams();
    switch (slug) {
        case 'ii-copa-arena-parintins-de-jiu-jitsu-arena-olimpica-rubens-dos-santos-gi':
            return (
                <Container>
                    <NavBar />
                    <Content>
                        <PaymentContainer>
                            <Title>Obrigado por fazer a sua inscrição na</Title>
                            <Title2>
                            II COPA ARENA PARINTINS DE JIU JITSU GI
                            </Title2>
                            <p style={{ color: "red", marginBottom: "0px" }}><b>Inscrição N° {id}</b></p>
                            <InstructionTitle>Instruções:</InstructionTitle>

                            <InstructionList>
                                {/* <InstructionItem color="#f0f4c3"> */}
                                <InstructionItem color="rgb(240, 240, 240)">
                                    <InstructionStep>Passo 1</InstructionStep>
                                    <InstructionItemContent>
                                        Coloca o número da inscrição ( <b style={{ color: "red", marginBottom: "30px" }}>{id}</b> ) na descrição do PIX.
                                    </InstructionItemContent>
                                </InstructionItem>

                                {/* <InstructionItem color="#e3f2fd"> */}
                                <InstructionItem color="rgb(230, 230, 230)">
                                    <InstructionStep>Passo 2</InstructionStep>
                                    <InstructionItemContent>
                                        Efetuar o pagamento através do PIX para:
                                        <ul style={{ listStyle: "none" }}>
                                            <br />
                                            <li>Banco Bradesco</li>
                                            <li>Chave PIX telefone</li>
                                            <li><b>Pix: 92 99468-5161</b></li>
                                            <li>José Michel Tavares Pio</li>
                                        </ul>
                                    </InstructionItemContent>
                                </InstructionItem>

                                <InstructionItem color="rgb(220, 220, 220)">
                                    {/* <InstructionItem color="#ffecb3"> */}
                                    <InstructionStep>Passo 3</InstructionStep>
                                    <InstructionItemContent>
                                        Enviar o comprovante de pagamento (com número da inscrição descrição)  para:
                                        <ul style={{ listStyle: "none" }}>
                                            <li>
                                                <br />
                                                <StyledLink
                                                    target="_blank"
                                                    href={`https://whatsa.me/5592994685161/?t=Ol%C3%A1%20Michel%20Tavares,%20vou%20enviar%20o%20comprovante%20de%20pagamento%20referente%20a%20inscri%C3%A7%C3%A3o%20na%20*II%20COPA%20ARENA%20PARINTINS%20DE%20JIU%20JITSU%20GI%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}*`}
                                                    >
                                                    +55 (92) 99468-5161 <FaWhatsapp />
                                                </StyledLink>
                                            </li>
                                        </ul>
                                    </InstructionItemContent>
                                </InstructionItem>

                                <InstructionItem color="rgb(210, 210, 210)">
                                    {/* <InstructionItem color="#c8e6c9"> */}
                                    <InstructionStep>Passo 4</InstructionStep>
                                    <InstructionItemContent>
                                        Acompanhe o status do pagamento em:
                                        <br />
                                        <ul style={{ listStyle: "none" }}>
                                            <li>
                                                <HighlightedLink href="/profile/subscription">Minhas Inscrições</HighlightedLink>
                                            </li>
                                        </ul>
                                    </InstructionItemContent>
                                </InstructionItem>
                            </InstructionList>
                        </PaymentContainer>
                    </Content>
                </Container>
            )
        case 'ii-copa-arena-parintins-de-jiu-jitsu-arena-olimpica-rubens-dos-santos-nogi':
            return (
                <Container>
                <NavBar />
                <Content>
                    <PaymentContainer>
                        <Title>Obrigado por fazer a sua inscrição na</Title>
                        <Title2>
                        II COPA ARENA PARINTINS DE JIU JITSU NOGI
                        </Title2>
                        <p style={{ color: "red", marginBottom: "0px" }}><b>Inscrição N° {id}</b></p>
                        <InstructionTitle>Instruções:</InstructionTitle>

                        <InstructionList>
                            {/* <InstructionItem color="#f0f4c3"> */}
                            <InstructionItem color="rgb(240, 240, 240)">
                                <InstructionStep>Passo 1</InstructionStep>
                                <InstructionItemContent>
                                    Coloca o número da inscrição ( <b style={{ color: "red", marginBottom: "30px" }}>{id}</b> ) na descrição do PIX.
                                </InstructionItemContent>
                            </InstructionItem>

                            {/* <InstructionItem color="#e3f2fd"> */}
                            <InstructionItem color="rgb(230, 230, 230)">
                                <InstructionStep>Passo 2</InstructionStep>
                                <InstructionItemContent>
                                    Efetuar o pagamento através do PIX para:
                                    <ul style={{ listStyle: "none" }}>
                                        <br />
                                        <li>Banco Bradesco</li>
                                        <li>Chave PIX telefone</li>
                                        <li><b>Pix: 92 99468-5161</b></li>
                                        <li>José Michel Tavares Pio</li>
                                    </ul>
                                </InstructionItemContent>
                            </InstructionItem>

                            <InstructionItem color="rgb(220, 220, 220)">
                                {/* <InstructionItem color="#ffecb3"> */}
                                <InstructionStep>Passo 3</InstructionStep>
                                <InstructionItemContent>
                                    Enviar o comprovante de pagamento (com número da inscrição descrição)  para:
                                    <ul style={{ listStyle: "none" }}>
                                        <li>
                                            <br />
                                            <StyledLink
                                                target="_blank"
                                                href={`https://whatsa.me/5592994685161/?t=Ol%C3%A1%20Michel%20Tavares,%20vou%20enviar%20o%20comprovante%20de%20pagamento%20referente%20a%20inscri%C3%A7%C3%A3o%20na%20*II%20COPA%20ARENA%20PARINTINS%20DE%20JIU%20JITSU%20NOGI%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}*`}
                                                >
                                                +55 (92) 99468-5161 <FaWhatsapp />
                                            </StyledLink>
                                        </li>
                                    </ul>
                                </InstructionItemContent>
                            </InstructionItem>

                            <InstructionItem color="rgb(210, 210, 210)">
                                {/* <InstructionItem color="#c8e6c9"> */}
                                <InstructionStep>Passo 4</InstructionStep>
                                <InstructionItemContent>
                                    Acompanhe o status do pagamento em:
                                    <br />
                                    <ul style={{ listStyle: "none" }}>
                                        <li>
                                            <HighlightedLink href="/profile/subscription">Minhas Inscrições</HighlightedLink>
                                        </li>
                                    </ul>
                                </InstructionItemContent>
                            </InstructionItem>
                        </InstructionList>
                    </PaymentContainer>
                </Content>
            </Container>
            )
        default:
            return <h1>Pagamento não liberado para esse torneio</h1>
    }
}
export default PaymentEnrollmentIiCopaArenaParintinsDeJiuJitsu
