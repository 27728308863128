import Axios from "axios";

const api = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(
  async (config: any) => {
    config.headers = {
      ...config.headers,
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    return config;
  },
  (error: any) => Promise.reject(error)
);

export default api;
