import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import Collapse from "../../components/atoms/colapso";
import Footer from "../../components/organisms/footer";
import NavBar from "../../components/organisms/navbar";
//import useAuthenticate from "../../hooks/useAuthenticate";
import {
  Container,
  Content,
  H3,
  H5,
  Location,
  P,
  QRCodeImg,
  Span,
  Title,
  TournamentActions,
  TournamentBanner,
  TournamentContainer,
  TournamentContainerBanner,
  TournamentContent,
  TournamentDescription,
  TournamentEnrollmentLink,
  TournamentEnrollmentLinkEndpage,
  TournamentLink,
} from "./styles";
// import tabelaPeso from "../../assets/images/tournaments/toledo-international-open-brazilian-jiu-jitsu-2023/tabepla-de-peso.jpeg";
import tabelaPeso from "../../assets/images/tournaments/open-guaraniacu-jiu-jitsu-2024/TABELA-OPEN-GUARANIACU-JIU-JITSU-2024.jpg";
import qrCodeGuaraniacuJiuJitsu2024 from "../../assets/images/tournaments/open-guaraniacu-jiu-jitsu-2024/qr-code-open-guaraniacu-jiu-jitsu-2024.png";
import qrCodeToledoInternationalOpenBrazilianJiuJitsu2023 from "../../assets/images/tournaments/toledo-international-open-brazilian-jiu-jitsu-2023/qr-coce-toledo-international-open-brazilian-jiu-jitsu-2023-banner.jpeg";

//Patrocinio
import torneio from "../../assets/images/sponsors/torneio-facil.png";
import twr from "../../assets/images/sponsors/twr.png";
import goldStar from "../../assets/images/sponsors/gold-star.jpeg";
import acai72 from "../../assets/images/sponsors/acai72.jpeg";
import toledo from "../../assets/images/sponsors/toledo.png";
import keikosports from "../../assets/images/sponsors/keikosports.jpeg";
import prime from "../../assets/images/sponsors/LOGO-PRIME.png";
//import yaraCountry from "../../assets/images/sponsors/yara-country.png";
//import Zaeli from "../../assets/images/sponsors/ZaeliLogo.png";

//Apoio
import agitoTur from "../../assets/images/sponsors/agito-tur.jpeg";
import prediletaRestaurante from "../../assets/images/sponsors/predileta-restaurante.png";
import donaMadameBoutique from "../../assets/images/sponsors/dona-madame-boutique.jpeg";
import escoveSeVilaA from "../../assets/images/sponsors/escove-se-vila-a.jpeg";
//import esporteNaPeriferia from "../../assets/images/sponsors/esporte-na-periferia.png";
import associacaoVivaFeliz from "../../assets/images/sponsors/associacao-viva-feliz.png";

import { SponsorContainer, SponsorList } from "src/components/atoms/sponsor-area/styles";
import SponsorButton from "src/components/atoms/sponsor-area";
import useAuthenticate from "../../hooks/useAuthenticate";

const sponsors = [
  { img: twr },
  { img: goldStar },
  { img: keikosports },
  { img: prime },
  //{ img: yaraCountry },
  { img: torneio, url: "http://www.google.com.br" },
  { img: agitoTur },
  { img: prediletaRestaurante },
  { img: donaMadameBoutique },
  { img: escoveSeVilaA },
];

const support = [
  //{ img: esporteNaPeriferia },
  { img: associacaoVivaFeliz },
];

const OpenGuaraniacuJiuJitsu2024: React.FC = () => {
  const { authenticated } = useAuthenticate();

  return (
    <>
      <NavBar />

      <Container>
        <TournamentContainer
          image={require("../../assets/images/tournaments/open-guaraniacu-jiu-jitsu-2024/open-guaraniacu-jiu-jitsu-2024.jpg")}
        >
          <TournamentContainerBanner>
            <TournamentBanner
              image={require("../../assets/images/tournaments/open-guaraniacu-jiu-jitsu-2024/open-guaraniacu-jiu-jitsu-2024.jpg")}
            />
          </TournamentContainerBanner>
        </TournamentContainer>
        <TournamentContent>
          <Content>
            <Title>Open Guaraniaçu Jiu Jitsu 2024</Title>
            <Location>
              <Span>Data: 25 de fevereiro de 2024</Span>
              <Span>Início: 9:30h</Span>
              <Span>Local: Ginásio de Esportes Onofre Garbasheski Guaraniaçu-PR</Span>
            </Location>
            <P></P>
            <br />
            <Collapse title="EDITAL OPEN GUARANIAÇU JIU JITSU 2024">
              <Span>
                O Open Guaraniaçu Jiu Jitsu é um evento tradicional do Oeste Paranaense
                fomentando e incentivando a prática do jiu jitsu no Paraná . As lutas
                serão exclusivamente com GI (com kimono), o evento disponibilizará
                categorias para todas as idades e faixas, conforme as tabelas de peso
                deste edital. Os competidores terão a oportunidade de testar suas
                habilidades em um ambiente controlado e com acompanhamento de
                profissionais qualificados.
              </Span>
              <br />
              <br />
              <br />
              <Collapse title="PROTOCOLO DE SEGURANÇA CONTRA COVID-19">
                <P>
                  Devido a dinâmica da situação atual, estamos avaliando diariamente as
                  recomendações dos Órgãos de Saúde e este protocolo pode ser atualizado a
                  qualquer momento caso novas determinações sejam publicadas.
                </P>
                <P>
                  Caso o evento seja cancelado por força maior, todas as inscrições serão
                  automaticamente reembolsadas.
                </P>
                <P>
                  A organização não é responsável pelos demais custos dos participantes,
                  tais como transporte e hospedagem.
                </P>
                <P>
                  Diretrizes do ginásio: Para a segurança de todos, por favor verifique
                  sua temperatura antes de se dirigir ao evento.{" "}
                </P>
                <P>Caso haja qualquer sintoma, procure um médico.</P>{" "}
                <P>
                  {" "}
                  Caso você se sinta indisposto ou esteve em contato com um paciente
                  diagnosticado com Covid-19, por favor fique em casa e procure seu
                  médico.
                </P>
                {/* <P>
                  Todo nosso material será desinfetado antes e durante o evento.
                </P> */}
              </Collapse>
              <Collapse title="PREMIAÇÕES ABSOLUTO GI">
                <H3>Por Equipes </H3>
                <P>1° lugar: Troféu + R$500,00</P>
                <P>2° lugar: Troféu + R$300,00</P>
                <P>3° lugar: Troféu + R$200,00</P>

                <br />
                <H3>Absoluto Masculino GI:</H3>
                <H5>Infanto Juvenil Graduado (Médio a Pesadissimo)</H5>
                <P>1° lugar: 1 Kimono + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <H5>Juvenil Branca</H5>
                <P>1° lugar: 1 Kimono + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <H5>Juvenil Azul</H5>
                <P>1° lugar: 1 Kimono + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <H5>Adulto Branca</H5>
                <P>1° lugar: 1 Kimono + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <H5>Adulto Azul</H5>
                <P>1° lugar: 1 Kimono + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <H5>Adulto Roxa</H5>
                <P>1° lugar: 1 Kimono + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <H5>Adulto Marrom</H5>
                <P>1° lugar: 1 Kimono + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <H5>Adulto Preta</H5>
                <P>1° lugar: 1 Kimono + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <H5>Master Marrom/Preta (JUNTOS)</H5>
                <P>1° lugar: 1 Kimonoa + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>

                <br />
                <br />
                <H3>Absoluto Feminino GI:</H3>
                <H5>Infanto Juvenil Graduado (Médio a Pesadissimo)</H5>
                <P>1° lugar: 1 Kimono + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <H5>Juvenil Branca</H5>
                <P>1° lugar: 1 Kimono + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <H5>Juvenil Graduado (Todas as faixas juntas)</H5>
                <P>1° lugar: 1 Kimono + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <H5>Adulto Branca</H5>
                <P>1° lugar: 1 Kimono + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <H5>Adulto Azul</H5>
                <P>1° lugar: 1 Kimono + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <H5>Adulto Roxa/Marrom/Preta (Todas as faixas juntas)</H5>
                <P>1° lugar: 1 Kimono + medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
                <br />

                <P>Mínimo de 4 atletas por absoluto para valer a premiação total.</P>

                <P>
                  Número menor que 4 atletas a premiação ficará a criterio da organização
                  do evento, podendo não ter premição.
                </P>

                <br />

                <H3>Demais Categorias GI</H3>
                <P>1° lugar: medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P>
              </Collapse>

              <Collapse title="INSCRIÇÕES">
                <Span>
                  As inscrições para o evento serão realizadas exclusivamente online, por
                  meio do site www.torneiofacil.com.br.
                </Span>
                <P>
                  Durante o processo de inscrição, o atleta ou seu responsável legal
                  deverá selecionar corretamente a categoria referente idade que o atleta
                  completará no ano de 2024, bem como sua faixa e peso.
                </P>
                <P>
                  A idade de competição do atleta é a idade máxima que ele terá em 2024,
                  conforme regra da CBJJ.
                </P>
                <br />

                <H3>Prazo de Inscrição</H3>
                <P>
                  O prazo final para inscrições e pagamento será até o dia 20/02/2024, às
                  23h59min.
                </P>
                <br />
                <P>As inscrições que não forem pagas serão canceladas.</P>
                <br></br>

                <H3>Valor</H3>
                <P>Inscrição GI é de R$ 100,00 (cem reais)</P>

                <br />
                <H3>Checagem</H3>

                <P>
                  A checagem ficará disponivel até a o dia 25 de fevereiro de 2024. Obs: A
                  correção ficará disponível somente até o dia 20 de fevereiro de 2024
                  (conforme explicado abaixo).
                </P>
                <br />
                <H3>Correção</H3>

                <P>
                  A correção da inscrição poderá ser realizada somente após a confirmação
                  do pagamento.
                </P>
                <P>
                  Em caso de inscrição equivocada, o atleta ou professor responsável
                  poderá corrigir as informações no site www.torneiofacil.com.br,
                  acessando a opção Meu Perfil em seguida Minhas Inscrições.
                </P>
                <P>
                  A correção da inscrição ficará disponivel até o encerramento das
                  inscrições 20/02/2024.
                </P>
                <P>
                  Após prazo para correção das inscrições, não serão aceitas modificações
                  nas inscrições.
                </P>
                <P>
                  Os atletas que se inscreverem incorretamente estarão sujeitos a
                  desclassificação <b>sem direito a reembolso.</b>
                </P>

                <br />

                <H3>Prazo de Correção</H3>
                <P>
                  A correção da inscrição fica disponível após a confirmação do pagamento
                  da mesma.
                </P>

                <P>
                  A correção da inscrição podera ser feita até o dia 20/02/2024 às
                  23h59min.
                </P>
                <H3>Checagem</H3>

                <P>
                  O sorteio e divulgação das chaves de lutas será no dia 24 de fevereiro
                  de 2024 até as 23h e 59min.
                </P>

                <br />
                <H3>Inscrição de Absoluto</H3>
                <P>
                  Somente atletas inscritos na categoria poderão se increver e participar
                  do absoluto.
                </P>
                <P>
                  As inscrições para os absolutos são gratuitas, podendo ter no máximo 4
                  atletas da mesma equipe por absoluto.
                </P>
                <P>
                  As inscrições para os absolutos serão realizadas pelo professor
                  responsável pelo atleta, junto a organização do evento no dia do torneio
                  após a finalização das lutas da categoria.
                </P>
                <P>
                  Os atletas só poderão se inscrever no absoluto referente a categoria da
                  sua inscrição no torneio. Exceção apenas para os master que poderá se
                  inscrever como absoluto adulto.
                </P>
              </Collapse>
              <Collapse title="PAGAMENTO">
                <H3>
                  A confirmação de pagamento deverá ser realizada via WhatsApp enviando o
                  comprovante para o contato abaixo:
                </H3>
                <P>Pag Bank:</P>
                <P>Titular: Juan Cesar de Oliveira Aracati</P>
                <P>Chave PIX do tipo telefone:</P> <P>PIX: 45 99945-1302</P>
                <QRCodeImg src={qrCodeGuaraniacuJiuJitsu2024} alt="qrCode" width={300} />
                <P>
                  <a
                    target={"blank"}
                    href="https://whatsa.me/5545999073832/?t=Ol%C3%A1+Raphaela+Franco%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+na+%2ATOLEDO+INTERNATIONAL+JIU+JITSU%2A"
                  >
                    <strong>WhatsApp: (45) 99907-3832 </strong>
                    <FaWhatsapp />
                  </a>
                </P>
                <p />
                {/* <QRCodeImg src={qrCodeCopaParana} alt="qrCode" width={300} /> */}
              </Collapse>

              <Collapse title="REGRAS">
                <Span>
                  As regras do evento são as da CBJJ/IBJJF. A tabela de peso por categoria
                  está disponível no botão "Tabela de Peso" no site do evento.
                </Span>

                <Span>
                  As lutas serão com GI (com kimonos de cores Oficiais pela cbjj branco,
                  azul e preto ) os kimonos devem estar limpos sem rasgos e sem odor .
                </Span>

                <Span>
                  Para categoria com 3 lutadores, os atletas farão a melhor de três para
                  definição do campão, segundo e terceiro lugar conforme regra da CBJJ
                </Span>
                <Span>
                  A pesagem será realizada no dia do evento. O competidor deverá
                  apresentar documento oficial com foto no momento da pesagem.
                </Span>
                <Span>
                  A pesagem será realizada 30 minutos antes de cada inicio da categoria.
                </Span>
                <Span>
                  Atletas que ultrapasarem o limite de peso da categoria que entiverem
                  inscritos serão automaticamente desclassificados e não poderão lutar o
                  Absoluto.
                </Span>
                <Span>Será realizada apenas uma pesagem oficial por atleta.</Span>
                <Span>
                  Teremos uma balança para pesagem livre (não oficial) para os atletas.
                </Span>
                <Span>Teremos duas balanças para pesagem oficial dos atletas.</Span>
                <Span>
                  Atletas que lutaram em outros eventos com faixa superior não poderão
                  lutar com a faixa abaixo. Se descoberto, o competidor será eliminado do
                  torneio sem direito a reembolso.
                </Span>
                <br />
                <H3>Pontuação por equipe</H3>
                <H5>Categoria</H5>
                <P>1° lugar: 9 pontos</P>
                <P>2° lugar: 3 pontos</P>
                <P>3° lugar: 1 ponto</P>
                <H5>Absoluto</H5>

                <P>1° lugar: 13.5 pontos</P>
                <P>2° lugar: 4.5 pontos</P>
                <P>3° lugar: 1.5 ponto</P>
                <br />

                <P>
                  A contagem dos pontos será realizada de forma geral contabilizando os
                  pontos de todas as faixas.
                </P>
                <P>Campeão direto não contam pontos, chaves com apenas um inscrito.</P>
                <P>
                  Campeão por WO, devido a desistencia do oponente, contará pontos desde
                  que na chave tenha mais de um inscrito.
                </P>
                <P>
                  Categorias com apenas dois atletas da mesma academia não contam pontos
                  para a disputa por academias.
                </P>
                <P>
                  O que não estiver neste edital, será acatado o que estiver no livro de
                  regras da CBJJ.
                </P>
                <br />
                <P>
                  <a
                    target={"blank"}
                    href="https://cbjj.com.br/books-videos"
                    style={{ color: "white" }}
                  >
                    <strong>LIVRO DE REGRAS DA CBJJ/IBJJF</strong>
                  </a>
                </P>
              </Collapse>
              <Collapse title="CRONOGRAMA">
                {/* (EM BREVE) */}
                <Container>
                  {/* <b><Span>Obs: O cronograma definitivo será atualizado no dia 11/11/2023.</Span></b> */}
                  <br />
                  <Span>Data: 25 de fevereiro de 2024</Span>
                  <Span>Início: 9:00h da manhã</Span>
                  <Span>Local: Ginásio de Esportes Onofre Garbasheski Guaraniaçu-PR</Span>
                  <br />
                  <H5>9:30h - Abertura do evento</H5>
                  <br />
                  <H3>Lutas GI</H3>
                  <H5>9:45h - 04, 05, 06, 07, 08, 09, 10 e 11 anos (Faixa branca e Graduado) masculino e feminino.</H5>
                  <H5>10:45h - 12, 13, 14 e 15 anos (Faixa branca e Graduado) masculino e feminino.</H5>
                  <H5>11:30h - 16 e 17 anos (Faixa Branca) masculino e feminino.</H5>
                  <H5>11:30h - 16 e 17 anos (Faixa Branca) masculino e feminino.</H5>
                  <H5>12:00h - Adulto (Faixa Branca) masculino e feminino.</H5>
                  <H5>12:40h - Master (Branca) masculino e feminino.</H5>
                  <H5>12:40h - Adulto (Faixa Azul) masculino e feminino.</H5>
                  <H5>13:40h - Adulto (Faixa Roxa) masculino e feminino.</H5>
                  <H5>13:40h - Master (Faixa Azul) masculino e feminino.</H5>
                  <H5>15:00 - Adulto e Master (Faixa Preta ) Masculino e Feminino</H5>
                  <br />
                  <H5>
                    Todos os atletas devem estar 45.00 minutos antes do tempo de sua
                    categoria na área de aquecimento podendo lutar até 30 min antes do
                    horário previsto no cronograma
                  </H5>
                  <br />
                  <H5>
                    Obs.1: O atleta deverá comparecer com uma (01) hora de antecedência do
                    horário previsto no cronograma.
                  </H5>
                  <H5>
                    Obs.2- Os absolutos serão disputados após o término das suas
                    respectivas categorias, lembrando que deverá ter no mínimo seis
                    atletas inscritos para validar a premiação.
                  </H5>
                  <Span>Usaremos 8 áreas de luta</Span>

                  <Span>
                    O atleta deverá estar presente no ginásio pelo menos 1 hora antes da
                    sua luta.
                  </Span>
                  <Span>
                    O cronograma pode ser adiantado em até 1 hora, e atrasar conforme a
                    necessidade do evento.
                  </Span>
                  <Span>
                    O atleta que não estiver presente no momento da chamada para a pesagem
                    ou luta ser desclassificado por W.O.
                  </Span>
                </Container>
              </Collapse>
              <Collapse title="ENTRADA">
                <P>
                  Será cobrado 1kg de alimento não perecível na entrada para atletas e
                  público.
                </P>
                <br />
                <P>Crianças até 5 anos ficam isentas de qualquer tipo de pagamento.</P>
                <br />
              </Collapse>
            </Collapse>
          </Content>
        </TournamentContent>
        <TournamentDescription>
          <TournamentActions>
            <Content>
              <h1 style={{ textAlign: "center" }}>PATROCINADORES</h1>
              <SponsorContainer>
                <SponsorList>
                  {sponsors &&
                    sponsors.map((buttons: any) => (
                      <SponsorButton
                        img={buttons.img}
                        url={buttons.url}
                        hasError={false}
                        error="Este campo é obrigatório"
                      />
                    ))}
                </SponsorList>
              </SponsorContainer>
              <h1 style={{ textAlign: "center" }}>APOIO</h1>
              <SponsorContainer>
                <SponsorList>
                  {support &&
                    support.map((buttons: any) => (
                      <SponsorButton
                        img={buttons.img}
                        url={buttons.url}
                        hasError={false}
                        error="Este campo é obrigatório"
                      />
                    ))}
                </SponsorList>
              </SponsorContainer>
            </Content>
            <br />
            <Content>
              {authenticated ? (
                <>
                  <TournamentEnrollmentLinkEndpage to="/enrollment/open-guaraniacu-brazilian-jiu-jitsu-2024-ginasio-de-esportes-onofre-garbasheski-guaraniacu-pr">
                    Inscrição GI - ENCERRADA
                  </TournamentEnrollmentLinkEndpage>

                  <TournamentEnrollmentLinkEndpage to="/profile/subscription">
                    Correção da Inscrição - ENCERRADA
                  </TournamentEnrollmentLinkEndpage>
                </>
              ) : (
                <>
                  <TournamentEnrollmentLinkEndpage to="/login">
                    Inscrição GI - ENCERRADA
                  </TournamentEnrollmentLinkEndpage>

                  <TournamentEnrollmentLinkEndpage to="/login">
                    Correção da Inscrição - ENCERRADA
                  </TournamentEnrollmentLinkEndpage>
                </>
              )}
              {/* <TournamentLink href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.767B171E&u=teste" target="_blank">Chaves</TournamentLink> */}
              {/* // /check/16-COPA-PARANA-DE-JIU-JITSU */}

              <TournamentLink
                // href="#"
                // target="_blank"
                // href="/check/open-guaraniacu-brazilian-jiu-jitsu-2024-ginasio-de-esportes-onofre-garbasheski-guaraniacu-pr/search"
                href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.D7A54E37&u=teste"
                target="_blank"
              >
                Checagem/Chaves
              </TournamentLink>

              {/* 
              <TournamentLink
                href="/check/toledo-international-open-brazilian-jiu-jitsu-nogi-2023-ginasio-de-esportes-alcides-pan-toledo-pr/search"
                target="_blank"
              >
                Checagem de atletas NO GI
              </TournamentLink>  */}

              {/*               <TournamentLink
                href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.D7A54E37&u=teste"
                target="_blank"
              >
                Chaves GI
              </TournamentLink> */}

              {/*               <TournamentLink
                href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.5B5B1C9A&u=teste"
                target="_blank"
              >
                Chaves NO GI
              </TournamentLink> */}

              <TournamentLink target="blank" href={tabelaPeso}>
                Tabela de pesos
              </TournamentLink>
              {/* <TournamentLink href="#">Resultados (EM BREVE)</TournamentLink> */}
            </Content>
          </TournamentActions>
        </TournamentDescription>
      </Container>
      <Footer />
    </>
  );
};

export default OpenGuaraniacuJiuJitsu2024;
